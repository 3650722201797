import React, { useEffect, useState } from 'react';
import { ICardInfo } from '../../Community/CardInfo/CardInfo';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { faTrophy, faHeart, faUser } from '@fortawesome/free-solid-svg-icons';

import styles from './GalleryRow.module.scss';
import ActionButton from '../../ActionButton/ActionButton';
import Spinner from '../../Spinner/spinner';
import { fullPathLogo, startDateString } from '../../../helpers/utils';
import axiosHelper from '../../../helpers/axiosHelper';

import DslStore, { setCommunityUser, setCurrentContest, setCurrentDesign, setDesignsILike } from '../../../store/DslStore';
import { useNavigate } from 'react-router-dom';
import { useStoreState } from 'pullstate';
import { getCurrentContest, getCurrentUser } from '../../../store/Selectors';
import { Contest, Design, GalleryDesign, User } from '../../../global';
import DesignPlaceholder from '../../../assets/Design.png';
import { useAuth } from '../../../context/AuthContext';
import { ROUTEPATHS } from '../../../routes/routePaths';

interface IContestRow extends ICardInfo {
  contest:Contest;
  design: GalleryDesign;
  contestTitle?: string;
  creationDate?: string;
  name?: string;
  votes: number;
  galleryImages?: string[];
  image?: string;
  username?: string;
  designName: string;
  idContest?: number;
  idDesign: number;
  private?: boolean;
  hidden: boolean;
  idClient: number;
  endUploadDate: string;
  startDate: string;
}

const ContestRowGallery = (props: { items: string[]; id: number; contest:Contest, private?: boolean }) => {
  const redirect = useNavigate();

  const goToDetails = async () => {
    if (!props.private) {
      const design = await axiosHelper.retrieveCompleteDesign(props.id);
      if (design) {
        console.log(design);
        setCurrentDesign(design);
        redirect(`/${ROUTEPATHS.CONTEST}/${props.contest?.nameForUrl}/gallery/${design.nameForURL}`);
      }
    }
  };

  return (
    <>
      {props.items.map((item, index) => (
        <div className={`${styles.contestRowGalleryItem} ${index % 2 === 0 ? 'bg-light-gray-2' : 'bg-light-gray'}`}>
          {item ? <img src={fullPathLogo(item)} className="cursor-pointer" onClick={() => goToDetails()} alt="Gallery Item Cover" /> : <Spinner size={5} />}
        </div>
      ))}
    </>
  );
};

const GalleryRowWLF: React.FC<IContestRow> = (props) => {
  const redirect = useNavigate();
  const { loggedUser: user, isDesignLiked, toggleDesignLiked } = useAuth();
  const [imagesPath, setImagesPath] = useState<string[]>(['']);

  const fillImagesPath = () => {
    let array: string[] = [];

    props.galleryImages && props.galleryImages.map((item, index) => item !== '' && array.push(item));
    // console.log(array);
    setImagesPath(array);
  };

  const hiddenOptionActive = () => {
    if (!props.private && props.startDate && props.endUploadDate) {
      const dateNumber = new Date().getTime() / 86400000;
      const startDateNumber = new Date(props.startDate).getTime() / 86400000;
      const endDateNumber = new Date(props.endUploadDate).getTime() / 86400000;
      const halfDate = (endDateNumber - startDateNumber) / 2;
      if (dateNumber - startDateNumber < halfDate) {
        return true;
      } else {
        return false;
      }
    }
  };

  const goToDesignDetails = async (idDesign?: number) => {
    console.log(idDesign);
    if (!props.private) {
      if (props.hidden && hiddenOptionActive()) {
        if (user?.id === props.userId) {
          const design = await axiosHelper.retrieveCompleteDesign(idDesign);
          if (design) {
            console.log(design);
            setCurrentDesign(design);
            redirect(`/${ROUTEPATHS.CONTEST}/${props.contest?.nameForUrl}/${ROUTEPATHS.CONTEST_GALLERY}/${design.nameForURL}`);
          }
        }
      } else {
        const design = await axiosHelper.retrieveCompleteDesign(idDesign);
        if (design) {
          console.log(design);
          setCurrentDesign(design);
          redirect(`/${ROUTEPATHS.CONTEST}/${props.contest?.nameForUrl}/${ROUTEPATHS.CONTEST_GALLERY}/${design.nameForURL}`);
        }
      }
    }
  };

  useEffect(() => {
    // console.log(props.galleryImages);
    console.log('entro in GalleryRow');
    console.log(props.galleryImages);

    fillImagesPath();
  }, [props]);


  const goToContestDetails = async (contestId?: number) => {
    if (contestId) {
      const result = await axiosHelper.retrieveCompleteContest(contestId);
      // console.log(result.data.client);
      // console.log(contest);
      // console.log(result);
      setCurrentContest(result.data);

      redirect(`/${ROUTEPATHS.CONTEST}/${result.data.nameForUrl}/${ROUTEPATHS.CONTEST_BRIEF}`);
    }
  };

  const goToDetail = async (userid?: number) => {
    // console.log(userid);
    if (userid) {
      let result: User = await axiosHelper.retrieveUserById(userid);
      // console.log(result);

      setCommunityUser(result);

      setTimeout(() => {
        console.log('This will run after 1 second!');
        redirect(`/${ROUTEPATHS.COMMUNITY}/${result.nameForURL}/Profile`);
      }, 1000);
    }
  };

  return (
    <div className={`${styles.contestRowWrapper} col-12 mb-5 position-relative`}>
      <div className="row bg-light">
        <div className="col-12 col-lg-7 d-flex flex-nowrap px-0">
          {/* Cover Image */}
          <div
            className={`${props.image ? styles.contestRowImage : styles.contestRowImagePlaceholder} bg-light-gray-2 flex-shrink-0 position-relative ${
              (props.hidden && hiddenOptionActive() && (user?.id !== props.userId || user?.id === props.idClient || user?.userType === 'G')) || props.private
                ? ''
                : 'cursor-pointer'
            }`}
          >
            {props.image ? (
              <img
                src={
                  props.hidden && hiddenOptionActive()
                    ? user?.id === props.userId || user?.id === props.idClient || user?.userType === 'G'
                      ? fullPathLogo(props.image)
                      : DesignPlaceholder
                    : fullPathLogo(props.image)
                }
                alt="Contest Cover"
                onClick={() => goToDesignDetails(props.idDesign)}
              />
            ) : // <FontAwesomeIcon icon={faPencil as IconProp} className="color-light" />
            null}
            {!isDesignLiked(props.design) && <ActionButton color={!user ? 'light-gray-3' : ''} icon="addFavorite" position="top-right" action={() => user && toggleDesignLiked(props.design)} />}
            {isDesignLiked(props.design) && <ActionButton icon="confirm" position="top-right" color="success" action={() => toggleDesignLiked(props.design)} />}
            {props.hidden && hiddenOptionActive() && <ActionButton icon="private" position="bottom-right" color="" />}
          </div>
          {/* Project Info */}
          <div className="flex-grow-1">
            <div className="row ps-0 flex-grow-1 h-100">
              {/* Project Title */}
              <div className="col-12  col-lg-5 d-flex flex-wrap  ps-5 mt-4 mt-lg-0" style={{ height: '90px' }}>
                <div className="d-flex align-items-center mt-2" onClick={() => goToDesignDetails(props.idDesign)}>
                  <h3
                    className={`text-xl d-flex fw-bold color-blue ${
                      (props.hidden && hiddenOptionActive() && (user?.id !== props.userId || user?.id === props.idClient || user?.userType === 'G')) ||
                      props.private
                        ? ''
                        : 'cursor-pointer'
                    }`}
                  >
                    {props.hidden && user
                      ? user.id === props.userId || (user.userType === 'C' && user.id === props.idClient) || user.userType === 'G'
                        ? props.designName
                        : 'Hidden by author'
                      : props.hidden && hiddenOptionActive()
                      ? 'Hidden by author'
                      : props.designName}
                    {/* {props.designName} */}
                  </h3>
                </div>
              </div>
              {/* Project Author */}
              <div className="col-6 col-lg-4 d-flex flex-column justify-content-center ps-5">
                <div className="d-flex justify-content-start">
                  <div className="d-flex flex-column justify-content-center">
                    <FontAwesomeIcon icon={faUser as IconProp} className=" text color-blue me-3" />
                  </div>
                  <div className="text-s text-start color-blue cursor-pointer" onClick={() => goToDetail(props.userId)}>
                    {props.username}
                  </div>
                </div>
              </div>
              {/* Project Stats */}
              <div className="col-6 col-lg-3 d-flex flex-column justify-content-center px-2 ">
                <div className="d-flex justify-content-start mb-2 ">
                  <div className="d-flex ms-1 justify-content-center align-items-center">
                    <span>
                      <FontAwesomeIcon icon={faCalendar as IconProp} className=" text color-blue me-3" />
                    </span>
                  </div>
                  <div className="text-s text-start ">Posted: {startDateString(props.creationDate)}</div>
                </div>
                <div className="d-flex justify-content-start mb-2">
                  <div className="d-flex justify-content-center align-items-center ">
                    <span>
                      <FontAwesomeIcon icon={faTrophy as IconProp} className=" text color-blue me-3" />
                    </span>
                  </div>
                  <div className="text-s text-start color-blue cursor-pointer" onClick={() => goToContestDetails(props.idContest)}>
                    {props.contestTitle}
                  </div>
                </div>
                <div className="d-flex justify-content-start mb-2 ">
                  <div className=" ms-1 d-flex justify-content-center align-items-center ">
                    <span>
                      <FontAwesomeIcon icon={faHeart as IconProp} className=" text color-blue me-3" />
                    </span>
                  </div>
                  <div className="text-s text-start ">{props.votes} votes</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Project Gallery */}
        <div className={`${styles.contestRowGallery}  col-12 col-lg-5 d-flex justify-content-center justify-content-lg-end px-0 mt-3 mt-lg-0`}>
          {props.hidden && hiddenOptionActive() ? (
            user?.id === props.userId || user?.id === props.idClient || user?.userType === 'G' ? (
              <ContestRowGallery items={imagesPath} contest={props.contest} private={props.private} id={props.idDesign} />
            ) : (
              ''
            )
          ) : (
            <ContestRowGallery items={imagesPath} contest={props.contest} private={props.private} id={props.idDesign} />
          )}
        </div>
      </div>
    </div>
  );
};

export default GalleryRowWLF;
