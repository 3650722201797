import React, { useEffect, useState } from 'react';
import ActionButton from '../../ActionButton/ActionButton';

import styles from './DesignerSquare.module.scss';
import { User } from '../../../global';
import { useNavigate } from 'react-router-dom';
import DslStore, {
  setCommunityUser,
  setCurrentUser,
} from '../../../store/DslStore';
import axiosHelper from '../../../helpers/axiosHelper';

import { useStoreState } from 'pullstate';
import { getCurrentUser } from '../../../store/Selectors';
import SquareInfo from './SquareInfo/SquareInfo';
import { fullPathLogoCommunity } from '../../../helpers/utils';
import { ROUTEPATHS } from '../../../routes/routePaths';

interface IDesignerSquare {
  user: User;
  liked: boolean;
  children?: React.ReactNode;
}

const DesignerSquare: React.FC<IDesignerSquare> = (props) => {
  const redirect = useNavigate();
  const loggedUser = useStoreState(DslStore, getCurrentUser);
  const [liked, setLiked] = useState(props.liked);

  useEffect(() => {
    console.log('DESIGNER SQUARE USE EFFECT');
    console.log(props.user);
  });

  const goToDetail = async (userId?: number) => {
    if (userId) {
      let result: User = await axiosHelper.retrieveUserById(userId);
      setCommunityUser(result);
      redirect(`/${ROUTEPATHS.COMMUNITY}/${props.user.nameForURL}/Profile`);
    }
  };

  useEffect(() => {
    setLiked(props.liked);
  }, [props.liked]);

  const userLiked = (userId?: number) => {
    if (userId) {
      loggedUser &&
        loggedUser.id !== userId &&
        axiosHelper.likeUser(userId).then(async (response) => {
          setLiked(true);
          await axiosHelper.retrieveUserById(loggedUser.id)?.then((response) => {
            setCurrentUser(response);
          });
          // alert(`${props.user?.username} added to favorites`);
        });
    }
  };

  const userUnliked = (userId?: number) => {
    if (userId) {
      loggedUser &&
        loggedUser.id !== userId &&
        axiosHelper.unlikeUser(userId).then(async (response) => {
          setLiked(false);
          await axiosHelper.retrieveUserById(loggedUser.id)?.then((response) => {
            setCurrentUser(response);
          });
          // alert(`${props.user?.username} removed from favorites`);
        });
    }
  };

  return (
    <div className={`${styles.square}  position relative`}>
      <SquareInfo image={fullPathLogoCommunity(props.user.profilePic)} name={props.user.username} userId={props.user.id}>
        <div className="text-center ">
          {!liked && (
            <ActionButton
              label={loggedUser ? 'Save as favorite' : 'Login to save as favorite'}
              color={!loggedUser ? 'light-gray-3' : ''}
              icon="addFavorite"
              position="top-right"
              action={() => loggedUser && userLiked(props.user?.id)}
            />
          )}
          {liked && (
            <ActionButton
              label="Remove from favorites"
              icon="confirm"
              position="top-right"
              color="success"
              action={() => userUnliked(props.user?.id)}
            />
          )}
          <div className="container bg-light justify-content-center">
            <div className={`${styles.rowTitle} row  `}>
              <div
                className="col  text-center align-self-center  mb-3 "
                style={{ overflow: 'hidden', cursor: 'pointer' }}
                onClick={() => goToDetail(props.user?.id)}
              >
                {/* <Link to={`/${ROUTEPATHS.COMMUNITY}/${props.nameForURL}/Profile`}> */}
                <span className="fw-bold text-16 color-blue d-none d-md-block ">{props.user.username}</span>
                <span className="fw-bold text-16 color-blue d-md-none d-sm-block ">{props.user.username}</span>
                {/* </Link> */}
                
              </div>
            </div>
          </div>
        </div>
      </SquareInfo>
    </div>
  );
};

export default DesignerSquare;
