import React from 'react';
import { GifComponent, GifSectionProps, StrapiImage } from '../../../../global';
import { pathStrapiImage } from '../../../../helpers/utils';
import HeroSection from '../../../HeroSection/HeroSection';
import { useNavigate } from 'react-router-dom';

function GifSectionComponent(props: GifSectionProps) {
  const redirect = useNavigate();

  return (
    <HeroSection
      key={props.id}
      minHeight="70vh"
      alpha={0.6}
      onClick={() =>
        props.link && !props.buttontext && window.open(`${props.link}`, `${props.target ? props.target : '_self'}`)
      }
      bgColor="#304053"
      bgImage={pathStrapiImage(props.image.data.attributes.url)}
      bgVideo={pathStrapiImage(props.image.data.attributes.url)}
    >
      {props.title && <h1 className={`bold-title color-${props.titlecolor}`}>{props.title}</h1>}
      {props.subtitle && <h3 className={`sub-title color-${props.subtitlecolor}`}>{props.subtitle}</h3>}
      {props.buttontext && (
        <a href={props.link} target={`${props.target ? props.target : '_self'}`}>
          <button className={`btn-${props.buttoncolor} rounded mt-4`}>{props.buttontext}</button>
        </a>
      )}
    </HeroSection>
  );
}

export default GifSectionComponent;
