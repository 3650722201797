import { useStoreState } from 'pullstate';
import React, { useEffect, useState } from 'react';
//Header Data
import { useNavigate } from 'react-router-dom';
import headerLogo from '../assets/desall_logo.png';
//Footer Data
import footerImg from '../assets/footer_placeholder_transparent.png';
//Assets
//Components
import AppFooter from '../components/AppFooter/AppFooter';
import AppHeader from '../components/AppHeader/AppHeader';
import PageNavigation from '../components/PageNavigation/PageNavigation';
import StrapiRenderer from '../components/StrapiRenderer';
import { MenuData, StrapiPageData } from '../global';
import axiosHelper from '../helpers/axiosHelper';
import { lastPathElement } from '../helpers/utils';
import DslStore, { setFooterMenuSelected } from '../store/DslStore';
import { getFooterMenu, getFooterMenuSelected, getHeaderMenu, getHiddenMenu } from '../store/Selectors';

const StrapiPage: React.FC = () => {
  const footerMenu = useStoreState(DslStore, getFooterMenu);
  const headerMenu = useStoreState(DslStore, getHeaderMenu);
  const hiddenMenu = useStoreState(DslStore, getHiddenMenu);
  const navigation = useStoreState(DslStore, getFooterMenuSelected);
  const [page, setPage] = useState<StrapiPageData>();
  const [breadcrumbs, setBreadcrumbs] = useState('');
  const [pagePath, setPagePath] = useState('');
  const path = window.location.pathname;
  const redirect = useNavigate();

  useEffect(() => {
    console.log('USE EFFECT STRAPIPAGE');
    console.log(window.location.pathname);
    console.log(window.location.href);
    console.log(lastPathElement(window.location.href));
    console.log(footerMenu);
    console.log(headerMenu);
    console.log(hiddenMenu);
    console.log(navigation);
    fetchPage('/' + lastPathElement(window.location.href));
  }, []);

  // useEffect(() => {
  //   console.log(path);
  //   let firstLevel = '/' + path.split('/')[1];
  //   let secondLevel = '/' + path.split('/')[2];
  //   let thirdLevel = '/' + path.split('/')[3];
  //   let fourthLevel = '/' + path.split('/')[4];

  //   console.log(firstLevel);
  //   console.log(secondLevel);
  //   console.log(thirdLevel);
  //   console.log(fourthLevel);
  //   if (fourthLevel !== '/undefined') {
  //     setPagePath(fourthLevel);
  //   } else if (thirdLevel !== '/undefined') {
  //     setPagePath(fourthLevel);
  //   } else if (secondLevel !== '/undefined') {
  //     setPagePath(secondLevel);
  //   } else {
  //     setPagePath(firstLevel);
  //   }
  // }, [path]);

  useEffect(() => {
    console.log(path);
    let pathLength = path.split('/').length - 1;
    let first = path.split('/')[1].replace(/_|-/gi, ' ');

    if (pathLength === 2) {
      setBreadcrumbs(first);
      let ciccio: MenuData[] = footerMenu.filter((menu) => menu.title === first);
      if (ciccio.length === 0) {
        ciccio = hiddenMenu.filter((menu) => menu.path === '/' + first.replace(/ /g, '_'));
        // console.log('ciccio hidden' + JSON.stringify(ciccio));
        setFooterMenuSelected(ciccio[0]);
      } else {
        setFooterMenuSelected(ciccio[0]);
      }
    } else if (pathLength === 3) {
      let second = path.split('/')[2].replace(/_|-/gi, ' ');
      console.log(second);
      let ciccio: MenuData[] = footerMenu.filter((menu) => menu.title === second);
      if (ciccio.length === 0) {
        ciccio = hiddenMenu.filter((menu) => menu.path === '/' + second.replace(/ /g, '_'));
        // console.log('ciccio hidden' + JSON.stringify(ciccio));
        setFooterMenuSelected(ciccio[0]);
      } else {
        setFooterMenuSelected(ciccio[0]);
      }
      setBreadcrumbs(first + '/' + second);
    } else if (pathLength === 4) {
      let second = path.split('/')[2].replace(/_|-/gi, ' ');
      let third = path.split('/')[3].replace(/_|-/gi, ' ');
      console.log(third);
      let ciccio: MenuData[] = footerMenu.filter((menu) => menu.title === third);
      if (ciccio.length === 0) {
        ciccio = hiddenMenu.filter((menu) => menu.path === '/' + third.replace(/ /g, '_'));
        // console.log('ciccio hidden' + JSON.stringify(ciccio));
        setFooterMenuSelected(ciccio[0]);
      } else {
        setFooterMenuSelected(ciccio[0]);
      }
      setBreadcrumbs(first + '/' + second + '/' + third);
    }
  }, [path]);

  const fetchPage = async (pagePath: string) => {
    console.log('FETCHING ' + pagePath);
    const response = await axiosHelper.fetchStrapiPage(pagePath);
    if (response) {
      console.log(response);
      setPage(response.data[0]);
    }
    // ?.then((response) => {
    //   // console.log(response.data);
    //   if (response.data.length > 0) {
    //     // console.log(response.data[0])
    //     setPage(response.data[0]);
    //     setStrapiPage(response.data[0]);
    //   } else {
    //     redirect('/PageNotFound');
    //   }
    // });
  };

  // useEffect(() => {
  //   console.log(pagePath);
  //   fetchPage();
  // }, [pagePath]);
  if (page)
    return (
      <>
        <AppHeader
          links={headerMenu}
          bgColor={page?.attributes.headercolor ? page?.attributes.headercolor : '#7997c5'}
          logoImg={headerLogo}
          pageTitle={page?.attributes.footertitle}
          breadcrumbs={breadcrumbs}
        />
        {navigation && navigation.data.length > 1 && <PageNavigation bgColor="#E3E3E3" links={navigation.data} />}

        <StrapiRenderer page={page} />

        <AppFooter links={footerMenu} bgColor="#304053" logoImg={headerLogo} bgImage={footerImg} />
      </>
    );
  else {
    return null;
  }
};

export default StrapiPage;
