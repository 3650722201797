import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './PopUpLogin.module.scss';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCircleExclamation, faXmark } from '@fortawesome/free-solid-svg-icons';
import { Modal } from 'react-bootstrap';
import { faFacebook, faGoogle, faLinkedin, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import {
  IResolveParams,
  LoginSocialFacebook,
  LoginSocialGoogle,
  LoginSocialLinkedin,
  LoginSocialTwitter,
} from 'reactjs-social-login';
import { useAuth } from '../../../context/AuthContext';

interface PopUpLoginProps {
  visible: boolean;
  callback: (success: boolean) => void;
}

const PopUpLoginWLF: React.FC<PopUpLoginProps> = (props) => {
  const { login, fbLogin, ggLogin, twLogin, lkLogin } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    if (props.visible) {
      setEmail('');
      setPassword('');
      setRememberMe(true);
      setError('');
    }
  }, [props.visible]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!email || !password) {
      setError('Username (or email) and password are required');
      return;
    }
    setError('');
    login(email, password, rememberMe)
      .then((data) => {
        if (data) {
          props.callback(true);
        } else {
          setError('Unknown error');
        }
      })
      .catch((e) => {
        console.log(e);
        setError('Wrong username or password');
      });
  };

  const facebookLogin = async (token: string, uid: string, email: string) => {
    setError('');
    fbLogin(email, token, uid)
      .then((data) => {
        if (data) {
          props.callback(true);
        } else {
          setError('Unknown error');
        }
      })
      .catch((e) => {
        console.log(e);
        setError('An error occurred');
      });
  };

  const linkedinLogin = async (token: string, uid: string) => {
    setError('');
    lkLogin(token, uid)
      .then((data) => {
        if (data) {
          props.callback(true);
        } else {
          setError('Unknown error');
        }
      })
      .catch((e) => {
        console.log(e);
        setError('An error occurred');
      });
  };

  const twitterLogin = async (token: string, uid: string) => {
    setError('');
    twLogin(token, uid)
      .then((data) => {
        if (data) {
          props.callback(true);
        } else {
          setError('Unknown error');
        }
      })
      .catch((e) => {
        console.log(e);
        setError('An error occurred');
      });
  };

  const googleLogin = async (token: string, uid: string, email: string) => {
    ggLogin(email, token, uid)
      .then((data) => {
        if (data) {
          props.callback(true);
        } else {
          setError('Unknown error');
        }
      })
      .catch((e) => {
        console.log(e);
        setError('An error occurred');
      });
  };

  return (
    <>
      <Modal
        show={props.visible}
        onHide={() => console.log('MODAL CLOSE')}
        className={`d-flex flex-column justify-content-center `}
        contentClassName={` ${styles.rounded}`}
      >
        <div className={`d-flex flex-column justify-content-center text-center  full bg-light-gray ${styles.rounded}`}>
          <Modal.Body className="d-flex justify-content-center">
            <div className="row justify-content-center align-items-center">
              <div className="col-12">
                <div className="d-flex justify-content-end w-100">
                  <div
                    className={` ${styles.closeButton}`}
                    onClick={() => {
                      props.callback(false);
                    }}
                  >
                    <FontAwesomeIcon icon={faXmark as IconProp} className=" text color-light " />
                  </div>
                </div>
              </div>

              <div className="col-12 mb-4">
                <span className="bigger-text-bold ">Please login</span>
              </div>
              <div className="col-12 mb-6">
                <span className="text ">
                  Not registered yet?{' '}
                  <a href="/signup" target="_self" className="me-1">
                    signup
                  </a>
                  now!
                </span>
              </div>
              <div className="d-flex flex-row align-items-center justify-content-center mb-4">
                <div className="col-5 pe-3">
                  <LoginSocialGoogle
                    client_id={'571721759610-a952df14qdtva6cbusfsv46sqqg5aja1.apps.googleusercontent.com'}
                    redirect_uri={'/'}
                    onReject={() => console.log('error')}
                    onResolve={({ provider, data }: IResolveParams) => {
                      console.log(data);
                      googleLogin(data?.access_token, data?.sub, data?.email);
                    }}
                  >
                    <button className="btn-social__google full rounded ">
                      <div className="btn-icon ">
                        <FontAwesomeIcon icon={faGoogle as IconProp} className="text color-light" />
                      </div>
                      google
                    </button>
                  </LoginSocialGoogle>
                </div>
                <div className="col-5 ps-3">
                  <LoginSocialFacebook
                    appId={process.env.REACT_APP_FB_APP_ID || ''}
                    onResolve={({ provider, data }: IResolveParams) => {
                      console.log(data);
                      console.log(data?.accessToken);
                      facebookLogin(data?.accessToken, data?.userID, data?.email);
                    }}
                    onReject={(err: any) => {
                      console.log(err);
                    }}
                  >
                    <button className="btn-social__facebook full rounded">
                      <div className="btn-icon">
                        <FontAwesomeIcon icon={faFacebook as IconProp} className="text color-light" />
                      </div>
                      facebook
                    </button>
                  </LoginSocialFacebook>
                </div>
              </div>
              <div className="d-flex flex-row align-items-center justify-content-center mb-4">
                <div className="col-5  pe-3">
                  <LoginSocialTwitter
                    client_id="MWNPQmc5NHRQdE5sZ1RTczRyLTQ6MTpjaQ"
                    redirect_uri={process.env.REACT_APP_SOCIAL_LOGIN_ENDPOINT || ''}
                    onReject={(error) => console.log(error)}
                    onResolve={({ provider, data }: IResolveParams) => {
                      console.log(provider);
                      console.log(data);
                      console.log(data?.access_token);
                      console.log(data?.id);
                      twitterLogin(data?.access_token, data?.id);
                    }}
                    onLoginStart={() => console.log('start twitter login')}
                  >
                    <button className="btn-social__twitter full rounded">
                      <div className="btn-icon">
                        <FontAwesomeIcon icon={faXTwitter as IconProp} className="text color-light" />
                      </div>
                      x-twitter
                    </button>
                  </LoginSocialTwitter>
                </div>
                <div className="col-5  ps-3">
                  <LoginSocialLinkedin
                    client_id="78ic7x2w9maoqp"
                    redirect_uri={process.env.REACT_APP_SOCIAL_LOGIN_ENDPOINT || ''}
                    scope="profile email"
                    client_secret="ioKQM8Z2vJzcnj7H"
                    onLoginStart={() => console.log('start login')}
                    onReject={(error: any) => {
                      console.log(error);
                    }}
                    onResolve={({ provider, data }: IResolveParams) => {
                      // setProvider(provider);
                      // setProfile(data);
                      console.log(data);
                      console.log(data?.accessToken);
                      linkedinLogin(data?.access_token, data?.id_token);
                    }}
                  >
                    <button className="btn-social__linkedin full rounded ">
                      <div className="btn-icon">
                        <FontAwesomeIcon icon={faLinkedin as IconProp} className="text color-light" />
                      </div>
                      linkedin
                    </button>
                  </LoginSocialLinkedin>
                </div>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="d-flex justify-content-center">
                  <div className="col-10 mb-4 ">
                    <input
                      id="email"
                      type="text"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="username or e-mail"
                      className=" text-center "
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-center">
                  <div className="col-10">
                    <input
                      id="password"
                      type="password"
                      placeholder="password"
                      className=" text-center "
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                </div>
                <div className="d-flex flex-row align-items-center justify-content-center mb-4 color-dark_red">
                  {error ? (
                    <>
                      <FontAwesomeIcon icon={faCircleExclamation as IconProp} className="me-2" /> {error}
                    </>
                  ) : (
                    <>&nbsp;</>
                  )}
                </div>

                <div className="d-flex justify-content-center">
                  <div className="col-10 d-flex justify-content-start">
                    <input
                      type="checkbox"
                      id="checkbox-1"
                      checked={rememberMe}
                      onChange={(e) => setRememberMe(e.target.checked)}
                      className=""
                    />
                    <label htmlFor="checkbox-1" className=" text-start text">
                      Remember me
                    </label>
                  </div>
                </div>

                <div className="row m-4">
                  <div className="col-12  d-flex justify-content-center ">
                    <button className="btn-dark-blue rounded submit">Login</button>
                  </div>
                </div>
              </form>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default PopUpLoginWLF;
