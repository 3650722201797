import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

interface Props {
  summary: string;
  textDescription: string;
}
const BriefPiece: React.FC<Props> = (props) => {
  return (
    <div className="row mb-5">
      <div className="col-12 sub-title fw-bold mb-3">{props.summary}</div>
      <div className="col-12">
        <ReactMarkdown
          className="text-16"
          children={props.textDescription}
          remarkPlugins={[remarkGfm]}
        />
      </div>
    </div>
  );
};

export default BriefPiece;
