import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from './AppHeader.module.scss';
import { hexToRGB } from '../../helpers/utils';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faClose, faBars, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import UserPic from '../Community/UserPic/UserPic';
import { MenuData, SearchForm } from '../../global';
import DslStore, {
  setMobileMode,
  setSearchResultContest,
  setSearchResultContestCopy,
  setSearchResultContestFiltered,
  setSearchResultDesign,
  setSearchResultDesignCopy,
  setSearchResultUser,
  setSearchResultUserCopy,
} from '../../store/DslStore';
import {
  getCurrentUser,
  getFooterMenu,
  getHeaderMenu,
  getHiddenMenu,
  getStrapiPaths,
} from '../../store/Selectors';
import axiosHelper from '../../helpers/axiosHelper';
import { useForm } from 'react-hook-form';
import useWindowDimensions from '../../helpers/useWindowDimensions';
import { useAuth } from '../../context/AuthContext';
import { ROUTEPATHS } from '../../routes/routePaths';

interface IHeader {
  links: MenuData[];
  bgColor: string;
  bgImage?: string;
  alpha?: number;
  logoImg?: string;
  pageTitle?: string;
  breadcrumbs?: string;
  transparent?: boolean;
  hideSearch?: boolean;
}

const TopNav = ({ links }: { links: MenuData[] }) => {
  return (
    <ul className={`${styles.headerTopNav}  d-block d-lg-flex justify-content-start pt-5 mt-2 pt-lg-0 mt-lg-2 mb-0`}>
      {links.map((navLink, index) => (
        <li key={'topnavlink_' + index} className="mb-5 mb-lg-0">
          <Link className="text-16 color-light font-heading d-block d-lg-flex" to={navLink.path}>
            {navLink.title}
          </Link>
        </li>
      ))}
      <li className="mb-5 mb-lg-0"></li>
    </ul>
  );
};

interface BreadcrumbsProps {
  title: string;
}
interface BreadcrumbProps {
  item: string;
  index: number;
}

const BreadCrumbs = (props: BreadcrumbsProps) => {
  const redirect = useNavigate();
  // const hiddenMenus = useStoreState(DslStore, getHiddenMenu);
  // const footerMenus = useStoreState(DslStore, getFooterMenu);
  // const currentUser = useStoreState(DslStore, getCurrentUser);
  const verify = useRef(false);
  const [key, setKey] = useState(0);

  const array = useRef<string[]>([]);
  const arrayTitle = useRef<string[]>([]);
  const currentPath = window.location.pathname;

  useEffect(() => {
    // console.log(props.title);
    // console.log(currentPath);
    if (props.title) {
      array.current = props.title.split('/');
      arrayTitle.current = currentPath.split('/');
      // console.log(arrayTitle.current);
    }
  }, [currentPath, props.title]);

  const goToPage = (breadcrumbIndex: number) => {
    let string = '';
    console.log(arrayTitle.current[0]);
    console.log(breadcrumbIndex);
    if (breadcrumbIndex === 0 && arrayTitle.current[0] === '') {
      console.log('ciao');
      redirect(`/`);
    }
    arrayTitle.current.map(
      (item: string, index: number) => (
        index > 0 && index <= breadcrumbIndex + 1 && (string += '/' + item),
        //se stringa contiene contest aggiungo /brief alla fine, se stringa contiene community aggiungo profile
        // index === breadcrumbIndex && (string += item),
        console.log(string)
      )
    );
    // console.log(string.includes('Contest/')),
    const includeContest: string[] = string.split('/');
    if (includeContest.length > 1) {
      if (string.includes('contest/') && includeContest.length < 4) {
        string += '/brief';
        redirect(string);
      }
      // console.log(string)
      redirect(string);
    }
    const includeCommunity: string[] = string.split('/');
    // if (includeCommunity.length > 1) {
    //   if (string.includes('community/') && includeCommunity.length < 4) {
    //     {
    //       currentUser && string.includes(`${currentUser.username}`)
    //         ? (string += '/profile-editable')
    //         : (string += '/Profile');
    //     }
    //     console.log(string);
    //     redirect(string);
    //   }
    //   console.log(string);
    //   redirect(string);
    // }

    // if (includeContest || includeCommunity) {
    //   redirect(string);
    // }
  };

  const verifyPath = (stringa: string, index: number) => {
    console.log(verify.current);
    // footerMenus.map((item: MenuData) => {
    //   if (item.title === stringa) {
    //     verify.current = true;
    //   }
    // });
    // hiddenMenus.map((item: MenuData) => {
    //   if (item.title === stringa) {
    //     verify.current = true;
    //   }
    // });

    console.log(verify.current);
    if (verify.current === false) {
      goToPage(index);
    } else {
      // console.log('TROVATA XDVR');
      verify.current = false;
    }
  };

  const cursorDetector = (stringa: string) => {
    let trovata: boolean = false;
    // footerMenus.map((item: MenuData) => {
    //   if (item.title === stringa) {
    //     trovata = true;
    //   }
    // });
    // hiddenMenus.map((item: MenuData) => {
    //   if (item.title === stringa) {
    //     trovata = true;
    //   }
    // });
    return trovata;
  };
  const BreadCrumb = (props: BreadcrumbProps) => {
    return (
      <>
        {props.item === 'Home' ? (
          <Link className="text-16 fw-normal color-light font-heading px-1" to={'/'}>
            Home/
          </Link>
        ) : (
          <span
            className="text-16 color-light font-heading  px-1"
            style={{ cursor: cursorDetector(props.item) ? 'auto' : 'pointer' }}
            onClick={() => verifyPath(props.item, props.index)}
          >
            {props.item + '/'}
          </span>
        )}
      </>
    );
  };
  useEffect(() => {
    setKey(key + 1);
  }, []);
  return (
    <div key={key} className={`col-12 text-16 color-light fw-bold " ${styles.headerBreadCrumbs}`}>
      {/* <Link className="text color-light font-heading px-1" to={'/'}> */}
      {/* <span className="text-16 color-light font-heading  px-1">{props.title + '/'}</span> */}
      {array.current.map((item: string, index: number) => (
        <BreadCrumb key={index} item={item} index={index} />
      ))}
    </div>
  );
};

const ToggleButton = (props: { opened: boolean; onToggle: () => void }) => {
  return (
    <button className={`${styles.openCloseButton}`} onClick={() => props.onToggle()}>
      {props.opened ? (
        <FontAwesomeIcon icon={faClose as IconProp} className="bigger-text color-light" />
      ) : (
        <FontAwesomeIcon icon={faBars as IconProp} className="bigger-text color-light" />
      )}
    </button>
  );
};

interface IUserArea {
  onSignUp: () => void;
  onSignIn: () => void;
}

const UserArea: React.FC<IUserArea> = (props) => {
  const { loggedUser: user, logout } = useAuth();

  const logoutFunction = () => {
    logout();
    // axiosHelper.logout();
  };

  return user ? (
    <div className="col-12 col-md-3 d-flex flex-column justify-content-start  justify-content-md-center pt-5 pt-md-0">
      <div
        className={`${styles.profileWrapper} d-flex flex-column flex-md-row justify-content-end align-items-center align-items-md-start position-relative `}
      >
        <div className="d-flex justify-content-end align-items-center position-relative ">
          <div className="text fw-bold color-light pe-3 d-none d-lg-block d-xl-block">
            Welcome {user?.username}!
          </div>
          <div className={`${styles.profilePic} position-relative  `}>
            <UserPic image={user?.logoAttachment ? user?.logoAttachment.s3Path : ''} />
          </div>
        </div>

        <div className={`${styles.profileActions} pt-3 pt-md-0 ps-0 ps-md-4 px-lg-5`}>
          <div className={`${styles.profileName} text-xxl fw-bold pb-3`}>
            {/* {currentUser?.firstName + ' ' + (currentUser?.lastName ? currentUser.lastName : ' ')} */}
            {user?.username}
          </div>
          <div className={`${styles.profileMail} text mb-5 mb-md-4`}>{user?.email}</div>
          <div className="d-flex justify-content-sm-center justify-content-md-start flex-md-column">
            <div className="pb-3 mx-5 mx-md-0 col-md-12">
              <Link
                to={`/${ROUTEPATHS.COMMUNITY}/${user?.nameForURL}/profile-editable`}
                className={`${styles.profileLink} text d-block`}
              >
                Profile
              </Link>
            </div>
            <div className="pb-3 mx-5 mx-md-0 col-md-12">
              <Link
                to={'/'}
                onClick={() => {
                  logoutFunction();
                }}
                className={`${styles.profileLink} text d-block`}
              >
                Logout
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="col-12 col-md-3 d-flex flex-column justify-content-center ">
      <div className="d-flex justify-content-center  justify-content-md-end my-5 my-lg-0">
        <button className="btn-dark rounded me-5" onClick={() => props.onSignUp()}>
          Signup
        </button>
        <button className="btn-dark rounded" onClick={() => props.onSignIn()}>
          Login
        </button>
      </div>
    </div>
  );
};

//TODO add breadcrumbs (https://www.npmjs.com/package/use-react-router-breadcrumbs)
const AppHeaderWLF: React.FC<IHeader> = (props) => {
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const [opened, setOpened] = useState<boolean>(false);
  const { height, width } = useWindowDimensions();

  let navigate = useNavigate();
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<SearchForm>();

  const redirect = useNavigate();

  const handleScroll = () => {
    let position = window.scrollY;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (width < 980) {
      setMobileMode(true);
      // setLayoutContest(1);
    } else {
      setMobileMode(false);
    }
  }, [width]);

  const onSubmit = async (data: SearchForm) => {
    //TODO CONTEST, USERS, DESIGNS

    await axiosHelper
      .searchContest(data)
      .then((response) => {
        setSearchResultContest(response);
        setSearchResultContestFiltered(response);
        setSearchResultContestCopy(response);
      })
      .catch((error) => {
        console.log(error);
      });
    await axiosHelper
      .searchUser(data)
      .then((response) => {
        setSearchResultUser(response);
        setSearchResultUserCopy(response);
      })
      .catch((error) => {
        console.log(error);
      });
    await axiosHelper
      .searchDesign(data)
      .then((response) => {
        setSearchResultDesign(response);
        setSearchResultDesignCopy(response);
      })
      .catch((error) => {
        console.log(error);
      });

    redirect('/Search/Community');
  };

  let c = hexToRGB(props.bgColor);

  const handleOpenCloseHeader = () => {
    console.log(opened);
    setOpened(!opened);
  };

  const handleSignUp = () => {
    let path = `/signup`;
    navigate(path);
    // alert('Signup');
  };

  const handleSignIn = () => {
    let path = `/login`;
    // alert('Signin');
    navigate(path);
  };

  return (
    <div
      className={`${styles.headerWrapper}  container-fluid px-0`}
      style={{
        backgroundColor: `${props.transparent ? 'transparent' : props.bgColor}`,
        position: `${props.transparent ? 'absolute' : 'relative'}`,
      }}
    >
      {/* Top Row ============================================================================== */}
      <div
        className={`container-fluid  ${styles.headerTopRowContainer} pt-3 pb-3 ${opened ? styles.opened : ''}`}
        // style={{ backgroundColor:  'transparent' }}
        style={{ backgroundColor: scrollPosition > 50 || opened ? props.bgColor : 'transparent' }}
      >
        <div className={`container-md d-flex flex-column justify-content-center ${styles.headerContainer}`}>
          <div className={`row ${styles.headerTopRow}  pt-3 pb-3 ${opened ? styles.opened : ''}`}>
            <div className={`col-10  col-lg-2 ${styles.headerLogo}`}>
              <img src={props.logoImg} alt="Desall" onClick={() => navigate('/')} className="cursor-pointer ps-1" />
            </div>
            <div className="col-2 d-flex d-lg-none justify-content-end">
              <ToggleButton opened={opened} onToggle={handleOpenCloseHeader} />
            </div>
            <div className="col-12 col-lg-10">
              <div className="row d-flex align-items-center justify-content-between  h-100">
                <div className={`${styles.wolfWrapper} col-12 col-md-7 d-flex flex-column justify-content-center`}>
                  <TopNav links={props.links} />
                </div>
                <UserArea onSignIn={handleSignIn} onSignUp={handleSignUp} />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Bottom Row ============================================================================== */}

      {(props.pageTitle || props.breadcrumbs) && (
        <div
          className={`${styles.headerContentWrapper}  container-md pb-7`}
          style={{
            backgroundImage: `
          linear-gradient(rgba(${c[0]},${c[1]},${c[2]},${props.alpha ?? 0.8}), 
          rgba(${c[0]},${c[1]},${c[2]},${props.alpha ?? 0.8})), 
          url(${props.bgImage})
          `,
          }}
        >
          {/* Bottom Row */}
          {props.breadcrumbs && (
            <div className="row  mt-5 ">{props.breadcrumbs && <BreadCrumbs title={props.breadcrumbs} />} </div>
          )}
          {props.pageTitle && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row  ">
                <div className="col-12 col-md-6  light-title color-light">{props.pageTitle}</div>
                <div className="col-12 col-md-6  d-flex flex-row-reverse align-items-center light-title color-light">
                  {!props.hideSearch &&
                  <div className="col-6 d-flex flex-row-reverse">
                    <input
                      type="text "
                      className="text fw-bold text-uppercase"
                      placeholder="search"
                      {...register('search')}
                    />
                    <button className="btn-reset-blue rounded d-flex flex-row align-items-center justify-content-center position-absolute">
                      <FontAwesomeIcon icon={faMagnifyingGlass as IconProp} className=" text color-light " />
                    </button>
                  </div>}
                </div>
              </div>
            </form>
          )}
        </div>
      )}
    </div>
  );
};

export default AppHeaderWLF;
