import { useForm } from 'react-hook-form';
import { UpdateNewsletterAndNotification, User } from '../../../global';
import { useEffect, useState } from 'react';

import styles from './Newsletter&Notification.module.scss';
import { useStoreState } from 'pullstate';
import DslStore, { setCurrentUser } from '../../../store/DslStore';
import { getCurrentUser } from '../../../store/Selectors';
import axiosHelper from '../../../helpers/axiosHelper';
import { response } from 'express';
interface NewsletterAndNotificationsProps {
  user?: User;
  upload: (n: number) => void;
  open: (open: number) => void;
  isOpen: boolean;
}

const NewsletterAndNotifications: React.FC<NewsletterAndNotificationsProps> = (props) => {
  const currentUser = useStoreState(DslStore, getCurrentUser);
  const { register, handleSubmit, getValues } = useForm<UpdateNewsletterAndNotification>();
  const [notificationsPopUp, setNotificationsPopUp] = useState(props.isOpen);
  const [key, setKey] = useState(1);
  const [receiveNewsletter, setReceiveNewsletter] = useState(currentUser?.receiveNewsletter);
  const [privateMessagesNotification, setPrivateMessagesNotification] = useState(
    currentUser?.hasPrivateMessagesNotification
  );
  const [badgeUpdatesNotification, setBadgeUpdatesNotification] = useState(currentUser?.hasBadgeNotification);
  const [newContestOnDesallNotification, setNewContestOnDesallNotification] = useState(
    currentUser?.hasNewContestOnDesallNotification
  );
  const [contestStatusChangesNotification, setContestStatusChangesNotification] = useState(
    currentUser?.hasContestStatusChangesNotification
  );
  const [newCommentForMyDesignNotification, setNewCommentForMyDesignNotification] = useState(
    currentUser?.hasNewCommentForMyDesignNotification
  );
  const [repliesOnMyCommentsNotification, setRepliesOnMyCommentsNotification] = useState(
    currentUser?.hasRepliesOnMyCommentsNotification
  );
  const [somebodyFavoriteMyDesignNotification, setSomebodyFavoriteMyDesignNotification] = useState(
    currentUser?.hasSomebodyFavoriteMyDesignNotification
  );
  const [meAsFavouriteNotification, setMeAsFavouriteNotification] = useState(currentUser?.hasMeAsFavouriteNotification);
  const [newDesignFromFavoriteNotification, setNewDesignFromFavoriteNotification] = useState(
    currentUser?.hasNewDesignFromFavoriteNotification
  );
  const [newEntryForFavoriteNotification, setNewEntryForFavoriteNotification] = useState(
    currentUser?.hasNewEntryForFavoriteNotification
  );

  const onSubmit = async (data: UpdateNewsletterAndNotification) => {
    //TODO
    fillData(data);
    console.log(data);
    if (data && currentUser?.id) {
      await axiosHelper
        .updateUserNotifications(data, currentUser.id)
        .then((response) => {
          console.log(response);
          props.upload(1);
          window.scrollTo(0, 0);
          setCurrentUser(response);
        })
        .catch((error) => {
          props.upload(2);
        });
    }
  };

  useEffect(() => {
    // console.log(props.isOpen);
    // if (notificationsPopUp) {
    //   props.open(true);
    // } else {
    //   setNotificationsPopUp(false);
    //   props.open(false);
    // }
    setNotificationsPopUp(props.isOpen);
  }, [props.isOpen]);

  const fillData = (data: UpdateNewsletterAndNotification) => {
    //TODO
    data.receiveNewsletter = receiveNewsletter;
    data.hasPrivateMessagesNotification = privateMessagesNotification;
    data.hasBadgeNotification = badgeUpdatesNotification;
    data.hasNewContestOnDesallNotification = newContestOnDesallNotification;
    data.hasContestStatusChangesNotification = contestStatusChangesNotification;
    data.hasNewCommentForMyDesignNotification = newCommentForMyDesignNotification;
    data.hasRepliesOnMyCommentsNotification = repliesOnMyCommentsNotification;
    data.hasSomebodyFavoriteMyDesignNotification = somebodyFavoriteMyDesignNotification;
    data.hasMeAsFavouriteNotification = meAsFavouriteNotification;
    data.hasNewDesignForMyContestNotification = newDesignFromFavoriteNotification;
    data.hasNewEntryForFavoriteNotification = newEntryForFavoriteNotification;
    localStorage.setItem('editProfileNewsetterAndNotificationsData', JSON.stringify(data));
  };
  return (
    <>
      <div
        key={key}
        className="col-12   bg-blue mb-5 align-items-center justify-content-center  d-flex"
        style={{ height: '30px', borderRadius: '8px', cursor: 'pointer' }}
        onClick={() => (
          setNotificationsPopUp(!notificationsPopUp), props.open(notificationsPopUp ? 0 : 3), setKey(key + 1)
        )}
      >
        <div className="col-2"></div>
        <div className="col-8 justify-content-center d-flex ">
          <span className="text-xxl fw-bold color-light" style={{ fontSize: '2rem' }}>
            Newsletter and notifications
          </span>
        </div>

        <div
          className={`col-2 d-flex justify-content-end align-items-center ${styles.toggle} ${
            notificationsPopUp && styles.open
          }`}
          onClick={() => setNotificationsPopUp(!notificationsPopUp)}
        >
          <div className={`${styles.arrow} `}> </div>
        </div>
      </div>
      {notificationsPopUp && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="px-5 mt-7">
            <div className="row mb-7 d-flex  ">
              <div className="col-12 col-md-2  mb-3  ">
                <span className="text fw-bold">Newsletter</span>
              </div>

              <div className="col-12 col-md-10 d-flex flex-row justify-content-start align-items-center ">
                <input
                  type="checkbox"
                  checked={receiveNewsletter}
                  onClick={() => setReceiveNewsletter(!receiveNewsletter)}
                  className="flex-shrink-none"
                  {...register('receiveNewsletter')}
                />
                <span className="align-self-middle ps-5 ">Receive desall newsletter</span>
              </div>
            </div>
            <div className="row mb-7">
              <div className="col-12 col-md-2 mb-3 text fw-bold">General</div>

              <div className="col-12 mb-5 col-md-10 d-flex flex-row justify-content-start align-items-center ">
                <input
                  type="checkbox"
                  checked={privateMessagesNotification}
                  onClick={() => setPrivateMessagesNotification(!privateMessagesNotification)}
                  className="flex-shrink-none"
                  {...register('hasPrivateMessagesNotification')}
                />
                <span className="align-self-middle ps-5 ">Send and receive private messages</span>
              </div>
              <div className="col-12 col-md-2"></div>
              <div className="col-12 col-md-10 d-flex flex-row justify-content-start align-items-center ">
                <input
                  type="checkbox"
                  checked={badgeUpdatesNotification}
                  onClick={() => setBadgeUpdatesNotification(!badgeUpdatesNotification)}
                  className="flex-shrink-none"
                  {...register('hasBadgeNotification')}
                />
                <span className="align-self-middle ps-5 ">Badge updates</span>
              </div>
            </div>
            <div className="row mb-7">
              <div className="col-12 col-md-2 mb-3 text fw-bold">Contest</div>
              <div className="col-12 col-md-10 mb-5 d-flex flex-row justify-content-start align-items-center ">
                <input
                  type="checkbox"
                  checked={newContestOnDesallNotification}
                  onClick={() => setNewContestOnDesallNotification(!newContestOnDesallNotification)}
                  className="flex-shrink-none"
                  {...register('hasNewContestOnDesallNotification')}
                />
                <span className="align-self-middle ps-5 ">New contest on desall.com</span>
              </div>
              <div className="col-12 col-md-2"></div>
              <div className="col-12 col-md-10  mb-3 d-flex flex-row justify-content-start align-items-center w-75 ">
                <input
                  type="checkbox"
                  checked={contestStatusChangesNotification}
                  onClick={() => setContestStatusChangesNotification(!contestStatusChangesNotification)}
                  className="flex-shrink-none"
                  {...register('hasContestStatusChangesNotification')}
                />
                <span className="align-self-middle ps-5 ">
                  My favourite contests brief revisions and contests status changes <br /> (upload phase, vote phase,
                  winner announcement)
                </span>
              </div>
            </div>
            <div className="row mb-7">
              <div className="col-12 col-md-2 mb-3 text fw-bold">Design</div>
              <div className="col-12 col-md-10 mb-5 d-flex flex-row justify-content-start align-items-center ">
                <input
                  type="checkbox"
                  checked={newCommentForMyDesignNotification}
                  onClick={() => setNewCommentForMyDesignNotification(!newCommentForMyDesignNotification)}
                  className="flex-shrink-none"
                  {...register('hasNewCommentForMyDesignNotification')}
                />
                <span className="align-self-middle ps-5 ">New comments on my designs</span>
              </div>
              <div className="col-12 col-md-2"></div>
              <div className="col-12 col-md-10 mb-5 d-flex flex-row justify-content-start align-items-center ">
                <input
                  type="checkbox"
                  checked={repliesOnMyCommentsNotification}
                  onClick={() => setRepliesOnMyCommentsNotification(!repliesOnMyCommentsNotification)}
                  className="flex-shrink-none"
                  {...register('hasRepliesOnMyCommentsNotification')}
                />
                <span className="align-self-middle ps-5 ">Replies on my comments</span>
              </div>
              <div className="col-12 col-md-2"></div>
              <div className="col-12 col-md-10 mb-5 d-flex flex-row justify-content-start align-items-center ">
                <input
                  type="checkbox"
                  checked={somebodyFavoriteMyDesignNotification}
                  onClick={() => setSomebodyFavoriteMyDesignNotification(!somebodyFavoriteMyDesignNotification)}
                  className="flex-shrink-none"
                  {...register('hasSomebodyFavoriteMyDesignNotification')}
                />
                <span className="align-self-middle ps-5 ">Somebody favourite my designs</span>
              </div>
            </div>
            <div className="row mb-7">
              <div className="col-12 col-md-2 mb-3 text fw-bold">Favourite</div>
              <div className="col-12 col-md-10 mb-5 d-flex flex-row justify-content-start align-items-center ">
                <input
                  type="checkbox"
                  checked={meAsFavouriteNotification}
                  onClick={() => setMeAsFavouriteNotification(!meAsFavouriteNotification)}
                  className="flex-shrink-none"
                  {...register('hasMeAsFavouriteNotification')}
                />
                <span className="align-self-middle ps-5 ">Somebody saves me as favourite</span>
              </div>
              <div className="col-12 col-md-2"></div>
              <div className="col-12 col-md-10 mb-5 d-flex flex-row justify-content-start align-items-center ">
                <input
                  type="checkbox"
                  checked={newDesignFromFavoriteNotification}
                  onClick={() => setNewDesignFromFavoriteNotification(!newDesignFromFavoriteNotification)}
                  className="flex-shrink-none"
                  {...register('hasNewDesignFromFavoriteNotification')}
                />
                <span className="align-self-middle ps-5 ">My favourite designers upload a new design</span>
              </div>
              <div className="col-12 col-md-2"></div>
              <div className="col-12 col-md-10 d-flex mb-5 flex-row justify-content-start align-items-center ">
                <input
                  type="checkbox"
                  checked={newEntryForFavoriteNotification}
                  onClick={() => setNewEntryForFavoriteNotification(!newEntryForFavoriteNotification)}
                  className="flex-shrink-none"
                  {...register('hasNewEntryForFavoriteNotification')}
                />
                <span className="align-self-middle ps-5 ">
                  My favourite contests receive a new entry <br />
                  (during the ending phase you may receive a lot of notifications)
                </span>
              </div>
            </div>
            <div className="row  d-flex justify-content-center align-items-center mb-7">
              <div className="row justify-content-center ">
                <button className={`btn-success rounded col-12 col-md-4 ${styles.button}  `}>update preferences</button>
              </div>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export default NewsletterAndNotifications;
