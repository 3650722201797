import React, { useEffect, useRef, useState } from 'react';
import styles from './PortfolioNavigation.module.scss';

import resetFilter from '../../../assets/Reset-Filter.svg';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import DslStore, {
  setContestPortfolioPageNumber,
  setPortfolioDesign,
  setPortfolioPageNumber,
} from '../../../store/DslStore';
import { ContestPortfolioLayoutSwitch, PortfolioLayoutSwitch } from '../../Reusable/LayoutSwitch';
import {
  getContestCategories,
  getPortfolioDesigns,
  getPortfolioDesignsCopy,
  getPortfolioPageNumber,
} from '../../../store/Selectors';
import { useStoreState } from 'pullstate';
import { DropDownInspirationCategory, DropDownInspirationSort } from '../../Forms/DropDown/DropDownInspirations';
import { UserDesigns } from '../../../global';

type IProjecNavigation = {
  onChangeLayout?: (val: number) => void;
};

type PaginationSwitchProps = {
  onChangePage?: (val: number) => void;
};

export const PaginationSwitch: React.FC<PaginationSwitchProps> = (props) => {
  const pageNumber = useStoreState(DslStore, getPortfolioPageNumber);
  const portfolioDesign = useStoreState(DslStore, getPortfolioDesigns);
  const portfolioDesignCopy = useStoreState(DslStore, getPortfolioDesignsCopy);
  const [correctArray, setCorrectArray] = useState<UserDesigns[]>([]);

  const getCorrectPublicDesignsNumber = () => {
    let correctPublicDesignsArray: UserDesigns[] = [];
    portfolioDesign.map((item) => {
      !item.isCensored &&
        !item.isHidden &&
        item.isPublished &&
        item.contest.name &&
        correctPublicDesignsArray.push(item);
    });
    setCorrectArray(correctPublicDesignsArray);
  };

  const gallery: number = correctArray.length;
  //   const contests = useStoreState(DslStore, getInspirations);
  // const gallery = 4;
  let designsPerPage = 20;
  const [pagernumber, setPagerNumber] = useState(0);
  const limit = Math.ceil(gallery / designsPerPage);
  const [array, setArray] = useState<Array<string>>(['1', '2', '3', '...', `${limit}`]);

  // const arrayCorrectPage = () => {
  //   let arrayCorrect: Array<number> = [];
  //   if (pageNumberLimit < 5) {
  //     for (let i = 1; i <= pageNumberLimit; i++) {
  //       arrayCorrect[i] = i;
  //       // console.log(arrayCorrect);
  //     }
  //     setArray(arrayCorrect);
  //   }
  // };

  useEffect(() => {
    console.log(portfolioDesign);
    getCorrectPublicDesignsNumber();
    setPortfolioPageNumber(1);
  }, []);

  useEffect(() => {
    getCorrectPublicDesignsNumber();
    setPortfolioPageNumber(1);
  }, [portfolioDesign, portfolioDesignCopy]);

  useEffect(() => {
    console.log('cambiato numero page');
    if (limit < 5) {
      let arr: string[] = [];
      for (let i = 0; i < limit; i++) {
        let ciccio = i + 1;
        arr.push(ciccio.toString());
      }

      setArray(arr);
    }
    if (pageNumber < 3 && limit >= 5) {
      setArray([`1`, `2`, `3`, `...`, `${limit}`]);
    } else if (pageNumber >= 3 && pageNumber <= limit - 3 && limit >= 5) {
      setArray([`${pageNumber - 2}`, `${pageNumber - 1}`, `${pageNumber}`, `...`, `${limit}`]);
    } else if (pageNumber === limit - 2 && limit >= 5) {
      setArray([`${pageNumber - 2}`, `${pageNumber - 1}`, `${pageNumber}`, `${pageNumber + 1}`, `${limit}`]);
    } else if (pageNumber === limit - 1 && limit >= 5) {
      setArray([`${pageNumber - 3}`, `${pageNumber - 2}`, `${pageNumber - 1}`, `${pageNumber}`, `${limit}`]);
    } else if (pageNumber === limit && limit >= 5) {
      setArray([`${pageNumber - 4}`, `${pageNumber - 3}`, `${pageNumber - 2}`, `${pageNumber - 1}`, `${pageNumber}`]);
    }
  }, [pageNumber, limit]);

  const handleChange = (value: number) => {
    console.log(value);
    if (value > 0 && value <= limit) {
      props.onChangePage && props.onChangePage(value);
      if (pageNumber >= 3 && pageNumber <= limit - 2) {
        setArray([`${pageNumber - 2}`, `${pageNumber - 1}`, `${pageNumber}`, `${limit - 1}`, `${limit}`]);
      }
    } else {
      console.log('0 o max++');
    }
  };

  return (
    <div className={`${styles.paginationSwitch} bg-light py-2 px-2 `}>
      <button className="btn-icon" onClick={() => handleChange(pageNumber - 1)}>
        <FontAwesomeIcon icon={faAngleLeft as IconProp} className={'btn-icon active'} />
      </button>

      {/* <button className={'btn-icon active'} onClick={() => handleChange(pageNumber - 1)}>
        <strong> &lt; </strong>
      </button> */}

      {array.map((item: string, index: number) => (
        <button
          key={index}
          className={pageNumber.toString() == item ? 'btn-icon active' : 'btn-icon'}
          onClick={() => handleChange(Number(item))}
        >
          <small>{item}</small>
        </button>
      ))}

      {/* <button className={'btn-icon active'} onClick={() => handleChange(pageNumber + 1)}>
        <strong> &gt; </strong>
      </button> */}

      <button className="btn-icon" onClick={() => handleChange(pageNumber + 1)}>
        <FontAwesomeIcon icon={faAngleRight as IconProp} className="btn-icon active " />
      </button>

      {/* 
      <button className={"btn-icon active"} 
      //  onClick={() => handleClick(1)}
      > <b> &lt; </b> </button>
       <button className={pageNumber == 1 ? "btn-icon active" : "btn-icon"} 
      //  onClick={() => handleClick(1)}
      >
        1
      </button>
      <button className={pageNumber == 2 ? "btn-icon active" : "btn-icon"} 
      // onClick={() => handleClick(2)}
      >
        2
      </button>
      <button className={pageNumber == 3 ? "btn-icon active" : "btn-icon"} 
      // onClick={() => handleClick(3)}
      >
        3
      </button>
      <button className={pageNumber == 4 ? "btn-icon active" : "btn-icon"} 
      // onClick={() => handleClick(4)}
      >
        4
      </button>
      <button className={pageNumber == 5 ? "btn-icon active" : "btn-icon"} 
      // onClick={() => handleClick(5)}
      >
        5
      </button>  */}

      {/* <button className="btn-icon">
         <FontAwesomeIcon icon={faAngleRight as IconProp} className="text" />
       </button> */}

      {/* <Pagination count={numberOfPages} page={pageNumber} color='primary'  hideNextButton={numberOfPages > 1 ? false : true} hidePrevButton={numberOfPages > 1 ? false : true} size="small" onChange={handleChange} /> */}
    </div>
  );
};

let filterCategories = [
  {
    label: 'All',
    value: '0',
  },
  {
    label: 'Product Design',
    sort: 'Product Design',
    value: '1',
  },
  {
    label: 'New Idea',
    sort: 'New Idea',
    value: '2',
  },
  {
    label: 'Interior Design',
    sort: 'Interior design',
    value: '3',
  },
  {
    label: 'Packaging',
    sort: 'Packaging',
    value: '4',
  },
  {
    label: 'Naming',
    sort: 'Naming',
    value: '5',
  },
  {
    label: 'Co-Creation',
    sort: 'Co-creation',
    value: '6',
  },
  {
    label: 'Craft Design',
    sort: 'Craft design',
    value: '7',
  },
  {
    label: 'Link to Other',
    sort: 'Link to other',
    value: '8',
  },
];

let filterSort = [
  {
    label: 'Name',
    sort: 'name',
    value: '1',
  },
  {
    label: 'Votes',
    sort: 'votes',
    value: '2',
  },

  {
    label: 'Date',
    sort: 'date',
    value: '3',
  },
];

interface IDropDownItem {
  label: string;
  sort: string;
  value: string;
}

export const PortfolioNavigation: React.FC<IProjecNavigation> = (props) => {
  const portfolioDesign = useStoreState(DslStore, getPortfolioDesigns);
  const portfolioDesignCopy = useStoreState(DslStore, getPortfolioDesignsCopy);
  const categories = useStoreState(DslStore, getContestCategories);
  const [descs, setDescs] = useState(false);
  const [reload, setReload] = useState(false);
  const [categoriesFilter, setCategoriesFilter] = useState<IDropDownItem[]>([]);
  const [typologySort, setTypologySort] = useState('');
  const [sortBySort, setSortBySort] = useState('');
  const gallery: number = portfolioDesign?.length;
  let designsPerPage = 20;
  const pageNumberLimit = useRef(Math.ceil(gallery / designsPerPage));

  useEffect(() => {
    let newItems: IDropDownItem[] = [];
    if (categories) {
      console.log(categories);
      categories.map((item, index: number) => {
        let name = item.name === 'Link to Other' ? 'Partnership' : item.name;

        if (!newItems.includes({ label: `${name}`, sort: `${item.name}`, value: `${index}` })) {
          newItems.push({ label: `${name}`, sort: `${item.name}`, value: `${index}` });
          // setTypologyFilter(newItems);
          // console.log(newItems);
        }
      });
      setCategoriesFilter(newItems);
    }
  }, [categories]);

  useEffect(() => {
    if (gallery) {
      pageNumberLimit.current = Math.ceil(gallery / designsPerPage);
    }
  }, [gallery]);

  const sortDesigns = () => {
    let sorted: UserDesigns[] = [...portfolioDesignCopy];
    if (typologySort) {
      let typologySorted: UserDesigns[] = [];
      sorted.map((item) => {
        if (item.contest.category) {
          item.contest.category.name === typologySort && typologySorted.push(item);
        }
      });
      sorted = typologySorted;
    }
    if (sortBySort) {
      if (sortBySort === 'name') {
        console.log('by name');
        if (descs) {
          sorted.sort((a, b) =>
            b.name.toLowerCase() > a.name.toLowerCase() ? 1 : a.name.toLowerCase() > b.name.toLowerCase() ? -1 : 0
          );
        } else {
          sorted.sort((a, b) =>
            b.name.toLowerCase() > a.name.toLowerCase() ? -1 : b.name.toLowerCase() > a.name.toLowerCase() ? 1 : 0
          );
        }
      } else if (sortBySort === 'date') {
        if (descs) {
          sorted.sort((a, b) => {
            if (a.uploadDate && b.uploadDate) {
              return a.uploadDate.localeCompare(b.uploadDate);
            }
            return 0;
          });
        } else {
          sorted.sort((a, b) => {
            if (a.uploadDate && b.uploadDate) {
              return b.uploadDate.localeCompare(a.uploadDate);
            }
            return 0;
          });
        }
      } else if (sortBySort === 'votes') {
        if (descs) {
          sorted.sort((a, b) => (b.votesCount > a.votesCount ? 1 : a.votesCount > b.votesCount ? -1 : 0));
        } else {
          sorted.sort((a, b) => (b.votesCount > a.votesCount ? -1 : a.votesCount > b.votesCount ? 1 : 0));
        }
      }
    }
    setPortfolioDesign(sorted);
  };

  useEffect(() => {
    sortDesigns();
  }, [typologySort, sortBySort, descs]);

  return (
    <div className="row mb-4 ">
      <div className="col-12 col-lg-9   d-flex flex-column  flex-lg-row justify-content-between mb-4 mb-md-0">
        <div className="me-0 me-md-5 w-100   mb-sm-4 mb-lg-0">
          <DropDownInspirationCategory
            label="Category"
            options={categoriesFilter}
            reset={reload}
            onSelect={(option) => (option.sort ? setTypologySort(option.sort) : setTypologySort(''))}
            thin
          />
        </div>
        <div className="me-0 me-md-5 w-100 mb-lg-0 mb-sm-4">
          <DropDownInspirationSort
            label="Sort by"
            options={filterSort}
            reset={reload}
            descs={(descs) => setDescs(descs)}
            onSelect={(option) => option.sort && setSortBySort(option.sort)}
            thin
          />
        </div>
        <div className=" d-flex flex-row align-items-center justify-content-center h-100 w-100">
          <div
            // className="btn-blue rounded"
            className=" d-flex flex-row align-items-center justify-content-center btn-reset-blue   "
            onClick={() => (setReload(!reload), setPortfolioDesign(portfolioDesignCopy))}
            style={{ width: '3rem', borderRadius: '8px' }}
          >
            <img src={resetFilter} style={{ height: '25px' }} />
          </div>
          {/* <div className="btn-blue rounded" onClick={() => (setReload(!reload), setPortfolioDesign(correctDesignCopy))}>
            reset
          </div> */}
        </div>
      </div>
      <div className={`col-12  col-lg-3 col-xl-3  d-flex  justify-content-sm-start justify-content-lg-end `}>
        <div
          className={`  col-lg-9 me-lg-2   col-md-3 col-sm-4    mb-lg-0  d-flex  justify-content-sm-start justify-content-lg-center  justify-content-xl-center   `}
        >
          {pageNumberLimit.current > 0 ? <PaginationSwitch onChangePage={setPortfolioPageNumber} /> : ''}
        </div>
        <div className={`col-lg-3  col-md-9 col-sm-8   mb-lg-0  d-flex  justify-content-sm-end`}>
          <div className=" d-lg-block ">
            {pageNumberLimit.current > 0 ? <PortfolioLayoutSwitch onChangeLayout={props.onChangeLayout} /> : ''}
          </div>
        </div>
      </div>
    </div>
  );
};

export const ContestPortfolioNavigation: React.FC<IProjecNavigation> = (props) => {
  const [reload, setReload] = useState(false);
  const [activeSearch, setActiveSearch] = useState(false);
  const [descs, setDescs] = useState(false);
  const [typologySort, setTypologySort] = useState('');
  const [sortBySort, setSortBySort] = useState('');

  const sortDesigns = () => {};

  return (
    <div className="row py-4 mt-4 px-2">
      <div className="col-12 col-md-6 col-lg-8 d-flex flex-column px-4 flex-lg-row justify-content-between mb-4 mb-md-0">
        <div className="me-0 me-md-5 w-100 mb-2 mb-lg-0">
          <DropDownInspirationCategory label="Category" reset={reload} options={filterCategories} thin />
        </div>
        <div className="w-100">
          <DropDownInspirationSort reset={reload} label="Sort by" options={filterSort} thin />
        </div>
        <div className=" d-flex flex-row align-items-center justify-content-center h-100 w-100">
          {activeSearch && (
            <div className="btn-blue rounded" onClick={() => (setReload(!reload), setActiveSearch(false))}>
              reset
            </div>
          )}
          {!activeSearch && (
            <div className="btn-blue rounded" onClick={() => (setActiveSearch(true), sortDesigns())}>
              apply
            </div>
          )}
        </div>
      </div>
      <div className={`col-12 col-md-6 col-lg-4 d-flex px-4 justify-content-sm-start justify-content-md-end `}>
        <div className="me-md-5 me-lg-5 me-sm-5 ">
          <PaginationSwitch onChangePage={setContestPortfolioPageNumber} />
        </div>
        <div>
          <ContestPortfolioLayoutSwitch onChangeLayout={props.onChangeLayout} />
        </div>
      </div>
    </div>
  );
};
