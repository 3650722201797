import React, { useEffect } from 'react';
import styles from './MiniTimeLine.module.scss';
import { Transition } from 'react-transition-group';

const MemoProgressBar = (props: { perc: number }) => {

  const animationDuration = 800;

  let transitions: any = {
    entering: {
      width: 0,
      opacity: 1,
    },
    entered: {
      opacity: 1,
      width: props.perc + '%',
    },
    exiting: {
      opacity: 0,
      width: 0,
    },
    exited: {
      opacity: 0,
      width: 0,
    },
  };

  return (
    <Transition in={true} appear={true} timeout={animationDuration}>
      {(state) => <div className={`${styles.progressBar} `}  style={{ ...transitions[state] }}></div>}
    </Transition>
  );
};

export default React.memo(MemoProgressBar);
