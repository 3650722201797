import React, { useEffect, useState } from 'react';
import { ICardInfo } from '../../Community/CardInfo/CardInfo';
import SquaredImage from '../../SquaredImage/SquaredImage';
import namingPlaceholder from '../../../assets/Naming.png';
import designPlaceholder from '../../../assets/Design.png';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { faTrophy, faHeart, faUser } from '@fortawesome/free-solid-svg-icons';

import styles from './GalleryCard.module.scss';
import ActionButton, { MultiActionButtonsWrapper } from '../../ActionButton/ActionButton';
import { Contest, Design, GalleryDesign, User } from '../../../global';
import DslStore, {
  setCommunityUser,
  setCurrentContest,
  setCurrentDesign,
  setDesignsILike,
} from '../../../store/DslStore';
import { useNavigate } from 'react-router-dom';
import axiosHelper from '../../../helpers/axiosHelper';
import { finalString, fullPathLogoDesign } from '../../../helpers/utils';
import { useStoreState } from 'pullstate';
import { useAuth } from '../../../context/AuthContext';
import { ROUTEPATHS } from '../../../routes/routePaths';

interface IContestCard extends ICardInfo {
  contest: Contest;
  design: Design | GalleryDesign;
  title: string;
  typology?: string;
  category?: string;
  creationDate?: string;
  contestTitle?: string;
  username?: string;
  award?: string;
  awarded: boolean;
  private?: boolean;
  image?: string;
  firstname?: string;
  lastname?: string;
  votes: number;
  idUser: number;
  idContest?: number;
  idDesign?: number;
  type?: string;
  hidden: boolean;
  idClient: number;
  startDate: string;
  endUploadDate: string;
}

const GalleryCardWLF: React.FC<IContestCard> = (props) => {
  const redirect = useNavigate();
  const {loggedUser: user, isDesignLiked, toggleDesignLiked} = useAuth();

  const goToDesignDetails = async (idDesign?: number) => {
    // console.log(idDesign);

    console.log(props.category);
    if (user) {
      if (user.id === props.idUser || user.userType === 'G') {
        console.log('sono dio')
        const design = await axiosHelper.retrieveCompleteDesign(idDesign);
        if (design) {
          // console.log(design);

          // console.log(design.contest.isPrivate);
          setCurrentDesign(design);
        }
        redirect(`/${ROUTEPATHS.CONTEST}/${props.contest?.nameForUrl}/${ROUTEPATHS.CONTEST_GALLERY}/${design?.nameForURL}`);
      }
    }
    if (!props.private && !props.hidden) {
      const design = await axiosHelper.retrieveCompleteDesign(idDesign);
      if (design) {
        // console.log(design);

        // console.log(design.contest.isPrivate);
        setCurrentDesign(design);
      }

      redirect(`/${ROUTEPATHS.CONTEST}/${props.contest?.nameForUrl}/${ROUTEPATHS.CONTEST_GALLERY}/${design?.nameForURL}`);
    }
    if (!props.private && props.hidden && !hiddenOptionActive()) {
      const design = await axiosHelper.retrieveCompleteDesign(idDesign);
      if (design) {
        // console.log(design);

        // console.log(design.contest.isPrivate);
        setCurrentDesign(design);
      }

      redirect(`/${ROUTEPATHS.CONTEST}/${props.contest?.nameForUrl}/${ROUTEPATHS.CONTEST_GALLERY}/${design?.nameForURL}`);
    }
  };

  const hiddenOptionActive = () => {
    if (!props.private && props.startDate && props.endUploadDate) {
      const dateNumber = new Date().getTime() / 86400000;
      const startDateNumber = new Date(props.startDate).getTime() / 86400000;
      const endDateNumber = new Date(props.endUploadDate).getTime() / 86400000;
      const halfDate = (endDateNumber - startDateNumber) / 2;
      if (dateNumber - startDateNumber < halfDate) {
        return true;
      } else {
        return false;
      }
    }
  };

  const goToDetail = async (userid: number) => {
    // console.log(userid);

    let result: User = await axiosHelper.retrieveUserById(userid);
    // console.log(result);

    setCommunityUser(result);

    setTimeout(() => {
      console.log('This will run after 1 second!');
      redirect(`/${ROUTEPATHS.COMMUNITY}/${result.nameForURL}/${ROUTEPATHS.COMMUNITY_PROFILE}`);
    }, 1000);
  };

  const goToContestDetails = async (contestId?: number) => {
    if (contestId) {
      const result = await axiosHelper.retrieveCompleteContest(contestId);
      // console.log(result.data.client);
      // console.log(contest);
      // console.log(result);
      setCurrentContest(result.data);

      redirect(`/${ROUTEPATHS.CONTEST}/${result.data.nameForUrl}/${ROUTEPATHS.CONTEST_BRIEF}`);
    }
  };

  // useEffect(() => {
  //   console.log('entro in GalleryCard');
  // });

  return (
    <div
      className={`${styles.card} col-12  d-flex flex-row align-items-center justify-content-center position-relative`}
    >
      <div className={`${styles.cardInfoWrapper} w-sm-75 d-flex flex-column  bg-light`}>
        <div
          //   onClick={() => (getContestUser(props.contest.id), console.log('click'))}
          className={`${styles.cardInfoImage}  bg-light ${props.circle && 'px-5 pt-4 '} position-relative ${
            (props.hidden &&
              hiddenOptionActive() &&
              (user?.id !== props.idUser ||
                user?.id === props.idClient ||
                user?.userType === 'G')) ||
            props.private
              ? ''
              : 'cursor-pointer'
          }`}

          // onClick={() => goToDesignDetails(props.idDesign)}
        >
          <div className="" onClick={() => goToDesignDetails(props.idDesign)}>
            {props.image ? (
              <SquaredImage
                image={
                  props.hidden && hiddenOptionActive()
                    ? user?.id === props.idUser ||
                      user?.id === props.idClient ||
                      user?.userType === 'G'
                      ? fullPathLogoDesign(props.image)
                      : designPlaceholder
                    : fullPathLogoDesign(props.image)
                }
                altText={props.altText}
                circle={props.circle}
              />
            ) : (
              <SquaredImage image={namingPlaceholder} />
              // <div className={`${styles.squaredImageWrapper}`}>
              //   <div className={`${styles.imageInner}`}>
              //     {/* <Spinner /> */}
              //     <img src={namingPlaceholder} />
              //   </div>
              // </div>
              // <FontAwesomeIcon icon={faPencil as IconProp} className="color-light" />
            )}
          </div>
          {!isDesignLiked(props.design) && (
            <ActionButton
              label={user ? 'Save as favorite' : 'Login to save as favorite'}
              color={!user ? 'light-gray-3' : ''}
              icon="addFavorite"
              position="top-right"
              action={() => user && toggleDesignLiked(props.design)}
            />
          )}
          {isDesignLiked(props.design) && (
            <ActionButton
              label="Remove from favorites"
              icon="confirm"
              position="top-right"
              color="success"
              action={() => toggleDesignLiked(props.design)}
            />
          )}

          <MultiActionButtonsWrapper position="bottom-left">
            {props.awarded && <ActionButton label="award" icon="award" />}
            {props.private && <ActionButton label="Private gallery" icon="private" />}
            {props.hidden && hiddenOptionActive() && <ActionButton icon="private" label="Hidden design" color="" />}
            {/* {props.accesscode && <ActionButton label="Access code" icon="accesscode" />}
            {props.nda && <ActionButton label="Accept nda" icon="acceptnda" />}
            {props.global && <ActionButton label="Global contest" icon="global" />} */}
          </MultiActionButtonsWrapper>
        </div>
        {/* <StatusBar status={props.status} progress={props.progress} /> */}
        <div className={`${styles.cardInfoContent} container bg-light py-4`}>
          <div className="row h-25 mb-4 px-2">
            <div className={`col text-center align-self-center `} onClick={() => goToDesignDetails(props.idDesign)}>
              <span
                className={`fw-bold color-blue d-none d-sm-block text-16 ${
                  (props.hidden &&
                    hiddenOptionActive() &&
                    (user?.id !== props.idUser ||
                      user?.id === props.idClient ||
                      user?.userType === 'G')) ||
                  props.private
                    ? ''
                    : 'cursor-pointer'
                } `}
              >
                {props.hidden && user
                  ? user.id === props.idUser ||
                    (user.userType === 'C' && user.id === props.idClient) ||
                    user.userType === 'G'
                    ? props.title
                    : 'Hidden by author'
                  : props.hidden && hiddenOptionActive()
                  ? 'Hidden by author'
                  : props.title}
              </span>
              <span
                className={`fw-bold color-blue d-sm-none d-sm-block text-16 ${
                  (props.hidden &&
                    hiddenOptionActive() &&
                    (user?.id !== props.idUser ||
                      user?.id === props.idClient ||
                      user?.userType === 'G')) ||
                  props.private
                    ? ''
                    : 'cursor-pointer'
                }`}
              >
                {props.hidden && user
                  ? user.id === props.idUser ||
                    (user.userType === 'C' && user.id === props.idClient) ||
                    user.userType === 'G'
                    ? props.title
                    : 'Hidden by author'
                  : props.hidden && hiddenOptionActive()
                  ? 'Hidden by author'
                  : props.title}
              </span>
            </div>
          </div>

          <div className=" col align-self-center text-center  mb-5 ">{props.typology}</div>

          <div className={`${styles.infoContainer} position-static`}>
            <div
              className=" justify-content-left  mb-3 "
              style={{ cursor: 'pointer' }}
              onClick={() => goToDetail(props.idUser)}
            >
              <FontAwesomeIcon icon={faUser as IconProp} className="ps-1 text-left color-blue me-3" />
              <span className=" mx-2 justify-content-left color-blue">{props.username}</span>
            </div>
            <div className="text text-start justify-content-left mb-3 ">
              <FontAwesomeIcon icon={faCalendar as IconProp} className="ps-1 text color-blue me-3" />
              <span className="mx-1  justify-content-left mb-4">Posted: {props.creationDate}</span>
            </div>
            <div
              className="text text-start  justify-content-left mb-3  "
              style={{ height: '20px', overflow: 'hidden', cursor: 'pointer' }}
              onClick={() => goToContestDetails(props.idContest)}
            >
              <FontAwesomeIcon icon={faTrophy as IconProp} className=" text color-blue me-3" />
              <span className="  justify-content-left  color-blue ">{props.award} </span>
            </div>
            <div className="text text-start justify-content-left mb-3 ">
              <FontAwesomeIcon icon={faHeart as IconProp} className="text ps-1 color-blue me-3" />
              <span className="  fw-bold ">
                {props.votes} <span className="text "> votes</span>
              </span>
            </div>

            {/* <div className="d-flex justify-content-left mb-2">
              <div className="d-flex align-items-center ">
                <FontAwesomeIcon icon={faHeart as IconProp} className="text color-blue me-3" />
                <span className=" text color-blue fw-bold ">
                  {props.votes} <span className="color-blue fw-normal"> votes</span>
                </span>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GalleryCardWLF;
