import React, { useEffect, useRef, useState } from 'react';
import AppHeader from '../../components/AppHeader/AppHeader';
import AppFooter from '../../components/AppFooter/AppFooter';

import ContestStats from '../../components/Community/ContestStats/ContestStats';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { faMapMarkerAlt, faIndustry, faXmark } from '@fortawesome/free-solid-svg-icons';
import styles from './ContestSkeleton.module.scss';

//Header Data

import headerLogo from '../../assets/desall_logo.png';
import { briefNavPages } from '../../mockdata/navPages';

//Footer Data
import footerImg from '../../assets/footer_placeholder_transparent.png';
import TimeLineWLF from '../../components/TimeLine/TimeLineWLF';
import ActionButton, { MultiActionButtonsWrapper } from '../../components/ActionButton/ActionButton';

import { getCoCreationContestStats, getFooterMenu, getHeaderMenu, getAllCoCreationContests, getContestAccessDenied } from '../../store/Selectors';
import { useStoreState } from 'pullstate';
import DslStore, { setCommunityUser, setContestAccessDenied, setContestTags } from '../../store/DslStore';
import { finalSlicedString, finalString, numberVotes } from '../../helpers/utils';
import axiosHelper from '../../helpers/axiosHelper';

import { Contest, TimelineData } from '../../global';
import { useNavigate, useParams } from 'react-router-dom';
import PopUpLogin from '../../components/PopUp/PopUpLogin/PopUpLogin';
import BriefWLF from './BriefWLF';
import Gallery from './Gallery';
import UploadContest from './UploadContest';
import UploadInspiration from './UploadInspiration';
import EditContestInspiration from './EditContestInspiration';
import useSWR from 'swr';
import { useAuth } from '../../context/AuthContext';
import { calculateTimelines, getContestStatusText } from '../../helpers/utilities';
import InspirationsWLF from './InspirationsWLF';
import AwardsWLF from './AwardsWLF';
import { PageNavigationBriefWLF } from '../../components/PageNavigation/PageNavigationWLF';
import UploadContestWLF from './UploadContestWLF';
import PopUpLoginWLF from '../../components/PopUp/PopUpLogin/PopUpLoginWLF';
import MessageComponent from '../../components/Message/MessageComponent';
import MessagesContainer from '../../components/Message/MessagesContainer';
import GalleryWLF from './GalleryWLF';
import { ROUTEPATHS } from '../routePaths';
import AppHeaderWLF from '../../components/AppHeader/AppHeaderWLF';

const ContestSkeletonWLF: React.FC = () => {
  const footerMenu = useStoreState(DslStore, getFooterMenu);
  const headerMenu = useStoreState(DslStore, getHeaderMenu);
  const [contest, setContest] = useState<Contest>();
  const cocreationstats = useStoreState(DslStore, getCoCreationContestStats);
  const contestAccessDenied = useStoreState(DslStore, getContestAccessDenied);
  const { nameForUrl } = useParams();
  const { pageName } = useParams();
  const [subPage, setSubPage] = useState('Brief');
  const [breadcrumbs, setBreadcurmbs] = useState('');
  const [pageTitle, setPageTitle]= useState('Brief');
  const [messageBanner, setMessageBanner] = useState(0);
  const [privateGalleryMessage, setPrivateGalleryMessage] = useState(false);
  const path = window.location.pathname;

  const [accesscodeauth, setAccessCodeAuth] = useState(true);
  const [ndaauth, setNdaAuth] = useState(true);

  const [popup, setPopup] = useState(false);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const redirect = useNavigate();
  const { data, error, isLoading } = useSWR('contestname|' + nameForUrl, axiosHelper.contestsFetcher);

  useEffect(() => {
    console.log('USEFFECT PAGE NAME', pageName);
    const which = briefNavPages.find((item) => item.path === '/' + pageName);
    if (which) {
      setPageTitle(which.text);
    }else {
      setPageTitle(briefNavPages[0].text);
      setSubPage(briefNavPages[0].path);
    }
    console.log(which);
    if (pageName) {
      setSubPage(pageName);
    }
  }, [pageName]);

  useEffect(() => {
    console.log('USEFFECT DATA', data);
    if (data) {
      console.log('GOT DATA');
      console.log(data);
      setContest(data);
    }
  }, [data]);

  useEffect(() => {
    console.log('USEFFECT CONTEST', contest);
    if (contest) {
      if (contest.isPrivate) {
        setPrivateGalleryMessage(true);
      }
    }
  }, [contest]);

  const goToDetail = async (userid?: number) => {
    console.log('GO TO DETAIL ' + userid);
    if (userid) {
      //     console.log(userid);
      try {
        const response = await axiosHelper.retrieveUserById(contest?.userId);
        console.log(response);
        //         console.log(response);
        setCommunityUser(response);
        redirect(`/${ROUTEPATHS.COMMUNITY}/${response.nameForURL}/Profile`);
        //       });
      } catch (e) {
        console.log(e);
      }
    }
  };
  useEffect(() => {
    console.log('SHOW LOGIN POPUPPPP ' + showLoginPopup);
  }, [showLoginPopup]);
  const invokeLogin = () => {
    console.log('PRESSED INVOKE LOGIN');
    setShowLoginPopup(true);
  };
  const loginCallback = (success: boolean) => {
    setShowLoginPopup(false);
    console.log('LOGIN CALLBACK SUCCESS: ' + success);
  };
  return (
    <div className="bg-light-gray">
      <AppHeaderWLF
        links={headerMenu}
        bgColor={contest?.bannerColor ? contest.bannerColor : '#C3DE72'}
        logoImg={headerLogo}
        bgImage={contest?.bannerImage}
        alpha={0}
        breadcrumbs={`/Home/Contest/${contest?.name}`}
        pageTitle={pageTitle}
        hideSearch={true}
      />
      {contest && (
        <>
          <PageNavigationBriefWLF contest={contest} pageName={pageName} />
          <MessagesContainer>
            <MessageComponent
              visible={privateGalleryMessage}
              message="This contest has a private gallery. You can join it but you can’t see its entries"
              level="warning"
              closeCallback={() => setPrivateGalleryMessage(false)}
            />
            <MessageComponent visible={contestAccessDenied} message="Access Denied!" level="error" closeCallback={() => setContestAccessDenied(false)} />
            {messageBanner === 1 && <MessageComponent visible={privateGalleryMessage} message="Message sent!" level="success" closeCallback={() => setMessageBanner(0)} />}
            {messageBanner === 2 && (
              <MessageComponent visible={privateGalleryMessage} message="The mailserver is busy, please try again!" level="error" closeCallback={() => setMessageBanner(0)} />
            )}
          </MessagesContainer>

          <main className={`container mb-5 overflow-hidden`}>
            <div className="row px-4  px-sm-1  ">
              <div className="col-12 col-md-3  text-center mx-3 mx-sm-0  d-flex align-items-end justify-content-sm-center justify-content-md-start ">
                <div className="position-relative ">
                  <img style={{ width: '270px', height: '270px' }} className="img-fluid rounded mt-5 " src={contest?.image} alt="Placeholder" />
                  {contest?.isPrivate ||
                    (contest?.hideGalleryTab && (
                      <MultiActionButtonsWrapper position="bottom-left" rounded>
                        <ActionButton label="Private gallery" icon="private" />
                      </MultiActionButtonsWrapper>
                    ))}
                </div>
              </div>
              <div className="col-12 col-md-9 px-3 pt-5">
                <div className="row">
                  <div className="info-main mb-5 px-3 px-sm-2  col-12">
                    <h1 className="sub-title-brief  color-dark">{contest.name}</h1>
                    <div className="text-16 color-dark">{finalString(contest?.category)}</div>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-12 px-3 px-sm-2 col-md-3">
                    <div className="info">
                      <div className="d-flex justify-content-start mb-3" style={{ height: '20px', overflow: 'hidden' }}>
                        <div className="d-flex flex-column justify-content-center">
                          <FontAwesomeIcon icon={faIndustry as IconProp} className=" text color-blue me-2" />
                        </div>
                        <div className="text color-blue" style={{ cursor: 'pointer' }} onClick={() => goToDetail(contest.userId)}>
                          {contest.userName != null ? finalSlicedString(contest.userName) : finalString(contest.linkToClient)}
                        </div>
                      </div>
                      <div className="d-flex  justify-content-start mb-3">
                        <div className="d-flex flex-column justify-content-center">
                          <FontAwesomeIcon icon={faMapMarkerAlt as IconProp} className=" text color-blue me-3" />
                        </div>
                        <div className="text">{contest?.userCity + ', ' + contest?.countryName}</div>
                      </div>
                      <div className="d-flex  justify-content-start">
                        <div className="d-flex flex-column justify-content-center">
                          <FontAwesomeIcon icon={faCalendar as IconProp} className=" text color-blue me-3" />
                        </div>
                        <div className="text ">{getContestStatusText(contest)}</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-9  mt-5 mt-md-0 ">
                    {contest.contestType !== 'Co-creation' ? (
                      <ContestStats
                        ndaAuth={ndaauth}
                        accessCodeAuth={accesscodeauth}
                        isPrivate={contest?.isPrivate}
                        entries={contest?.entries ? contest.entries : 0}
                        inspirations={numberVotes(contest?.inspirationCount)}
                        awards={contest?.prices}
                        votes={numberVotes(contest?.sumVotesCount)}
                      />
                    ) : (
                      <ContestStats
                        entries={cocreationstats?.entries}
                        inspirations={cocreationstats?.inspirations}
                        awards={cocreationstats?.awards}
                        votes={cocreationstats?.votes}
                      />
                    )}
                  </div>
                  <div className=" d-none  d-md-flex flex-row align-items-end justify-content-center ">
                    <div className="container px-1 ps-sm-2 mx-0 pe-lg-1">
                      <TimeLineWLF contest={contest} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Body ====================================================================== */}
            {contest && (
              <>
                {subPage === 'brief' && (
                  <BriefWLF
                    contest={contest}
                    loginCallback={invokeLogin}
                    breadcrumbs={(title) => setBreadcurmbs(title)}
                    messageBanner={(message) => setMessageBanner(message)}
                    accesscodeauth={(auth) => setAccessCodeAuth(auth)}
                    ndaauth={(auth) => setNdaAuth(auth)}
                  />
                )}
                {subPage.includes('new') && <UploadInspiration breadcrumbs={(title) => setBreadcurmbs(title)} />}
                {subPage.includes('edit') && <EditContestInspiration breadcrumbs={(title) => setBreadcurmbs(title)} />}
                {subPage.includes('inspirations') && !path.includes('new') && !path.includes('edit') && (
                  <InspirationsWLF contest={contest} loginCallback={invokeLogin} breadcrumbs={(title) => setBreadcurmbs(title)} />
                )}
                {subPage.includes('awards') && (
                  <>
                    <AwardsWLF
                      contest={contest}
                      loginCallback={invokeLogin}
                      breadcrumbs={(title) => setBreadcurmbs(title)}
                      messageBanner={(message) => setMessageBanner(message)}
                    />
                  </>
                )}

                {subPage.includes('gallery') && <GalleryWLF contest={contest} breadcrumbs={(title) => setBreadcurmbs(title)} />}
                {subPage.includes('upload') && (
                  <UploadContestWLF
                    contest={contest}
                    loginCallback={invokeLogin}
                    breadcrumbs={(title) => setBreadcurmbs(title)}
                    messageBanner={(message) => setMessageBanner(message)}
                  />
                )}
              </>
            )}
          </main>
          <PopUpLoginWLF visible={showLoginPopup} callback={loginCallback} />
          <AppFooter links={footerMenu} bgColor="#304053" logoImg={headerLogo} bgImage={footerImg} />
        </>
      )}
    </div>
  );
};

export default ContestSkeletonWLF;
