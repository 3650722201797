import React, { useState, useRef } from 'react';
import axiosHelper from '../../../helpers/axiosHelper';
import { setContest } from '../../../store/DslStore';

import styles from './DropDown.module.scss';

interface IDropDown {
  options?: IDropDownItem[];
  onSelect?: (o: IDropDownOption) => void;
  label?: string;
  thin?: boolean;
}

interface IDropDownItem {
  label: string;
  value: string;
}
export interface IDropDownOption extends IDropDownItem {
  index: number;
  ours?: string;
}

const DropDown: React.FC<IDropDown> = (props) => {
  const dropDownContainer = useRef<any>(null);
  let [open, setOpen] = useState<boolean>(false);
  let [itemSelected, setItemSelected] = useState<IDropDownOption>();

  const generateOptionsList = (options: IDropDownItem[]) => {
    console.log(itemSelected);
    let indexedOptions: IDropDownOption[] = [];
    options.forEach((item, index) => {
      indexedOptions.push({
        ...item,
        index: index,
      });
    });
    return indexedOptions;
  };

  const globalClickListener = (e: MouseEvent) => {
    if (dropDownContainer.current && dropDownContainer.current.contains(e.target)) {
      return;
    } else {
      setOpen(false);
    }
  };

  const setContestFiltered = async (option: IDropDownOption) => {
    // const emptyContest :  Contest[] =  [];
    // setContest(emptyContest)
    console.log(option);
    let sortFilter: string | undefined = option.ours;
    const result = await axiosHelper.getContestsSortBy(sortFilter);
    console.log(result.data);
    setContest(result.data);
  };

  const handleOnClick = () => {
    if (!open) {
      document.addEventListener('click', (e) => globalClickListener(e));
    } else {
      document.removeEventListener('click', (e) => globalClickListener(e));
    }
    setOpen(!open);
  };

  const handleOnSelect = (option: IDropDownOption) => {
    console.log(option);
    setItemSelected(option);
    setOpen(!open);
    setContestFiltered(option);
    props.onSelect && props.onSelect(option);
  };
  const getSelectedStyle = (index: number) => {
    return itemSelected && itemSelected.index === index ? styles.selected : '';
  };

  const optionsList = props.options ? generateOptionsList(props.options) : [];

  return (
    <div ref={dropDownContainer} className={`${styles.dropdown_wrapper} ${props.thin && styles.thin_ui}`}>
      <div className={`${styles.dropdown} ${open ? styles.open : ''}`}>
        <div className={`${styles.dropdown__toggle}`} onClick={handleOnClick}>
          {props.label && <div className="fw-bold text-uppercase pe-3"> {props.label}</div>}
          <span className={`${styles.dropdown__label} ${props.label && 'text-center flex-grow-1'}`}>
            {itemSelected ? itemSelected.label : 'Choose'}
          </span>
          <div className={`${styles.arrow}`}></div>
        </div>

        <div className={`${styles.dropdown__optionslist}`}>
          {optionsList.map((option, index) => (
            <div
              key={index + option.value}
              className={`${styles.dropdown__option} ${getSelectedStyle(index)}`}
              data-value={option.value}
              onClick={() => handleOnSelect(option)}
            >
              {option.label}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DropDown;
