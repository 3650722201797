import React from 'react';
import styles from '../Community/ProjectsNavigation/ProjectsNavigation.module.scss';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faTableCells, faTableList } from '@fortawesome/free-solid-svg-icons';

type LayoutSwitchPropsWLF = {
  activeLayout: number;
  changeLayout: (number: number) => void;
};

export const LayoutContestSwitchWLF: React.FC<LayoutSwitchPropsWLF> = (props) => {
  return (
    <div className={`${styles.layoutSwitch} bg-light py-2 px-2  d-none d-sm-block`}>
      <button
        className={1 === props.activeLayout ? 'btn-icon active' : 'btn-icon'}
        onClick={() => props.changeLayout(1)}
      >
        <FontAwesomeIcon icon={faTableCells as IconProp} className="text" />
      </button>
      <button
        className={2 === props.activeLayout ? 'btn-icon active' : 'btn-icon'}
        onClick={() => props.changeLayout(2)}
      >
        <FontAwesomeIcon icon={faTableList as IconProp} className="text" />
      </button>
    </div>
  );
};
