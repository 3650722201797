import React, { useEffect, useState } from 'react';
import styles from './TimeLine.module.scss';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faIndustry, faUsers, faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';

import { ITimeLine } from './timelineInterfaces';
import TLHLP from './timeline.helper';

import Timer from './components/Timer';
import ProgressSection from './components/ProgressSection';
import { Datestr } from '../../helpers/utils';
import { useStoreState } from 'pullstate';
import DslStore from '../../store/DslStore';
import { getAllCoCreationContests, getCoCreationContests, getCurrentContest } from '../../store/Selectors';
import { CoCreationContest, Contest } from '../../global';

interface TimelineProps {
  contest: Contest | CoCreationContest;
}

const TimeLine: React.FC<ITimeLine> = (props) => {
  const cocreationContests = useStoreState(DslStore, getAllCoCreationContests);
  const contest = useStoreState(DslStore, getCurrentContest);
  const date = new Date().getTime();
  // const [daysLeft, setDaysLeft] = useState(0);
  // const [leftHours, setLeftHours] = useState(0);
  // const [leftMinutes, setLeftMinutes] = useState(0);
  // const [leftSeconds, setLeftSeconds] = useState(0);
  const [totalUpload, setTotalUpload] = useState(0);
  const [passedUpload, setPassedUpload] = useState(0);
  const [totalCommunity, setTotalCommunity] = useState(0);
  const [passedCommunity, setPassedCommunity] = useState(0);
  const [totalClient, setTotalClient] = useState(0);
  const [passedClient, setPassedClient] = useState(0);
  const [effectiveEndDate, setEffectiveEndDate] = useState('');

  const [uploadActive, setUploadActive] = useState(false);
  const [communityActive, setCommunityActive] = useState(false);
  const [clientActive, setClientActive] = useState(false);

  useEffect(() => {
    console.log(props.contest);
    if (contest) {
      setContestEndDate();
    }
  }, []);

  useEffect(() => {
    if (props.contest) {
      setContestEndDate();
    }
  }, [contest]);

  useEffect(() => {
    if (props.contest) {
      setContestEndDate();
    }
  }, [cocreationContests]);

  useEffect(() => {
    if (contest) {
      setContestEndDate();
    }
  }, [props.cocreationcontest, props.contest]);

  const setContestEndDate = () => {
    // console.log(props.contest.contestType);
    let sons: CoCreationContest[] = [];
    if (contest) {
      if (props.contest.contestType === 'Co-creation') {
        console.log(cocreationContests);
        props.cocreationcontest.map((item) => {
          if (item.mainContest.id === contest.id) {
            sons.push(item);
          }
        });
        sons.map((item) => {
          console.log(item);
          if (date > new Date(item.startUploadDate).getTime() && date < new Date(item.endUploadDate).getTime()) {
            console.log('upload phase');
            setTotalUpload(
              Math.trunc((new Date(item.endUploadDate).getTime() - new Date(item.startUploadDate).getTime()) / 86400000)
            );

            setPassedUpload(Math.trunc((date - new Date(item.startUploadDate).getTime()) / 86400000));
            if (item.endVotingDate) {
              setCommunityActive(false);
            }
            setClientActive(false);
            setTotalClient(100);
            setPassedClient(10);
            setUploadActive(true);
            setEffectiveEndDate(item.endUploadDate);
            return;
            // console.log(new Date(item.endUploadDate).getTime());
          } else {
            if (item.endVotingDate) {
              if (date > new Date(item.endUploadDate).getTime() && date < new Date(item.endVotingDate).getTime()) {
                setCommunityActive(true);
                setUploadActive(false);
                setTotalUpload(100);
                setPassedUpload(100);
                setTotalCommunity(
                  Math.trunc(
                    (new Date(item.endVotingDate).getTime() - new Date(item.endUploadDate).getTime()) / 86400000
                  )
                );
                setPassedCommunity(Math.trunc((date - new Date(item.endUploadDate).getTime()) / 86400000));
                setTotalClient(100);
                setPassedClient(0);
                setEffectiveEndDate(item.endVotingDate);
              }
            } else {
              if (item.hypotheticalEndDate) {
                if (
                  date > new Date(item.endUploadDate).getTime() &&
                  date < new Date(item.hypotheticalEndDate).getTime()
                ) {
                  console.log('client vote');
                  setClientActive(true);
                  // console.log(
                  //   Math.trunc(
                  //     (new Date(item.hypotheticalEndDate).getTime() - new Date(item.endUploadDate).getTime()) / 86400000
                  //   )
                  // );
                  setTotalClient(
                    Math.trunc(
                      (new Date(item.hypotheticalEndDate).getTime() - new Date(item.endUploadDate).getTime()) / 86400000
                    )
                  );
                  setPassedClient(Math.trunc((date - new Date(item.endUploadDate).getTime()) / 86400000));
                  setEffectiveEndDate(item.hypotheticalEndDate);
                  // console.log(Math.trunc((date - new Date(item.endUploadDate).getTime()) / 86400000));
                } else {
                  if (
                    date > new Date(item.endUploadDate).getTime() &&
                    date > new Date(item.hypotheticalEndDate).getTime()
                  ) {
                    console.log('closed');
                  }
                }
              } else {
              }
            }
            console.log(date);
          }
        });
      } else {
        if (date > new Date(props.contest.startDate).getTime() && date < new Date(props.contest.endUploadDate).getTime()) {
          // console.log('upload contest normale');
          setUploadActive(true);
          setClientActive(false);
          setCommunityActive(false);
          if (props.contest.endVotingDate) {
            setCommunityActive(true);
          }
          setTotalUpload(
            Math.trunc(
              (new Date(props.contest.endUploadDate).getTime() - new Date(props.contest.startDate).getTime()) / 86400000
            )
          );
          console.log('setto passed');
          console.log((date - new Date(props.contest.startDate).getTime()) / 86400000);
          setPassedUpload(Math.trunc((date - new Date(props.contest.startDate).getTime()) / 86400000));
          setEffectiveEndDate(props.contest.endUploadDate);
        }
        if (contest.endVotingDate) {
          setCommunityActive(true);
          if (
            date > new Date(props.contest.endUploadDate).getTime() &&
            date < new Date(props.contest.endVotingDate).getTime()
          ) {
            console.log('community vote contest normale');
            setClientActive(false);
            setUploadActive(false);
            setTotalUpload(100);
            setPassedUpload(100);
            setTotalCommunity(
              Math.trunc(
                (new Date(props.contest.endVotingDate).getTime() - new Date(props.contest.endUploadDate).getTime()) / 86400000
              )
            );
            setPassedCommunity(Math.trunc((date - new Date(props.contest.endUploadDate).getTime()) / 86400000));
            setEffectiveEndDate(props.contest.endVotingDate);
          } else if (date > new Date(props.contest.endVotingDate).getTime()) {
            console.log('client vote contest normale');
            if (contest.hypotheticalEndDate) {
              setUploadActive(false);
              setTotalUpload(100);
              setPassedUpload(100);

              setTotalCommunity(100);
              setPassedCommunity(100);

              setClientActive(true);
              setTotalClient(
                Math.trunc(
                  (new Date(props.contest.hypotheticalEndDate).getTime() -
                    new Date(props.contest.endVotingDate).getTime()) /
                    86400000
                )
              );
              setPassedClient(Math.trunc((date - new Date(props.contest.endVotingDate).getTime()) / 86400000));
              setEffectiveEndDate(props.contest.hypotheticalEndDate);
            } else if (props.contest.isClosed) {
              setUploadActive(false);
              setTotalUpload(100);
              setPassedUpload(100);
              setTotalCommunity(100);
              setPassedCommunity(100);
              setClientActive(false);
              setTotalClient(100);
              setPassedClient(100);
            }
          }
        } else {
          if (props.contest.hypotheticalEndDate) {
            if (
              date > new Date(props.contest.endUploadDate).getTime() &&
              date < new Date(props.contest.hypotheticalEndDate).getTime()
            ) {
              console.log('community vote contest normale');
              setClientActive(true);
              setUploadActive(false);
              setTotalUpload(100);
              setPassedUpload(100);

              setTotalClient(
                Math.trunc(
                  (new Date(props.contest.hypotheticalEndDate).getTime() - new Date(props.contest.endUploadDate).getTime()) /
                    86400000
                )
              );
              setPassedClient(Math.trunc((date - new Date(props.contest.endUploadDate).getTime()) / 86400000));
              setEffectiveEndDate(props.contest.hypotheticalEndDate);
            }
          } else {
            if (props.contest.isClosed) {
              setUploadActive(false);
              setTotalUpload(100);
              setPassedUpload(100);
              setTotalCommunity(100);
              setPassedCommunity(100);
              setClientActive(false);
              setTotalClient(100);
              setPassedClient(100);
            }
          }
        }
      }
    }
  };

  const uploadProgress: number = (passedUpload / totalUpload) * 100;
  const communityProgress: number | undefined =
    totalCommunity && passedCommunity ? (passedCommunity / totalCommunity) * 100 : undefined;
  const clientProgress: number = (passedClient / totalClient) * 100;

  let isClosed: boolean = uploadProgress + (communityProgress ?? 100) + clientProgress === 300;


  return (
    <div className={`row ${styles.timelineContainer}`}>
      <div className="col-12">
        <div className={`row ${styles.timelineWrapper} ${isClosed && styles.closed} px-0 d-flex`}>
          <Timer
            leftDays={
              uploadActive && !clientActive && !communityProgress
                ? TLHLP.getDaysLeft({ total: totalUpload, passed: passedUpload + 1 })
                : communityActive && !uploadActive && !clientActive
                ? TLHLP.getDaysLeft({ total: totalCommunity, passed: passedCommunity + 1 })
                : clientActive
                ? TLHLP.getDaysLeft({ total: totalClient, passed: passedClient + 1 })
                : 0
            }
            // leftDays ={TLHLP.getLeftDays([props.progress])}
            closed={isClosed}
            endUploadDate={effectiveEndDate}
          />

          <div
            className={`col-3 g-0 flex-grow-1 ${styles.progressWrapper} ${styles.upload}  ${
              (communityProgress || clientActive) && styles.closed
            }`}
          >
            <ProgressSection
              progress={uploadProgress}
              label="Upload Phase"
              icon={faCloudArrowUp as IconProp}
              iconLabel="upload phase"
              daysLeft={TLHLP.getDaysLeft({ total: totalUpload, passed: passedUpload + 1 })}
              // active={TLHLP.isUploadActive(props.progress)}
              active={uploadActive && !communityProgress && !clientActive}
            />
          </div>

          {communityActive && (
            <div
              className={`col-2 g-0 ${styles.progressWrapper} ${styles.community} ${isClosed && styles.closed} ${
                (clientActive || uploadActive) && styles.closed
              }`}
            >
              <ProgressSection
                progress={uploadProgress > 99 ? (passedCommunity * 100) / totalCommunity : 0}
                label="Vote"
                icon={faUsers as IconProp}
                iconLabel="community vote"
                daysLeft={TLHLP.getDaysLeft({ total: totalCommunity, passed: passedCommunity + 1 })}
                active={communityActive && !uploadActive && !clientActive}
              />
            </div>
          )}
          {!clientActive && (
            <div
              className={`col-2 g-0 ${styles.progressWrapper} ${styles.client}  ${
                (TLHLP.isUploadActive(props.progress) || TLHLP.isCommunityactive(props.progress)) && styles.closed
              }`}
            >
              <ProgressSection
                progress={clientProgress}
                label="Client Vote"
                icon={faIndustry as IconProp}
                iconLabel="client vote"
                daysLeft={TLHLP.getDaysLeft({ total: totalClient, passed: passedClient + 1 })}
                active={false}
                pero={isClosed ? 1 : 0}
              />
            </div>
          )}
          {clientActive && (
            <div className={`col-2 g-0 ${styles.progressWrapper} ${styles.client} ${isClosed && styles.closed}`}>
              <ProgressSection
                progress={clientProgress}
                label="Client Vote"
                icon={faIndustry as IconProp}
                iconLabel="client vote"
                daysLeft={TLHLP.getDaysLeft({ total: totalClient, passed: passedClient + 1 })}
                active={clientActive}
                pero={isClosed ? 1 : 0}
              />
            </div>
          )}
          <div className={`col flex-shrink-1 ${styles.progressWrapper} ${styles.endSection} m-0 p-0`}>
            {isClosed && (
              <ProgressSection progress={100} label="Closed" closed={true} daysLeft={0} active={true} pero={1} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeLine;
