import React, { useEffect, Component, useState, useRef } from 'react';

//Header Data

import {
  getCommunityUser,
  getCurrentUser,
  getFooterMenu,
  getHeaderMenu,
  getInspirationsContestName,
  getUserInspirationPageNumber,
  getUserInspirations,
  getUserInspirationsFavorites,
} from '../../../store/Selectors';
import DslStore, { setInspirationsContestName, setUserInspirationsPageNumber } from '../../../store/DslStore';
import { useStoreState } from 'pullstate';
import styles from '../Profile.module.scss';
import inspirationPlaceholder from '../../../assets/Inspiration.png';

import {
  AddInspirationCardProfile,
  InspirationCard,
  InspirationCardProfile,
} from '../../../components/Contest/InspirationCard/InspirationCard';

import { InspirationVotes, User, UserInspiration } from '../../../global';
import DesignerInspirationNavigation, {
  PaginationSwitch,
} from '../../../components/Profile/DesignerInspirationsNavigation/DesignerInspirationNavigation';
import axiosHelper from '../../../helpers/axiosHelper';

interface ProfileProps {
  user: User;
  loggedUser: User | null;

  breadcrumbs: (breadcrumbs: string) => void;
}

const InspirationsWLF: React.FC<ProfileProps> = (props) => {
  const currentUser = useStoreState(DslStore, getCurrentUser);
  const allInspirations = useStoreState(DslStore, getUserInspirations);
  const allFavoriteInspirations = useStoreState(DslStore, getUserInspirationsFavorites);
  const pageNumber = useStoreState(DslStore, getUserInspirationPageNumber);
  const communityUser = useStoreState(DslStore, getCommunityUser);

  const [visibleInspirationsFavorite, setVisibileInspirationsFavorite] = useState<InspirationVotes[]>([]);
  const [userFavoriteInspirations, setUserFavoriteInspirations] = useState<InspirationVotes[]>([]);
  const [visibleInspirations, setVisibleInspirations] = useState<UserInspiration[]>([]);
  const [key, setKey] = useState(0);

  const firstInspiration: UserInspiration = {
    id: 0,
    textDescription:
      'Share with the community what inspires you in your design and earn extra reputation points for your profile',
    contest: {
      id: 0,
      name: 'Add inspiration',
      category: {
        name: '',
        id: 0,
      },
    },
    isPrivate: false,
    uploadDate: '',
    votesCount: 0,
    inspirationVotes: [],
  };

  useEffect(() => {
    props.breadcrumbs && props.breadcrumbs('Inspirations');
    // inspirationsFavorites();
    arraysfill();
    arraysInspirationsfill();
  }, []);

  useEffect(() => {
    console.log(allFavoriteInspirations);
    console.log('sono cambiate e faccio arrayFill');
    arraysInspirationsfill();
  }, [allFavoriteInspirations]);

  useEffect(() => {
    arraysInspirationsfill();
  }, [communityUser]);

  const arraysfill = () => {
    let chunkSize = 19;
    let startchunk = (pageNumber - 1) * chunkSize;
    let tempArray: UserInspiration[] = [];
    let removePrivate: UserInspiration[] = [];
    allInspirations.map((item) => {
      if (item.isPrivate) {
        if (currentUser?.id === communityUser?.id) {
          removePrivate.push(item);
        }
      } else {
        removePrivate.push(item);
      }
    });
    const chunk: UserInspiration[] = removePrivate.slice(startchunk, startchunk + chunkSize);

    for (let i = 0; i < allInspirations.length; i += chunkSize) {
      const chunk: UserInspiration[] = removePrivate.slice(startchunk, startchunk + chunkSize);
    }
    for (let i = 0; i < chunkSize; i++) {
      if (chunk[i] != undefined) {
        tempArray.push(chunk[i]);
      } else {
        break;
      }
    }
    currentUser?.id === communityUser?.id && tempArray.unshift(firstInspiration);
    setVisibleInspirations(tempArray);
  };

  const arraysInspirationsfill = () => {
    console.log('favorite fill');
    let chunkSize = 20;
    let startchunk = (pageNumber - 1) * chunkSize;
    let tempArray: InspirationVotes[] = [];
    const chunk: InspirationVotes[] = allFavoriteInspirations.slice(startchunk, startchunk + chunkSize);

    for (let i = 0; i < allFavoriteInspirations.length; i += chunkSize) {
      const chunk: InspirationVotes[] = allFavoriteInspirations.slice(startchunk, startchunk + chunkSize);
    }
    for (let i = 0; i < chunkSize; i++) {
      if (chunk[i] !== undefined) {
        tempArray.push(chunk[i]);
      } else {
        break;
      }
    }
    console.log(tempArray);

    setVisibileInspirationsFavorite(tempArray);
    // setKey(key + 1);
  };

  function youtube_parser(url?: string) {
    if (url) {
      var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
      var match = url.match(regExp);
      // console.log( match && match[7].length == 11 ? match[7] : false);
      console.log(match && match[7].length == 11 && `https://img.youtube.com/vi/${match[7]}/default.jpg`);
      return match && match[7].length == 11
        ? `https://img.youtube.com/vi/${match[7]}/default.jpg`
        : inspirationPlaceholder;
    } else {
      return inspirationPlaceholder;
    }
  }

  useEffect(() => {
    setVisibleInspirations(allInspirations);
    console.log('sono cambiate e faccio arrayFill NORMALE');
    arraysfill();
  }, [allInspirations]);

  useEffect(() => {
    setVisibleInspirations(allInspirations);

    arraysfill();
  }, [pageNumber]);

  return (
    <div className="bg-light-gray" key={key}>
      <main className={`${styles.profileWrapper} container pt-5  mb-5 px-sm-0 `}>
        <div className={`row`}>
          <DesignerInspirationNavigation />

          <div className="row pt-5 px-0 ps-1 " key={key}>
            {visibleInspirations?.map((aInspiration: UserInspiration, index: number) =>
              aInspiration.id > 0 ? (
                aInspiration.isPrivate ? (
                  currentUser?.id === communityUser?.id ? (
                    (aInspiration.websiteURL && youtube_parser(aInspiration.websiteURL),
                    (
                      <div className="col-6 col-md-6 col-lg-4 col-xl-3 py-3 px-2">
                        <InspirationCardProfile
                          inspiration={aInspiration}
                          userId={communityUser?.id}
                          key={key}
                          url={aInspiration.websiteURL}
                          image={
                            aInspiration?.imageAttachment
                              ? aInspiration?.imageAttachment?.s3Path
                              : youtube_parser(aInspiration.websiteURL)
                          }
                          name={aInspiration.contest?.name}
                          text={aInspiration.textDescription}
                          private={aInspiration.isPrivate}
                          contestId={aInspiration.contest?.id}
                        ></InspirationCardProfile>
                      </div>
                    ))
                  ) : (
                    ''
                  )
                ) : (
                  (aInspiration.websiteURL && youtube_parser(aInspiration.websiteURL),
                  (
                    <div className="col-6 col-md-6 col-lg-4 col-xl-3 py-3 ">
                      <InspirationCardProfile
                        inspiration={aInspiration}
                        userId={communityUser?.id}
                        key={key}
                        url={aInspiration.websiteURL}
                        image={
                          aInspiration?.imageAttachment
                            ? aInspiration?.imageAttachment?.s3Path
                            : youtube_parser(aInspiration.websiteURL)
                        }
                        private={aInspiration.isPrivate}
                        name={aInspiration.contest?.name}
                        text={aInspiration.textDescription}
                        contestId={aInspiration.contest?.id}
                      ></InspirationCardProfile>
                    </div>
                  ))
                )
              ) : (
                <div className="col-6  col-md-6 col-lg-4 col-xl-3 py-3 ">
                  <AddInspirationCardProfile
                    id={aInspiration.id}
                    text={aInspiration.textDescription}
                    name={aInspiration.contest?.name}
                    private={false}
                    image={''}
                  ></AddInspirationCardProfile>
                </div>
              )
            )}
            {visibleInspirationsFavorite.map((aFavoriteInspiration: InspirationVotes, index: number) => (
              // console.log(aFavoriteInspiration),
              <div className="col-6  col-md-6 col-lg-4 col-xl-3 py-3">
                <InspirationCard
                  text={aFavoriteInspiration.inspiration.textDescription}
                  image={
                    aFavoriteInspiration.inspiration.imageAttachment
                      ? aFavoriteInspiration.inspiration.imageAttachment.s3Path
                      : youtube_parser(aFavoriteInspiration.inspiration.websiteURL)
                  }
                  profile={true}
                  contestName={aFavoriteInspiration.inspiration.contest?.name}
                  id={aFavoriteInspiration.inspiration.id}
                  key={key}
                  private={aFavoriteInspiration.inspiration.isPrivate}
                  name={aFavoriteInspiration.inspiration.user.username}
                  userId={aFavoriteInspiration.inspiration.user.id}
                  url={aFavoriteInspiration.inspiration.websiteURL}
                  inspirationVotes={aFavoriteInspiration.inspiration.inspirationVotes.length}
                  contestId={aFavoriteInspiration.inspiration.contest?.id}
                />
              </div>
            ))}
          </div>

          <div className="row mt-3 px-4"></div>
          <div className="row ">
            <div className="col-12 px-4 d-flex justify-content-sm-start justify-content-md-end ">
              {visibleInspirations.length > 0 ? <PaginationSwitch onChangePage={setUserInspirationsPageNumber} /> : ''}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default InspirationsWLF;
