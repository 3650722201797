import React from 'react';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';

interface DesignPagerProps {
  click: (number: number) => void;
}

export const DesignPager: React.FC<DesignPagerProps> = (props) => {
  return (
    <div className={` py-2 px-2 `}>
      <button className="btn-icon" onClick={() => props.click(-1)}>
        <FontAwesomeIcon icon={faAngleLeft as IconProp} className="btn-icon active " /> &nbsp; Prev entries
      </button>
      &nbsp;&nbsp;
      <button className="btn-icon" onClick={() => props.click(1)}>
        Next entries &nbsp;
        <FontAwesomeIcon icon={faAngleRight as IconProp} className="btn-icon active " />
      </button>
    </div>
  );
};
