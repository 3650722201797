import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import styles from './ContestsNavigation.module.scss';
import DslStore, { setCurrentPage } from '../../../store/DslStore';
import { getContestSearchParams } from '../../../store/Selectors';
import { useStoreState } from 'pullstate';

type PaginationSwitcherPropsWLF = {};

export const PaginationSwitcherWLF: React.FC<PaginationSwitcherPropsWLF> = () => {
  const [array, setArray] = useState<Array<number>>([1, 2, 3, 4, 5]);
  const contestSearchParams = useStoreState(DslStore, getContestSearchParams);

  useEffect(() => {
    const pageNumber = contestSearchParams.currentPage;
    var i;
    if (contestSearchParams.numberOfPages === 1) {
      setArray([1]);
    }
    if (contestSearchParams.numberOfPages < 5) {
      let numbers: number[] = [];
      for (i = 1; i < contestSearchParams.numberOfPages + 1; i++) {
        numbers.push(i);
      }
      setArray(numbers);
    } else {
      let numbers: number[] = [];
      if (pageNumber <= 3) {
        for (i = 1; i < contestSearchParams.numberOfPages + 1; i++) {
          if (i < 6) {
            numbers.push(i);
          }
        }
      }
      setArray(numbers);
      if (pageNumber > 3 && pageNumber <= contestSearchParams.numberOfPages - 2) {
        setArray([pageNumber - 2, pageNumber - 1, pageNumber, pageNumber + 1, pageNumber + 2]);
      } else {
        if (pageNumber > 3 && pageNumber <= contestSearchParams.numberOfPages - 1) {
          setArray([pageNumber - 3, pageNumber - 2, pageNumber - 1, pageNumber, pageNumber + 1]);
        } else {
          if (pageNumber > 3 && pageNumber === contestSearchParams.numberOfPages) {
            setArray([pageNumber - 4, pageNumber - 3, pageNumber - 2, pageNumber - 1, pageNumber]);
          }
        }
      }
    }
  }, [contestSearchParams.numberOfPages, contestSearchParams.currentPage]);

  const handleChange = (value: number) => {
    console.log(value);
    if (value > 0 && value <= contestSearchParams.numberOfPages) {
      setCurrentPage(value);
      // if (pageNumber >= 3 && pageNumber <= pagesCount - 2) {
      //   setArray([pageNumber - 2, pageNumber - 1, pageNumber, pageNumber + 1, pageNumber + 2]);
      // }
    } else {
      console.log('0 o max++');
    }
  };

  return (
    <div className={`${styles.paginationSwitch}  d-flex align-items-center justify-content-center bg-light py-2 px-0 `}>
      <button className="btn-icon" onClick={() => handleChange(contestSearchParams.currentPage - 1)}>
        <FontAwesomeIcon icon={faAngleLeft as IconProp} className="btn-icon active " />
      </button>
      {array.map((index: number) => (
        <button
          key={index}
          className={`${contestSearchParams.currentPage === index ? 'btn-icon active' : 'btn-icon'} px-2`}
          onClick={() => handleChange(index)}
        >
          <small>{index}</small>
        </button>
      ))}
      <button className="btn-icon" onClick={() => handleChange(contestSearchParams.currentPage + 1)}>
        <FontAwesomeIcon icon={faAngleRight as IconProp} className="btn-icon active " />
      </button>
    </div>
  );
};
export default PaginationSwitcherWLF;
