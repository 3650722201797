import { CaseStudiesHalfImgProps } from '../../../../global';
import { pathStrapiImage } from '../../../../helpers/utils';

import SplitSectionImage from '../../../SplitSectionImage/SplitSectionImage';

function CaseStudiesBigHalfImgComponent(props: CaseStudiesHalfImgProps) {
  console.log(props)
  return (
    <>
      {props.imagepos === 'right' ? (
        <SplitSectionImage
          key={props.id}
          bgColor={props.background}
          bgImage={props.image && pathStrapiImage(props.image.data.attributes.url)}
          reverse
          content={
            <div className=" row " style={{ height: '50vh' }}>
              <div className=" col-12 ms-md-2 ps-md-3 my-7">
                {props.title && (
                  <div className="row">
                    <div className="col-12">
                      <h1
                        className={`color-${props.titlecolor}  ${
                          props.titlealign ? `text-${props.titlealign}` : `text-start`
                        } bold-title-100  mb-7`}
                      >
                        {' '}
                        {props.title}
                      </h1>
                    </div>
                  </div>
                )}
                {props.text && (
                  <div className="row">
                    <div className="row">
                      <div
                        className={`color-${props.textcolor}  ${
                          props.textalign ? `text-${props.textalign}` : `text-start`
                        } col-12 col-lg-8 text-16`}
                      >
                        {' '}
                        {props.text}
                      </div>
                    </div>
                  </div>
                )}
                {props.buttontext && (
                  <div className="row">
                    <div className="col-12  d-flex align-items-center justify-content-start mt-4">
                      <a href={props.link} target={`${props.target ? props.target : '_self'}`}>
                        <button className={`btn-${props.butncolor} rounded `}>{props.buttontext}</button>
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </div>
          }
        />
      ) : (
        <SplitSectionImage
          key={props.id}
          bgColor={props.background}
          bgImage={props.image && pathStrapiImage(props.image.data.attributes.url)}
          content={
            <div className="row py-7">
              <div className="col-12 py-md-7">
                {props.title && (
                  <div className="row">
                    <div className="col-12">
                      <div className="col-12">
                        <h1
                          className={`color-${props.titlecolor}  ${
                            props.titlealign ? `text-${props.titlealign}` : `text-start`
                          } bold-title-100  mb-7`}
                        >
                          {' '}
                          {props.title}
                        </h1>
                      </div>
                    </div>
                  </div>
                )}
                {props.text && (
                  <div className="row">
                    <div
                      className={`color-${props.textcolor}  ${
                        props.textalign ? `text-${props.textalign}` : `text-start`
                      } col-12 col-lg-8 text-16`}
                    >
                      {props.text}
                    </div>
                  </div>
                )}
                {props.buttontext && (
                  <div className="row">
                    <div className="col-12">
                      <a href={props.link} target={`${props.target ? props.target : '_self'}`}>
                        <button className={`btn-${props.butncolor} rounded mt-7`}>{props.buttontext}</button>
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </div>
          }
        />
      )}
    </>
  );
}

export default CaseStudiesBigHalfImgComponent;
