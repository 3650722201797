import { useEffect, useState } from 'react';
import { User } from '../../../global';
import styles from './UpdatePassword.module.scss';
import axiosHelper from '../../../helpers/axiosHelper';

interface UpdatePasswordProps {
  upload: (n: number) => void;
  open: (open: number) => void;
  isOpen: boolean;
}

const UpdatePassword: React.FC<UpdatePasswordProps> = (props) => {
  const [changePwdError, setChangePwdError] = useState(false);
  const [updatePasswordPopUp, setUpdatePasswordPopUp] = useState(props.isOpen);
  const [key, setKey] = useState(1);
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordRpt, setNewPasswordRpt] = useState('');

  const changePassword = async () => {
    console.log(password);
    console.log(newPassword);
    console.log(newPasswordRpt);
    if (newPassword === newPasswordRpt) {
      if (password && newPassword) {
        await axiosHelper
          .changePassword(password, newPassword)
          .then((response) => {
            console.log(response);
            setPassword('');
            setNewPassword('');
            setNewPasswordRpt('');
            window.scrollTo(0, 0);
            props.upload(3);
          })
          .catch((error) => {
            props.upload(2);
          });
      }
    } else {
      setChangePwdError(true);
    }
  };

  useEffect(() => {
    setUpdatePasswordPopUp(props.isOpen);
  }, [props.isOpen]);

  return (
    <>
      <div
        key={key}
        className="col-12    bg-blue mb-5 align-items-center justify-content-center  d-flex"
        style={{ height: '30px', borderRadius: '8px', cursor: 'pointer' }}
        onClick={() => (
          setUpdatePasswordPopUp(!updatePasswordPopUp), props.open(updatePasswordPopUp ? 0 : 4), setKey(key + 1)
        )}
      >
        <div className="col-2"></div>
        <div className="col-8 justify-content-center d-flex ">
          <span className="text-xxl fw-bold color-light" style={{ fontSize: '2rem' }}>
            Update password
          </span>
        </div>

        <div
          className={`col-2 d-flex justify-content-end align-items-center ${styles.toggle} ${
            updatePasswordPopUp && styles.open
          }`}
          onClick={() => setUpdatePasswordPopUp(!updatePasswordPopUp)}
        >
          <div className={`${styles.arrow} `}> </div>
        </div>
      </div>
      {updatePasswordPopUp && (
        <form>
          <div className="px-5 mt-7">
            <div className="row mb-5 pt-5">
              <div className="col-12 col-md-2 text fw-bold">Old password</div>
              <div className="col-12 col-md-10">
                <input type="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)} />
              </div>
            </div>
            <div className="row mb-5 mt-4">
              <div className="col-12 col-md-2 text fw-bold">New password</div>
              <div className="col-12 col-md-10">
                <input
                  type="password"
                  id="newPassword"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </div>
            </div>
            <div className="row mb-5 mt-4">
              <div className="col-12 col-md-2 text fw-bold"> Repeat new</div>
              <div className="col-12 col-md-10">
                <input
                  type="password"
                  id="newPasswordRpt"
                  value={newPasswordRpt}
                  onChange={(e) => setNewPasswordRpt(e.target.value)}
                />
                {changePwdError ? <p>passwords are different</p> : ''}
              </div>
            </div>
            <div className="row  d-flex justify-content-center align-items-center mb-7 mt-4">
              <div className="row justify-content-center">
                <div
                  className={`col-12 col-md-3 my-5 btn-success rounded 3 ${styles.button} `}
                  onClick={changePassword}
                >
                  update password
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export default UpdatePassword;
