import React from 'react';
import SquaredImage from '../../SquaredImage/SquaredImage';

import styles from './cardInfo.module.scss';
import { User } from '../../../global';
import axiosHelper from '../../../helpers/axiosHelper';
import DslStore, { setCommunityUser } from '../../../store/DslStore';
import { useNavigate } from 'react-router-dom';
import profileImg from '../../../assets/Profile.png';
import { useStoreState } from 'pullstate';
import { getCurrentUser } from '../../../store/Selectors';
import { ROUTEPATHS } from '../../../routes/routePaths';
export interface ICardInfo {
  image?: string;
  circle?: boolean;
  altText?: string;
  name?: string;
  userId?: number;
  children?: React.ReactNode;
}

const CardInfo: React.FC<ICardInfo> = (props) => {
  const redirect = useNavigate();
  const loggedUser = useStoreState(DslStore, getCurrentUser);

  const goToDetail = async (userId?: number) => {
    // console.log(user);
    if (userId) {
      let result: User = await axiosHelper.retrieveUserById(userId);
      // console.log(result);
      setCommunityUser(result);
      if (loggedUser?.id && loggedUser.id === result.id) {
        redirect(`/${ROUTEPATHS.COMMUNITY}/${props.name}/profile-editable`);
      } else {
        redirect(`/${ROUTEPATHS.COMMUNITY}/${props.name}/Profile`);
      }
    }
  };

  return (
    <div className={`${styles.cardInfoWrapper}  d-flex flex-column bg-light position-relative`}>
      <div
        className={`${props.image ? styles.cardInfoImage : styles.cardImagePlaceholder} bg-light ${
          props.circle && 'px-5 pt-4'
        }`}
        onClick={() => goToDetail(props.userId)}
        style={{ cursor: 'pointer' }}
      >
        {props.image ? (
          // <img src={props.image} alt={props.altText} className={`${props.circle ?? 'circle'}`} />
          <SquaredImage image={props.image} altText={props.altText} circle={props.circle} />
        ) : (
          <div className={`${styles.squaredImageWrapper}`}>
            <div className={`${styles.imageInner}`}>
              <img src={profileImg} alt="profile" />
              {/* <FontAwesomeIcon icon={faUser as IconProp} className="color-light " /> */}
            </div>
          </div>
        )}
      </div>
      <div className={`${styles.cardInfoContent} bg-light`}>{props.children}</div>
    </div>
  );
};

export default CardInfo;
