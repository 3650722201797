import React, { useEffect, useState } from 'react';
import styles from './SearchNavigation.module.scss';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import resetFilter from '../../assets/Reset-Filter.svg';

import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import DslStore, { setContestGallery, setDesignSearchPageNumber, setSearchResultDesign } from '../../store/DslStore';
import { GalleryInspirationSwitch } from '../Reusable/LayoutSwitch';
import {
  getCoCreationContestGallery,
  getDesignSearchPageNumber,
  getDesignSearchPageNumberLimit,
  getSearchResultDesignCopy,
} from '../../store/Selectors';
import { useStoreState } from 'pullstate';
import { DropDownInspirationSort } from '../Forms/DropDown/DropDownInspirations';

import { GalleryDesign } from '../../global';
import { getSearchResultDesign } from '../../store/Selectors';

type IProjecNavigation = {
  onChangeLayout?: (val: number) => void;
};

type PaginationSwitchProps = {
  onChangePage?: (val: number) => void;
};

export const DesignSearchPaginationSwitch: React.FC<PaginationSwitchProps> = (props) => {
  const pageNumber = useStoreState(DslStore, getDesignSearchPageNumber);
  const limit = useStoreState(DslStore, getDesignSearchPageNumberLimit);

  //   const contests = useStoreState(DslStore, getInspirations);
  // const gallery = 4;

  const [array, setArray] = useState<Array<string>>(['1', '2', '3', '...', `${limit}`]);

  useEffect(() => {
    console.log('cambiato numero page');
    if (limit < 5) {
      let arr: string[] = [];
      for (let i = 0; i < limit; i++) {
        let ciccio = i + 1;
        arr.push(ciccio.toString());
      }

      setArray(arr);
    }
    if (pageNumber < 3 && limit >= 5) {
      setArray([`1`, `2`, `3`, `...`, `${limit}`]);
    } else if (pageNumber >= 3 && pageNumber <= limit - 3 && limit >= 5) {
      setArray([`${pageNumber - 2}`, `${pageNumber - 1}`, `${pageNumber}`, `...`, `${limit}`]);
    } else if (pageNumber === limit - 2 && limit >= 5) {
      setArray([`${pageNumber - 2}`, `${pageNumber - 1}`, `${pageNumber}`, `${pageNumber + 1}`, `${limit}`]);
    } else if (pageNumber === limit - 1 && limit >= 5) {
      setArray([`${pageNumber - 3}`, `${pageNumber - 2}`, `${pageNumber - 1}`, `${pageNumber}`, `${limit}`]);
    } else if (pageNumber === limit && limit >= 5) {
      setArray([`${pageNumber - 4}`, `${pageNumber - 3}`, `${pageNumber - 2}`, `${pageNumber - 1}`, `${pageNumber}`]);
    }
  }, [pageNumber, limit]);

  const handleChange = (value: number) => {
    console.log(value);
    if (value > 0 && value <= limit) {
      props.onChangePage && props.onChangePage(value);
      if (pageNumber >= 3 && pageNumber <= limit - 2) {
        setArray([`${pageNumber - 2}`, `${pageNumber - 1}`, `${pageNumber}`, `${limit - 1}`, `${limit}`]);
      }
    } else {
      console.log('0 o max++');
    }
  };

  return (
    <div className={`${styles.paginationSwitch} bg-light py-2 px-2 `}>
      <button className="btn-icon" onClick={() => handleChange(pageNumber - 1)}>
        <FontAwesomeIcon icon={faAngleLeft as IconProp} className="btn-icon active " />
      </button>

      {array.map((item: string, index: number) => (
        <button
          key={index}
          className={pageNumber.toString() == item ? 'btn-icon active' : 'btn-icon'}
          onClick={() => handleChange(Number(item))}
        >
          <small>{item}</small>
        </button>
      ))}
      <button className="btn-icon" onClick={() => handleChange(pageNumber + 1)}>
        <FontAwesomeIcon icon={faAngleRight as IconProp} className="btn-icon active " />
      </button>
    </div>
  );
};

let filterCategories = [
  {
    label: 'All',
    value: '0',
  },
  {
    label: 'Product Design',
    sort: 'Product Design',
    value: '1',
  },
  {
    label: 'New Idea',
    sort: 'New Idea',
    value: '2',
  },
  {
    label: 'Interior Design',
    sort: 'Interior design',
    value: '3',
  },
  {
    label: 'Packaging',
    sort: 'Packaging',
    value: '3',
  },
  {
    label: 'Naming',
    sort: 'Naming',
    value: '3',
  },
  {
    label: 'Co-Creation',
    sort: 'Co-creation',
    value: '3',
  },
  {
    label: 'Craft Design',
    sort: 'Craft design',
    value: '3',
  },
  {
    label: 'Link to Other',
    sort: 'Link to other',
    value: '3',
  },
];

let filterSort = [
  {
    label: 'Title',
    sort: 'designName',
    value: '1',
  },
  {
    label: 'Votes',
    sort: 'votes',
    value: '2',
  },

  {
    label: 'Date',
    sort: 'uploadDate',
    value: '3',
  },
];

const DesignSearchNavigation: React.FC<IProjecNavigation> = (props) => {
  const designs = useStoreState(DslStore, getSearchResultDesign);
  const designsCopy = useStoreState(DslStore, getSearchResultDesignCopy);
  const [descs, setDescs] = useState(false);
  const [reload, setReload] = useState(false);
  const [sort, setSort] = useState('');

  const sortDesigns = () => {
    setSearchResultDesign(designsCopy);
    let sorted: GalleryDesign[] = [...designs];
    if (sort) {
      console.log(descs);
      if (sort === 'uploadDate') {
        if (!descs) {
          sorted.sort((a, b) => {
            if (a.uploadDate && b.uploadDate) {
              return a.uploadDate.localeCompare(b.uploadDate);
            }
            return 0;
          });
        } else {
          sorted.sort((a, b) => {
            if (a.uploadDate && b.uploadDate) {
              return b.uploadDate.localeCompare(a.uploadDate);
            }
            return 0;
          });
        }
      } else if (sort === 'votes') {
        if (!descs) {
          sorted.sort((a, b) =>
            a.votesCount && b.votesCount && a.votesCount < b.votesCount ? 1 : a.votesCount > b.votesCount ? -1 : 0
          );
        } else {
          sorted.sort((a, b) =>
            a.votesCount && b.votesCount && a.votesCount < b.votesCount ? -1 : a.votesCount > b.votesCount ? 1 : 0
          );
        }
      } else if (sort === 'designName') {
        if (descs) {
          sorted.sort((a, b) =>
            b.name.toLowerCase() > a.name.toLowerCase() ? 1 : a.name.toLowerCase() > b.name.toLowerCase() ? -1 : 0
          );
        } else {
          sorted.sort((a, b) =>
            a.name.toLowerCase() > b.name.toLowerCase() ? 1 : b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0
          );
        }
      }
      setSearchResultDesign(sorted);
    }
  };

  useEffect(() => {
    sortDesigns();
  }, [descs, sort]);

  return (
    <div className="row py-4 mt-4">
      <div className="col-12 col-md-12 col-lg-8 d-flex flex-column px-3 flex-lg-row justify-content-between mb-4 mb-md-0">
        <div className="w-100  mb-sm-3 mb-lg-0">
          <DropDownInspirationSort
            label="Sort by"
            reset={reload}
            onSelect={(option) => option.sort && setSort(option.sort)}
            descs={(descs) => setDescs(descs)}
            options={filterSort}
            thin
          />
        </div>
        <div className=" d-flex flex-row align-items-center justify-content-center h-100 w-100">
          <div
            // className="btn-blue rounded"
            className=" d-flex flex-row align-items-center justify-content-center btn-reset-blue   "
            onClick={() => (setReload(!reload), setSearchResultDesign(designsCopy))}
            style={{ width: '3rem', borderRadius: '8px' }}
          >
            <img src={resetFilter} style={{ height: '25px' }} />
          </div>
        </div>
      </div>
      <div className={`col-12 col-md-12 col-lg-4 d-flex px-1 justify-content-sm-start justify-content-lg-end `}>
        <div className="ms-sm-2 ps-sm-1 me-lg-5 me-sm-5  ">
          <DesignSearchPaginationSwitch onChangePage={setDesignSearchPageNumber} />
        </div>
        <div>
          <GalleryInspirationSwitch onChangeLayout={props.onChangeLayout} />
        </div>
      </div>
    </div>
  );
};

export default DesignSearchNavigation;
