import React from 'react';
import ActionButton from '../ActionButton/ActionButton';
import styles from './SquaredImage.module.scss';
import profileImg from '../../assets/Profile.png';
import { fullPathLogo } from '../../helpers/utils';
interface ISquaredImage {
  image: string;
  circle?: boolean;
  altText?: string;
}

const SquaredImage: React.FC<ISquaredImage> = (props) => {
  // console.log(props.circle);
  return (
    <div className={`${styles.squaredImageWrapper} ${props.circle && styles.circle}`}>
      <div className={`${styles.imageInner} `}>
        {props.image !== '' && <img src={props.image} alt={props.altText} />}
        {props.image === '' && <img src={profileImg} alt="" />}
      </div>
    </div>
  );
};

export default SquaredImage;
