import React, { useEffect, useState } from 'react';
import styles from './ProjectsNavigation.module.scss';
import resetFilter from '../../../assets/Reset-Filter.svg';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';

import DslStore, { setCommunity, setCommunityCopy, setCommunityPageNumber } from '../../../store/DslStore';
import { LayoutCommunitySwitch } from '../../Reusable/LayoutSwitch';
import { useStoreState } from 'pullstate';
import { getCommunityPageNumber, getCommunityPageNumberLimit, getUsers, getUsersCopy } from '../../../store/Selectors';
import { DropDownCommunitySorted, DropDownCommunityType } from '../../Forms/DropDown/DropDownCommunity';
import { User } from '../../../global';

import axiosHelper from '../../../helpers/axiosHelper';

type IProjecCommunityNavigation = {
  onChangeLayout?: (val: number) => void;
};

type CommunityPaginationSwitchProps = {
  onChangePage?: (val: number) => void;
};

export const CommunityPaginationSwitch: React.FC<CommunityPaginationSwitchProps> = (props) => {
  const pageNumber = useStoreState(DslStore, getCommunityPageNumber);
  const limit = useStoreState(DslStore, getCommunityPageNumberLimit);

  const [array, setArray] = useState<Array<string>>(['1', '2', '3', '...', `${limit}`]);

  useEffect(() => {
    console.log('cambiato numero page');
    if (limit < 5) {
      let arr: string[] = [];
      for (let i = 0; i < limit; i++) {
        let ciccio = i + 1;
        arr.push(ciccio.toString());
      }

      setArray(arr);
    }
    if (pageNumber < 3 && limit >= 5) {
      setArray([`1`, `2`, `3`, `...`, `${limit}`]);
    } else if (pageNumber >= 3 && pageNumber <= limit - 3 && limit >= 5) {
      setArray([`${pageNumber - 2}`, `${pageNumber - 1}`, `${pageNumber}`, `...`, `${limit}`]);
    } else if (pageNumber === limit - 2 && limit >= 5) {
      setArray([`${pageNumber - 2}`, `${pageNumber - 1}`, `${pageNumber}`, `${pageNumber + 1}`, `${limit}`]);
    } else if (pageNumber === limit - 1 && limit >= 5) {
      setArray([`${pageNumber - 3}`, `${pageNumber - 2}`, `${pageNumber - 1}`, `${pageNumber}`, `${limit}`]);
    } else if (pageNumber === limit && limit >= 5) {
      setArray([`${pageNumber - 4}`, `${pageNumber - 3}`, `${pageNumber - 2}`, `${pageNumber - 1}`, `${pageNumber}`]);
    }
  }, [pageNumber, limit]);

  const handleChange = (value: number) => {
    console.log(value);
    if (value > 0 && value <= limit) {
      props.onChangePage && props.onChangePage(value);
      if (pageNumber >= 3 && pageNumber <= limit - 2) {
        setArray([`${pageNumber - 2}`, `${pageNumber - 1}`, `${pageNumber}`, `${limit - 1}`, `${limit}`]);
      }
    } else {
      console.log('0 o max++');
    }
  };

  return (
    <div className={`${styles.paginationSwitch} bg-light py-2 px-2`}>
      <button className="btn-icon" onClick={() => handleChange(pageNumber - 1)}>
        <FontAwesomeIcon icon={faAngleLeft as IconProp} className="btn-icon active " />
      </button>

      {/* <button className={'btn-icon active'} onClick={() => handleChange(pageNumber - 1)}>
        <strong> &lt; </strong>
      </button> */}

      {array.map((item: string, index: number) => (
        <button
          key={index}
          className={pageNumber.toString() == item ? 'btn-icon active' : 'btn-icon'}
          onClick={() => handleChange(Number(item))}
        >
          <small>{item}</small>
        </button>
      ))}

      {/* <button className={'btn-icon active'} onClick={() => handleChange(pageNumber + 1)}>
        <strong> &gt; </strong>
      </button> */}

      <button className="btn-icon" onClick={() => handleChange(pageNumber + 1)}>
        <FontAwesomeIcon icon={faAngleRight as IconProp} className="btn-icon active " />
      </button>
    </div>
  );
};

let filterProfile = [
  {
    label: 'All',
    sort: 'all',
    value: '1',
  },
  {
    label: 'All Users',
    sort: 'designers',
    value: '2',
  },

  {
    label: 'All Clients',
    sort: 'clients',
    value: '3',
  },
  {
    label: '',
    sort: '',
    value: '',
  },
  {
    label: 'User',
    sort: '',
    value: '4',
  },
  {
    label: 'Student',
    sort: 'student',
    value: '5',
  },
  {
    label: 'Designer',
    sort: 'designer',
    value: '6',
  },
  {
    label: 'Senior Designer',
    sort: 'senior designer',
    value: '7',
  },
  {
    label: 'Architect',
    sort: 'architect',
    value: '8',
  },
  {
    label: 'Engineer',
    sort: 'engineer',
    value: '9',
  },
  {
    label: 'Inventor',
    sort: 'inventor',
    value: '10',
  },
  {
    label: 'Makers / Artisan',
    sort: 'makers / artisan',
    value: '11',
  },
  {
    label: 'Graphic Designer',
    sort: 'graphic designer',
    value: '12',
  },
  {
    label: 'Copywriter',
    sort: 'copywriter',
    value: '13',
  },
  {
    label: 'Artist',
    sort: 'artist',
    value: '14',
  },
  {
    label: 'Design Lovers',
    sort: 'design lovers',
    value: '15',
  },
  {
    label: 'Other',
    sort: 'other',
    value: '16',
  },
  {
    label: '',
    sort: '',
    value: '',
  },
  {
    label: 'Client',
    sort: '',
    value: '17',
  },
  {
    label: 'Company',
    sort: 'company',
    value: '18',
  },
  {
    label: 'Individual',
    sort: 'individual',
    value: '5',
  },
];

let filterSort = [
  {
    label: 'Name',
    sort: 'username',
    value: '1',
  },
  {
    label: 'Date',
    sort: 'registrationDate',
    value: '2',
  },
  {
    label: 'Design',
    sort: 'badgeDesignsOut',
    value: '3',
  },
  {
    label: 'Contest',
    sort: 'contestsCount',
    value: '4',
  },
  {
    label: 'Award',
    sort: 'badgeAmountIn',
    value: '5',
  },
  {
    label: 'Reputation',
    sort: 'reputationCount',
    value: '6',
  },
];

const CommunityNavigation: React.FC<IProjecCommunityNavigation> = (props) => {
  const [reload, setReload] = useState(false);
  const [activeSearch, setActiveSearch] = useState(false);
  const [typologySort, setTypologySort] = useState('');
  const [sortBySort, setSortBySort] = useState('');
  const [descs, setDescs] = useState(false);
  const community = useStoreState(DslStore, getUsers);
  const communityCopy = useStoreState(DslStore, getUsersCopy);
  const pageNumber = useStoreState(DslStore, getCommunityPageNumber);
  const [currentBatch, setCurrentBatch] = useState(1);

  useEffect(() => {
    if (pageNumber % 3 < 3) {
      let batchNumber = Math.trunc(pageNumber / 3 + 1);
      // console.log('batch: ' + batchNumber);
      if (currentBatch < batchNumber) {
        search(batchNumber).then((response) => {
          setCurrentBatch(batchNumber);
          let oldCommunity: User[] = [...community];
          response.map((item) => {
            oldCommunity.push(item);
          });
          setCommunity(oldCommunity);
        });
      }
    }
    if (!typologySort && !sortBySort) {
      if (pageNumber % 50 === 0) {
        if (pageNumber / 50 === currentBatch) {
          // console.log('to mare');
          // setCurrentBatch(currentBatch + 1);
          axiosHelper.getUsers(currentBatch + 1).then((response) => {
            setCurrentBatch(currentBatch + 1);
            let oldCommunity: User[] = [...community];
            response.data.map((item) => {
              oldCommunity.push(item);
            });
            setCommunity(oldCommunity);
            setCommunityCopy(oldCommunity);
          });
        }
      }
    }
  }, [pageNumber]);

  useEffect(() => {
    setCurrentBatch(1);
    search(1, true).then(() => setCommunityPageNumber(1));
  }, [sortBySort, typologySort, descs]);

  const search = (batch: number, first?: boolean) =>
    new Promise<User[]>((resolve, reject) => {
      let sorted: User[] = [...community];
      console.log(descs);
      if (typologySort) {
        console.log(typologySort);
        if (typologySort === 'all') {
          if (sortBySort) {
            console.log(sortBySort);
            axiosHelper.getUsersSorted(descs, batch, sortBySort).then((response) => {
              first && setCommunity(response);
              resolve(response);
            });
          } else {
            axiosHelper.getUsersSorted(descs, batch).then((response) => {
              first && setCommunity(response);
              resolve(response);
            });
          }
        } else if (typologySort === 'designers') {
          if (sortBySort) {
            axiosHelper.getDesignersSorted(descs, batch, sortBySort).then((response) => {
              first && setCommunity(response);
              resolve(response);
            });
          } else {
            axiosHelper.getDesignersSorted(descs, batch).then((response) => {
              first && setCommunity(response);
              resolve(response);
            });
          }
        } else if (typologySort === 'clients') {
          if (sortBySort) {
            axiosHelper.getClientsSorted(descs, batch, sortBySort).then((response) => {
              first && setCommunity(response);
              resolve(response);
            });
          } else {
            axiosHelper.getClientsSorted(descs, batch).then((response) => {
              first && setCommunity(response);
              resolve(response);
            });
          }
        } else if (typologySort === 'company') {
          if (sortBySort) {
            axiosHelper.getCompanySorted(descs, batch, sortBySort).then((response) => {
              first && setCommunity(response);
              resolve(response);
            });
          } else {
            axiosHelper.getCompanySorted(descs, batch).then((response) => {
              first && setCommunity(response);
              resolve(response);
            });
          }
        } else if (typologySort === 'individual') {
          if (sortBySort) {
            axiosHelper.getIndividualSorted(descs, batch, sortBySort).then((response) => {
              first && setCommunity(response);
              resolve(response);
            });
          } else {
            axiosHelper.getIndividualSorted(descs, batch).then((response) => {
              first && setCommunity(response);
              resolve(response);
            });
          }
        } else {
          if (sortBySort) {
            console.log(descs, typologySort, batch, sortBySort);
            axiosHelper.getDesignersJobSorted(descs, typologySort, batch, sortBySort).then((response) => {
              console.log(response);
              first && setCommunity(response);
              resolve(response);
            });
          } else {
            axiosHelper.getDesignersJobSorted(descs, typologySort, batch).then((response) => {
              console.log(descs, typologySort, batch);
              first && setCommunity(response);
              resolve(response);
            });
          }
        }
      } else if (sortBySort) {
        // console.log(sortBySort);
        // if (sortBySort === 'username') {
        axiosHelper.getAllUsersSorted(descs, batch, sortBySort).then((response) => {
          first && setCommunity(response);
          resolve(response);
        });
        // } else if (sortBySort === 'registrationDate') {
        //   sorted.sort((a, b) => {
        //     if (descs) {
        //       return b.registrationDate.localeCompare(a.registrationDate);
        //     } else {
        //       return a.registrationDate.localeCompare(b.registrationDate);
        //     }
        //   });
        // } else if (sortBySort === 'badgeDesignsOut') {
        //   sorted.sort((a, b) =>
        //     descs
        //       ? b.badgeDesignsOut > a.badgeDesignsOut
        //         ? 1
        //         : a.badgeDesignsOut > b.badgeDesignsOut
        //         ? -1
        //         : 0
        //       : b.badgeDesignsOut > a.badgeDesignsOut
        //       ? -1
        //       : a.badgeDesignsOut > b.badgeDesignsOut
        //       ? 1
        //       : 0
        //   );
        // } else if (sortBySort === 'contestsCount') {
        //   sorted.sort((a, b) =>
        //     descs
        //       ? b.contestsCount > a.contestsCount
        //         ? 1
        //         : a.contestsCount > b.contestsCount
        //         ? -1
        //         : 0
        //       : b.contestsCount > a.contestsCount
        //       ? -1
        //       : a.contestsCount > b.contestsCount
        //       ? 1
        //       : 0
        //   );
        // } else if (sortBySort === 'badgeAmountIn') {
        //   sorted.sort((a, b) =>
        //     descs
        //       ? b.badgeAmountIn > a.badgeAmountIn
        //         ? 1
        //         : a.badgeAmountIn > b.badgeAmountIn
        //         ? -1
        //         : 0
        //       : b.badgeAmountIn > a.badgeAmountIn
        //       ? -1
        //       : a.badgeAmountIn > b.badgeAmountIn
        //       ? 1
        //       : 0
        //   );
        // } else if (sortBySort === 'reputationCount') {
        //   sorted.sort((a, b) =>
        //     descs
        //       ? b.reputationCount > a.reputationCount
        //         ? 1
        //         : a.reputationCount > b.reputationCount
        //         ? -1
        //         : 0
        //       : b.reputationCount > a.reputationCount
        //       ? -1
        //       : a.reputationCount > b.reputationCount
        //       ? 1
        //       : 0
        //   );
        // }
      }
      setCommunity(sorted);
    });

  return (
    <div className="row py-4  px-sm-2 px-md-4  px-lg-1 px-xl-2 mt-4">
      <div className="col-12  col-lg-8 d-flex flex-column  flex-lg-row justify-content-between mb-4 mb-md-0">
        <div className="me-0 me-md-5 w-100 mb-4 mb-lg-2">
          <DropDownCommunityType
            label="Profile"
            reset={reload}
            options={filterProfile}
            onSelect={(option) => (option.sort && setTypologySort(option.sort), setActiveSearch(false))}
            thin
          />
        </div>
        <div className="me-0 me-md-5 w-100 mb-4 mb-lg-2">
          <DropDownCommunitySorted
            label="Sort by"
            reset={reload}
            options={filterSort}
            descs={(descs) => setDescs(descs)}
            onSelect={(option) => (option.sort && setSortBySort(option.sort), setActiveSearch(false))}
            thin
          />
        </div>
        <div className=" d-flex flex-row align-items-center justify-content-center h-100  w-100">
          <div
            // className="btn-blue rounded"
            className=" d-flex flex-row align-items-center justify-content-center btn-reset-blue   "
            onClick={() => (setReload(!reload), setCommunity(communityCopy))}
            style={{ width: '3rem', borderRadius: '8px' }}
          >
            <img src={resetFilter} style={{ height: '25px' }} />
          </div>
          {/* {activeSearch && (
            <div
              className="btn-blue rounded"
              onClick={() => (
                setReload(!reload),
                setActiveSearch(false),
                setCommunityPageNumber(1),
                setCommunity(communityCopy),
                setTypologySort(''),
                setSortBySort('')
              )}
            >
              reset
            </div>
          )}
          {!activeSearch && (
            <div
              className="btn-blue rounded"
              onClick={() => (setActiveSearch(true), search(1, true), setCommunityPageNumber(1))}
            >
              apply
            </div>
          )} */}
        </div>
      </div>
      <div className="col-12 col-md-12 col-lg-4 d-flex  justify-content-sm-start justify-content-lg-end">
        <div
          className={`  col-lg-9 me-lg-2   col-md-3 col-sm-4    mb-lg-0  d-flex  justify-content-sm-start justify-content-lg-end   `}
        >
          <CommunityPaginationSwitch onChangePage={setCommunityPageNumber} />
        </div>
        <div className={`col-lg-3  col-md-9 col-sm-8   mb-lg-0  d-flex  justify-content-sm-end`}>
          <div className="d-lg-block">
            <LayoutCommunitySwitch onChangeLayout={props.onChangeLayout} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommunityNavigation;
