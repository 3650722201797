import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './PopUpShare.module.scss';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faEnvelope, faMessage, faXmark } from '@fortawesome/free-solid-svg-icons';
import { Modal } from 'react-bootstrap';
import { faFacebookF, faTwitter, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { useNavigate } from 'react-router-dom';
import xLogo from '../../../assets/x-twitter-01.png';

interface PopUpLoginProps {
  popup: boolean;
  shortUrl: string;
  close: (popup: boolean) => void;
}

const PopUpShare: React.FC<PopUpLoginProps> = (props) => {
  const [checkbox, setCheckbox] = useState(false);
  const [popup, setPopup] = useState(props.popup);
  const [responseError, setResponseError] = useState();
  const redirect = useNavigate();

  useEffect(() => {
    setPopup(props.popup);
  }, [props.popup]);

  const onSubmit = (data: any) => {
    console.log(data.e);
    console.log(data.p);
    // realLogin(data.e, data.p);
  };

  return (
    <>
      <Modal
        show={popup}
        onHide={() => (setPopup(!popup), props.close && props.close(false))}
        className={`d-flex flex-column justify-content-center `}
        contentClassName={` ${styles.rounded}`}
      >
        <div
          className={`d-flex flex-column justify-content-center text-center rounded full bg-light-gray ${styles.rounded}`}
        >
          {/* <Modal.Header className="d-flex justify-content-center ">
                <div className="row justify-content-center align-items-center">
                  <h1 className="mb-4 sub-title">
                    Login or <Link to={`/signup`}>Signup</Link> required.
                  </h1>
                  <h4 className="text-xl mb-4">
                    <i>Connect with</i>
                  </h4>

                  <div className="col-6 mb-3">
                    <button className="btn-social__twitter full rounded mb-3">
                      <div className="btn-icon">
                        <FontAwesomeIcon icon={faTwitter as IconProp} className="text color-light" />
                      </div>
                      twitter
                    </button>
                  </div>
                  <div className="col-6 mb-3">
                    <button className="btn-social__facebook full rounded mb-3">
                      <div className="btn-icon">
                        <FontAwesomeIcon icon={faFacebook as IconProp} className="text color-light" />
                      </div>
                      facebook
                    </button>
                  </div>
                  <div className="col-6 mb-3">
                    <button className="btn-social__linkedin full rounded mb-3">
                      <div className="btn-icon">
                        <FontAwesomeIcon icon={faLinkedin as IconProp} className="text color-light" />
                      </div>
                      linkedin
                    </button>
                  </div>
                  <div className="col-6 mb-3">
                    <button className="btn-social__google full rounded mb-3">
                      <div className="btn-icon">
                        <FontAwesomeIcon icon={faGoogle as IconProp} className="text color-light" />
                      </div>
                      google
                    </button>
                  </div>
                </div>
              </Modal.Header> */}
          <Modal.Body className="d-flex justify-content-center">
            {/* <form onSubmit={handleSubmit(onSubmit)}> */}
            <div className="row justify-content-center align-items-center  ">
              <div className="col-12 mb-3">
                <span className="bigger-text-bold ">Share Design!</span>
              </div>
              <div className="col-12 mb-5">
                <span className="text-16 ">Share it with your friends and increase its chance to win</span>
              </div>

              <div className="d-flex flex-row col-12 justify-content-center align-items-center  mb-5">
                <div className=" col-4  px-3 ">
                  <a href={`https://www.facebook.com/sharer/sharer.php?u=${props.shortUrl}`} target="_blank">
                    <button className="btn-social__facebook full rounded ">
                      <div className="btn-icon">
                        <FontAwesomeIcon icon={faFacebookF as IconProp} className="text color-light" />
                      </div>
                      <span className="text-lowercase">Facebook</span>
                    </button>
                  </a>
                </div>
                <div className="col-4  px-3 ">
                  <a href={`http://twitter.com/share?url=${props.shortUrl}`} target="_blank">
                    <button className="btn-social__twitter full rounded ">
                      <div className="btn-icon">
                      {/* <img src={xLogo} className='' style={{height:'15px', width:'15px'}}/> */}
                        <FontAwesomeIcon icon={faXTwitter as IconProp} className="text color-light" />
                      </div>
                      <span className="text-lowercase">x-twitter</span>
                    </button>
                  </a>
                </div>
                <div className="col-4  px-3 ">
                  <a href={`mailto:?subject=Check This Out&body=${props.shortUrl}`}>
                    <button className="btn-dark-blue full rounded ">
                      <div className="btn-icon">
                        <FontAwesomeIcon icon={faEnvelope as IconProp} className="text color-light" />
                      </div>
                      <span className="text-lowercase">email</span>
                    </button>
                  </a>
                </div>
              </div>
              {/* <div className="d-flex justify-content-center">
                  <div className="col-10 mb-4">
                    <input
                      id="pwd"
                      type="password"
                      placeholder="password"
                      className=" text-center border"
                    //   {...register('p')}
                    />
                  </div>
                </div> */}
              <div className="row d-flex align-items-center mb-4 ">
                <label className=" text-start col-2 text"> Url</label>
                <div className="col-10 ">
                  <input type="text" name="checkbox" id="checkbox-1" value={props.shortUrl} />
                </div>
              </div>
              <div className="row">
                <div className="col-12  d-flex justify-content-end ">
                  <div className="col-3  d-flex flex-row justify-content-end">
                    <div className="d-flex justify-content-center align-items-center ">
                      <span className="px-3">Close</span>
                      <div
                        className={` ${styles.closeButton}`}
                        onClick={() => {
                          setPopup(!popup);
                          props.close(false);
                        }}
                      >
                        <FontAwesomeIcon icon={faXmark as IconProp} className=" text color-light " />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* </form> */}

            {/* <h5>You will be redirected to the Artsana platform innovation.artsana.com</h5> */}
          </Modal.Body>
        </div>
        {/* <Modal.Footer className="d-flex flex-row justify-content-between">
              <Button className="btn-red " onClick={() => setPopup(!popup)}>
                Close
              </Button>
              <a href={`/${ROUTEPATHS.CONTEST}/${contest?.nameForUrl}/${ROUTEPATHS.CONTEST_BRIEF}`} target="_self">
                <Button className="btn-success" onClick={() => setPopup(!popup)}>
                  Login
                </Button>
              </a>
            </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default PopUpShare;
