import AppHeader from '../../components/AppHeader/AppHeader';
import AppFooter from '../../components/AppFooter/AppFooter';
import DslStore from '../../store/DslStore';
import { useStoreState } from 'pullstate';
import { getHeaderMenu } from '../../store/Selectors';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import headerimg from '../../assets/header_placeholder_transparent.png';
import headerLogo from '../../assets/desall_logo.png';
import { PageNavigationProfile } from '../../components/PageNavigation/PageNavigation';
import { designerNavPages } from '../../mockdata/navPages';
import { INavLink } from '../../global';

const ProfilePage: React.FC = () => {
  let { username, subpage } = useParams();

  const headerMenu = useStoreState(DslStore, getHeaderMenu);
  const [pageTitle, setPageTitle] = useState('Profile');
  const [links, setLinks] = useState<INavLink[]>(designerNavPages);

  useEffect(() => {
    console.log('USE EFFECT DI PROFILE PAGE');
    console.log(username);
    console.log(subpage);
    console.log(headerMenu);
    console.log(links);
  }, []);

  return (
    <div className="bg-light-gray">
      <AppHeader
        links={headerMenu}
        bgColor="#eab95a"
        logoImg={headerLogo}
        bgImage={headerimg}
        alpha={0.4}
        breadcrumbs={`Community/${username}`}
        pageTitle={pageTitle}
      />
      <PageNavigationProfile
        bgColor="#E3E3E3"
        links={links}
        //   currentUser !== undefined
        //     ? currentUser.id === communityUser?.id
        //       ? currentUser.userType !== 'C'
        //         ? personalProfileNavPages
        //         : personalClientNavPages
        //       : communityUser?.userType === 'D'
        //       ? designerNavPages
        //       : clientNavPages
        //     : communityUser?.userType === 'D'
        //     ? designerNavPages
        //     : clientNavPages
        // }
      />
    </div>
  );
};
export default ProfilePage;
