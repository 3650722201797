import { createSelector } from 'reselect';
import { IDslStore } from './DslStore';

const getDslState = (state: IDslStore) => state;

export const getMobileMode = createSelector(getDslState, (state) => state.mobileMode);
export const getContests = createSelector(getDslState, (state) => state.contests);
export const getCoCreationContests = createSelector(getDslState, (state) => state.cocreationContests);
export const getCoCreationContestStats = createSelector(getDslState, (state) => state.cocreationContestStats);
export const getCurrentContest = createSelector(getDslState, (state) => state.currentContest);
export const getTimelineTotUpload = createSelector(getDslState, (state) => state.contestTimelineTotUpload);
export const getTimelinePassedUpload = createSelector(getDslState, (state) => state.contestTimelinePassedUpload);
export const getTimelineTotCommunityVote = createSelector(
  getDslState,
  (state) => state.contestTimelineTotCommunityVote
);
export const getTimelinePassedCommunityVote = createSelector(
  getDslState,
  (state) => state.contestTimelinePassedCommunityVote
);
export const getTimelineTotClientVote = createSelector(getDslState, (state) => state.contestTimelineTotClientVote);
export const getTimelinePassedClientVote = createSelector(
  getDslState,
  (state) => state.contestTimelinePassedClientVote
);
export const getTimerHours = createSelector(getDslState, (state) => state.timerHours);
export const getTimerMinutes = createSelector(getDslState, (state) => state.timerMinutes);
export const getTimerSeconds = createSelector(getDslState, (state) => state.timerSeconds);
export const getLayoutContest = createSelector(getDslState, (state) => state.layoutContests);
export const getContestsFiltered = createSelector(getDslState, (state) => state.contestsFiltered);
export const getContestPageNumber = createSelector(getDslState, (state) => state.contestPageNumber);
export const getAllCoCreationContests = createSelector(getDslState, (state) => state.allCoCreationContests);
export const getLayoutCommunity = createSelector(getDslState, (state) => state.layoutCommunity);
export const getCommunityPageNumber = createSelector(getDslState, (state) => state.communityPageNumber);
export const getCurrentUser = createSelector(getDslState, (state) => state.currentUser);
export const getUsers = createSelector(getDslState, (state) => state.community);
export const getUsersCopy = createSelector(getDslState, (state) => state.communityCopy);
export const getCommunityPageNumberLimit = createSelector(getDslState, (state) => state.communityPageNumberLimit);
export const getCommunityUser = createSelector(getDslState, (state) => state.communityUser);
export const getContestNumber = createSelector(getDslState, (state) => state.contestNumber);
export const getCommunityNumber = createSelector(getDslState, (state) => state.communityNumber);
export const getNavLinkActive = createSelector(getDslState, (state) => state.navLinkId);
export const getInspirationsPageNumber = createSelector(getDslState, (state) => state.contestInspirationsPageNumber);
export const getUserInspirationPageNumber = createSelector(getDslState, (state) => state.userInspirationsPageNumber);
export const getLayoutInspirations = createSelector(getDslState, (state) => state.layoutInspirations);
export const getLayoutGallery = createSelector(getDslState, (state) => state.layoutGallery);
export const getGalleryPageNumber = createSelector(getDslState, (state) => state.galleryPageNumber);
export const getLayoutClientContest = createSelector(getDslState, (state) => state.layoutClientContest);
export const getClientContestPageNumber = createSelector(getDslState, (state) => state.clientContestPageNumber);
export const getClientContestNumber = createSelector(getDslState, (state) => state.clientContestNumber);
export const getClientContests = createSelector(getDslState, (state) => state.clientContests);
export const getContestInspirations = createSelector(getDslState, (state) => state.contestInspirations);
export const getContestInspirationsCopy = createSelector(getDslState, (state) => state.contestInspirationsCopy);
export const getContestAwards = createSelector(getDslState, (state) => state.contestAwards);
export const getContestTags = createSelector(getDslState, (state) => state.contestTags);
export const getContestDesign = createSelector(getDslState, (state) => state.contestDesign);
export const getLayoutPortfolio = createSelector(getDslState, (state) => state.layoutPortfolio);
export const getPortfolioPageNumber = createSelector(getDslState, (state) => state.portfolioPageNumber);
export const getPortfolioDesigns = createSelector(getDslState, (state) => state.portfolioDesign);
export const getPortfolioDesignsCopy = createSelector(getDslState, (state) => state.portfolioDesignCopy);
export const getUserInspirations = createSelector(getDslState, (state) => state.userInspirations);
export const getUserInspirationsCopy = createSelector(getDslState, (state) => state.userInspirationsCopy);
export const getInspirationsContestName = createSelector(getDslState, (state) => state.inspirationsContestName);
export const getContestPortfolioPageNumber = createSelector(getDslState, (state) => state.contestPortfolioPageNumber);
export const getLayoutContestPortfolio = createSelector(getDslState, (state) => state.layoutContestPortfolio);
export const getUserDesignsFavorites = createSelector(getDslState, (state) => state.userDesignsFavorites);
export const getUserDesignsFavoritesCopy = createSelector(getDslState, (state) => state.userDesignsFavoritesCopy);
export const getUserInspirationsFavorites = createSelector(getDslState, (state) => state.userInspirationsFavorites);
export const getUserInspirationsFavoritesCopy = createSelector(
  getDslState,
  (state) => state.userInspirationsFavoritesCopy
);
export const getUserContestsFavorites = createSelector(getDslState, (state) => state.userContestsFavorites);
export const getUserContestsFavoritesCopy = createSelector(getDslState, (state) => state.userContestsFavoritesCopy);
export const getUserUsersFavorites = createSelector(getDslState, (state) => state.userUsersFavorites);
export const getUserUsersFavoritesCopy = createSelector(getDslState, (state) => state.userUsersFavoritesCopy);
export const getUserUserFavorited = createSelector(getDslState, (state) => state.userUserFavorited);
export const getUserUserFavoritedCopy = createSelector(getDslState, (state) => state.userUserFavoritedCopy);
export const getUserFavoritesPageNumber = createSelector(getDslState, (state) => state.userFavoritesPageNumber);
export const getTopOfAllTimes = createSelector(getDslState, (state) => state.topOfAllTimes);
export const getTopOfTheWeek = createSelector(getDslState, (state) => state.topOfTheWeek);
export const getLastUsers = createSelector(getDslState, (state) => state.lastUsers);
export const getCustomUsers = createSelector(getDslState, (state) => state.customUsers);
export const getUserAwards = createSelector(getDslState, (state) => state.userAwards);
export const getDesignCurrentImage = createSelector(getDslState, (state) => state.designCurrentImage);
export const getDesignCommentsVisibility = createSelector(getDslState, (state) => state.designCommentsVisibility);
export const getCurrentDesign = createSelector(getDslState, (state) => state.currentDesign);
export const getUserFavoritesType = createSelector(getDslState, (state) => state.userFavoritesType);
export const getContestGallery = createSelector(getDslState, (state) => state.contestGalleryDesigns);
export const getContestGalleryCopy = createSelector(getDslState, (state) => state.contestGalleryDesignsCopy);
export const getFooterNav = createSelector(getDslState, (state) => state.footerdata);
export const getAllMenus = createSelector(getDslState, (state) => state.allMenus);
export const getFooterMenu = createSelector(getDslState, (state) => state.footerMenus);
export const getHeaderMenu = createSelector(getDslState, (state) => state.headerMenus);
export const getHiddenMenu = createSelector(getDslState, (state) => state.hiddenMenus);
export const getStrapiPaths = createSelector(getDslState, (state) => state.strapiPaths);
export const getFooterMenuSelected = createSelector(getDslState, (state) => state.menuSelected);
export const getBusy = createSelector(getDslState, (state) => state.busy);
export const getStrapiPage = createSelector(getDslState, (state) => state.strapiPage);
export const getStrapiCommunity = createSelector(getDslState, (state) => state.strapiCommunity);
export const getCoCreationContestGallery = createSelector(getDslState, (state) => state.cocreationContestGallery);
export const getCoCreationContestAwards = createSelector(getDslState, (state) => state.cocreationContestAward);
export const getEditProfile = createSelector(getDslState, (state) => state.editProfile);
export const getTags = createSelector(getDslState, (state) => state.tags);
export const getInspirationToEdit = createSelector(getDslState, (state) => state.inspitationToEdit);
export const getContestCategories = createSelector(getDslState, (state) => state.contestCategories);
export const getDesignSearchError = createSelector(getDslState, (state) => state.designSearchError);
export const getSocialUser = createSelector(getDslState, (state) => state.socialUser);
export const getUserBlackList = createSelector(getDslState, (state) => state.blackList);
export const getWelcomeMessage = createSelector(getDslState, (state) => state.welcomeMessage);
export const getContestGuidelines = createSelector(getDslState, (state) => state.contestGuidelines);
export const getContestInspirationToEdit = createSelector(getDslState, (state) => state.contestInspirationToEdit);
export const getContestAccessDenied = createSelector(getDslState, (state) => state.contestAccessDenied);
export const getSearchResultContest = createSelector(getDslState, (state) => state.searchResultContest);
export const getSearchResultContestFiltered = createSelector(getDslState, (state) => state.searchResultContestFiltered);
export const getSearchResultUser = createSelector(getDslState, (state) => state.searchResultUser);
export const getSearchResultDesign = createSelector(getDslState, (state) => state.searchResultDesign);
export const getSearchResultContestCopy = createSelector(getDslState, (state) => state.searchResultContestCopy);
export const getSearchResultUserCopy = createSelector(getDslState, (state) => state.searchResultUserCopy);
export const getSearchResultDesignCopy = createSelector(getDslState, (state) => state.searchResultDesignCopy);
export const getUserSearchPageNumber = createSelector(getDslState, (state) => state.userSearchPageNumber);
export const getContestSearchPageNumber = createSelector(getDslState, (state) => state.contestSearchPageNumber);
export const getDesignSearchPageNumber = createSelector(getDslState, (state) => state.designSearchPageNumber);
export const getUserSearchPageNumberLimit = createSelector(getDslState, (state) => state.userSearchPageNumberLimit);
export const getDesignSearchPageNumberLimit = createSelector(getDslState, (state) => state.designSearchPageNumberLimit);
export const getUserBadges = createSelector(getDslState, (state) => state.userBadges);
export const getContestSearchPageNumberLimit = createSelector(
  getDslState,
  (state) => state.contestSearchPageNumberLimit
);
export const getContestSearchParams = createSelector(getDslState, (state) => state.contestSearchParams);
export const getContestsByName = createSelector(getDslState, (state) => state.contestsByName);  