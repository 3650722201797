import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DslStore, {
  setCurrentContest,
  setPortfolioDesign,
  setUserAwards,
  setUserInspirations,
  setUserInspirationsCopy,
} from '../../../store/DslStore';

import styles from './PopUpErrorUploadDesign.module.scss';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

import axiosHelper from '../../../helpers/axiosHelper';
import { Modal } from 'react-bootstrap';
import { getCurrentDesign, getCurrentUser } from '../../../store/Selectors';
import { useStoreState } from 'pullstate';
import { useNavigate } from 'react-router-dom';
import { ROUTEPATHS } from '../../../routes/routePaths';

interface PopUpLoginProps {
  popup: boolean;
  close: () => void;
}

const PopUpErrorUploadDesign: React.FC<PopUpLoginProps> = (props) => {
  const currentdesign = useStoreState(DslStore, getCurrentDesign);
  const currentUser = useStoreState(DslStore, getCurrentUser);

  const [popup, setPopup] = useState(props.popup);
  const redirect = useNavigate();

  const goToEditDraft = async () => {
    if (currentdesign) {
      await axiosHelper.retrieveContestByName(currentdesign.contest.nameForURL).then((response) => {
        setCurrentContest(response.data);
        redirect(`/${ROUTEPATHS.COMMUNITY}/${currentUser?.username}/portfolio/${currentdesign?.nameForURL}`);
      });
    }
  };

  return (
    <>
      <Modal
        show={props.popup}
        onHide={() => (setPopup(!popup), props.close && props.close())}
        className={`d-flex flex-column justify-content-center `}
        contentClassName={` ${styles.rounded}`}
      >
        <div className={`d-flex flex-column justify-content-center text-center  full bg-light-gray ${styles.rounded}`}>
          <Modal.Body className="d-flex justify-content-center p-3">
            {/* <form onSubmit={handleSubmit(onSubmit)}> */}
            <div className="row justify-content-center align-items-center  ">
              <div className="d-flex col-12 py-4 flex-row align-items-center justify-content-center">
                <p className="text-16">
                  You're trying to publish your design but some fields are missing <br /> Please EDIT your design and
                  complete all info, then click PUBLISH
                </p>
              </div>
              <div className="row ">
                <div className="col-12  d-flex justify-content-between ">
                  <div className="col-3 ps-1  d-flex  ">
                    <div className="d-flex justify-content-center align-items-center ">
                      <div
                        className={` ${styles.closeButton}`}
                        onClick={() => {
                          setPopup(!popup);
                          props.close();
                        }}
                      >
                        <FontAwesomeIcon icon={faXmark as IconProp} className=" text color-light " />
                      </div>
                      <span className="ps-3">Close</span>
                    </div>
                  </div>
                  <div className="col-4 pe-1 d-flex   ">
                    <button
                      className={`btn-success rounded full 
                      
                     mb-3`}
                      onClick={() => goToEditDraft()}
                    >
                      edit draft
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* </form> */}
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default PopUpErrorUploadDesign;
