import React from 'react';
import { GifComponent, TwoElementsComponent } from '../../../global';
import { pathStrapiImage } from '../../../helpers/utils';
import HeroSection from '../../HeroSection/HeroSection';
import SplitSection from '../../SplitSection/SplitSection';

const TwoElementsSection: React.FC<TwoElementsComponent> = (props) => {
  return (
    <SplitSection
      key={props.id}
      bgColorLeft={props.leftside.bgcolor}
      bgColorRight={props.rightside.bgcolor}
      itemLeft={
        <>
          <div>
            <div className="row ">
              <div className="col-12 " style={{ height: '90px' }}>
                <h1 className={`bold-title px-2 color-${props.leftside.titlecolor} ${props.leftside.titlealign ? `text-${props.rightside.titlealign}` : `text-center`}`}>{props.leftside.title}</h1>
              </div>
            </div>
            <div className="row py-5  ">
              <div className={`col-12 col-lg-6 ${props.leftside.textalign ? `text-${props.leftside.textalign}`: `text-start`}   ms-3  pe-5  text-16 color-${props.leftside.textcolor}`}>
                <span className="pe-5 " style={{ height: '160px' }}>
                  {props.leftside.text}
                </span>
              </div>
              <div className="col-12 col-lg-5  ms-lg-5 ms-md-0  d-flex justify-content-center justify-content-lg-end">
                <img
                  className="img-270"
                  src={pathStrapiImage(props.leftside.image.data.attributes.url)}
                  alt="Placeholder"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 d-flex justify-content-center ">
                <a href={props.leftside.link} target={`${props.leftside.target ? props.leftside.target : '_self'}`}>
                  <button className={`btn-${props.leftside.butcolor} rounded`}>{props.leftside.buttontext}</button>
                </a>
              </div>
            </div>
          </div>
        </>
      }
      itemRight={
        <>
          <div>
            <div className="row">
              <div className="col-12 " style={{ height: '90px' }}>
                <h1 className={`bold-title color-${props.rightside.titlecolor} px-3 ${props.rightside.titlealign? `text-${props.rightside.titlealign}` : `text-center`}`}>
                  {props.rightside.title}
                </h1>
              </div>
            </div>
            <div className="row py-5">
              <div className={`col-12 col-lg-6  ms-3 pe-5 me-2 ${props.rightside.textalign ? `text-${props.rightside.textalign}` : `text-left`} h-100 text-16 color-${props.rightside.textcolor}`}>
                <span className="">{props.rightside.text}</span>
              </div>
              <div className="col-12 col-lg-5 d-flex justify-content-center pe-3  ">
                <img
                  className="img-270 me-3"
                  src={pathStrapiImage(props.rightside.image.data.attributes.url)}
                  alt="Placeholder"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 d-flex justify-content-center">
                <a href={props.rightside.link} target={`${props.rightside.target ? props.rightside.target : '_self'}`}>
                  <button className={`btn-${props.rightside.butcolor} rounded`}>{props.rightside.buttontext}</button>
                </a>
              </div>
            </div>
          </div>
        </>
      }
    />
  );
};

export default TwoElementsSection;
