import React, { useEffect, useState } from 'react';
import styles from './ClientContestNavigation.module.scss';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { DropDownContestCategory, DropDownContestSort } from '../../Forms/DropDown/DropDownContest';
import DslStore, { setClientContestPageNumber, setContestPageNumber, setLayoutContest } from '../../../store/DslStore';
import { LayoutClientContestSwitch, LayoutContestSwitch } from '../../Reusable/LayoutSwitch';
import { getClientContestNumber, getClientContestPageNumber, getContestNumber, getContestPageNumber, getContests } from '../../../store/Selectors';
import { useStoreState } from 'pullstate';

type IProjecNavigation = {
  onChangeLayout?: (val: number) => void;
};

type PaginationSwitchProps = {
  onChangePage?: (val: number) => void;
};

export const PaginationSwitch: React.FC<PaginationSwitchProps> = ({ onChangePage = () => {} }) => {
  const pageNumber = useStoreState(DslStore, getClientContestPageNumber);
  const clientContestNumber = useStoreState(DslStore, getClientContestNumber);
  const contests = useStoreState(DslStore, getContests);
  let contestPerPage = 20;
  const [pagernumber, setPagerNumber] = useState(0);
  const pageNumberLimit = Math.ceil(clientContestNumber / contestPerPage);
  const [array, setArray] = useState<Array<number>>([1, 2, 3, 4, 5]);

  useEffect(() => {
    console.log('cambiato numero page');
    if (pageNumber >= 3 && pageNumber <= pageNumberLimit - 2) {
      setArray([pageNumber - 2, pageNumber - 1, pageNumber, pageNumber + 1, pageNumber + 2]);
    }
  }, [pageNumber]);

  useEffect(() => {
    console.log('contests: ' + JSON.stringify(contests.length));
    console.log('pagine: ' + Math.ceil(contests.length / contestPerPage));
    setPagerNumber(pageNumberLimit);
    // fillContests(pageNumber -1);
  }, [contests]);

  const handleChange = (value: number) => {
    console.log(value);
    if (value > 0 && value <= pageNumberLimit) {
      onChangePage(value);
      if (pageNumber >= 3 && pageNumber <= pageNumberLimit - 2) {
        setArray([pageNumber - 2, pageNumber - 1, pageNumber, pageNumber + 1, pageNumber + 2]);
      }
    } else {
      console.log('0 o max++');
    }
  };

  return (
    <div className={`${styles.paginationSwitch} bg-light py-2 px-2 `}>
      <button className="btn-icon" onClick={() => handleChange(pageNumber - 1)}>
        <FontAwesomeIcon icon={faAngleLeft as IconProp} className="btn-icon active " />
      </button>

      {/* <button className={'btn-icon active'} onClick={() => handleChange(pageNumber - 1)}>
        <strong> &lt; </strong>
      </button> */}

      {array.map((index: number) => (
        <button
          key={index}
          className={pageNumber == index ? 'btn-icon active' : 'btn-icon'}
          onClick={() => handleChange(index)}
        >
          <small>{index}</small>
        </button>
      ))}

      {/* <button className={'btn-icon active'} onClick={() => handleChange(pageNumber + 1)}>
        <strong> &gt; </strong>
      </button> */}

      <button className="btn-icon" onClick={() => handleChange(pageNumber + 1)}>
        <FontAwesomeIcon icon={faAngleRight as IconProp} className="btn-icon active " />
      </button>

      {/* 
      <button className={"btn-icon active"} 
      //  onClick={() => handleClick(1)}
      > <b> &lt; </b> </button>
       <button className={pageNumber == 1 ? "btn-icon active" : "btn-icon"} 
      //  onClick={() => handleClick(1)}
      >
        1
      </button>
      <button className={pageNumber == 2 ? "btn-icon active" : "btn-icon"} 
      // onClick={() => handleClick(2)}
      >
        2
      </button>
      <button className={pageNumber == 3 ? "btn-icon active" : "btn-icon"} 
      // onClick={() => handleClick(3)}
      >
        3
      </button>
      <button className={pageNumber == 4 ? "btn-icon active" : "btn-icon"} 
      // onClick={() => handleClick(4)}
      >
        4
      </button>
      <button className={pageNumber == 5 ? "btn-icon active" : "btn-icon"} 
      // onClick={() => handleClick(5)}
      >
        5
      </button>  */}

      {/* <button className="btn-icon">
         <FontAwesomeIcon icon={faAngleRight as IconProp} className="text" />
       </button> */}

      {/* <Pagination count={numberOfPages} page={pageNumber} color='primary'  hideNextButton={numberOfPages > 1 ? false : true} hidePrevButton={numberOfPages > 1 ? false : true} size="small" onChange={handleChange} /> */}
    </div>
  );
};

let filterCategories = [
  {
    label: 'All',
    value: '0',
  },
  {
    label: 'Product Design',
    sort: 'Product Design',
    value: '1',
  },
  {
    label: 'New Idea',
    sort: 'New Idea',
    value: '2',
  },
  {
    label: 'Interior Design',
    sort: 'Interior design',
    value: '3',
  },
  {
    label: 'Packaging',
    sort: 'Packaging',
    value: '3',
  },
  {
    label: 'Naming',
    sort: 'Naming',
    value: '3',
  },
  {
    label: 'Co-Creation',
    sort: 'Co-creation',
    value: '3',
  },
  {
    label: 'Craft Design',
    sort: 'Craft design',
    value: '3',
  },
  {
    label: 'Link to Other',
    sort: 'Link to other',
    value: '3',
  },
];

let filterSort = [
  {
    label: 'Date',
    sort: 'startDate',
    value: '1',
  },
  {
    label: 'Votes',
    sort: 'sumVotesCount',
    value: '2',
  },
  {
    label: 'Title',
    sort: 'name',
    value: '3',
  },
  {
    label: 'Type',
    sort: 'contestType',
    value: '4',
  },
];

const ClientContestsNavigation: React.FC<IProjecNavigation> = (props) => {
  return (
    <div className="row py-4 mt-4">
      <div className="col-12 col-md-6 col-lg-6 d-flex flex-column px-4 flex-lg-row justify-content-between mb-4 mb-md-0">
        <div className="me-0 me-md-5 w-100 mb-2 mb-lg-0">
          <DropDownContestCategory label="Category" options={filterCategories} thin />
        </div>
        <div className="w-100">
          <DropDownContestSort label="Sort by" options={filterSort} thin />
        </div>
      </div>
      <div className={`col-12 col-md-6 d-flex px-4 justify-content-sm-start justify-content-md-end `}>
        <div className="me-md-5 me-lg-5 me-sm-5 ">
          <PaginationSwitch onChangePage={setClientContestPageNumber} />
        </div>
        <div >
          <LayoutClientContestSwitch onChangeLayout={props.onChangeLayout} />
        </div>
      </div>
    </div>
  );
};

export default ClientContestsNavigation;
