import React, { useState, useRef } from 'react';
import { Job } from '../../../global';

import styles from './DropDown.module.scss';
interface Props {
  options: Job[];
  selectedJob?: Job;
  setSelectedJob: (j: Job) => void;
}

const DropDownJobWLF: React.FC<Props> = ({ options, selectedJob, setSelectedJob }) => {
  const dropDownContainer = useRef<any>(null);
  let [open, setOpen] = useState<boolean>(false);

  const isSelected = (option: Job) => {
    return selectedJob && selectedJob.id === option.id ? styles.selected : '';
  };

  const selectJob = (job: Job) => {
    setSelectedJob(job);
    setOpen(false);
  };

  return (
    <div ref={dropDownContainer} className={`${styles.dropdown_wrapper_year}`}>
      <div className={`${styles.dropdown} ${open ? styles.open : ''}`}>
        <div className={`${styles.dropdown__toggle}`} onClick={() => setOpen(!open)}>
          <span className={`${styles.dropdown__label} w-75`}>{selectedJob ? selectedJob.name : 'Choose'}</span>
          <div className={`${styles.arrow}`}></div>
        </div>
        <div className={`${styles.dropdown__optionslist}`}>
          {options.map((option, index) => (
            <div key={index} className={`${styles.dropdown__option} ${isSelected(option)}`} data-value={option.name} onClick={() => selectJob(option)}>
              {option.name}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DropDownJobWLF;
