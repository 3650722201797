import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DslStore, { setCurrentContest } from '../../../store/DslStore';
import { getCurrentContest, getCurrentUser } from '../../../store/Selectors';
import { useStoreState } from 'pullstate';
import { Link } from 'react-router-dom';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faExclamation } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';
import { NDAConfirm } from '../../../global';
import axiosHelper from '../../../helpers/axiosHelper';

interface NDAFormProps {
  auth: (auth: boolean) => void;
}

const NDAForm: React.FC<NDAFormProps> = (props) => {
  const [checkbox, setCheckbox] = useState(false);
  const [responseError, setResponseError] = useState();
  const currentUser = useStoreState(DslStore, getCurrentUser);
  const contest = useStoreState(DslStore, getCurrentContest);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<NDAConfirm>();

  const onSubmit = (data: any) => {
    console.log(data.firstName);
    console.log(data.lastName);
    console.log(data.email);
    sendNdaConfirm(data);
  };

  const sendNdaConfirm = async (data: NDAConfirm) => {
    if (responseError) {
      setResponseError(undefined);
    }

    if (checkbox) {
      await axiosHelper
        .sendNDAConfirm(data, contest?.id)
        .then((response) => {
          if (response.data.brief) {
            setCurrentContest(response.data);
          }
          if (response.status === 200) {
            props.auth(true);
          }
        })
        .catch((error) => {
          setResponseError(error.response.status);
          console.log(error);
          console.log(error.response.status);
          props.auth(false);
        });
    }
  };

  useEffect(() => {
    if (responseError) {
      if (responseError === 404) {
      }
    }
  }, [responseError]);

  return (
    <>
      <div className="row mb-5">
        <div className="col-12 col-md-2 text fw-bold mb-3 ">
          <div className="d-flex flex-row justify-content-start align-items-center">
            <span className="me-2">NDA required</span>
            <div
              className="bg-danger d-flex align-items-center justify-content-center"
              style={{ borderRadius: '50%', height: '20px', width: '20px' }}
            >
              <FontAwesomeIcon icon={faExclamation as IconProp} className="text color-light" />
            </div>
          </div>
        </div>
        <div className="col-12 col-md-10">
          You must <Link to={`/login`}>login</Link> (or <Link to={`/signup`}>signup</Link>) and accept the contest NDA
          in order to read the full brief and join the contest or access the gallery. If you do not use your real name,
          your account may be suspended or terminated.
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row mb-5">
          <div className="col-12 col-md-2 text fw-bold mb-3">First name</div>
          <div className="col-12 col-md-10">
            <input type="text" value={currentUser ? `${currentUser.firstName}` : ''} {...register('firstName')}></input>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-12 col-md-2 text fw-bold mb-3">Last name</div>
          <div className="col-12 col-md-10">
            <input type="text" value={currentUser ? `${currentUser.lastName}` : ''} {...register('lastName')}></input>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-12 col-md-2 text fw-bold mb-3">Email</div>
          <div className="col-12 col-md-10">
            <input type="text" value={currentUser ? currentUser.email : ''} {...register('email')}></input>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-12 col-md-2 text fw-bold mb-3">NDA</div>
          <div className="col-12 col-md-10">
            <div className="d-flex flex-row justify-content-between align-items-center ">
              <div className=" d-flex align-items-center ">
                <input
                  type="checkbox"
                  className="me-5"
                  checked={checkbox}
                  onClick={() => setCheckbox(!checkbox)}
                ></input>
                <span>
                  I accept the&nbsp;
                  <a href={`${contest?.termsAttachment}`} target="_blank">
                    contest NDA
                  </a>
                </span>
              </div>
              <div>
                <button className="btn-dark-blue confirm rounded"> save</button>
              </div>
            </div>
            {responseError && responseError === 404 && (
              <div className="col-12 mt-3 color-danger">Profile not found</div>
            )}
            {responseError && responseError === 401 && (
              <div className="col-12 mt-3 color-danger">
                You must logged in to accept the&nbsp;
                <a href={`${contest?.termsAttachment}`} target="_blank">
                  contest NDA
                </a>
              </div>
            )}
          </div>
        </div>
      </form>
    </>
  );
};

export default NDAForm;
