import { useStoreState } from 'pullstate';
import React, { useState, useRef, useEffect } from 'react';
import { Job } from '../../../global';
import DslStore from '../../../store/DslStore';
import { getCurrentUser } from '../../../store/Selectors';

import styles from './DropDown.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface IDropDown {
  options?: Job[];
  onSelect?: (o: IDropDownOption) => void;
  label?: string;
  thin?: boolean;
}

interface IDropDownItem {
  name: string;
  id: number;
}
export interface IDropDownOption extends IDropDownItem {
  index: number;
  filter?: string;
}

export const DropDownJob: React.FC<IDropDown> = (props) => {
  const dropDownContainer = useRef<any>(null);
  let [open, setOpen] = useState<boolean>(false);
  const currentUser = useStoreState(DslStore, getCurrentUser);
  let [itemSelected, setItemSelected] = useState<IDropDownOption>();
  const [jobSelected, setJobSelected] = useState(currentUser?.job?.name);
  // const [dropdownAnimation, setDropDownAnimation] = useState(false);

  const generateOptionsList = (options: IDropDownItem[]) => {
    // console.log(itemSelected);
    let indexedOptions: IDropDownOption[] = [];
    options.forEach((item, index) => {
      indexedOptions.push({
        ...item,
        index: index,
      });
    });
    return indexedOptions;
  };

  const globalClickListener = (e: MouseEvent) => {
    if (dropDownContainer.current && dropDownContainer.current.contains(e.target)) {
      // setDropDownAnimation(true);
      return;
    } else {
      setOpen(false);
    }
  };

  // useEffect(() => {
  //   setDropDownAnimation(true);
  // }, [open]);

  const handleOnClick = () => {
    if (!open) {
      document.addEventListener('click', (e) => globalClickListener(e));
    } else {
      document.removeEventListener('click', (e) => globalClickListener(e));
    }
    setOpen(!open);
  };

  const handleOnSelect = (option: IDropDownOption) => {
    // console.log(option);
    setItemSelected(option);
    setJobSelected(option.name);
    setOpen(!open);

    props.onSelect && props.onSelect(option);
  };

  const getSelectedStyle = (index: number) => {
    return itemSelected && itemSelected.index === index ? styles.selected : '';
  };

  const optionsList = props.options ? generateOptionsList(props.options) : [];

  return (
    <div ref={dropDownContainer} className={`${styles.dropdown_wrapper_year} ${props.thin && styles.thin_ui}`}>
      <div className={`${styles.dropdown} ${open ? styles.open : ''}`}>
        <div className={`${styles.dropdown__toggle}`} onClick={handleOnClick}>
          {props.label && <div className="fw-bold text-uppercase pe-3"> {props.label}</div>}
          <span className={`${styles.dropdown__label} w-75 ${props.label && 'text-center flex-grow-1'}`}>
            {jobSelected ? jobSelected : 'Choose'}
          </span>
          <div className={`${styles.arrow}`}></div>
          {/* <div className=" d-flex w-25  flex-row-reverse">
            {!open && (
              <div
                className={`${styles.dropdown_angle} ${dropdownAnimation ? styles.transitionIn : null}`}
                onAnimationEnd={() => setDropDownAnimation(false)}
                // onClick={handleOnClick}
              >
                <FontAwesomeIcon icon={faAngleDown as IconProp} className={`text color-blue `} />
              </div>
            )}
            {open && (
              <div
                className={`${styles.dropdown_angle} ${dropdownAnimation ? styles.transitionIn : null}`}
                onAnimationEnd={() => setDropDownAnimation(false)}
                // onClick={handleOnClick}
              >
                <FontAwesomeIcon
                  icon={faAngleUp as IconProp}
                  className={`text color-blue `}
                  // onClick={handleOnClick}
                />
              </div>
            )}
          </div> */}
        </div>

        <div className={`${styles.dropdown__optionslist}`}>
          {optionsList.map((option, index) => (
            <div
              key={index + option.id}
              className={`${styles.dropdown__option} ${getSelectedStyle(index)}`}
              data-value={option.name}
              onClick={() => handleOnSelect(option)}
            >
              {option.name}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
