import { useNavigate } from 'react-router-dom';
import { GifSectionProps } from '../../../global';
import { pathStrapiImage } from '../../../helpers/utils';
import styles from './SmallBackgroundImage.module.scss';

function SmallBackgroundImage(props: GifSectionProps) {
  const redirect = useNavigate();
  return (
    <section
      key={props.id}
      className={`${styles.imagecontainer} `}
      style={{cursor:`${!props.buttontext && props.link ? 'pointer' : 'default'}`}}
      onClick={() =>
        props.link && !props.buttontext && window.open(`${props.link}`, `${props.target ? props.target : '_self'}`)
      }
    >
      <img alt="Placeholder" src={pathStrapiImage(props.image.data.attributes.url)} />
      <div className={`${styles.content} `}>
        {props.title && <h1 className={`bold-title color-${props.titlecolor}`}>{props.title}</h1>}
        {props.subtitle && <h3 className={`sub-title color-${props.subtitlecolor}`}>{props.subtitle}</h3>}
        {props.buttontext && (
          <a href={props.link} target={`${props.target ? props.target : '_self'}`}>
            <button className={`btn-${props.buttoncolor} rounded ${props.subtitle ? 'mt-5' : 'mt-5'}`}>
              {props.buttontext}
            </button>
          </a>
        )}
      </div>
    </section>
  );
}

export default SmallBackgroundImage;
