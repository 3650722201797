import React, { useEffect, useState } from 'react';
import AppHeader from '../../components/AppHeader/AppHeader';
import AppFooter from '../../components/AppFooter/AppFooter';

//Header Data
import headerimg from '../../assets/header_placeholder_transparent.png';
import headerLogo from '../../assets/desall_logo.png';
import { clientNavPages, designerNavPages } from '../../mockdata/navPages';
import { PageNavigationProfile } from '../../components/PageNavigation/PageNavigation';

//Footer Data
import footerImg from '../../assets/footer_placeholder_transparent.png';

//Styles
import styles from './ClientContest.module.scss';

import { choseUserName, numberVotes, startDateString, typeCategoryString } from '../../helpers/utils';
import {
  getClientContestPageNumber,
  getClientContests,
  getCommunityUser,
  getFooterMenu,
  getHeaderMenu,
  getLayoutClientContest,
} from '../../store/Selectors';
import { useStoreState } from 'pullstate';
import DslStore, { setClientContestPageNumber, setLayoutClientContest } from '../../store/DslStore';
import ContestRowBig from '../../components/Contest/ContestRowBig/ContestRowBig';
import ContestRow from '../../components/Contest/ContestRow/ContestRow';
import { Contest } from '../../global';
import ContestCard from '../../components/Contest/ContestCard/ContestCard';
import { PaginationSwitch } from '../../components/Contest/ContestsNavigation/ContestsNavigation';
import ClientContestsNavigation from '../../components/Community/ClientContestNavigation/ClientContestNavigation';

const ClientContest: React.FC = () => {
  const footerMenu = useStoreState(DslStore, getFooterMenu);
  const headerMenu = useStoreState(DslStore, getHeaderMenu);
  const communityUser = useStoreState(DslStore, getCommunityUser);
  const allContests = useStoreState(DslStore, getClientContests);
  const layoutContest = useStoreState(DslStore, getLayoutClientContest);
  const pageNumber = useStoreState(DslStore, getClientContestPageNumber);
  const date = new Date().toISOString();

  const [visibleContests, setVisibleContests] = useState<Contest[]>([]);

  useEffect(() => {
    console.log('client-contest');
    arraysfill();
    console.log(allContests);
  }, []);

  const arraysfill = () => {
    let chunkSize = 20;
    let startchunk = (pageNumber - 1) * chunkSize;
    let tempArray: Contest[] = [];
    const chunk: Contest[] = allContests.slice(startchunk, startchunk + chunkSize);

    for (let i = 0; i < allContests.length; i += chunkSize) {
      const chunk: Contest[] = allContests.slice(startchunk, startchunk + chunkSize);
      // console.log('chunk');
      // console.log(chunk);
    }
    for (let i = 0; i < chunkSize; i++) {
      // console.log(chunk[i]);
      // console.log(chunk[i] === undefined);
      if (chunk[i] != undefined) {
        tempArray.push(chunk[i]);
      } else {
        break;
      }
    }
    // console.log("array1:  ");
    // console.log(tempArray);
    setVisibleContests(tempArray);
  };

  const statusText = (isClosed: boolean, startDate: string, endUploadDate: string, endVotingDate?: string) => {
    // console.log(date);
    // console.log(endUploadDate);

    // console.log(date > endUploadDate);
    if (isClosed) {
      return `closed ${endVotingDate}`;
    } else if (date < startDate) {
      return 'coming soon';
    } else if (date < endUploadDate) {
      return `ends ${endUploadDate}`;
    } else if (endVotingDate && date < endVotingDate) {
      return `community vote`;
      // console.log(endVotingDate);
      // console.log(date > endVotingDate);
    } else if (endVotingDate && date > endVotingDate && !isClosed) {
      return `client vote`;
    } else if (date > endUploadDate && !isClosed) {
      return `client-vote`;
    }
    return `standby`;
  };

  const checkStatus = (isClosed: boolean, startDate: string, endUploadDate: string, endVotingDate?: string) => {
    // console.log(date);
    // console.log(endUploadDate);

    // console.log(date > endUploadDate);
    if (isClosed) {
      return 'closed';
    } else if (date < startDate) {
      return 'ongoing';
    } else if (date < endUploadDate) {
      return 'ongoing';
    } else if (endVotingDate && date < endVotingDate) {
      return 'community-vote';
      // console.log(endVotingDate);
      // console.log(date > endVotingDate);
    } else if (endVotingDate && date > endVotingDate && !isClosed) {
      return 'client-vote';
    } else if (date > endUploadDate && !isClosed) {
      return 'client-vote';
    }
    return 'standby';
  };

  return (
    <div className="bg-light-gray">
      <AppHeader
        links={headerMenu}
        bgColor="#eab95a"
        logoImg={headerLogo}
        bgImage={headerimg}
        alpha={0.4}
        pageTitle="Profile"
      />
      <PageNavigationProfile
        bgColor="#E3E3E3"
        links={communityUser?.userType == 'C' ? clientNavPages : designerNavPages}
      />
      <main className={`${styles.profileWrapper} container pt-5 mb-5 overflow-hidden`}>
        <div className="row">
          <div className="col-12">
            <ClientContestsNavigation onChangeLayout={setLayoutClientContest} />
          </div>
        </div>
        <div className="row ">
          {layoutContest == 1
            ? visibleContests?.map(
                (aContest: Contest, index: number) => (
                  console.log(aContest),
                  (
                    // console.log(startDateString(aContest?.startDate)),
                    <ContestCard
                      key={index}
                      liked={false}
                      endVotingDate={aContest.endVotingDate}
                      startDate={aContest.startDate}
                      contestId={aContest.id}
                      image={aContest?.image}
                      title={aContest?.name}
                      entries={numberVotes(aContest?.entries)}
                      category={typeCategoryString(aContest?.contestType, aContest?.contestCategory)}
                      endUploadDate={startDateString(aContest?.endUploadDate)}
                      name={choseUserName(aContest)}
                      award={aContest?.prices}
                      // name={choseUserName(aContestMini)}
                      private={aContest.isPrivate}
                      accesscode={aContest.accessCode}
                      nda={aContest.nda}
                      global
                      statusText={statusText(
                        aContest.isClosed,
                        aContest.startDate,
                        aContest.endUploadDate,
                        aContest.endVotingDate
                      )}
                      status={checkStatus(
                        aContest.isClosed,
                        aContest.startDate,
                        aContest.endUploadDate,
                        aContest.endVotingDate
                      )}
                      userId={aContest.userId}
                      contestType={aContest.contestType}
                    ></ContestCard>
                  )
                )
              )
            : ''}
        </div>

        <div className="row mt-4 px-4 ">
          {layoutContest == 2
            ? visibleContests?.map((aContest: Contest, index: number) => (
                // console.log(aContest),
                <ContestRow
                  liked={false}
                  key={index}
                  endUploadDate={aContest.endUploadDate}
                  endVotingDate={aContest.endVotingDate}
                  contestId={aContest.id}
                  image={aContest.image}
                  contestTitle={aContest.name}
                  entries={56}
                  award={aContest.prices}
                  category={typeCategoryString(aContest.contestType, aContest.contestCategory)}
                  startDate={startDateString(aContest.startDate)}
                  clientName={choseUserName(aContest)}
                  galleryImages={[]}
                  statusText={statusText(
                    aContest.isClosed,
                    aContest.startDate,
                    aContest.endUploadDate,
                    aContest.endVotingDate
                  )}
                  status={checkStatus(aContest.isClosed, aContest.startDate, aContest.endUploadDate, aContest.endVotingDate)}
                  progress={60}
                  private={aContest.isPrivate}
                  accesscode={aContest.accessCode}
                  nda={aContest.nda}
                  userId={aContest.userId}
                  contestType={aContest.contestType}
                  hideGallery={aContest.hideGalleryTab}
                ></ContestRow>
              ))
            : ''}
        </div>

        <div className="row mt-3 px-4">
          {layoutContest == 2
            ? visibleContests?.map((aContest: Contest, index: number) => (
                // console.log(aContest),
                <ContestRowBig
                  key={index}
                  contest={aContest}
                  contestType={aContest.contestType}
                  image={aContest?.image}
                  contestTitle={aContest?.name}
                  entries={aContest?.entries}
                  award={aContest?.prices}
                  category={typeCategoryString(aContest?.contestType, aContest?.contestCategory)}
                  endUploadDate={startDateString(aContest?.endUploadDate)}
                  clientName={choseUserName(aContest)}
                  private={aContest.isPrivate}
                  accesscode={aContest.accessCode}
                  nda={aContest.nda}
                  statusText={statusText(
                    aContest.isClosed,
                    aContest.startDate,
                    aContest.endUploadDate,
                    aContest.endVotingDate
                  )}
                  status={checkStatus(aContest.isClosed, aContest.startDate, aContest.endUploadDate, aContest.endVotingDate)}
                  progress={60}
                ></ContestRowBig>
              ))
            : ''}
        </div>
        <div className="row ">
          <div className="col-12 px-4 d-flex justify-content-sm-start justify-content-md-end ">
            <PaginationSwitch onChangePage={setClientContestPageNumber} />
          </div>
        </div>
      </main>
      <AppFooter links={footerMenu} bgColor="#304053" logoImg={headerLogo} bgImage={footerImg} />
    </div>
  );
};

export default ClientContest;
