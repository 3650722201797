import React from 'react';
import { useStoreState } from 'pullstate';
//Header Data
import headerLogo from '../assets/desall_logo.png';

//Footer Data
import footerImg from '../assets/footer_placeholder_transparent.png';
//Assets
import factory from '../assets/factory_placeholder.png';
//Components

import AppFooter from '../components/AppFooter/AppFooter';

import DslStore from '../store/DslStore';
import { getFooterMenu, getHeaderMenu } from '../store/Selectors';

import AppHeader from '../components/AppHeader/AppHeader';
import PageNavigation, { PageNavigationSearch } from '../components/PageNavigation/PageNavigation';
import { searchResultNavPages } from '../mockdata/navPages';
import ContestSearch from './search/ContestSearch';
import DesignSearch from './search/DesignSearch';
import UserSearch from './search/UserSearch';

const SearchResults: React.FC = () => {
  const footerMenu = useStoreState(DslStore, getFooterMenu);
  const headerMenu = useStoreState(DslStore, getHeaderMenu);
  const path = window.location.pathname;

  return (
    <>
      <div className="bg-light-gray">
        <AppHeader
          links={headerMenu}
          bgColor={'#7997c5'}
          logoImg={headerLogo}
          breadcrumbs={`Search/`}
          pageTitle={'Search results'}
        />
        <PageNavigationSearch bgColor="#E3E3E3" links={searchResultNavPages} />

        <main className={`container pt-5 mb-5 overflow-hidden `} >
          <div className=" w-100 ">{path.includes('Contest') && <ContestSearch />}</div>
          <div className=" w-100 ">{path.includes('Design') && <DesignSearch />}</div>
          <div className=" w-100 ">{path.includes('Community') && <UserSearch />}</div>
        </main>

        <AppFooter links={footerMenu} bgColor="#304053" logoImg={headerLogo} bgImage={footerImg} />
      </div>
    </>
  );
};

export default SearchResults;
