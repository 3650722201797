// import { useStoreState } from 'pullstate';
import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Contest, INavLink, User } from '../../global';
import styles from './PageNavigation.module.scss';
import { useAuth } from '../../context/AuthContext';
import { ROUTEPATHS } from '../../routes/routePaths';
import { briefNavPages, designerNavPages } from '../../mockdata/navPages';
import { setNavLinkActive } from '../../store/DslStore';

interface Props {
  contest: Contest;
  pageName?: string;
}
export const PageNavigationBriefWLF: React.FC<Props> = ({ contest, pageName = 'brief' }) => {
  const navigationRef = useRef<any>(null);
  const { loggedUser: user } = useAuth();
  const [visible, setVisible] = useState(false);

  const callbackFunction = () => {
    const scroll = window.scrollY;
    if (scroll > 170) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  const isDisabled = (navLink: any) => {
    if (navLink.text === 'Gallery') {
      if (user?.userType === 'G') {
        return false;
      }
      return contest.isPrivate || contest.hideGalleryTab;
    } else if (navLink.text === 'Upload') {
      return contest.isClosed;
    } else if (navLink.text === 'Awards') {
      return contest.hideAwardsTab;
    } else if (navLink.text === 'Inspirations') {
      return contest.hideInspirationTab;
    }
    return false;
  };

  window.addEventListener('scroll', callbackFunction);
  return (
    <div className={`${styles.navigationWrapperBrief} container-fluid px-0 ${!visible ? '' : styles.fixed}`} ref={navigationRef} style={{ backgroundColor: '#E3E3E3' }}>
      <div className="container-md">
        <div className="row">
          <div className={`col-12 d-flex flex-nowrap ${styles.tabsWrapperBrief}`}>
            {briefNavPages.map((navLink, index) => (
              <Link
                key={'pageLink_' + index}
                className={`text color-dark flex-grow-1 flex-shrink-0 text-center ${styles.pageTabBrief} 
                ${navLink.path === '/' + pageName && styles.activeTabBrief}
                ${isDisabled(navLink) && styles.disabledLinks}`}
                to={`/${ROUTEPATHS.CONTEST}/${contest?.nameForUrl}${navLink.path}`}
              >
                <span className="text-16">{navLink.text}</span>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

