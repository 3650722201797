import { ImgProps } from '../../../global';
import { pathStrapiImage } from '../../../helpers/utils';
import HorizontalSection from '../../HorizontalSection/HorizontalSection';

function BigImageVertical(props: ImgProps) {
  return (
    <>
      <HorizontalSection key={props.id} bgColor={props.bgcolor}>
        <div className="row my-5   px-1 mx-1 ">
          <div className="col-12 d-flex justify-content-center align-items-center">
            <img
              className="img-fluid w-100"
              style={{  height: '1300px' }}
              alt="Placeholder"
              src={pathStrapiImage(props.image.data.attributes.url)}
            />
          </div>
        </div>
      </HorizontalSection>
    </>
  );
}

export default BigImageVertical;
