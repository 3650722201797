import React from 'react';
import styles from './YourProfile.module.scss';
import { Controller, set, useForm } from 'react-hook-form';
import { User } from '../../../global';
import { DropDownYears } from '../../Forms/DropDown/DropDownBirthYear';

interface Props {
user:User
}

const YourProfileWLF: React.FC<Props> = ({user}) => {
  const { register, handleSubmit, getValues } = useForm<User>();

  const [opened, setOpened] = React.useState(false);

  const openClicked = () => {
    console.log('openClicked');
    setOpened(!opened);
  };
  const onSubmit = async (data: User) => {
  }
  return (
    <>
      <div
        className="col-12  bg-blue mb-5 align-items-center justify-content-center  d-flex"
        style={{ height: '30px', borderRadius: '8px', cursor: 'pointer' }}
        onClick={openClicked}
      >
        <div className="col-2"></div>
        <div className="col-8  justify-content-center d-flex ">
          <span className="text-xxl fw-bold color-light" style={{ fontSize: '2rem' }}>
            Your profile
          </span>
        </div>

        <div className={`col-2 d-flex  justify-content-end align-items-center ${styles.toggle} ${opened && styles.open}`} onClick={openClicked}>
          <div className={`${styles.arrow}  `}> </div>
        </div>
      </div>
      {opened && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="px-5 mt-7">
            <div className="row mb-7 mt-4">
              <div className="col-12 col-md-2 text fw-bold"></div>
              <div className="col-12 col-md-10">
                <span className="text color-blue">
                  <i>
                    <b>
                      <span className="color-red">*</span> &nbsp; Mandatory fields (required to{' '}
                      {user?.userType === 'D' ? 'upload your design' : 'launch a contest'})
                    </b>
                  </i>
                </span>
              </div>
            </div>
            <div className="row mb-5 mt-4">
              <div className="col-12 col-md-2 text fw-bold">Username</div>
              <div className="col-12 col-md-10">
                <input
                  type="text"
                  style={{ border: '0px ' }}
                  id="username"
                  disabled={true}
                  // placeholder={user?.username}
                  value={user?.username}
                />
              </div>
            </div>
            <div className="row mb-5 mt-4">
              <div className="col-12 col-md-2 text fw-bold">Email</div>
              <div className="col-12 col-md-10">
                <input
                  type="text"
                  style={{ border: '0px ' }}
                  id="username"
                  disabled={true}
                  // placeholder={user?.email}
                  value={user?.email}
                />
              </div>
            </div>

            <div className="row mb-5">
              <div className="col-12 col-md-2 text fw-bold">
                First name <span className="color-red">*</span>
              </div>
              <div className="col-12 col-md-10">
                <input
                  type="text"
                  defaultValue={user.firstName}
                  {...register('firstName', {
                    onChange: (e) => (
                      console.log(e.target.value)//, setFirstName(e.target.value), setContextfirstname(e.target.value)
                    ),
                  })}
                />
              </div>
            </div>

            <div className="row mb-5">
              <div className="col-12 col-md-2 text fw-bold">
                Last name <span className="color-red">*</span>
              </div>
              <div className="col-12 col-md-10">
                <input
                  type="text"
                  defaultValue={user.lastName}
                  {...register('lastName', {
                    onChange: (e) => (console.log(e.target.value)),//setLastName(e.target.value), setContextlastname(e.target.value)),
                  })}
                />
              </div>
            </div>

            <div className="row mb-5">
              <div className="col-12 col-md-2"></div>
              <div className="col-12 col-md-10 d-flex flex-row justify-content-start align-items-center ">
                <input
                  type="checkbox"
                  defaultChecked={user.hasPublicName}
                  className="flex-shrink-none"
                  {...register('hasPublicName')}
                />
                <span className="align-self-middle ps-3 ">Public (your full name visible in your profile page)</span>
              </div>
            </div>
            {user?.userType === 'D' && (
              <>
                <div className="row mb-5">
                  <div className="col-12 col-md-2 text fw-bold">Biography</div>
                  <div className="col-12 col-md-10">
                    <textarea
                      placeholder={'Please write here a short bio'}
                      defaultValue={user.biography}
                      rows={7}
                      className="d-flex flex-col justify-content-center align-items-center "
                      {...register('biography', {
                        // onChange: (e) => (setBiography(e.target.value), setContextbio(e.target.value)),
                      })}
                    />
                  </div>
                </div>
                <div className="row mb-5">
                  <div className="col-12 col-md-2 text fw-bold">Birth year</div>
                  <div className="col-12 col-md-4">
                    {/* <DropDownYears options={years} onSelect={(e: number) => setYearSelected(e)} /> */}
                  </div>
                </div>
                <div className="row mb-5">
                  <div className="col-12 col-md-2 text fw-bold">Birth date</div>
                  <div className="col-12 col-md-4">
                    <div>
                      {/* <DatePicker
                        value={birthDate}
                        onChange={(e) => setBirthDate(e)}
                        formatInputText={formatInputValue}
                        inputPlaceholder={userBirthDate ? startDateString(userBirthDate) : 'Select your birthDate'}
                        wrapperClassName={`col-12 `}
                        inputClassName={`${styles.birthDateInput}`}
                        colorPrimary="#7997c5"
                      /> */}
                    </div>
                  </div>
                </div>
                <div className="row mb-5">
                  <div className="col-12 col-md-2"></div>
                  <div className="col-12 col-md-10 d-flex flex-row justify-content-start align-items-center ">
                    
                    <input
                      defaultChecked={user.hasPublicBirthDate}
                      type="checkbox"
                      // onClick={() => (
                        // setHasPublicBirthDate(!hasPublicBirthDate), setContexthaspublicbirthdate(!hasPublicBirthDate)
                      // )}
                      className="flex-shrink-none"
                      {...register('hasPublicBirthDate')}
                    />
                    <span className="align-self-middle ps-3 ">Public (birth date visible in your profile page)</span>
                  </div>
                </div>

                <div className="row mb-5">
                  <div className="col-12 col-md-2 text fw-bold">Gender</div>
                  <div className="col-12 col-md-10 d-flex flex-row justify-content-between align-items-center ">
                    <div>
                      <input
                        value={'N'}
                        type="radio"
                        {...register('gender')}
                        defaultChecked={user.gender === 'N' }
                      />
                      <label className="align-self-middle fw-normal ">N/A</label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        value={'M'}
                        {...register('gender')}
                        defaultChecked={user.gender === 'M'}
                      />
                      <label className="align-self-middle fw-normal ">Male</label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        value={'F'}
                        {...register('gender')}
                        defaultChecked={user.gender === 'F' }
                      />
                      <label className="align-self-middle fw-normal ">Female</label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        value={'O'}
                        {...register('gender')}
                        defaultChecked={user.gender === 'O'}
                      />
                      <label className="align-self-middle fw-normal ">Other</label>
                    </div>
                  </div>
                </div>

                <div className="row mb-5">
                  <div className="col-12 col-md-2 text fw-bold">
                    Mobile
                    <br /> number <span className="color-red">*</span>
                  </div>
                  <div className="col-12 col-md-10">
                    <input
                      type="text"
                      defaultValue={user.mobileNumber}
                      {...register('mobileNumber', {
                        // onChange: (e) => (containsOnlyNumbers(e.target.value) ? setMobileNumber(e.target.value) : null),
                      })}
                    />
                  </div>
                </div>
                <div className="row mb-5">
                  <div className="col-12 col-md-2 text fw-bold">Phone number</div>
                  <div className="col-12 col-md-10">
                    <input
                      type="text"
                      defaultValue={user.phoneNumber}
                      {...register('phoneNumber', {
                        required: false,
                        // onChange: (e) =>
                        //   containsOnlyNumbers(e.target.value)
                        //     ? (setPhoneNumber(e.target.value), setContextphonenumber(e.target.value))
                        //     : null,
                      })}
                    />
                  </div>
                </div>
              </>
            )}
            {user?.userType === 'C' && (
              <div className="row mb-5">
                <div className="col-12 col-md-2 text fw-bold">
                  Description <span className="color-red">*</span>
                </div>
                <div className="col-12 col-md-10">
                  {/* <textarea
                    placeholder={'Please write here a company description'}
                    value={user.clientDescription}
                    rows={7}
                    {...register('description', {
                      required: true,
                      onChange: (e) => (setClientDescr(e.target.value), setContextcompanydescription(e.target.value)),
                    })}
                  /> */}
                </div>
              </div>
            )}

            <div className="row mb-5">
              <div className="col-12 col-md-2 text fw-bold">
                Address <span className="color-red">*</span>
              </div>
              <div className="col-12 col-md-10">
                <input
                  type="text"
                  value={user.addressLine1}
                  // {...register('addressLine1', {
                  //   required: user?.addressLine1 ? false : true,
                  //   onChange: (e) => (setAddressLine1(e.target.value), setContextaddressline1(e.target.value)),
                  // })}
                />
              </div>
            </div>

            <div className="row mb-5">
              <div className="col-12 col-md-2 text fw-bold">
                City <span className="color-red">*</span>
              </div>
              <div className="col-12 col-md-10">
                <input
                  type="text"
                  value={user.city}
                  // {...register('city', {
                  //   required: true,
                  //   onChange: (e) => (setCity(e.target.value), setContextcity(e.taget.value)),
                  // })}
                />
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-12 col-md-2"></div>
              <div className="col-12 col-md-10 d-flex flex-row justify-content-start align-items-center ">
                <input
                  type="checkbox"
                  checked={user.hasPublicCity}
                  // onClick={() => (setHasPublicCity(!hasPublicCity), setContexthaspubliccity(!hasPublicCity))}
                  className="flex-shrink-none"
                />

                <span className="align-self-middle ps-3 ">Public (city visible in your profile page)</span>
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-12 col-md-2 text fw-bold">
                State <span className="color-red">*</span>
              </div>
              <div className="col-12 col-md-10">
                <input
                  type="text"
                  value={user.state}
                  // {...register('state', { required: true, onChange: (e) => setState(e.target.value) })}
                />
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-12 col-md-2 text fw-bold">
                Zip Code <span className="color-red">*</span>
              </div>
              <div className="col-12 col-md-10">
                <input
                  type="text"
                  value={user.zipCode}
                  {...register('zipCode', {
                    required: true,
                    // onChange: (e) => (setZipCode(e.target.value), setContextzipcode(e.target.value)),
                  })}
                />
              </div>
            </div>

            <div className="row mb-5">
              <div className="col-12 col-md-2 text fw-bold">
                Your current country <span className="color-red">*</span>
              </div>
              <div className="col-12 col-md-4 d-flex flex-row justify-content-start align-items-center ">
                {/* <DropDownCountry options={countries} onSelect={(e: Country) => (setCountry(e), setContextcountry(e))} /> */}
              </div>
            </div>

            <div className="row mb-5">
              <div className="col-12 col-md-2"></div>
              <div className="col-12 col-md-10 d-flex flex-row justify-content-start align-items-center ">
                <input
                  type="checkbox"
                  checked={user.hasPublicCountry}
                  // onClick={() => (
                  //   setHasPublicCountry(!hasPublicCountry), setContexthaspubliccountry(!hasPublicCountry)
                  // )}
                  className="flex-shrink-none"
                  {...register('hasPublicCountry')}
                />

                <span className="align-self-middle ps-3 ">Public (country visible in your profile page)</span>
              </div>
            </div>
            {user?.userType === 'D' && (
              <div className="row mb-5">
                <div className="col-12 col-md-2 text fw-bold">Contact language</div>
                <div className="col-12 col-md-10 d-flex flex-row justify-content-between align-items-center ">
                  <div>
                    <input
                      type="radio"
                      value={'N'}
                      // onChange={(e) => (setContactLanguage(e.target.value), setContextcontactlanguage(e.target.value))}
                      checked={user.contactLanguage === 'N' ? true : false}
                    />
                    <label className="align-self-middle fw-normal ">N/A</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      value={'E'}
                      // onChange={(e) => (setContactLanguage(e.target.value), setContextcontactlanguage(e.target.value))}
                      checked={user.contactLanguage === 'E'}
                    />
                    <label className="align-self-middle fw-normal ">English</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      value={'I'}
                      // onChange={(e) => (setContactLanguage(e.target.value), setContextcontactlanguage(e.target.value))}
                      checked={user.contactLanguage === 'I' }
                    />
                    <label className="align-self-middle fw-normal ">Italian</label>
                  </div>
                </div>
              </div>
            )}
            <div className="row mb-5">
              <div className="col-12 col-md-2 text fw-bold">Tags</div>
              <div className="col-12 col-md-10">
                {/* <input
                  type="text"
                  value={tagString}
                  {...register('tempTags', {
                    required: false,
                    onChange: (e) => (setTagString(e.target.value), setContexttags(e.target.value)),
                  })}
                />
                {suggestionArray.length > 0 && tagStringCheck() && (
                  <div className={`${styles.suggestionWrapper}`}>
                    {suggestionArray.map((item: DSLTag, index: number) => (
                      <div className={`text ${styles.suggestionRow}`} key={index} onClick={() => addTag(item.name)}>
                        {item.name}
                      </div>
                    ))}
                  </div>
                )} */}
                <span className=" ms-2 color-dark_grey_40">please separate each tag with spaces</span>
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-12 col-md-2 text fw-bold"></div>
              <div className="col-12 col-md-10">
                {/* {tagsArray.map(
                  (item: string, index: number) =>
                    item !== '' && (
                      <button disabled className="btn-tag mb-4 " key={index}>
                        <span>{item}</span>
                      </button>
                    )
                )} */}
              </div>
            </div>

            <div className="row  d-flex justify-content-center align-items-center mb-7 mt-7">
              {/* <div
                className={`btn-dark_blue col-md-2 mb-sm-3 mb-md-0 rounded mx-5 ${styles.button} `}
                onClick={handleSubmit(gotoProfilePreview)}
              >
                preview
              </div> */}
              <button className={`btn-success rounded col-md-2 mx-5 ${styles.button}  `}>update</button>
            </div>
          </div>
        </form>
      )}

    </>
  );
};

export default YourProfileWLF;
