import React, { useState, useRef, useEffect } from 'react';

import axiosHelper from '../../../helpers/axiosHelper';
import { setCommunity, setCommunityPageNumber } from '../../../store/DslStore';

import styles from './DropDown.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface IDropDown {
  options?: IDropDownItem[];
  onSelect?: (o: IDropDownOption) => void;
  descs?: (descs: boolean) => void;
  reset?: boolean;
  label?: string;
  thin?: boolean;
}

interface IDropDownItem {
  label: string;
  value: string;
}
export interface IDropDownOption extends IDropDownItem {
  index: number;
  sort?: string;
}

export const DropDownCommunitySorted: React.FC<IDropDown> = (props) => {
  const dropDownContainer = useRef<any>(null);
  let [open, setOpen] = useState<boolean>(false);
  let [itemSelected, setItemSelected] = useState<IDropDownOption>({
    label: 'Date',
    value: '2',
    sort: 'registrationDate',
    index: 1,
  });
  const [descs, setDescs] = useState(false);
  const [prevChoice, setPrevChoice] = useState<IDropDownOption>({
    label: 'Date',
    value: '2',
    sort: 'registrationDate',
    index: 1,
  });

  useEffect(() => {
    setItemSelected({
      label: 'Date',
      value: '2',
      sort: 'registrationDate',
      index: 1,
    });
    setDescs(false);
  }, [props.reset]);

  const generateOptionsList = (options: IDropDownItem[]) => {
    // console.log(itemSelected);
    let indexedOptions: IDropDownOption[] = [];
    options.forEach((item, index) => {
      indexedOptions.push({
        ...item,
        index: index,
      });
    });
    return indexedOptions;
  };

  const globalClickListener = (e: MouseEvent) => {
    if (dropDownContainer.current && dropDownContainer.current.contains(e.target)) {
      // setDropDownAnimation(true);
      return;
    } else {
      setOpen(false);
    }
  };

  const handleOnClick = () => {
    if (!open) {
      document.addEventListener('click', (e) => globalClickListener(e));
    } else {
      document.removeEventListener('click', (e) => globalClickListener(e));
    }
    setOpen(!open);
  };

  const handleOnSelect = (option: IDropDownOption) => {
    console.log(option);
    setItemSelected(option);
    setOpen(!open);
    props.onSelect && props.onSelect(option);
    setPrevChoice(option);
    // props.descs && props.descs(descs);
  };
  const getSelectedStyle = (index: number) => {
    return itemSelected && itemSelected.index === index ? styles.selected : '';
  };

  const optionsList = props.options ? generateOptionsList(props.options) : [];

  // const [dropdownAnimation, setDropDownAnimation] = useState(false);

  return (
    <div ref={dropDownContainer} className={`${styles.dropdown_wrapper} ${props.thin && styles.thin_ui}`}>
      <div className={`${styles.dropdown} ${open ? styles.open : ''}`}>
        <div className={`${styles.dropdown__toggle}`} onClick={handleOnClick}>
          {props.label && <div className="fw-bold text-uppercase pe-3"> {props.label}</div>}
          <span className={`${styles.dropdown__label} ${props.label && 'text-center flex-grow-1'}`}>
            <div className="d-flex flex-row align-items-center justify-content-center ">
              <span className="me-2">{itemSelected.label}</span>
              <div className="d-flex flex-column  justify-content-center   ">
                <div className="d-flex flex-row align-items-start  " style={{ height: '1rem' }}>
                  <FontAwesomeIcon
                    icon={faCaretUp as IconProp}
                    className={` ${!descs ? `color-blue` : `color-light_grey_30`} `}
                    style={{ fontSize: '1.2rem' }}
                  />
                </div>
                <div className="d-flex flex-row align-items-end" style={{ height: '1rem' }}>
                  <FontAwesomeIcon
                    icon={faCaretDown as IconProp}
                    className={` ${descs ? `color-blue` : `color-light_grey_30`}`}
                    style={{ fontSize: '1.2rem' }}
                  />
                </div>
              </div>
            </div>
          </span>
          <div className={`${styles.arrow}`}></div>
          {/* <div
            className="d-flex flex-row-reverse"
            onClick={handleOnClick}
            onAnimationEnd={() => setDropDownAnimation(false)}
          >
            {!open && (
              <div
                className={`${styles.dropdown_angle} ${dropdownAnimation ? styles.transitionIn : null}`}
                onAnimationEnd={() => setDropDownAnimation(false)}
              >
                <FontAwesomeIcon icon={faAngleDown as IconProp} className={`text color-blue `} />
              </div>
            )}
            {open && (
              <div
                className={`${styles.dropdown_angle} ${dropdownAnimation ? styles.transitionIn : null}`}
                onAnimationEnd={() => setDropDownAnimation(false)}
              >
                <FontAwesomeIcon icon={faAngleUp as IconProp} className={`text color-blue `} />
              </div>
            )}
          </div> */}
        </div>

        <div className={`${styles.dropdown__optionslist}`}>
          {optionsList.map((option, index) => (
            <div
              key={index + option.value}
              className={`${styles.dropdown__option} ${getSelectedStyle(index)} `}
              data-value={option.value}
              onClick={() =>
                prevChoice
                  ? prevChoice.label !== option.label
                    ? (handleOnSelect(option), props.descs && props.descs(false), setDescs(false))
                    : (handleOnSelect(option), props.descs && props.descs(!descs), setDescs(!descs))
                  : (handleOnSelect(option), props.descs && props.descs(false), setDescs(false))
              }
            >
              <div className=" w-100 d-flex flex-row algin-items-center justify-content-between ">
                {option.label}
                {itemSelected && option.label === itemSelected.label && (
                  <div className="d-flex flex-column  justify-content-center  ">
                    <div className="d-flex flex-row align-items-start  " style={{ height: '1.2rem' }}>
                      <FontAwesomeIcon
                        icon={faCaretUp as IconProp}
                        className={` ${!descs ? `color-blue` : `color-light_grey_30`} `}
                        style={{ fontSize: '1.4rem' }}
                      />
                    </div>
                    <div className="d-flex flex-row align-items-end" style={{ height: '1.2rem' }}>
                      <FontAwesomeIcon
                        icon={faCaretDown as IconProp}
                        className={` ${descs ? `color-blue` : `color-light_grey_30`}`}
                        style={{ fontSize: '1.4rem' }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export const DropDownCommunityType: React.FC<IDropDown> = (props) => {
  const dropDownContainer = useRef<any>(null);
  let [open, setOpen] = useState<boolean>(false);
  let [itemSelected, setItemSelected] = useState<IDropDownOption>();

  useEffect(() => {
    setItemSelected(undefined);
  }, [props.reset]);

  const generateOptionsList = (options: IDropDownItem[]) => {
    // console.log(itemSelected);
    let indexedOptions: IDropDownOption[] = [];
    options.forEach((item, index) => {
      indexedOptions.push({
        ...item,
        index: index,
      });
    });
    return indexedOptions;
  };

  const globalClickListener = (e: MouseEvent) => {
    if (dropDownContainer.current && dropDownContainer.current.contains(e.target)) {
      // setDropDownAnimation(true);
      return;
    } else {
      setOpen(false);
    }
  };
  const handleOnClick = () => {
    if (!open) {
      document.addEventListener('click', (e) => globalClickListener(e));
    } else {
      document.removeEventListener('click', (e) => globalClickListener(e));
    }
    setOpen(!open);
  };

  const handleOnSelect = (option: IDropDownOption) => {
    console.log(option);
    setItemSelected(option);
    setOpen(!open);

    props.onSelect && props.onSelect(option);
  };
  const getSelectedStyle = (index: number) => {
    return itemSelected && itemSelected.index === index ? styles.selected : '';
  };

  const optionsList = props.options ? generateOptionsList(props.options) : [];

  // const [dropdownAnimation, setDropDownAnimation] = useState(false);

  return (
    <div ref={dropDownContainer} className={`${styles.dropdown_wrapper} ${props.thin && styles.thin_ui}`}>
      <div className={`${styles.dropdown} ${open ? styles.open : ''}`}>
        <div className={`${styles.dropdown__toggle}`} onClick={handleOnClick}>
          {props.label && <div className="fw-bold text-uppercase pe-3"> {props.label}</div>}
          <span className={`${styles.dropdown__label} ${props.label && 'text-center flex-grow-1'}`}>
            {itemSelected ? itemSelected.label : 'All'}
          </span>
          <div className={`${styles.arrow}`}></div>
          {/* <div
            className="d-flex  flex-row-reverse"
            onClick={handleOnClick}
            // onAnimationEnd={() => setDropDownAnimation(false)}
          >
            {!open && (
              <div
                className={`${styles.dropdown_angle} ${dropdownAnimation ? styles.transitionIn : null}`}
                onAnimationEnd={() => setDropDownAnimation(false)}
              >
                <FontAwesomeIcon icon={faAngleDown as IconProp} className={`text color-blue `} />
              </div>
            )}
            {open && (
              <div
                className={`${styles.dropdown_angle} ${dropdownAnimation ? styles.transitionIn : null}`}
                onAnimationEnd={() => setDropDownAnimation(false)}
              >
                <FontAwesomeIcon icon={faAngleUp as IconProp} className={`text color-blue `} />
              </div>
            )}
          </div> */}
        </div>

        <div className={`${styles.dropdown__optionslist}`}>
          {optionsList.map((option, index) => (
            <div
              key={index + option.value}
              className={`${
                option.label !== '' ? styles.dropdown__option : styles.dropdown__option_noText
              } ${getSelectedStyle(index)} ${
                option.label === 'User' && ` ${styles.dropdown__option_noText} fw-bold text-16`
              } ${option.label === 'Client' && ` ${styles.dropdown__option_noText} fw-bold text-16`}`}
              data-value={option.value}
              onClick={() => handleOnSelect(option)}
            >
              {option.label}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
