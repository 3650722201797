import React, { useEffect, useState } from 'react';

//Header Data

import designDefaultImage from '../../assets/Design.png';
import designNamingDefaultImage from '../../assets/Naming.png';

import {
  getCoCreationContestGallery,
  getLayoutGallery,
  getSearchResultDesign,
  getDesignSearchPageNumber,
} from '../../store/Selectors';
import DslStore, { setDesignSearchPageNumber, setLayoutGallery } from '../../store/DslStore';
import { useStoreState } from 'pullstate';

import GalleryCard from '../../components/Contest/GalleryCard/GalleryCard';
import { startDateString } from '../../helpers/utils';

import GalleryRow from '../../components/Contest/GalleryRow/GalleryRow';
import { GalleryDesign } from '../../global';

import DesignSearchNavigation, {
  DesignSearchPaginationSwitch,
} from '../../components/SearchNavigation/DesignSearchNavigation';

const DesignSearch: React.FC = () => {
  const layoutGallery = useStoreState(DslStore, getLayoutGallery);
  const pageNumber = useStoreState(DslStore, getDesignSearchPageNumber);
  const designs = useStoreState(DslStore, getSearchResultDesign);
  const [visibleDesign, setVisibleDesign] = useState<GalleryDesign[]>([]);

  const arraysfill = () => {
    let chunkSize = 20;
    let startchunk = (pageNumber - 1) * chunkSize;
    let tempArray: GalleryDesign[] = [];
    let chunk: GalleryDesign[] = designs.slice(startchunk, startchunk + chunkSize);
    console.log(designs);

    for (let i = 0; i < designs.length; i += chunkSize) {
      chunk = designs.slice(startchunk, startchunk + chunkSize);

      // console.log('chunk');
      // console.log(chunk);
    }
    for (let i = 0; i < chunkSize; i++) {
      // console.log(chunk[i]);
      // console.log(chunk[i] === undefined);
      //   console.log(chunk[i]);
      if (chunk[i] !== undefined) {
        tempArray.push(chunk[i]);
      } else {
        break;
      }
    }
    // console.log("array1:  ");
    console.log(tempArray);

    setVisibleDesign(tempArray);
  };

  useEffect(() => {
    arraysfill();
  }, [designs]);

  useEffect(() => {
    console.log(pageNumber);

    console.log(visibleDesign);

    arraysfill();
  }, [pageNumber]);

  useEffect(() => {
    arraysfill();
  }, []);

  return (
    <div className="bg-light-gray">
      <main className={`container pt-5 mb-5 px-sm-0 overflow-hidden`}>
        <div className="row ">
          <DesignSearchNavigation onChangeLayout={setLayoutGallery} />
        </div>
        <div className="row ">
          {layoutGallery === 1
            ? visibleDesign.length > 0
              ? visibleDesign.map((aPublicDesign: GalleryDesign, index: number) => (
                  // console.log(aPublicDesign),
                  <div className=" col-12 col-md-6 col-lg-4 col-xl-3">
                    <GalleryCard
                      key={index}
                      title={aPublicDesign.name}
                      typology={aPublicDesign.contest.contestType.name}
                      category={aPublicDesign.contest.category ? aPublicDesign.contest.category.name : ''}
                      creationDate={startDateString(aPublicDesign.uploadDate)}
                      username={aPublicDesign.designer.username}
                      award={aPublicDesign.contest.name}
                      awarded={aPublicDesign.hasAwards}
                      firstname={aPublicDesign.designer.firstname}
                      lastname={aPublicDesign.designer.lastName}
                      private={aPublicDesign.contest.isPrivate}
                      image={
                        aPublicDesign.contest.contestType.name !== 'Naming'
                          ? aPublicDesign.image1Attachment
                            ? aPublicDesign.image1Attachment.s3Path
                            : designDefaultImage
                          : designNamingDefaultImage
                      }
                      votes={aPublicDesign.votesCount}
                      idUser={aPublicDesign.designer.id}
                      idContest={aPublicDesign.contest.id}
                      idDesign={aPublicDesign.id}
                      type={aPublicDesign.contest.contestType.name}
                      hidden={aPublicDesign.isHidden}
                      idClient={aPublicDesign.contest.client.id}
                      startDate={aPublicDesign.contest.startUploadDate}
                      endUploadDate={aPublicDesign.contest.endUploadDate}
                    />
                  </div>
                ))
              : ''
            : ''}
        </div>
        <div className="row mt-3 px-sm-1">
          <div className="px-sm-3">
            {layoutGallery === 2
              ? visibleDesign.length > 0
                ? visibleDesign.map((aPublicDesign: GalleryDesign, index: number) => (
                    <GalleryRow
                      key={index}
                      username={aPublicDesign.designer.username}
                      userId={aPublicDesign.designer.id}
                      contestTitle={aPublicDesign.contest.name}
                      creationDate={aPublicDesign.uploadDate}
                      name={aPublicDesign.contest.client.username}
                      votes={aPublicDesign.votesCount}
                      galleryImages={[
                        aPublicDesign.image1Attachment ? aPublicDesign.image1Attachment.s3Path : '',
                        aPublicDesign.image2Attachment ? aPublicDesign.image2Attachment.s3Path : '',
                        aPublicDesign.image3Attachment ? aPublicDesign.image3Attachment.s3Path : '',
                        aPublicDesign.image4Attachment ? aPublicDesign.image4Attachment.s3Path : '',
                        aPublicDesign.image5Attachment ? aPublicDesign.image5Attachment.s3Path : '',
                      ]}
                      idContest={aPublicDesign.contest.id}
                      image={
                        aPublicDesign.contest.contestType.name !== 'Naming'
                          ? aPublicDesign.image1Attachment
                            ? aPublicDesign.image1Attachment.s3Path
                            : ''
                          : designNamingDefaultImage
                      }
                      designName={aPublicDesign.name}
                      idDesign={aPublicDesign.id}
                      private={aPublicDesign.contest.isPrivate}
                      hidden={aPublicDesign.isHidden}
                      idClient={aPublicDesign.contest.client.id}
                      endUploadDate={aPublicDesign.contest.endUploadDate}
                      startDate={aPublicDesign.contest.startUploadDate}
                    ></GalleryRow>
                  ))
                : ''
              : ''}
          </div>
        </div>
        <div className="row px-2">
          <div className="col-12 px-4 d-flex justify-content-sm-start justify-content-md-end ">
            <DesignSearchPaginationSwitch onChangePage={setDesignSearchPageNumber} />
          </div>
        </div>
      </main>
    </div>
  );
};

export default DesignSearch;
