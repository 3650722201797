import React, { useContext, useEffect, useState } from 'react';
import AppHeader from '../../components/AppHeader/AppHeader';
import AppFooter from '../../components/AppFooter/AppFooter';
import { ProfileHeader } from '../../components/Profile/ProfileHeader/ProfileHeader';

import { ProfileStats } from '../../components/Community/ContestStats/ContestStats';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { faMapMarkerAlt, faCircle } from '@fortawesome/free-solid-svg-icons';

//Header Data
import headerimg from '../../assets/header_placeholder_transparent.png';
import headerLogo from '../../assets/desall_logo.png';

//Footer Data
import footerImg from '../../assets/footer_placeholder_transparent.png';

//Styles
import styles from './Profile.module.scss';
import { ProfilePic } from '../../components/Profile/ProfilePic/ProfilePic';

import {
  birthDateString,
  finalSlicedString,
  finalString,
  fullPathLogoUser,
  locationString,
  registrationDateString,
} from '../../helpers/utils';
import { getCurrentUser, getFooterMenu, getHeaderMenu } from '../../store/Selectors';
import { useStoreState } from 'pullstate';
import {
  faBehanceSquare,
  faDribbbleSquare,
  faFacebookSquare,
  faFlickr,
  faGooglePlusSquare,
  faInstagramSquare,
  faLinkedin,
  faPinterestSquare,
  faSquareXTwitter,
  faTumblrSquare,
  faTwitterSquare,
  faVimeoSquare,
  faYoutubeSquare,
} from '@fortawesome/free-brands-svg-icons';
import { Tag, UpdateProfessionalProfile, UpdateUserData, UpdateUserProfile } from '../../global';
import DslStore from '../../store/DslStore';
import { MyUserContext } from '../../UserContext';

interface ProfilePreviewProps {
  data?: UpdateUserData;
}

const ProfilePreview: React.FC<ProfilePreviewProps> = (props) => {
  const contextUser = useContext(MyUserContext);
  const footerMenu = useStoreState(DslStore, getFooterMenu);
  const headerMenu = useStoreState(DslStore, getHeaderMenu);
  const currentUser = useStoreState(DslStore, getCurrentUser);
  const [editProfileData, setEditProfileData] = useState<UpdateUserProfile>();
  const [editProfessionalProfileData, setEditProfessionalProfileData] = useState<UpdateProfessionalProfile>();
  const [tags, setTags] = useState<string[]>([]);
  useEffect(() => {
    console.log(props.data);
    const ProfileJSON = localStorage.getItem('editYourProfileData');
    const ProfessionalProfileJSON = localStorage.getItem('editProfessionalProfileData');
    if (ProfileJSON) {
      const profiledatas = JSON.parse(ProfileJSON);
      console.log(profiledatas);
      setEditProfileData(profiledatas);
    }
    if (ProfessionalProfileJSON) {
      const professionaldata = JSON.parse(ProfessionalProfileJSON);
      console.log(professionaldata);
      setEditProfessionalProfileData(professionaldata);
    }
  }, []);

  useEffect(() => {
    if (editProfileData?.tempTags) {
      console.log(editProfileData.tempTags);
      let splittedTags: string[] = editProfileData.tempTags.split(' ');
      setTags(splittedTags);
    }
  }, [editProfessionalProfileData]);

  useEffect(() => {
    console.log(editProfileData);
  }, []);

  const goTo = (link?: string) => {
    if (link) window.open(link, '_blank');
  };

  return (
    <div className="bg-light-gray">
      <AppHeader
        links={headerMenu}
        bgColor="#eab95a"
        logoImg={headerLogo}
        bgImage={headerimg}
        alpha={0.4}
        breadcrumbs={`Community/${currentUser?.username}`}
        pageTitle="Profile Preview"
      />
      {/* <PageNavigationProfile
        bgColor="#E3E3E3"
        links={communityUser?.userType === 'C' ? clientNavPages : designerNavPages}
      /> */}
      <main className={`${styles.profileWrapper} container pt-5  overflow-hidden`}>
        <div className="row">
          <div className="col-12 px-0  ">
            {currentUser?.bannerAttachment && <ProfileHeader bgImage={currentUser?.bannerAttachment?.s3Path} />}
          </div>
        </div>
        <div className={`row px-4 mt-3 px-sm-0  ${currentUser?.bannerAttachment ? '' : 'pt-5'}`}>
          <div className="col-3 "></div>
          <div className="col-9 d-sm-none d-md-block">
            <h1 className="bigger-text-bold ms-4 color-blue">{finalSlicedString(currentUser?.username)}</h1>
            <div className="sub-title color-dark ms-4 text-capitalize"> {editProfessionalProfileData?.job?.name}</div>
          </div>
          <div
            className={`col-12 col-md-3 d-sm-flex align-items-sm-center justify-content-sm-center text-center position-relative ${styles.profilePic} ${styles.picUp}`}
          >
            <ProfilePic image={fullPathLogoUser(currentUser?.logoAttachment?.s3Path)}>
              {/* <div className={`${styles.badges}`}> 
                <div className={`${styles.badge} bg-red color-light`}>
                  <FontAwesomeIcon icon={faFlask as IconProp} />
                </div>
                <div className={`${styles.badgeOver} bg-green color-light`}>
                  <FontAwesomeIcon icon={faMapMarkerAlt as IconProp} />
                </div>
              </div> */}
            </ProfilePic>
          </div>
          <div className="col-12 d-md-none d-flex mt-10 justify-content-center">
            <div className="text-center">
              <h1 className="bigger-text-bold ms-4 color-blue">{finalSlicedString(currentUser?.username)}</h1>
              <div className="sub-title-editable ms-4 color-dark text-capitalize">
                {editProfessionalProfileData?.job?.name}
              </div>
            </div>
          </div>

          <div className="col-12 col-md-3  mb-lg-3 ">
            <div className="info ms-4  d-flex flex-column-reverse align-items-center h-100  ">
              <div className=" d-flex flex-row align-items-center  justify-content-center col-12 mb-lg-5 mb-md-4 mb-3 ">
                <div className="me-2  d-flex flex-row align-items-center">
                  <FontAwesomeIcon icon={faCircle as IconProp} className=" text color-green " />
                </div>
                <div className="text color-green col-11">Dream Team user</div>
              </div>
              <div className=" d-flex flex-row align-items-center  justify-content-center col-12 mb-lg-3 mb-md-4 mb-3  ">
                <div className="me-2  d-flex flex-row align-items-center">
                  <FontAwesomeIcon icon={faCalendar as IconProp} className=" text color-blue " />
                </div>
                <div className="text col-11 ">since {registrationDateString(currentUser?.registrationDate)}</div>
              </div>

              <div className=" d-flex flex-row align-items-center  justify-content-center col-12 mb-lg-3 mb-md-4  mb-3">
                {(editProfileData?.hasPublicCity || editProfileData?.hasPublicCountry) && (
                  <>
                    <div className="me-2  d-flex flex-row align-items-center">
                      <FontAwesomeIcon icon={faMapMarkerAlt as IconProp} className=" text color-blue " />
                    </div>

                    <div className="text col-11">
                      {editProfileData?.hasPublicCity &&
                        editProfileData.hasPublicCountry &&
                        locationString(editProfileData?.city, editProfileData?.country?.name)}
                      {editProfileData?.hasPublicCity &&
                        !editProfileData.hasPublicCountry &&
                        locationString(editProfileData?.city)}
                      {!editProfileData?.hasPublicCity &&
                        editProfileData?.hasPublicCountry &&
                        locationString(editProfileData?.country?.name)}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6  pt-4  ">
            <ProfileStats
              entries={currentUser?.badgeDesignsOut}
              inspirations={currentUser?.badgeInspirationOut}
              awards={currentUser?.badgeAmountIn}
              contests={currentUser?.contestsCount}
              reputation={currentUser?.reputationCount}
              favorites={currentUser?.badgeFavoritesCount}
              votes={currentUser?.badgeVotesIn}
              comments={currentUser?.badgeCommentsIn}
            />
          </div>
        </div>
        <div className={`row px-4 px-sm-0 `}>
          {/* Left column  ====================================================== */}
          <div className="col-12  col-md-3 px-3">
            <div className="buttons">
              <button className="btn-blue rounded full mt-4 mb-3">Add to favorite</button>
              <button className="btn-blue rounded full mb-3">Send message</button>
              <button className="btn-red rounded big full mb-3">Block</button>
            </div>
            <div className="tags mt-7  d-flex flex-wrap">
              {tags &&
                tags.map((item, index: number) => (
                  <>
                    <button className="btn-tag mb-4 ">
                      <span>{item}</span>
                    </button>
                  </>
                ))}
              {/* <button className="btn-tag mb-4 ">
                <span>Even more looong stuff</span>
              </button>
              <button className="btn-tag  mb-4">
                <span>Short</span>
              </button>
              <button className="btn-tag  mb-4">
                <span>The</span>
              </button>
              <button className="btn-tag  mb-4">
                <span>The medium</span>
              </button> */}
            </div>
          </div>
          <div className="col-12 col-md-9 ">
            <div className="row">
              <div className="col-12 ps-4 ps-sm-5">
                {/*                 
                <div className="row mb-5 mt-4">
                  <div className="col-12 col-md-2 text text-left mb-2 fw-bold">Username</div>
                  <div className="col-12 col-md-10">{finalString(communityUser?.username)}</div>
                </div> */}
                {editProfileData?.hasPublicName && (
                  <div className="row mb-5 mt-4">
                    <div className="col-12 col-md-2 text text-left mb-2 fw-bold">Name</div>
                    <div className="col-12 col-md-10">
                      {finalString(editProfileData?.firstName, editProfileData?.lastName)}
                    </div>
                  </div>
                )}
                {editProfileData?.birthDate && editProfileData.hasPublicBirthDate && (
                  <div className="row mb-5">
                    <div className="col-12 col-md-2 text text-left mb-2 fw-bold">Birth Date</div>
                    <div className="col-12 col-md-10">{birthDateString(editProfileData?.birthDate)}</div>
                  </div>
                )}
                {/* {editProfileData?.addressLine1 && (
                  <div className="row mb-5 mt-4">
                    <div className="col-12 col-md-2 text text-left mb-2 fw-bold">Address</div>
                    <div className="col-12 col-md-10">{editProfileData.addressLine1}</div>
                  </div>
                )}
                {editProfileData?.zipCode && (
                  <div className="row mb-5 mt-4">
                    <div className="col-12 col-md-2 text text-left mb-2 fw-bold">Zip code</div>
                    <div className="col-12 col-md-10">{editProfileData.zipCode}</div>
                  </div>
                )} */}
                {/* {editProfileData?.description && (
                  <div className="row mb-5">
                    <div className="col-12 col-md-2 text text-left mb-2 fw-bold">Description</div>
                    <div className="col-12 col-md-10">{editProfileData?.description}</div>
                  </div>
                )} */}
                {currentUser?.userType === 'C' && editProfileData?.description && (
                  <div className="row mb-5">
                    <div className="col-12 col-md-2 text text-left mb-2 fw-bold">Description</div>
                    <div className="col-12 col-md-10">{editProfileData.description}</div>
                  </div>
                )}
                {currentUser?.userType === 'C' && editProfessionalProfileData?.sector && (
                  <div className="row mb-5">
                    <div className="col-12 col-md-2 text text-left mb-2 fw-bold">Sector</div>
                    <div className="col-12 col-md-10">{editProfessionalProfileData?.sector}</div>
                  </div>
                )}
                {currentUser?.userType === 'C' && editProfessionalProfileData?.companyName && (
                  <div className="row mb-5">
                    <div className="col-12 col-md-2 text text-left mb-2 fw-bold">Company name</div>
                    <div className="col-12 col-md-10">{editProfessionalProfileData?.companyName}</div>
                  </div>
                )}
                {currentUser?.userType === 'D' && editProfileData?.biography && (
                  <div className="row mb-5">
                    <div className="col-12 col-md-2 text text-left mb-2 fw-bold">Biography</div>
                    <div className="col-12 col-md-10">
                      <p>{finalString(editProfileData?.biography)}</p>
                    </div>
                  </div>
                )}
                {editProfessionalProfileData?.jobTitle && (
                  <div className="row mb-5">
                    <div className="col-12 col-md-2 text text-left mb-2 fw-bold">Who are you</div>

                    <div className="col-12 col-md-10">
                      {finalString(editProfessionalProfileData?.jobTitle)},&nbsp;{' '}
                      {finalString(editProfessionalProfileData?.job?.name)}
                    </div>
                  </div>
                )}
                {editProfessionalProfileData?.companyName && (
                  <div className="row mb-5">
                    <div className="col-12 col-md-2 text text-left mb-2 fw-bold">Company</div>

                    <div className="col-12 col-md-10">{editProfessionalProfileData.companyName}</div>
                  </div>
                )}
                {editProfessionalProfileData?.school1 && editProfessionalProfileData.hasPublicFirstSchool && (
                  <div className="row mb-5">
                    <div className="col-12 col-md-2 text text-left mb-2 fw-bold">School</div>

                    <div className="col-12 col-md-10">{editProfessionalProfileData.school1}</div>
                  </div>
                )}
                {editProfessionalProfileData?.school2 && editProfessionalProfileData.hasPublicSecondSchool &&(
                  <div className="row mb-5">
                    <div className="col-12 col-md-2 text text-left mb-2 fw-bold">Other School</div>

                    <div className="col-12 col-md-10">{editProfessionalProfileData.school2}</div>
                  </div>
                )}
                {editProfessionalProfileData?.freelanceLicense &&
                  editProfessionalProfileData?.freelanceLicense !== 'N' && (
                    <div className="row mb-5">
                      <div className="col-12 col-md-2 text text-left mb-2 fw-bold">Freelance license</div>

                      <div className="col-12 col-md-10">
                        {editProfessionalProfileData.freelanceLicense === 'Y' ? 'yes' : 'no'}
                      </div>
                    </div>
                  )}
                {editProfileData?.gender && (
                  <div className="row mb-5">
                    <div className="col-12 col-md-2 text text-left mb-2 fw-bold">Gender</div>
                    <div className="col-12 col-md-10 ">
                      {editProfileData?.gender === 'M'
                        ? 'He/Him'
                        : editProfileData?.gender === 'F'
                        ? 'She/Her'
                        : editProfileData?.gender === 'N'
                        ? 'N/A'
                        : 'other'}
                    </div>
                  </div>
                )}

                {currentUser?.socialNetworks && currentUser?.socialNetworks.length > 0 && (
                  <div className="row mb-5">
                    <div className="col-12 col-md-2 text text-left mb-2 fw-bold">Social network</div>
                    <div className="col-12 col-md-10 d-flex flex-row align-items-center">
                      {currentUser?.socialNetworks && currentUser?.socialNetworks.length < 1 ? <span> - </span> : null}
                      {/* {console.log(currentEditProfile?.socialNetworks)} */}
                      {currentUser?.socialNetworks &&
                        currentUser?.socialNetworks.map((aSocialNetwork) =>
                          aSocialNetwork.socialNetworkFinder.comparisonString === 'facebook.' ? (
                            <span>
                              <FontAwesomeIcon
                                icon={faFacebookSquare as IconProp}
                                className=" text color-blue me-3 cursor-pointer"
                                style={{ fontSize: '2rem' }}
                                onClick={() => goTo(aSocialNetwork.url)}
                              />
                            </span>
                          ) : null
                        )}
                      {currentUser?.socialNetworks &&
                        currentUser?.socialNetworks.map((aSocialNetwork) =>
                          aSocialNetwork.socialNetworkFinder.comparisonString === 'twitter.' ? (
                            <span>
                              <FontAwesomeIcon
                                icon={faSquareXTwitter as IconProp}
                                className=" text color-blue me-3 cursor-pointer"
                                style={{ fontSize: '2rem' }}
                                onClick={() => goTo(aSocialNetwork.url)}
                              />
                              {/* <img src={xLogo} className="" style={{ height: '2rem', width: '2rem' }} /> */}
                            </span>
                          ) : null
                        )}
                      {currentUser?.socialNetworks &&
                        currentUser?.socialNetworks.map((aSocialNetwork) =>
                          aSocialNetwork.socialNetworkFinder.comparisonString === 'behance.' ? (
                            <span>
                              <FontAwesomeIcon
                                icon={faBehanceSquare as IconProp}
                                className=" text color-blue me-3 cursor-pointer"
                                style={{ fontSize: '2rem' }}
                                onClick={() => goTo(aSocialNetwork.url)}
                              />
                            </span>
                          ) : null
                        )}
                      {currentUser?.socialNetworks &&
                        currentUser?.socialNetworks.map((aSocialNetwork) =>
                          aSocialNetwork.socialNetworkFinder.comparisonString === 'dribbble.' ? (
                            <span>
                              <FontAwesomeIcon
                                icon={faDribbbleSquare as IconProp}
                                className=" text color-blue me-3 cursor-pointer"
                                style={{ fontSize: '2rem' }}
                                onClick={() => goTo(aSocialNetwork.url)}
                              />
                            </span>
                          ) : null
                        )}
                      {currentUser?.socialNetworks &&
                        currentUser?.socialNetworks.map((aSocialNetwork) =>
                          aSocialNetwork.socialNetworkFinder.comparisonString === 'flickr.' ? (
                            <span>
                              <FontAwesomeIcon
                                icon={faFlickr as IconProp}
                                className=" text color-blue me-3 cursor-pointer"
                                style={{ fontSize: '2rem' }}
                                onClick={() => goTo(aSocialNetwork.url)}
                              />
                            </span>
                          ) : null
                        )}
                      {currentUser?.socialNetworks &&
                        currentUser?.socialNetworks.map((aSocialNetwork) =>
                          aSocialNetwork.socialNetworkFinder.comparisonString === 'google.' ? (
                            <span>
                              <FontAwesomeIcon
                                icon={faGooglePlusSquare as IconProp}
                                className=" text color-blue me-3 cursor-pointer"
                                style={{ fontSize: '2rem' }}
                                onClick={() => goTo(aSocialNetwork.url)}
                              />
                            </span>
                          ) : null
                        )}
                      {currentUser?.socialNetworks &&
                        currentUser?.socialNetworks.map((aSocialNetwork) =>
                          aSocialNetwork.socialNetworkFinder.comparisonString === 'linkedin.' ? (
                            <span>
                              <FontAwesomeIcon
                                icon={faLinkedin as IconProp}
                                className=" text color-blue me-3 cursor-pointer"
                                style={{ fontSize: '2rem' }}
                                onClick={() => goTo(aSocialNetwork.url)}
                              />
                            </span>
                          ) : null
                        )}
                      {currentUser?.socialNetworks &&
                        currentUser?.socialNetworks.map((aSocialNetwork) =>
                          aSocialNetwork.socialNetworkFinder.comparisonString === 'pinterest.' ? (
                            <span>
                              <FontAwesomeIcon
                                icon={faPinterestSquare as IconProp}
                                className=" text color-blue me-3 cursor-pointer"
                                style={{ fontSize: '2rem' }}
                                onClick={() => goTo(aSocialNetwork.url)}
                              />
                            </span>
                          ) : null
                        )}
                      {currentUser?.socialNetworks &&
                        currentUser?.socialNetworks.map((aSocialNetwork) =>
                          aSocialNetwork.socialNetworkFinder.comparisonString === 'tumblr.' ? (
                            <span>
                              <FontAwesomeIcon
                                icon={faTumblrSquare as IconProp}
                                className=" text color-blue me-3 cursor-pointer"
                                style={{ fontSize: '2rem' }}
                                onClick={() => goTo(aSocialNetwork.url)}
                              />
                            </span>
                          ) : null
                        )}
                      {currentUser?.socialNetworks &&
                        currentUser?.socialNetworks.map((aSocialNetwork) =>
                          aSocialNetwork.socialNetworkFinder.comparisonString === 'vimeo.' ? (
                            <span>
                              <FontAwesomeIcon
                                icon={faVimeoSquare as IconProp}
                                className=" text color-blue me-3 cursor-pointer"
                                style={{ fontSize: '2rem' }}
                                onClick={() => goTo(aSocialNetwork.url)}
                              />
                            </span>
                          ) : null
                        )}
                      {currentUser?.socialNetworks &&
                        currentUser?.socialNetworks.map((aSocialNetwork) =>
                          aSocialNetwork.socialNetworkFinder.comparisonString === 'instagram.' ? (
                            <span>
                              <FontAwesomeIcon
                                icon={faInstagramSquare as IconProp}
                                className=" text color-blue me-3 cursor-pointer"
                                style={{ fontSize: '2rem' }}
                                onClick={() => goTo(aSocialNetwork.url)}
                              />
                            </span>
                          ) : null
                        )}
                      {currentUser?.socialNetworks &&
                        currentUser?.socialNetworks.map((aSocialNetwork) =>
                          aSocialNetwork.socialNetworkFinder.comparisonString === 'youtube.' ? (
                            <span>
                              <FontAwesomeIcon
                                icon={faYoutubeSquare as IconProp}
                                className=" text color-blue me-3 cursor-pointer"
                                style={{ fontSize: '2rem' }}
                                onClick={() => goTo(aSocialNetwork.url)}
                              />
                            </span>
                          ) : null
                        )}
                    </div>
                  </div>
                )}
                {editProfessionalProfileData?.website && (
                  <div className="row mb-5">
                    <div className="col-12 col-md-2 text text-left mb-2 fw-bold">Website</div>
                    <div className="col-12 col-md-10">
                      <a href={editProfessionalProfileData?.website}>
                        {finalString(editProfessionalProfileData?.website)}
                      </a>
                    </div>
                  </div>
                )}
                {/* <div className="row mb-5">
                  <div className="col-12 col-md-2 text text-left mb-2 fw-bold">Groups</div>
                  <div className="col-12 col-md-10">
                    <a href="#">Dream Team - Shenkar College</a>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </main>
      <AppFooter links={footerMenu} bgColor="#304053" logoImg={headerLogo} bgImage={footerImg} />
    </div>
  );
};

export default ProfilePreview;
