import React, {  useEffect, useRef, useState } from 'react';
import styles from './ProfileEditable.module.scss';
import 'react-bootstrap-country-select/dist/react-bootstrap-country-select.css';
import DslStore, {
  setClientContest,
  setClientContestNumber,
  setPortfolioDesign,
  setPortfolioDesignCopy,
  setUserAwards,
  setUserInspirations,
} from '../../store/DslStore';

import { Contest, ContestTag, User } from '../../global';

import axiosHelper from '../../helpers/axiosHelper';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import UpdatePassword from '../../components/ProfileEditable/UpdatePassword/UpdatePassword';
import NewsletterAndNotifications from '../../components/ProfileEditable/Newsletter&Notifications/Newsletter&Notifications';
import YourProfessionalProfile from '../../components/ProfileEditable/YourProfessionalProfile/YourProfessionalProfile';
import YourProfile from '../../components/ProfileEditable/YourProfile/YourProfile';
import YourProfileWLF from '../../components/ProfileEditable/YourProfile/YourProfileWLF';

interface DatePickerVal {
  day: number;
  month: number;
  year: number;
}

interface ProfileProps {
  user: User;
  breadcrumbs: (breadcrumbs: string) => void;
  successBanner: (success: number) => void;
}

const ProfileEditable: React.FC<ProfileProps> = ({ user, breadcrumbs, successBanner }) => {
  const [open, setOpen] = useState(0);

  const imageAdded = useRef<string>('');

  const [getPreviewProfile, setGetPreviewProfile] = useState(false);
  const [getPreviewProfessional, setGetPreviewProfessional] = useState(false);

  useEffect(() => {
    if (breadcrumbs) {
      breadcrumbs('Edit Profile');
    }
  }, [breadcrumbs]);

  const setUserDetails = async (userId?: number) => {
    user?.userType === 'D'
      ? await axiosHelper.getDesignerDetails(userId)?.then(async (design) => {
          await axiosHelper.getDesignerAwards(userId)?.then((awards) => {
            setUserAwards(awards.awards);
          });
          await axiosHelper.getDesignerInspirations(userId)?.then((inspirations) => {
            setUserInspirations(inspirations.inspirations);
          });
          setPortfolioDesign(design.designs);
          setPortfolioDesignCopy(design.designs);
        })
      : await axiosHelper.getUserDetails(userId)?.then(async (design) => {
          await axiosHelper.getUserAwards(userId)?.then((awards) => {
            setUserAwards(awards.awards);
          });
          await axiosHelper.getUserInspirations(userId)?.then((inspirations) => {
            setUserInspirations(inspirations.inspirations);
          });
          setPortfolioDesign(design.designs);
          setPortfolioDesignCopy(design.designs);
        });
    // if (result) {
    //   setUserInspirations(result.inspirations);
    //   setUserInspirationsCopy(result.inspirations);
    //   setUserAwards(result.awards);
    //   setPortfolioDesign(result.designs);
    //   setPortfolioDesignCopy(result.designs);
  };

  const setClientContests = async () => {
    if (user) {
      let result = await axiosHelper.getContestsByClientId(user?.id);
      const clientContests: Contest[] = result.data;
      if (clientContests) {
        setClientContest(clientContests);
      }
    } else {
      console.log('CECCHETTO COGLIONE');
    }
  };

  const userContestNumber = () => {
    if (user) {
      let clientContestLength: number = user.contests.length;
      console.log(clientContestLength);
      setClientContestNumber(clientContestLength);
    }
  };

  const handleImageAdd = (e: string) => {
    console.log(e);
    // setImage(e);
    imageAdded.current = e;
    console.log(imageAdded.current);
  };

  const handleBannerAdd = (e: any) => {
    console.log(e);
    // setBannerImage(e);
    // setBanner(e);
  };

  useEffect(() => {
    // setCommunityUser(user);
    setPortfolioDesign([]);
    console.log(user);
    // console.log(communityUser);
  }, []);

  useEffect(() => {
    if (user?.userType === 'C') {
      userContestNumber();
      setClientContests();
    }
    setUserDetails(user?.id);
  }, [user]);

  return (
    <div className="bg-light-gray">
      <main className={`${styles.profileWrapper} container  px-sm-0  `}>
        <div className="row ">
          {/* Left column  ====================================================== */}
          <div className="col-12 col-md-3 pt-5 ">
            {/* <div className="buttons">
              <button className="btn-blue rounded full mt-7 mb-3">Add to favorite</button>
              <button className="btn-blue rounded full mb-3">Send message</button>
              <button className="btn-red rounded big full mb-3">Block</button>
            </div> */}
            {user?.tags &&
              user?.tags.map((item: ContestTag, index: number) => (
                <>
                  <button className="btn-tag mb-4 px-0 me-5 ">
                    <span>{item.name.replace(',', '')}</span>
                  </button>
                </>
              ))}
            {/* <div className="tags  d-flex flex-wrap">
              <button className="btn-tag mb-4 me-4">
                <span>Even more Looooong stuff</span>
              </button>
              <button className="btn-tag  mb-4">
                <span>Short</span>
              </button>
              <button className="btn-tag  mb-4">
                <span>The</span>
              </button>
              <button className="btn-tag  mb-4">
                <span>The medium</span>
              </button>
            </div> */}
          </div>
          <div className="col-12 col-md-9">
            <div className="row pt-5">
              <div className="col-12 ps-5  ">
                {user && <YourProfileWLF user={user} />}
                {/* <YourProfile
                  upload={(n) => successBanner(n)}
                  preview={getPreviewProfessional}
                  sendPreview={(e) => (console.log('send profile'), setGetPreviewProfile(e))}
                  open={(open: number) => setOpen(open)}
                  isOpen={open === 1}
                /> */}

                <YourProfessionalProfile
                  user={user}
                  upload={(n) => successBanner(n)}
                  preview={getPreviewProfile}
                  sendPreview={(e) => (console.log('send professional'), setGetPreviewProfessional(e))}
                  open={(open: number) => setOpen(open)}
                  isOpen={open === 2}
                />

                <NewsletterAndNotifications user={user} upload={(n) => successBanner(n)} open={(open: number) => setOpen(open)} isOpen={open === 3} />

                <UpdatePassword upload={(n) => successBanner(n)} open={(open: number) => setOpen(open)} isOpen={open === 4} />
              </div>
            </div>
          </div>
        </div>
      </main>
      {/* <AppFooter links={footerMenu} bgColor="#304053" logoImg={headerLogo} bgImage={footerImg} /> */}
    </div>
  );
};

export default ProfileEditable;
