import React, { useEffect, useState } from 'react';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { faTrophy, faHeart } from '@fortawesome/free-solid-svg-icons';

import styles from './DesignCard.module.scss';
import { ICardInfo } from '../../../Community/CardInfo/CardInfo';
import { useNavigate } from 'react-router-dom';
import DslStore, { setCurrentContest, setCurrentDesign, setDesignsILike } from '../../../../store/DslStore';

import axiosHelper from '../../../../helpers/axiosHelper';
import SquaredImage from '../../../SquaredImage/SquaredImage';
import { Datestr, fullPathLogo, fullPathLogoDesign } from '../../../../helpers/utils';
import Spinner from '../../../Spinner/spinner';
import ActionButton, { MultiActionButtonsWrapper } from '../../../ActionButton/ActionButton';
import { DesignContestType } from '../../../../global';
import { useStoreState } from 'pullstate';
import { getCurrentUser } from '../../../../store/Selectors';
import DesignDefaultImg from '../../../../assets/Design.png';
import { ROUTEPATHS } from '../../../../routes/routePaths';

interface IDesignUserCard extends ICardInfo {
  name: string;
  uploadDate: string;
  votes: number;
  image: string;
  awarded: boolean;
  idDesign: number;
  contest: DesignContestType;
  draft: boolean;
  mainContest?: number;
  hidden: boolean;
}

const DesignCard: React.FC<IDesignUserCard> = (props) => {
  const redirect = useNavigate();
  const currentUser = useStoreState(DslStore, getCurrentUser);
  const [liked, setLiked] = useState(true);
  let date = Math.trunc(Datestr(new Date().toISOString()) / 86400000);
  let endDateNumber = props.contest?.endUploadDate ? Math.trunc(Datestr(props.contest.endUploadDate) / 86400000) : 0;
  // const goToDetail = (contest: Contest) =>{
  //   setCurrentContest(contest);
  //   ;
  // }

  const ongoingDate = () => {
    let ongoing: boolean = true;
    if (endDateNumber < date) {
      ongoing = false;
      return ongoing;
    }
    return ongoing;
  };

  const hiddenOptionActive = () => {
    if (!props.contest.isPrivate && props.contest.startUploadDate && props.contest.endUploadDate) {
      const dateNumber = new Date().getTime() / 86400000;
      const startDateNumber = new Date(props.contest.startUploadDate).getTime() / 86400000;
      const endDateNumber = new Date(props.contest.endUploadDate).getTime() / 86400000;
      const halfDate = (endDateNumber - startDateNumber) / 2;
      if (dateNumber - startDateNumber < halfDate) {
        return true;
      } else {
        return false;
      }
    }
  };
  const goToDesignDetails = async () => {
    if (currentUser) {
      if ((currentUser && currentUser.id === props.userId) || currentUser.userType === 'G') {
        const design = await axiosHelper.retrieveCompleteDesign(props.idDesign);
        if (design) {
          console.log(design);
          setCurrentDesign(design);

          redirect(`/${ROUTEPATHS.CONTEST}/${design.contest.nameForURL}/gallery/${design?.nameForURL}`);
        }
      }
    }
    if (!props.contest.isPrivate && !props.contest.hideGalleryTab && !props.hidden) {
      const design = await axiosHelper.retrieveCompleteDesign(props.idDesign);
      if (design) {
        console.log(design);
        setCurrentDesign(design);

        redirect(`/${ROUTEPATHS.CONTEST}/${design.contest.nameForURL}/gallery/${design?.nameForURL}`);
      }
    }
    if (!props.contest.isPrivate && !props.contest.hideGalleryTab && props.hidden && !hiddenOptionActive()) {
      
      const design = await axiosHelper.retrieveCompleteDesign(props.idDesign);
      if (design) {
        console.log(design);
        setCurrentDesign(design);

        redirect(`/${ROUTEPATHS.CONTEST}/${design.contest.nameForURL}/gallery/${design?.nameForURL}`);
      }
    }
  };

  const goToEditDraft = async () => {
    if (currentUser) {
      if (currentUser.id === props.userId) {
        if (props.draft) {
          await axiosHelper.retrieveCompleteDesign(props.idDesign)?.then(async (design) => {
            console.log(design);
            setCurrentDesign(design);
            await axiosHelper.retrieveCompleteContest(design.contest.id).then((contest) => {
              setCurrentContest(contest.data);
              redirect(`/${ROUTEPATHS.COMMUNITY}/${currentUser?.username}/portfolio/${design?.nameForURL}`);
            });
          });
        }
      }
    }
  };

  const goToContestDetails = async (contestId?: number) => {
    if (contestId) {
      if (props.mainContest) {
        const result = await axiosHelper.retrieveCompleteContest(props.mainContest);
        // console.log(result.data.client);

        console.log(result);
        setCurrentContest(result.data);

        redirect(`/${ROUTEPATHS.CONTEST}/${result.data.nameForUrl}/${ROUTEPATHS.CONTEST_BRIEF}`);
      } else {
        const result = await axiosHelper.retrieveCompleteContest(contestId);
        // console.log(result.data.client);

        console.log(result);
        setCurrentContest(result.data);

        redirect(`/${ROUTEPATHS.CONTEST}/${result.data.nameForUrl}/${ROUTEPATHS.CONTEST_BRIEF}`);
      }
    }
  };

  const unlikedDesign = (idDesign?: number) => {
    if (idDesign !== undefined) {
      axiosHelper.unlikeDesign(idDesign)?.then((response) => {
        if (response.data.username === currentUser?.username) {
          setLiked(false);
          setDesignsILike(response.data.designsILike);
          // alert('Removed from favorites');
        }
      });
    }
  };

  const likeDesign = (idDesign?: number) => {
    if (idDesign !== undefined) {
      axiosHelper.likeDesign(idDesign)?.then((response) => {
        if (response.data.username === currentUser?.username) {
          setLiked(true);
          setDesignsILike(response.data.designsILike);
          // alert('Added as favorite');
        }
      });
    }
  };

  const isLiked = () => {
    let like: boolean;

    if (!currentUser) {
      like = false;
      setLiked(like);
    }
    if (currentUser?.designsILike) {
      like = currentUser.designsILike.some((item: any) => item.id === props.idDesign);
      setLiked(like);
    }
  };

  useEffect(() => {
    isLiked();
  }, [props.idDesign]);

  return (
    <div
      className={`${styles.card} col-12  d-flex flex-row align-items-center justify-content-center position-relative`}
    >
      <div className={`${styles.cardInfoWrapper} d-flex flex-column  bg-light`}>
        <div
          //   onClick={() => (getContestUser(props.contest.id), console.log('click'))}
          className={`${props.image ? styles.cardInfoImage : styles.cardImagePlaceholder} bg-light ${
            props.circle && 'px-5 pt-4 '
          } position-relative ${
            (props.hidden &&
              hiddenOptionActive() &&
              (currentUser?.id !== props.userId ||
                currentUser?.id === props.contest.client.id ||
                currentUser?.userType === 'G')) ||
            props.contest.isPrivate
              ? ''
              : 'cursor-pointer'
          }`}
          onClick={() => goToDesignDetails()}
        >
          {/* <a></a> */}
          {(props.contest?.isPrivate && currentUser?.userType === 'G') || props.userId === currentUser?.id ? (
            <div className={`${styles.squaredImageWrapper}`}>
              <SquaredImage
                image={
                  props.userId === currentUser?.id || currentUser?.userType === 'G' ? props.image : DesignDefaultImg
                }
                altText={props.altText}
                circle={props.circle}
              />
              {props.draft && !props.contest.isClosed && (
                <div className={`${styles.draft}`}>
                  <span> DRAFT </span>
                </div>
              )}
              {/* {props.draft && <div className={`${styles.draft}`}> DRAFT</div>} */}
            </div>
          ) : (
            <div className={`${styles.squaredImageWrapper}`}>
              {/* <div className={`${styles.imageInner} `}> */}
              <SquaredImage
                image={
                  props.contest.isPrivate || props.contest.hideGalleryTab
                    ? props.hidden && hiddenOptionActive()
                      ? currentUser?.id === props.userId ||
                        currentUser?.id === props.contest.client.id ||
                        currentUser?.userType === 'G'
                        ? fullPathLogoDesign(props.image)
                        : DesignDefaultImg
                      : DesignDefaultImg
                    : props.hidden && hiddenOptionActive()
                    ? DesignDefaultImg
                    : fullPathLogoDesign(props.image)
                }
                altText={props.altText}
                circle={props.circle}
              />
              {/* </div> */}
              {/* {props.draft && !props.contest.isClosed && ( */}
              {props.draft && !props.contest.isClosed && (
                <div className={`${styles.draft}`}>
                  <span> DRAFT </span>
                </div>
              )}
            </div>
            // <FontAwesomeIcon icon={faPencil as IconProp} className="color-light" />
          )}
          {props.draft && !props.contest.isClosed && ongoingDate() && (
            <ActionButton
              label="Edit draft"
              icon="edit"
              position="top-right"
              action={() => goToEditDraft()}
              color="success"
            />
          )}
          {!liked && props.userId !== currentUser?.id && (
            <ActionButton
              label={currentUser ? 'Save as favorite' : 'Login to save as favorite'}
              color={!currentUser ? 'light-gray-3' : ''}
              icon="addFavorite"
              position="top-right"
              action={() => currentUser && likeDesign(props.idDesign)}
            />
          )}
          {liked && (
            <ActionButton
              label="Remove from favorites"
              icon="confirm"
              position="top-right"
              color="success"
              action={() => unlikedDesign(props.idDesign)}
            />
          )}

          <MultiActionButtonsWrapper position="bottom-left">
            {props.awarded && <ActionButton label="award" icon="award" />}
            {props.contest?.isPrivate && <ActionButton label="Private gallery" icon="private" />}
            {props.hidden && hiddenOptionActive() && <ActionButton icon="private" label="Hidden design" color="" />}
            {/* {props.accesscode && <ActionButton label="Access code" icon="accesscode" />}
            {props.nda && <ActionButton label="Accept nda" icon="acceptnda" />}
            {props.global && <ActionButton label="Global contest" icon="global" />} */}
          </MultiActionButtonsWrapper>
        </div>
        {/* <StatusBar status={props.status} progress={props.progress} /> */}
        <div className={`${styles.cardInfoContent} container bg-light py-4 `}>
          <div className="row h-25 mb-3 px-2 ">
            <div
              className={`col text-center align-self-center  ${
                (props.hidden &&
                  hiddenOptionActive() &&
                  (currentUser?.id !== props.userId ||
                    currentUser?.id === props.contest.client.id ||
                    currentUser?.userType === 'G')) ||
                props.contest.isPrivate
                  ? ''
                  : 'cursor-pointer'
              }`}
              onClick={() => goToDesignDetails()}
            >
              <h3 className="fw-bold color-blue d-none d-md-block ">
                {props.hidden && hiddenOptionActive()
                  ? currentUser?.userType === 'G' ||
                    currentUser?.id === props.contest.client.id ||
                    currentUser?.id === props.userId
                    ? props.name
                    : 'Hidden by author'
                  : props.name}
              </h3>
              <h1 className="fw-bold color-blue d-md-none d-md-block">{props.name}</h1>
            </div>
          </div>

          <div className=" col align-self-center text-center mt-5 mb-5 ">{props.contest?.category?.name}</div>

          <div className={`${styles.infoContainer} position-static   `}>
            <div className=" justify-content-left mb-2    " style={{ height: '20px', overflow: 'hidden' }}>
              <FontAwesomeIcon icon={faCalendar as IconProp} className="col-1  text color-blue " />
              <span className={`ps-2 justify-content-left col-11 text  ${props.draft ? 'color-danger' : ''}`}>
                {props.draft ? 'Not published yet' : `Posted: ${props.uploadDate}`}
              </span>
            </div>

            <div
              className=" justify-content-left  mb-2 d-flex flex-row   "
              style={{ height: '20px', overflow: 'hidden' }}
              onClick={() => goToContestDetails(props.contest.id)}
            >
              <FontAwesomeIcon icon={faTrophy as IconProp} className=" col-1  text color-blue " />
              <div className=" ps-2 justify-content-left col-11 text  color-blue " style={{ cursor: 'pointer' }}>
                {props.contest?.name}
              </div>
            </div>
            <div className=" justify-content-left mb-2 d-flex  flex-row align-items-center ">
              <FontAwesomeIcon icon={faHeart as IconProp} className="col-1  text color-blue " />
              <span className="ps-2 text color-blue fw-bold col-11 ">
                {props.votes} <span className="ps-1  fw-normal"> votes</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesignCard;
