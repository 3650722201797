import React from 'react';
import { Link } from 'react-router-dom';
import AppHeader from '../components/AppHeader/AppHeader';
import AppFooter from '../components/AppFooter/AppFooter';

//Header Data
import headerimg from '../assets/header_placeholder_transparent.png';
import headerLogo from '../assets/desall_logo.png';
import { topNavPages } from '../mockdata/libraryPages';
import PageNavigation from '../components/PageNavigation/PageNavigation';

//Footer Data
import footerImg from '../assets/footer_placeholder_transparent.png';
import { useStoreState } from 'pullstate';
import DslStore from '../store/DslStore';
import { getFooterMenu, getHeaderMenu } from '../store/Selectors';

const Library: React.FC = () => {
  const footerMenu = useStoreState(DslStore, getFooterMenu);
  const headerMenu = useStoreState(DslStore, getHeaderMenu);
  return (
    <>
      <AppHeader links={headerMenu} bgColor="#7997c5" logoImg={headerLogo} bgImage={headerimg} pageTitle="Library" />
      <PageNavigation bgColor="#E3E3E3" links={topNavPages} />
      <main className="container page-container mb-5">
        <div className="row">
          <div className="col-12">
            <ul>
              <li>
                <Link to="/typography">Typography</Link>
              </li>
              <li>
                <Link to="/images">Images</Link>
              </li>
              <li>
                <Link to="/buttons">Buttons</Link>
              </li>
              <li>
                <Link to="/cards">Cards</Link>
              </li>
              <li>
                <Link to="/forms">Forms</Link>
              </li>
            </ul>
          </div>
        </div>
      </main>
      <AppFooter links={footerMenu} bgColor="#304053" logoImg={headerLogo} bgImage={footerImg} />
    </>
  );
};

export default Library;
