import { CreativeBigImg, CreativesBigImgProps, CreativesCenteredProps } from '../../../../global';
import { pathStrapiImage } from '../../../../helpers/utils';
import HorizontalSection from '../../../HorizontalSection/HorizontalSection';

function CreativesCenteredComponent(props: CreativesCenteredProps) {
  return (
    <HorizontalSection bgColor={props.bgcolor ? props.bgcolor : '#3b3c3c'} key={props.id}>
      <section className="container py-5">
        <div className="row justify-content-center">
          {props.title && (
            <div className="col-12 text-center pb-3 pt-3">
              {props.title && (
                <h3
                  className={`bold-title ${props.titlealign ? `text-${props.titlealign}` : `text-center`} color-${
                    props.titlecolor
                  } pb-3`}
                >
                  {props.title}
                </h3>
              )}
            </div>
          )}
          <div className="col-8 d-flex justify-content-center ">
            <img
              className="img-270 fit-cover "
              src={props.image && pathStrapiImage(props.image.data.attributes.url)}
              alt="Placeholder"
            />
          </div>
          <div className="col-12 text-center pb-4 pt-2">
            {props.boldtext && (
              <p
                className={`text-16 mt-4 fw-bold  ${
                  props.boldtextalign ? `text-${props.boldtextalign}` : `text-center`
                } color-${props.boldtextcolor}`}
              >
                {props.boldtext}
              </p>
            )}
            {props.text && (
              <p
                className={`text-16 color-${props.textcolor}  ${
                  props.textalign ? `text-${props.textalign}` : `text-center`
                } pt-3`}
              >
                {props.text}
              </p>
            )}
            {props.buttontext && (
              <a href={props.link} target={`${props.target ? props.target : '_self'}`}>
                <button className={`btn-${props.buttoncolor} rounded mt-4 `}>{props.buttontext}</button>
              </a>
            )}
          </div>
        </div>
      </section>
    </HorizontalSection>
  );
}

export default CreativesCenteredComponent;
