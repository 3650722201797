import React from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMedal, faUser } from '@fortawesome/free-solid-svg-icons';
import { Contest, Award, User, DesignDetails, AwardDesign } from '../../../global';
import { awardTitleString, finalString, fullPathLogo, fullPathLogoDesign, fullPathLogoUser } from '../../../helpers/utils';
import DslStore, { setCommunityUser, setCurrentContest, setCurrentDesign } from '../../../store/DslStore';
import axiosHelper from '../../../helpers/axiosHelper';
import { useNavigate } from 'react-router-dom';
import { useStoreState } from 'pullstate';
import { getCurrentContest } from '../../../store/Selectors';
import styles from './AwardRow.module.scss';
import DesignDefaultImg from '../../../assets/Design.png';
import UserDefaultImg from '../../../assets/Profile.png';
import { ROUTEPATHS } from '../../../routes/routePaths';

interface IAwardRow {
  contest?: Contest;
  contestAward: Award;
  user?: User;
  image?: string;
  designImage?: string;
}

export const AwardRow: React.FC<IAwardRow> = (props) => {
  const redirect = useNavigate();

  const goToUserDetail = async (userid: number) => {
    // console.log(userid);

    let result: User = await axiosHelper.retrieveUserById(userid);
    console.log(result);

    setCommunityUser(result);
    redirect(`/${ROUTEPATHS.COMMUNITY}/${result.nameForURL}/Profile`);
  };

  const goToDesignDetail = async (design?: AwardDesign) => {
    if (design) {
      const idDesign = design.id;
      if (idDesign) {
        if (!props.contest?.isPrivate) {
          const result: DesignDetails | undefined = await axiosHelper.retrieveCompleteDesign(idDesign);

          if (result) {
            setCurrentDesign(result);
            redirect(`/${ROUTEPATHS.CONTEST}/${props.contest?.nameForUrl}/gallery/${result.nameForURL}`);
          }
        }
      }
    }
  };

  const designName = () => {
    console.log(props.contestAward);
    if (props.contest?.isPrivate) {
      return 'private gallery';
    } else {
      if (props.contestAward.design) {
        return props.contestAward.design.name;
      } else {
        return 'coming soon';
      }
    }
    // {props.contest}
    // {props.contest?.isPrivate ? props.contestAward.design && props.contestAward.design.name : 'coming soon'}
  };

  const name = designName();
  return (
    <div className="col-12 px-4 bg-light radius-m  ">
      <div className="row mb-2">
        <div className="col-md-4 text-center mt-4  ">
          <div className={`d-flex flex-column justify-content-center align-items-center    `}>
            <div className="d-flex flex-column align-items-center justify-content-center  " style={{ height: '140px', width: '140px' }}>
              <FontAwesomeIcon style={{ fontSize: '7.5rem' }} icon={faMedal as IconProp} color="#55b9b9" className="text" />

              <div className="d-flex flex-row justify-content-center mt-4 mb-3  fw-bold ">
                <span className="text-xxl fw-bold mt-2  " style={{ color: '#55b9b9', fontSize: '2.2rem' }}>
                  € {props.contestAward.price}
                </span>
              </div>
            </div>
          </div>
          <div className="d-flex flex-row justify-content-center mt-3  ">
            <span className="fw-bold  ">{awardTitleString(props.contestAward.title, props.contestAward.pos)}</span>
          </div>
        </div>
        <div className="col-md-4 text-center mt-4  ">
          <div className="d-flex  justify-content-center   ">
            <img
              className={`img-fluid rounded ${!props.contest?.isPrivate && props.contestAward.design && 'cursor-pointer'}`}
              style={{ width: '140px', height: '140px' }}
              src={props.contest?.isPrivate ? DesignDefaultImg : fullPathLogoDesign(props.designImage)}
              alt=""
              onClick={() => goToDesignDetail(props.contestAward.design)}
            />
          </div>
          <div className="d-flex flex-row  justify-content-center  mt-3 ">
            <span
              className={`fw-bold   ${name !== 'coming soon' && name !== 'private gallery' && styles.cursorPointer}`}
              onClick={() => goToDesignDetail(props.contestAward.design)}
            >
              {name}
            </span>
          </div>
        </div>
        <div className="col-md-4 text-center mt-4 ">
          <div className="d-flex  justify-content-center ">
            {props.image ? (
              <img
                alt=""
                className={`img-fluid ${props.user && 'cursor-pointer'}`}
                style={{ width: '140px', height: '140px', borderRadius: '50%' }}
                src={fullPathLogoUser(props.image)}
                onClick={() => goToUserDetail(props.contestAward.design.designer.id)}
              />
            ) : (
              <div
                className={` ${styles.noProfileImg}  d-flex align-items-center justify-content-center`}
                onClick={() => props.contestAward.design && goToUserDetail(props.contestAward.design.designer.id)}
              >
                <img
                  src={UserDefaultImg}
                  alt="defaultImg"
                  className={`img-fluid ${props.contestAward.design ? styles.cursorPointer : null}`}
                  style={{ width: '140px', height: '140px', borderRadius: '50%' }}
                />
              </div>
            )}
          </div>
          {props.contestAward.design ? (
            <div className="d-flex flex-row justify-content-center   mt-3">
              <span className="fw-bold color-blue " style={{ cursor: 'pointer' }} onClick={() => goToUserDetail(props.contestAward.design.designer.id)}>
                {finalString(props.contestAward.design.designer.username)}
              </span>
            </div>
          ) : (
            <div className="d-flex flex-row justify-content-center   mt-3">
              <span className="fw-bold ">coming soon</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const UserAwardRow: React.FC<IAwardRow> = (props) => {
  const redirect = useNavigate();
  const currentContest = useStoreState(DslStore, getCurrentContest);

  const goToContestDetail = async () => {
    if (props.contestAward.contest?.id) {
      if (props.contestAward.contest.mainContest) {
        const result = await axiosHelper.retrieveCompleteContest(props.contestAward.contest.mainContest.id);
        console.log(result.data.client);

        console.log(result);
        setCurrentContest(result.data);

        redirect(`/${ROUTEPATHS.CONTEST}/${currentContest?.nameForUrl}/${ROUTEPATHS.CONTEST_BRIEF}`);
      } else {
        const result = await axiosHelper.retrieveCompleteContest(props.contestAward.contest.id);
        console.log(result.data.client);

        console.log(result);
        setCurrentContest(result.data);

        redirect(`/${ROUTEPATHS.CONTEST}/${currentContest?.nameForUrl}/${ROUTEPATHS.CONTEST_BRIEF}`);
      }
    }
  };

  const goToDesignDetail = async (idDesign?: number) => {
    if (idDesign) {
      if (!props.contestAward.contest?.isPrivate) {
        const result: DesignDetails | undefined = await axiosHelper.retrieveCompleteDesign(idDesign);

        if (result) {
          setCurrentDesign(result);
          redirect(`/${ROUTEPATHS.CONTEST}/${props.contest?.nameForUrl}/gallery/${result.nameForURL}`);
        }
      }
    }
  };

  return (
    <div className="col-12 px-4 bg-light radius-m ">
      <div className="row  mb-2">
        <div className="col-md-4 text-center mt-4  ">
          <div className="d-flex flex-row justify-content-center aling-items-center ">
            <div className="d-flex flex-column align-items-center justify-content-center  " style={{ height: '140px', width: '140px' }}>
              <FontAwesomeIcon style={{ fontSize: '7.5rem' }} icon={faMedal as IconProp} color="#55b9b9" className="text" />
              {/* <FontAwesomeIcon style={{ fontSize: '9rem' }} icon={faMedal as IconProp} color="#55b9b9" className="text" /> */}

              <div className="d-flex flex-row justify-content-center  mt-4 mb-3 fw-bold ">
                {/* <FontAwesomeIcon
                  style={{ fontSize: '2rem' }}
                  icon={faEuro as IconProp}
                  color="#55b9b9"
                  className="text mt-1 me-1"
                /> */}
                <span className="text-xxl fw-bold mt-2  " style={{ color: '#55b9b9', fontSize: '2.2rem' }}>
                  € {props.contestAward.price}
                </span>
              </div>
            </div>
          </div>
          <div className="d-flex flex-row justify-content-center   ">
            <span className="fw-bold mt-2">{awardTitleString(props.contestAward.title, props.contestAward.pos)}</span>
          </div>
        </div>
        <div className="col-md-4 text-center mt-4 ">
          <div className="d-flex  justify-content-center ">
            {props.contestAward.contest?.isPrivate ? (
              <img
                className="img-fluid rounded"
                style={{ width: '140px', height: '140px' }}
                src={DesignDefaultImg}
                alt="Placeholder"
                onClick={() => goToDesignDetail(props.contestAward.design.id)}
              />
            ) : (
              <img
                className="img-fluid rounded"
                style={{ width: '140px', height: '140px', cursor: 'pointer' }}
                src={props.image}
                alt="Placeholder"
                onClick={() => goToDesignDetail(props.contestAward.design.id)}
              />
            )}
          </div>
          <div className={`d-flex flex-row justify-content-center  ${!props.contestAward.contest?.isPrivate && 'cursor-pointer'}`}>
            <span className="fw-bold mt-2" onClick={() => goToDesignDetail(props.contestAward.design.id)}>
              {props.contestAward.design.name}
            </span>
          </div>
        </div>
        <div className="col-md-4 text-center mt-4 ">
          <div className="d-flex  justify-content-center ">
            <img
              className="img-fluid "
              style={{ width: '140px', height: '140px', borderRadius: '50%', cursor: 'pointer' }}
              src={fullPathLogo(props.contestAward?.contest?.logoAttachment.s3Path)}
              alt="Placeholder"
              onClick={() => goToContestDetail()}
            />
          </div>
          <div className="d-flex flex-row justify-content-center ">
            <span className="fw-bold color-blue mt-2" style={{ cursor: 'pointer' }} onClick={() => goToContestDetail()}>
              {finalString(props.contestAward.contest?.name)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
