import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import styles from './MessageComponent.module.scss';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

interface Props {
  visible: boolean;
  message: string;
  level: string;
  closeCallback: () => void;
}
const MessageComponent: React.FC<Props> = ({ visible, message, level, closeCallback }) => {
  const [bannerStyle, setBannerStyle] = useState(styles.warningBackground);
  useEffect(() => {
    switch (level) {
      case 'warning':
        setBannerStyle(styles.warningBackground);
        break;
      case 'error':
        setBannerStyle(styles.errorBackground);
        break;
      case 'success':
        setBannerStyle(styles.successBackground);
        break;
      default:
        setBannerStyle(styles.warningBackground);
        break;
    }
  }, [level]);

  return (
    <div className={`d-flex flex-row align-items-center w-100 justify-content-center ${visible ? `${bannerStyle}` : `d-none`}`}>
      <div className="container d-flex flex-row align-items-center justify-content-center ">
        <div className="row col-12 ">
          <div className="col-12  d-flex flex-row align-items-center justify-content-end ">
            <div className="col-12 text-center align-self-end ">
              <span className="sub-title color-light " style={{ zIndex: '10', opacity: '2' }}>
                {message}
              </span>
            </div>
            <div>
              <button className={`${styles.removeBannerButton} bg-blue`} onClick={closeCallback}>
                <FontAwesomeIcon icon={faXmark as IconProp} className=" text color-light" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageComponent;
