import React from 'react';
import { HorizontalComponent, HorizontalComponentProps } from '../../../global';
import HorizontalSection from '../../HorizontalSection/HorizontalSection';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkRehype from 'remark-rehype/lib';

interface HorizontalComponentSection {
  data: HorizontalComponent;
}
function HorizontalSectionComponent(props: HorizontalComponentProps) {
  return (
    <HorizontalSection bgColor={props.bgcolor ? `${props.bgcolor}` : '#ededed'} key={props.id}>
      <section className="container mt-5 mb-5">
        <div className="row justify-content-center ">
          {props.title && (
            <div
              className={`col-12 b mb-4
               ${props.titlealign ? `text-${props.titlealign}` : `text-center`}
              fw-bold
                 color-${props.titlecolor} bold-title-100`}
            >
              {/* <h3
                className={` border  ${props.titlealign ? `text-${props.titlealign}` : `text-center`} fw-bold color-${
                  props.titlecolor
                }`}
              > */}
              <span>{props.title}</span>
              {/* </h3> */}
            </div>
          )}
          {props.subtitle && (
            <div
              className={`col-12 ${
                props.subtitlealign ? `text-${props.subtitlealign}` : `text-center`
              } mb-4  `}
            >
              <span className={`mt-4 sub-title  color-${props.subtitlecolor}`}> {props.subtitle}</span>
            </div>
          )}
          <div className="col-12 text-center  ">
            {props.bigtext && (
              <p
                className={`bigger-text  ${props.bigtextalign ? `text-${props.bigtextalign}` : `text-center`} color-${
                  props.bigtexcolor
                } mb-4 `}
              >
                {props.bigtext}
              </p>
            )}
            {props.boldtext &&
              (console.log(props.boldtextalign),
              (
                <div className={` ${props.boldtextalign ? `text-${props.boldtextalign}` : `text-center`}`}>
                  <span className={`sub-title color-${props.boldtextcolor}  fw-bold mb-4  `}>{props.boldtext}</span>
                </div>
              ))}
            {props.text && (
              // <p className={`text color-${props.textcolor}`}>{props.text}</p>
              // &&
              <ReactMarkdown
                children={props.text}
                remarkPlugins={[remarkGfm]}
                className={`text-16 ${props.textalign ? `text-${props.textalign}` : `text-center`} text-left color-${
                  props.textcolor
                } mt-4`}
              />
            )}
            {props.buttontext && (
              <a href={props.link && props.link} target={`${props.target ? props.target : '_self'}`}>
                <button className={`btn-${props.buttoncolor} rounded mt-4`}>{props.buttontext}</button>
              </a>
            )}
          </div>
        </div>
      </section>
    </HorizontalSection>
  );
}

export default HorizontalSectionComponent;
