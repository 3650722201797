import React, { useEffect, useState } from 'react';

//Header Data
import headerLogo from '../assets/desall_logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faTwitter, faFacebook, faGoogle, faLinkedin, faXTwitter } from '@fortawesome/free-brands-svg-icons';

//Assets
import creative from '../assets/creative_placeholder.png';
import footerImg from '../assets/footer_placeholder_transparent.png';

//Components
import AppHeader from '../components/AppHeader/AppHeader';
import AppFooter from '../components/AppFooter/AppFooter';
import { useForm } from 'react-hook-form';
import { CreateFacebookToken, CreateTwitterToken, SignUpData, SignUpForm, User } from '../global';
import axiosHelper from '../helpers/axiosHelper';
import SignupCard from '../components/SignupCard/SignupCard';
import { getFooterMenu, getHeaderMenu } from '../store/Selectors';
import DslStore, { setCommunityUser, setCurrentUser, setSocialUser } from '../store/DslStore';
import { useStoreState } from 'pullstate';
import { Modal } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import styles from './signup.module.scss';

import {
  IResolveParams,
  LoginSocialFacebook,
  LoginSocialGoogle,
  LoginSocialLinkedin,
  LoginSocialTwitter,
  objectType,
} from 'reactjs-social-login';
import { useAuth } from '../context/AuthContext';
import { ROUTEPATHS } from './routePaths';

const SignUp: React.FC = () => {
  const { login, tokLogin, setLoggedUser: setUser } = useAuth();

  const footerMenu = useStoreState(DslStore, getFooterMenu);
  const headerMenu = useStoreState(DslStore, getHeaderMenu);
  const [itemSelected, setItemSelected] = useState<string>('D');
  const [formValue, setFormValue] = useState<SignUpData>();
  const [privacy, setPrivacy] = useState<boolean>(false);
  const [pwd, setPwd] = useState('');
  const [email, setEmail] = useState('');
  const [popup, setPopup] = useState(false);
  const [usernameError, setUsernameError] = useState<boolean>(false);

  const params = useParams();
  const navigate = useNavigate();

  let strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})');
  let mediumPassword = new RegExp(
    '((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))'
  );
  let strengthBadge = document.getElementById('StrengthDisp');

  const { register, handleSubmit } = useForm<SignUpForm>();

  const onSubmit = (data: any) => {
    console.log(data.password + ' ' + data.passwordrpt);

    checkPwd(data.password);

    if (data.password === data.passwordrpt) {
      console.log('ciao');
      if (privacy) {
        console.log('ciao2');
        setFormValue(data);

        console.log(itemSelected);
        //   form!.email = data.email;
        //   form!.password = data.password;
        //   form!.username = data.username;
        //   console.log(form);
      }
    }
  };

  const checkPwd = (password: string) => {
    if (strongPassword.test(password)) {
      strengthBadge!.style.backgroundColor = 'green';
      strengthBadge!.textContent = 'Strong';
    } else if (mediumPassword.test(password)) {
      strengthBadge!.style.backgroundColor = 'blue';
      strengthBadge!.textContent = 'Medium';
    } else {
      strengthBadge!.style.backgroundColor = 'red';
      strengthBadge!.textContent = 'Weak';
    }
  };

  useEffect(() => {
    console.log(formValue);
    console.log(formValue?.password);
    console.log(formValue?.email);
    console.log(formValue?.username);
  }, [formValue]);

  useEffect(() => {
    if (formValue && itemSelected === 'D') {
      signUpCallDesigner(formValue);
    } else if (formValue && itemSelected === 'C') {
      signUpCallClient(formValue);
    }
  }, [formValue]);

  const handlePasswordChange = (event: any) => {
    // console.log(event.target.value)
    if (event.target.value !== null) {
      strengthBadge!.style.display = 'block';
      checkPwd(event.target.value);
    }

    setPwd(event.target.value);
  };

  const handleEmailChange = (event: any) => {
    // console.log(event.target.value)

    setEmail(event.target.value);
  };

  const signUpCallDesigner = async (form: SignUpData) => {
    setUsernameError(false);
    console.log(form);
    params.huntercode ? (form.hunter = params.huntercode) : (form.hunter = 'null');
    form.userType = itemSelected;
    await axiosHelper
      .signUpDesigner(form)
      ?.then((response) => {
        console.log(response);
        setTimeout(async () => {
          const result = await login(email, pwd, true);
          // const result = await axiosHelper.loginDesigner<User>(email, pwd);
          if (result !== null) {
            navigate('/');
          }
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
        setUsernameError(true);
      });
  };

  const signUpCallClient = async (form: SignUpData) => {
    setUsernameError(false);
    console.log(form);
    params.huntercode ? (form.hunter = params.huntercode) : (form.hunter = 'null');
    form.userType = itemSelected;
    await axiosHelper
      .signUpClient(form)
      ?.then((response) => {
        console.log(response);
        setTimeout(async () => {
          const result = await login(email, pwd, true);
          if (result !== null) {
            navigate(`/${ROUTEPATHS.COMMUNITY}/${result.nameForURL}/profile-editable`);
          }
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
        setUsernameError(true);
      });
  };

  const setPrivacyField = (val: boolean) => {
    setPrivacy(!val);
  };

  useEffect(() => {
    console.log(itemSelected);
  }, [itemSelected]);

  const facebookSignUp = async (token: string, uid: string, email: string, firstName: string, lastName: string) => {
    let userType = '';
    if (itemSelected === 'D') {
      userType = 'Designer';
    } else {
      userType = 'Client';
    }
    setSocialUser({ social: 'F', user: { uid: uid, email: email, firstname: firstName, lastname: lastName } });
    const cft: CreateFacebookToken = {
      uid: uid,
      token: token,
      userType: userType,
      huntercode: params.huntercode ? params.huntercode : '',
    };
    await axiosHelper.createFacebookToken(cft).then((response) => {
      if (response.dslUser) {
        tokLogin(response.dslUser.dslToken).then((response) => {
          navigate(`/${ROUTEPATHS.COMMUNITY}/${response?.nameForURL}/profile-editable`);
        });
      } else {
        console.log(response);
        navigate(`/choose-username`);
      }
    });
  };

  const googleSignUp = async (token: string, uid: string, email: string, firstName: string, lastName: string) => {
    let userType = '';
    if (itemSelected === 'D') {
      userType = 'Designer';
    } else {
      userType = 'Client';
    }
    setSocialUser({ social: 'G', user: { uid: uid, email: email, firstname: firstName, lastname: lastName } });
    const cft: CreateFacebookToken = {
      uid: uid,
      token: token,
      userType: userType,
      huntercode: params.huntercode ? params.huntercode : '',
    };
    await axiosHelper.createGoogleToken(cft).then((response) => {
      console.log(response);

      if (response.dslUser) {
        tokLogin(response.dslUser.dslToken).then((response) => {
          navigate(`/${ROUTEPATHS.COMMUNITY}/${response?.nameForURL}/profile-editable`);
        });
      } else {
        navigate(`/choose-username`);
      }
    });
  };

  const twitterTokenCreation = async (token: string, uid: string) => {
    const ctt: CreateTwitterToken = {
      token: token,
      uid: uid,
      huntercode: params.huntercode ? params.huntercode : '',
      userType: itemSelected === 'D' ? 'Designer' : 'Client',
    };
    await axiosHelper.createTwitterToken(ctt).then((response) => {
      console.log(response);
      if (response.dslUser) {
        tokLogin(response.dslUser.dslToken).then((response) => {
          navigate(`/${ROUTEPATHS.COMMUNITY}/${response?.nameForURL}/profile-editable`);
        });
      } else {
        setSocialUser({ social: 'T', user: { uid: uid } });
        navigate(`/choose-username`);
      }
    });
  };

  const linkedinTokenCreation = async (token: string, uid: string) => {
    const ctt: CreateTwitterToken = {
      token: token,
      uid: uid,
      huntercode: params.huntercode ? params.huntercode : '',
      userType: itemSelected === 'D' ? 'Designer' : 'Client',
    };
    await axiosHelper.createLinkedinToken(ctt).then((response) => {
      console.log(response);
      if (response.dslUser) {
        tokLogin(response.dslUser.dslToken).then((response) => {
          navigate(`/${ROUTEPATHS.COMMUNITY}/${response?.nameForURL}/profile-editable`);
        });
      } else {
        setSocialUser({ social: 'L', user: { uid: uid } });
        navigate(`/choose-username`);
      }
    });
  };

  return (
    <>
      <AppHeader links={headerMenu} bgColor="#7997c5" logoImg={headerLogo} bgImage={creative} pageTitle="Signup" />
      <main className="container-fluid py-5 bg-light-gray">
        <div className="row col-12 justify-content-center align-items-center ">
          <div className="col-12 col-md-5 col-lg-3  d-flex flex-direction-row align-items-center justify-content-center mb-5">
            <SignupCard
              selected={itemSelected === 'D'}
              onClick={() => setItemSelected('D')}
              topText="Sign up as a user"
              middleText={`Select the “individual user“ profile if you are:
              1) A creative and you want to participate in our contest.              
              2) A design lover and you want vote a project of a visible gallery contest.
              3) A company employee and you want to join your “company user“ profile.`}
              backGround={creative}
            ></SignupCard>
          </div>
          <div className="col-12 col-md-5 col-lg-3  d-flex flex-direction-row align-items-center justify-content-center mb-5">
            <SignupCard
              selected={itemSelected === 'C'}
              onClick={() => (setItemSelected('D'), setPopup(true))}
              topText="Sign up as a client"
              middleText={`Select the “client“ profile only if you represent your company and you want to launch a contest on desall.
            This is a client profile so this profile can’t vote or participate in our contests.`}
              backGround={creative}
            ></SignupCard>
          </div>
        </div>
        <div className="row text-center justify-content-center">
          <div className="col-12 col-md-3 col-lg-3">
            <h1 className="sub-title color-dark mb-5">Connect with</h1>

            <LoginSocialFacebook
              // redirect_uri={REDIRECT_URI}
              // scope="email, public_profile, gender, username"
              // xfbml={false}
              appId={process.env.REACT_APP_FB_APP_ID || ''}
              // onLoginStart={onLoginStart}
              onResolve={({ provider, data }: IResolveParams) => {
                // setProvider(provider);
                // setProfile(data);
                console.log(data);
                console.log(data?.accessToken);
                facebookSignUp(data?.accessToken, data?.userID, data?.email, data?.first_name, data?.last_name);
              }}
              onReject={(err: any) => {
                console.log(err);
              }}
            >
              <button className="btn-social__facebook full rounded mb-3">
                <div className="btn-icon">
                  <FontAwesomeIcon icon={faFacebook as IconProp} className="text color-light" />
                </div>
                facebook
              </button>
            </LoginSocialFacebook>
            <LoginSocialLinkedin
              client_id="78ic7x2w9maoqp"
              client_secret="ioKQM8Z2vJzcnj7H"
              scope="email%20profile%20openid%20w_member_social "
              // scope={`openidprofile email w_member_social`}
              // scope="r_liteprofile r_emailaddress"
              redirect_uri={process.env.REACT_APP_SOCIAL_LOGIN_ENDPOINT || ''}
              onLoginStart={() => console.log('start login linkedin')}
              onResolve={({ provider, data }: IResolveParams) => {
                console.log(data);
                console.log(data?.access_token);
                console.log(data?.id_token);
                linkedinTokenCreation(data?.access_token, data?.id_token);
              }}
              onReject={(error: any) => {
                console.log(error);
              }}
            >
              <button className="btn-social__linkedin full rounded mb-3">
                <div className="btn-icon">
                  <FontAwesomeIcon icon={faLinkedin as IconProp} className="text color-light" />
                </div>
                linkedin
              </button>
            </LoginSocialLinkedin>
            <LoginSocialGoogle
              client_id={'571721759610-a952df14qdtva6cbusfsv46sqqg5aja1.apps.googleusercontent.com'}
              scope="openid profile email"
              onReject={(reject: string | objectType) => {
                console.log(reject);
              }}
              onResolve={({ provider, data }: IResolveParams) => {
                console.log(data);
                googleSignUp(data?.access_token, data?.sub, data?.email, data?.given_name, data?.family_name);
              }}
            >
              <button className="btn-social__google full rounded mb-3">
                <div className="btn-icon">
                  <FontAwesomeIcon icon={faGoogle as IconProp} className="text color-light" />
                </div>
                google
              </button>
            </LoginSocialGoogle>
            <LoginSocialTwitter
              client_id="MWNPQmc5NHRQdE5sZ1RTczRyLTQ6MTpjaQ"
              redirect_uri={process.env.REACT_APP_SOCIAL_LOGIN_ENDPOINT || ''}
              onReject={(error) => console.log(error)}
              onResolve={({ provider, data }: IResolveParams) => {
                console.log(provider);
                console.log(data);
                console.log(data?.access_token);
                console.log(data?.id);
                twitterTokenCreation(data?.access_token, data?.id);
              }}
              onLoginStart={() => console.log('start twitter login')}
            >
              <button className="btn-social__twitter full rounded mb-3">
                <div className="btn-icon">
                  <FontAwesomeIcon icon={faXTwitter as IconProp} className="text color-light" />
                  {/* <img src={xLogo} className='' style={{height:'15px', width:'15px'}}/> */}
                </div>
                x-twitter
              </button>
            </LoginSocialTwitter>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12 col-md-4 col-lg-3">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-12 text-center">
                  <h1 className="sub-title color-dark my-5">or sign up</h1>
                </div>
              </div>
              <div className="row">
                <div className="col-12 mb-5">
                  <input
                    type="username"
                    id="username"
                    placeholder="username"
                    className=""
                    {...register('username', { required: true, maxLength: 50 })}
                  />
                  {usernameError ? (
                    <span className="color-red fw-bold">username already used, please change it</span>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-12 mb-5">
                  <input
                    type="email"
                    id="email"
                    placeholder="email"
                    className=""
                    {...register('email', { required: true, maxLength: 50 })}
                    onChange={handleEmailChange}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 mb-5">
                  <input
                    type="password"
                    id="password"
                    placeholder="password"
                    className=""
                    {...register('password')}
                    onChange={handlePasswordChange}
                  />
                  <span id="StrengthDisp" className="badge displayBadge" style={{ display: 'none' }}>
                    Weak
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-12 mb-5">
                  <input
                    type="password"
                    id="passwordrpt"
                    placeholder="repeat password"
                    className=""
                    {...register('passwordrpt')}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-12 d-flex mb-5">
                  <div>
                    <input
                      type="checkbox"
                      name="checkbox"
                      id="checkbox-1"
                      onClick={() => setPrivacyField(privacy)}
                      className="flex-shrink-none"
                    />
                  </div>
                  <div>
                    <label htmlFor="checkbox-1" className="d-block text-start ps-3 text">
                      I’m over 18 and agree to the <a href="#">Privacy Policy</a> & <a href="#">User Agreement</a>
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 d-flex">
                  <div>
                    <input
                      type="checkbox"
                      id="checkbox-1"
                      {...register('receiveNewsletter')}
                      className="flex-shrink-none"
                    />
                  </div>
                  <div>
                    <label htmlFor="checkbox-1" className="d-block text-start ps-3 text">
                      I want to receive the newsletter and agree to the <a href="#">Privacy policy Newsletter</a>
                    </label>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-12 my-5">
                  <button className="btn-dark-blue full rounded 3">Signup</button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <Modal
          show={popup}
          // onHide={() => setPopup(!popup)}
          className={`d-flex flex-column justify-content-center`}
          contentClassName={` ${styles.rounded}`}
        >
          <Modal.Body className={`d-flex justify-content-center   `}>
            <div className="row justify-content-start align-items-center ">
              <div className="col-12 mb-4">
                <span className="sub-title ">Are you really interested in launching a contest on desall.com?</span>
              </div>

              <div className="row text-center ">
                <div> ciao</div>
              </div>
              <div className="row text-center justify-content-center mt-5">
                <div className="btn-dark-blue rounded  col-4" onClick={() => (setItemSelected('C'), setPopup(!popup))}>
                  Signup
                </div>
                <div className="col-4">
                  <div
                    className="d-flex align-items-center justify-content-center "
                    onClick={() => setPopup(!popup)}
                    style={{ height: '30px' }}
                  >
                    <span className="text-xl me-3">Cancel</span>
                    <div
                      className="bg-blue d-flex align-items-center justify-content-center"
                      style={{ borderRadius: '50%', height: '30px', width: '30px' }}
                    >
                      <FontAwesomeIcon icon={faXmark as IconProp} className="text color-light" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <h5>You will be redirected to the Artsana platform innovation.artsana.com</h5> */}
          </Modal.Body>
        </Modal>
      </main>

      <AppFooter links={footerMenu} bgColor="#304053" logoImg={headerLogo} bgImage={footerImg} />
    </>
  );
};

export default SignUp;
