import React, { useEffect, useRef, useState } from 'react';
import styles from './SideActionsCard.module.scss';

interface ISideActionsCard {
  anchor?: boolean;
  anchored?: (anchor: boolean) => void;
  children?: JSX.Element|JSX.Element[];
}

const SideActionsCard: React.FC<ISideActionsCard> = (props) => {
  const cardRef = useRef<HTMLDivElement>(null);
  const [shouldAnchor, setShouldAnchor] = useState<boolean>(false);

  const handleScroll = () => {
    //cardRef.current && console.log(cardRef.current.getBoundingClientRect().top);
    let vDistance = window.scrollY;

    if (cardRef.current) {
      // console.log(cardRef.current.getBoundingClientRect().top, vDistance);
      if (cardRef.current.getBoundingClientRect().top < 155 && vDistance > 460) {
        setShouldAnchor(true);
        props.anchored && props.anchored(true);
      }
      if (vDistance < 460) {
        setShouldAnchor(false);
        props.anchored && props.anchored(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div ref={cardRef} className={`mt-5 bg-light px-4 py-5 radius-m  ${shouldAnchor ? `${styles.anchor} ` : null}`}>
      {props.children}
    </div>
  );
};

export default SideActionsCard;
