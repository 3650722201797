import React, { useEffect, useState } from 'react';
import styles from './UserFavoritesNavigation.module.scss';
import resetFilter from '../../../assets/Reset-Filter.svg';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import DslStore, {
  setFavoriteContests,
  setFavoriteDesigns,
  setFavoriteUsers,
  setUserFavoritesPageNumber,
  setUserUserFavorited,
} from '../../../store/DslStore';
import {
  getContestCategories,
  getUserContestsFavorites,
  getUserContestsFavoritesCopy,
  getUserDesignsFavorites,
  getUserDesignsFavoritesCopy,
  getUserFavoritesPageNumber,
  getUserFavoritesType,
  getUserInspirationsFavorites,
  getUserUserFavorited,
  getUserUserFavoritedCopy,
  getUserUsersFavorites,
  getUserUsersFavoritesCopy,
} from '../../../store/Selectors';
import { useStoreState } from 'pullstate';
import {
  DropDownUserFavoriteSort,
  DropDownUserFavoritesCategory,
  DropDownUserFavoritesType,
} from '../../Forms/DropDown/DropDownUserFavorites';
import { ContestsILike, DesignsILike, UserFavorited, UsersILike } from '../../../global';

type IProjecNavigation = {
  onChangeLayout?: (val: number) => void;
  array?: any[];
};

type PaginationSwitchProps = {
  onChangePage?: (val: number) => void;
};

export const PaginationSwitch: React.FC<PaginationSwitchProps> = ({ onChangePage = () => {} }) => {
  const pageNumber = useStoreState(DslStore, getUserFavoritesPageNumber);
  const userFavoritesDesigns = useStoreState(DslStore, getUserDesignsFavorites);
  let designsPerPage = 20;
  // const [pagernumber, setPagerNumber] = useState(0);
  const pageNumberLimit = Math.ceil(userFavoritesDesigns.length / designsPerPage);
  const [array, setArray] = useState<Array<number>>([1, 2, 3, 4, 5]);

  const arrayCorrectPage = () => {
    let arrayCorrect: Array<number> = [];
    if (pageNumberLimit < 5) {
      for (let i = 1; i <= pageNumberLimit; i++) {
        arrayCorrect[i] = i;
      }
      setArray(arrayCorrect);
    }
  };

  useEffect(() => {
    setUserFavoritesPageNumber(1);
    // setPagerNumber(1);
  }, []);

  useEffect(() => {
    console.log('cambiato numero page');
    if (pageNumber >= 3 && pageNumber <= pageNumberLimit - 2) {
      setArray([pageNumber - 2, pageNumber - 1, pageNumber, pageNumber + 1, pageNumber + 2]);
    }
  });

  useEffect(() => {
    console.log('inspirations: ' + JSON.stringify(userFavoritesDesigns.length));
    console.log('pagine: ' + Math.ceil(userFavoritesDesigns.length / designsPerPage));
    // setPagerNumber(pageNumberLimit);
    arrayCorrectPage();
    // fillContests(pageNumber -1);
  }, [userFavoritesDesigns]);

  const handleChange = (value: number) => {
    console.log(value);
    if (value > 0 && value <= pageNumberLimit) {
      onChangePage(value);
      if (pageNumber >= 3 && pageNumber <= pageNumberLimit - 2) {
        setArray([pageNumber - 2, pageNumber - 1, pageNumber, pageNumber + 1, pageNumber + 2]);
      }
    } else {
      console.log('0 o max++');
    }
  };

  return (
    <div className={`${styles.paginationSwitch} bg-light py-2 px-2 `}>
      <button
        className="btn-icon"
        onClick={() => handleChange(pageNumber - 1)}
        disabled={pageNumberLimit > 1 ? false : true}
      >
        <FontAwesomeIcon icon={faAngleLeft as IconProp} className="btn-icon active " />
      </button>

      {/* <button className={'btn-icon active'} onClick={() => handleChange(pageNumber - 1)}>
        <strong> &lt; </strong>
      </button> */}

      {array.map((index: number) => (
        <button
          key={index}
          className={pageNumber === index ? 'btn-icon active' : 'btn-icon'}
          onClick={() => handleChange(index)}
        >
          <small>{index}</small>
        </button>
      ))}

      {/* <button className={'btn-icon active'} onClick={() => handleChange(pageNumber + 1)}>
        <strong> &gt; </strong>
      </button> */}

      <button className="btn-icon" onClick={() => handleChange(pageNumber + 1)}>
        <FontAwesomeIcon icon={faAngleRight as IconProp} className="btn-icon active " />
      </button>

      {/* 
      <button className={"btn-icon active"} 
      //  onClick={() => handleClick(1)}
      > <b> &lt; </b> </button>
       <button className={pageNumber == 1 ? "btn-icon active" : "btn-icon"} 
      //  onClick={() => handleClick(1)}
      >
        1
      </button>
      <button className={pageNumber == 2 ? "btn-icon active" : "btn-icon"} 
      // onClick={() => handleClick(2)}
      >
        2
      </button>
      <button className={pageNumber == 3 ? "btn-icon active" : "btn-icon"} 
      // onClick={() => handleClick(3)}
      >
        3
      </button>
      <button className={pageNumber == 4 ? "btn-icon active" : "btn-icon"} 
      // onClick={() => handleClick(4)}
      >
        4
      </button>
      <button className={pageNumber == 5 ? "btn-icon active" : "btn-icon"} 
      // onClick={() => handleClick(5)}
      >
        5
      </button>  */}

      {/* <button className="btn-icon">
         <FontAwesomeIcon icon={faAngleRight as IconProp} className="text" />
       </button> */}

      {/* <Pagination count={numberOfPages} page={pageNumber} color='primary'  hideNextButton={numberOfPages > 1 ? false : true} hidePrevButton={numberOfPages > 1 ? false : true} size="small" onChange={handleChange} /> */}
    </div>
  );
};

export const PaginationSwitchFavoriteDesigns: React.FC<PaginationSwitchProps> = ({ onChangePage = () => {} }) => {
  const pageNumber = useStoreState(DslStore, getUserFavoritesPageNumber);
  const userFavoritesDesigns = useStoreState(DslStore, getUserDesignsFavorites);
  let designsPerPage = 20;
  // const [pagernumber, setPagerNumber] = useState(0);
  const pageNumberLimit = Math.ceil(userFavoritesDesigns.length / designsPerPage);
  const [array, setArray] = useState<Array<number>>([1, 2, 3, 4, 5]);

  const arrayCorrectPage = () => {
    let arrayCorrect: Array<number> = [];
    if (pageNumberLimit < 5) {
      for (let i = 1; i <= pageNumberLimit; i++) {
        arrayCorrect[i] = i;
        // console.log(arrayCorrect);
      }
      setArray(arrayCorrect);
    }
  };

  useEffect(() => {
    setUserFavoritesPageNumber(1);
  }, []);

  useEffect(() => {
    console.log('cambiato numero page');
    if (pageNumber >= 2 && pageNumber <= pageNumberLimit - 2) {
      setArray([pageNumber - 2, pageNumber - 1, pageNumber, pageNumber + 1, pageNumber + 2]);
    }
  }, [pageNumber]);

  useEffect(() => {
    console.log('inspirations: ' + JSON.stringify(userFavoritesDesigns.length));
    console.log('pagine: ' + Math.ceil(userFavoritesDesigns.length / designsPerPage));
    // setPagerNumber(pageNumberLimit);
    arrayCorrectPage();
    // fillContests(pageNumber -1);
  }, [userFavoritesDesigns]);

  const handleChange = (value: number) => {
    console.log(value);
    if (value > 0 && value <= pageNumberLimit) {
      onChangePage(value);
      if (pageNumber >= 3 && pageNumber <= pageNumberLimit - 2) {
        setArray([pageNumber - 2, pageNumber - 1, pageNumber, pageNumber + 1, pageNumber + 2]);
      }
    } else {
      console.log('0 o max++');
    }
  };

  return (
    <div className={`${styles.paginationSwitch} bg-light py-2 px-2 `}>
      <button
        className="btn-icon"
        onClick={() => handleChange(pageNumber - 1)}
        disabled={pageNumberLimit > 1 ? false : true}
      >
        <FontAwesomeIcon icon={faAngleLeft as IconProp} className="btn-icon active " />
      </button>

      {array.map((index: number) => (
        <button
          key={index}
          className={pageNumber === index ? 'btn-icon active' : 'btn-icon'}
          onClick={() => handleChange(index)}
        >
          <small>{index}</small>
        </button>
      ))}

      <button className="btn-icon" onClick={() => handleChange(pageNumber + 1)}>
        <FontAwesomeIcon icon={faAngleRight as IconProp} className="btn-icon active " />
      </button>
    </div>
  );
};

export const PaginationSwitchFavoriteUsers: React.FC<PaginationSwitchProps> = ({ onChangePage = () => {} }) => {
  const pageNumber = useStoreState(DslStore, getUserFavoritesPageNumber);
  const userFavoritesUsers = useStoreState(DslStore, getUserUsersFavorites);
  let designsPerPage = 20;
  // const [pagernumber, setPagerNumber] = useState(0);
  const pageNumberLimit = Math.ceil(userFavoritesUsers.length / designsPerPage);
  const [array, setArray] = useState<Array<number>>([1, 2, 3, 4, 5]);

  const arrayCorrectPage = () => {
    let arrayCorrect: Array<number> = [];
    if (pageNumberLimit < 5) {
      for (let i = 1; i <= pageNumberLimit; i++) {
        arrayCorrect[i] = i;
        // console.log(arrayCorrect);
      }
      setArray(arrayCorrect);
    }
  };

  useEffect(() => {
    setUserFavoritesPageNumber(1);
  }, []);

  useEffect(() => {
    console.log('cambiato numero page');
    if (pageNumber >= 2 && pageNumber <= pageNumberLimit - 2) {
      setArray([pageNumber - 2, pageNumber - 1, pageNumber, pageNumber + 1, pageNumber + 2]);
    }
  }, [pageNumber]);

  useEffect(() => {
    console.log('inspirations: ' + JSON.stringify(userFavoritesUsers.length));
    console.log('pagine: ' + Math.ceil(userFavoritesUsers.length / designsPerPage));
    // setPagerNumber(pageNumberLimit);
    arrayCorrectPage();
    // fillContests(pageNumber -1);
  }, [userFavoritesUsers]);

  const handleChange = (value: number) => {
    console.log(value);
    if (value > 0 && value <= pageNumberLimit) {
      onChangePage(value);
      if (pageNumber >= 3 && pageNumber <= pageNumberLimit - 2) {
        setArray([pageNumber - 2, pageNumber - 1, pageNumber, pageNumber + 1, pageNumber + 2]);
      }
    } else {
      console.log('0 o max++');
    }
  };

  return (
    <div className={`${styles.paginationSwitch} bg-light py-2 px-2 `}>
      <button
        className="btn-icon"
        onClick={() => handleChange(pageNumber - 1)}
        disabled={pageNumberLimit > 1 ? false : true}
      >
        <FontAwesomeIcon icon={faAngleLeft as IconProp} className="btn-icon active " />
      </button>

      {array.map((index: number) => (
        <button
          key={index}
          className={pageNumber === index ? 'btn-icon active' : 'btn-icon'}
          onClick={() => handleChange(index)}
        >
          <small>{index}</small>
        </button>
      ))}

      <button className="btn-icon" onClick={() => handleChange(pageNumber + 1)}>
        <FontAwesomeIcon icon={faAngleRight as IconProp} className="btn-icon active " />
      </button>
    </div>
  );
};

export const PaginationSwitchFavoriteInspirations: React.FC<PaginationSwitchProps> = ({ onChangePage = () => {} }) => {
  const pageNumber = useStoreState(DslStore, getUserFavoritesPageNumber);
  const userFavoritesInspirations = useStoreState(DslStore, getUserInspirationsFavorites);
  let inspirationsPerPage = 20;
  // const [pagernumber, setPagerNumber] = useState(0);
  const pageNumberLimit = Math.ceil(userFavoritesInspirations.length / inspirationsPerPage);
  const [array, setArray] = useState<Array<number>>([1, 2, 3, 4, 5]);

  const arrayCorrectPage = () => {
    let arrayCorrect: Array<number> = [];
    if (pageNumberLimit < 5) {
      for (let i = 1; i <= pageNumberLimit; i++) {
        arrayCorrect[i] = i;
        // console.log(arrayCorrect);
      }
      setArray(arrayCorrect);
    }
  };

  useEffect(() => {
    setUserFavoritesPageNumber(1);
  }, []);

  useEffect(() => {
    console.log('cambiato numero page');
    if (pageNumber >= 2 && pageNumber <= pageNumberLimit - 2) {
      setArray([pageNumber - 2, pageNumber - 1, pageNumber, pageNumber + 1, pageNumber + 2]);
    }
  }, [pageNumber]);

  useEffect(() => {
    console.log('inspirations: ' + JSON.stringify(userFavoritesInspirations.length));
    console.log('pagine: ' + Math.ceil(userFavoritesInspirations.length / inspirationsPerPage));
    // setPagerNumber(pageNumberLimit);
    arrayCorrectPage();
    // fillContests(pageNumber -1);
  }, [userFavoritesInspirations]);

  const handleChange = (value: number) => {
    console.log(value);
    if (value > 0 && value <= pageNumberLimit) {
      onChangePage(value);
      if (pageNumber >= 3 && pageNumber <= pageNumberLimit - 2) {
        setArray([pageNumber - 2, pageNumber - 1, pageNumber, pageNumber + 1, pageNumber + 2]);
      }
    } else {
      console.log('0 o max++');
    }
  };

  return (
    <div className={`${styles.paginationSwitch} bg-light py-2 px-2 `}>
      <button
        className="btn-icon"
        onClick={() => handleChange(pageNumber - 1)}
        disabled={pageNumberLimit > 1 ? false : true}
      >
        <FontAwesomeIcon icon={faAngleLeft as IconProp} className="btn-icon active " />
      </button>

      {array.map((index: number) => (
        <button
          key={index}
          className={pageNumber === index ? 'btn-icon active' : 'btn-icon'}
          onClick={() => handleChange(index)}
        >
          <small>{index}</small>
        </button>
      ))}

      <button className="btn-icon" onClick={() => handleChange(pageNumber + 1)}>
        <FontAwesomeIcon icon={faAngleRight as IconProp} className="btn-icon active " />
      </button>
    </div>
  );
};

export const PaginationSwitchFavoriteContests: React.FC<PaginationSwitchProps> = ({ onChangePage = () => {} }) => {
  const pageNumber = useStoreState(DslStore, getUserFavoritesPageNumber);
  const userFavoritesContests = useStoreState(DslStore, getUserContestsFavorites);
  let designsPerPage = 20;
  // const [pagernumber, setPagerNumber] = useState(0);
  const pageNumberLimit = Math.ceil(userFavoritesContests.length / designsPerPage);
  const [array, setArray] = useState<Array<number>>([1, 2, 3, 4, 5]);

  const arrayCorrectPage = () => {
    let arrayCorrect: Array<number> = [];
    if (pageNumberLimit < 5) {
      for (let i = 1; i <= pageNumberLimit; i++) {
        arrayCorrect[i] = i;
        // console.log(arrayCorrect);
      }
      setArray(arrayCorrect);
    }
  };

  useEffect(() => {
    setUserFavoritesPageNumber(1);
  }, []);

  useEffect(() => {
    console.log('cambiato numero page');
    if (pageNumber >= 3 && pageNumber <= pageNumberLimit - 2) {
      setArray([pageNumber - 2, pageNumber - 1, pageNumber, pageNumber + 1, pageNumber + 2]);
    }
  }, [pageNumber]);

  useEffect(() => {
    // console.log('inspirations: ' + JSON.stringify(userFavoritesDesigns.length));
    // console.log('pagine: ' + Math.ceil(userFavoritesDesigns.length / designsPerPage));
    // setPagerNumber(pageNumberLimit);
    arrayCorrectPage();
    // fillContests(pageNumber -1);
  }, []);

  const handleChange = (value: number) => {
    console.log(value);
    if (value > 0 && value <= pageNumberLimit) {
      onChangePage(value);
      if (pageNumber >= 3 && pageNumber <= pageNumberLimit - 2) {
        setArray([pageNumber - 2, pageNumber - 1, pageNumber, pageNumber + 1, pageNumber + 2]);
      }
    } else {
      console.log('0 o max++');
    }
  };

  return (
    <div className={`${styles.paginationSwitch} bg-light py-2 px-2 `}>
      <button
        className="btn-icon"
        onClick={() => handleChange(pageNumber - 1)}
        disabled={pageNumberLimit > 1 ? false : true}
      >
        <FontAwesomeIcon icon={faAngleLeft as IconProp} className="btn-icon active " />
      </button>

      {array.map((index: number) => (
        <button
          key={index}
          className={pageNumber === index ? 'btn-icon active' : 'btn-icon'}
          onClick={() => handleChange(index)}
        >
          <small>{index}</small>
        </button>
      ))}

      <button className="btn-icon" onClick={() => handleChange(pageNumber + 1)}>
        <FontAwesomeIcon icon={faAngleRight as IconProp} className="btn-icon active " />
      </button>
    </div>
  );
};

let filterType = [
  {
    label: 'Design',
    sort: 'Design',
    value: '1',
  },
  {
    label: 'Contests',
    sort: 'Contest',
    value: '2',
  },
  {
    label: 'Profile: favorite',
    sort: 'favorite',
    value: '3',
  },
  {
    label: 'Profile: favorited',
    sort: 'favorited',
    value: '4',
  },
];

let filterSortUsers = [
  {
    label: 'Name',
    sort: 'name',
    value: '1',
  },
  {
    label: 'Design',
    sort: 'design',
    value: '2',
  },
  {
    label: 'Date',
    sort: 'date',
    value: '3',
  },
  {
    label: 'Contest',
    sort: 'contest',
    value: '4',
  },
  {
    label: 'Award',
    sort: 'award',
    value: '5',
  },
  {
    label: 'Reputation',
    sort: 'reputation',
    value: '6',
  },
];

let filterSortContestDesign = [
  {
    label: 'Title',
    sort: 'title',
    value: '1',
  },
  {
    label: 'Name',
    sort: 'name',
    value: '2',
  },
  {
    label: 'Date',
    sort: 'date',
    value: '3',
  },
  {
    label: 'Votes',
    sort: 'votes',
    value: '4',
  },
];

let filterProfile = [
  {
    label: 'See all',
    sort: 'all',
    value: '1',
  },
  {
    label: 'See all Users',
    sort: 'designers',
    value: '2',
  },

  {
    label: 'See all Clients',
    sort: 'clients',
    value: '3',
  },
  {
    label: '',
    sort: '',
    value: '4',
  },
  {
    label: 'User',
    sort: '',
    value: '5',
  },
  {
    label: 'Student',
    sort: 'student',
    value: '5',
  },
  {
    label: 'Designer',
    sort: 'designer',
    value: '5',
  },
  {
    label: 'Senior Designer',
    sort: 'senior designer',
    value: '5',
  },
  {
    label: 'Architect',
    sort: 'architect',
    value: '5',
  },
  {
    label: 'Engineer',
    sort: 'engineer',
    value: '5',
  },
  {
    label: 'Inventor',
    sort: 'inventor',
    value: '5',
  },
  {
    label: 'Maker / Artisan',
    sort: 'maker / artisan',
    value: '5',
  },
  {
    label: 'Graphic Designer',
    sort: 'graphic designer',
    value: '5',
  },
  {
    label: 'Copywriter',
    sort: 'copywriter',
    value: '5',
  },
  {
    label: 'Artist',
    sort: 'artist',
    value: '5',
  },
  {
    label: 'Design Lover',
    sort: 'design lover',
    value: '5',
  },
  {
    label: 'Other',
    sort: 'other',
    value: '5',
  },
  {
    label: '',
    sort: '',
    value: '5',
  },
  {
    label: 'Client',
    sort: '',
    value: '5',
  },
  {
    label: 'Company',
    sort: 'company',
    value: '5',
  },
  {
    label: 'Individual',
    sort: 'individual',
    value: '5',
  },
];

interface IDropDownItem {
  label: string;
  sort: string;
  value: string;
}

const UserFavoriteNavigation: React.FC<IProjecNavigation> = (props) => {
  const userFavoritesdes = props.array ? props.array : [];
  const userFavoritesType = useStoreState(DslStore, getUserFavoritesType);
  const categories = useStoreState(DslStore, getContestCategories);
  const userFavoritesUsers = useStoreState(DslStore, getUserUsersFavorites);
  const userFavoritesContests = useStoreState(DslStore, getUserContestsFavorites);
  const userFavoritesDesigns = useStoreState(DslStore, getUserDesignsFavorites);
  const userFavoritesDesignsCopy = useStoreState(DslStore, getUserDesignsFavoritesCopy);
  const userFavoritesContestsCopy = useStoreState(DslStore, getUserContestsFavoritesCopy);
  const userFavoritesUsersCopy = useStoreState(DslStore, getUserUsersFavoritesCopy);
  const userUserFavorited = useStoreState(DslStore, getUserUserFavorited);
  const userUserFavoritedCopy = useStoreState(DslStore, getUserUserFavoritedCopy);
  const [descs, setDescs] = useState(false);
  const [typologySort, setTypologySort] = useState('');
  const [categorySort, setCategorySort] = useState('');
  const [sortBySort, setSortBySort] = useState('');
  const [categoriesFilter, setCategoriesFilter] = useState<IDropDownItem[]>([]);
  let designsPerPage = 20;
  const [reload, setReload] = useState(false);
  const pageNumberLimit = Math.ceil(userFavoritesdes.length / designsPerPage);
  const [activeSearch, setActiveSearch] = useState(false);

  // useEffect(() => {
  //   console.log(categorySort);
  // }, [categorySort]);

  useEffect(() => {
    let newItems: IDropDownItem[] = [];
    if (categories) {
      console.log(categories);
      categories.map((item, index: number) => {
        let name = item.name === 'Link to Other' ? 'Partnership' : item.name;

        if (!newItems.includes({ label: `${name}`, sort: `${item.name}`, value: `${index}` })) {
          newItems.push({ label: `${name}`, sort: `${item.name}`, value: `${index}` });
          // setTypologyFilter(newItems);
          // console.log(newItems);
        }
      });
      setCategoriesFilter(newItems);
    }
  }, [categories]);

  const sortFavorites = () => {
    console.log(userFavoritesType);
    if (userFavoritesType === 0) {
      let sorted: DesignsILike[] = [...userFavoritesDesignsCopy];
      if (categorySort) {
        let designsSortedCategories: DesignsILike[] = [];
        sorted.map((item) => {
          item.contest.category && item.contest.category.name === categorySort && designsSortedCategories.push(item);
        });
        sorted = designsSortedCategories;
      }
      if (sortBySort) {
        if (sortBySort === 'name') {
          sorted.sort((a, b) =>
            descs
              ? b.name.toLowerCase() > a.name.toLowerCase()
                ? 1
                : a.name.toLowerCase() > b.name.toLowerCase()
                ? -1
                : 0
              : b.name.toLowerCase() > a.name.toLowerCase()
              ? -1
              : a.name.toLowerCase() > b.name.toLowerCase()
              ? 1
              : 0
          );
        } else if (sortBySort === 'date') {
          sorted.sort((a, b) => {
            if (descs) {
              return b.uploadDate.localeCompare(a.uploadDate);
            } else {
              return a.uploadDate.localeCompare(b.uploadDate);
            }
          });
        } else if (sortBySort === 'title') {
          sorted.sort((a, b) =>
            descs
              ? b.contest.name.toLowerCase() > a.contest.name.toLowerCase()
                ? 1
                : a.contest.name.toLowerCase() > b.contest.name.toLowerCase()
                ? -1
                : 0
              : b.contest.name.toLowerCase() > a.contest.name.toLowerCase()
              ? -1
              : a.contest.name.toLowerCase() > b.contest.name.toLowerCase()
              ? 1
              : 0
          );
        } else if (sortBySort === 'votes') {
          sorted.sort((a, b) =>
            descs
              ? b.votesCount > a.votesCount
                ? 1
                : a.votesCount > b.votesCount
                ? -1
                : 0
              : b.votesCount > a.votesCount
              ? -1
              : a.votesCount > b.votesCount
              ? 1
              : 0
          );
        }
      }
      setFavoriteDesigns(sorted);
    } else if (userFavoritesType === 1) {
      let sorted: ContestsILike[] = [...userFavoritesContestsCopy];
      if (categorySort) {
        console.log(categorySort);
        let contestsSortedCategories: ContestsILike[] = [];
        sorted.map((item) => {
          item.category && item.category.name === categorySort && contestsSortedCategories.push(item);
        });
        sorted = contestsSortedCategories;
      }
      if (sortBySort) {
        if (sortBySort === 'name') {
          sorted.sort((a, b) =>
            descs
              ? b.client.username.toLowerCase() > a.client.username.toLowerCase()
                ? 1
                : a.client.username.toLowerCase() > b.client.username.toLowerCase()
                ? -1
                : 0
              : b.client.username.toLowerCase() > a.client.username.toLowerCase()
              ? -1
              : a.client.username.toLowerCase() > b.client.username.toLowerCase()
              ? 1
              : 0
          );
        } else if (sortBySort === 'date') {
          sorted.sort((a, b) => {
            console.log(a.creationDate, b.creationDate);
            if (descs) {
              return b.creationDate.localeCompare(a.creationDate);
            } else {
              return a.creationDate.localeCompare(b.creationDate);
            }
          });
        } else if (sortBySort === 'title') {
          sorted.sort((a, b) =>
            descs
              ? b.name.toLowerCase() > a.name.toLowerCase()
                ? 1
                : a.name.toLowerCase() > b.name.toLowerCase()
                ? -1
                : 0
              : b.name.toLowerCase() > a.name.toLowerCase()
              ? -1
              : a.name.toLowerCase() > b.name.toLowerCase()
              ? 1
              : 0
          );
        } else if (sortBySort === 'votes') {
          sorted.sort((a, b) =>
            descs
              ? b.sumVotesPublicDesigns > a.sumVotesPublicDesigns
                ? 1
                : a.sumVotesPublicDesigns > b.sumVotesPublicDesigns
                ? -1
                : 0
              : b.sumVotesPublicDesigns > a.sumVotesPublicDesigns
              ? -1
              : a.sumVotesPublicDesigns > b.sumVotesPublicDesigns
              ? 1
              : 0
          );
        }
      }
      setFavoriteContests(sorted);
    } else if (userFavoritesType === 2) {
      let sorted: UsersILike[] = [...userFavoritesUsersCopy];
      // console.log(sorted);
      if (categorySort) {
        let usersCategorySorted: UsersILike[] = [];
        if (categorySort === 'all') {
          sorted.map((item) => {
            usersCategorySorted.push(item);
          });
        } else if (categorySort === 'designers') {
          sorted.map((item) => {
            item.type === 'Designer' && usersCategorySorted.push(item);
          });
        } else if (categorySort === 'clients') {
          sorted.map((item) => {
            item.type === 'Client' && usersCategorySorted.push(item);
          });
        } else if (categorySort === 'individual') {
          sorted.map((item) => {
            !item.isBrand && usersCategorySorted.push(item);
          });
        } else if (categorySort === 'company') {
          sorted.map((item) => {
            item.isBrand && usersCategorySorted.push(item);
          });
        } else {
          console.log(categorySort);
          sorted.map((item) => {
            item.job && item.job.name === categorySort && usersCategorySorted.push(item);
          });
        }
        console.log(usersCategorySorted);
        sorted = usersCategorySorted;
      }
      if (sortBySort) {
        console.log(sortBySort);
        if (sortBySort === 'name') {
          sorted.sort((a, b) =>
            descs
              ? b.username.toLowerCase() > a.username.toLowerCase()
                ? 1
                : a.username.toLowerCase() > b.username.toLowerCase()
                ? -1
                : 0
              : b.username.toLowerCase() > a.username.toLowerCase()
              ? -1
              : a.username.toLowerCase() > b.username.toLowerCase()
              ? 1
              : 0
          );
        } else if (sortBySort === 'date') {
          sorted.sort((a, b) => {
            if (descs) {
              return b.registrationDate.localeCompare(a.registrationDate);
            } else {
              return a.registrationDate.localeCompare(b.registrationDate);
            }
          });
        } else if (sortBySort === 'design') {
          sorted.sort((a, b) =>
            descs
              ? b.badgeDesignsOut > a.badgeDesignsOut
                ? 1
                : a.badgeDesignsOut > b.badgeDesignsOut
                ? -1
                : 0
              : b.badgeDesignsOut > a.badgeDesignsOut
              ? -1
              : a.badgeDesignsOut > b.badgeDesignsOut
              ? 1
              : 0
          );
        } else if (sortBySort === 'contest') {
          sorted.sort((a, b) =>
            descs
              ? b.contestsCount > a.contestsCount
                ? 1
                : a.contestsCount > b.contestsCount
                ? -1
                : 0
              : b.contestsCount > a.contestsCount
              ? -1
              : a.contestsCount > b.contestsCount
              ? 1
              : 0
          );
        } else if (sortBySort === 'award') {
          sorted.sort((a, b) =>
            descs
              ? b.badgeAmountIn > a.badgeAmountIn
                ? 1
                : a.badgeAmountIn > b.badgeAmountIn
                ? -1
                : 0
              : b.badgeAmountIn > a.badgeAmountIn
              ? -1
              : a.badgeAmountIn > b.badgeAmountIn
              ? 1
              : 0
          );
        } else if (sortBySort === 'reputation') {
          sorted.sort((a, b) =>
            descs
              ? b.reputationCount > a.reputationCount
                ? 1
                : a.reputationCount > b.reputationCount
                ? -1
                : 0
              : b.reputationCount > a.reputationCount
              ? -1
              : a.reputationCount > b.reputationCount
              ? 1
              : 0
          );
        }
      }
      setFavoriteUsers(sorted);
    } else if (userFavoritesType === 3) {
      let sorted: UserFavorited[] = [...userUserFavoritedCopy];

      if (categorySort) {
        let usersCategorySorted: UserFavorited[] = [];
        if (categorySort === 'all') {
          sorted.map((item) => {
            usersCategorySorted.push(item);
          });
        } else if (categorySort === 'designers') {
          sorted.map((item) => {
            item.type === 'Designer' && usersCategorySorted.push(item);
          });
        } else if (categorySort === 'clients') {
          sorted.map((item) => {
            item.type === 'Client' && usersCategorySorted.push(item);
          });
        } else if (categorySort === 'individual') {
          sorted.map((item) => {
            !item.isBrand && usersCategorySorted.push(item);
          });
        } else if (categorySort === 'company') {
          sorted.map((item) => {
            item.isBrand && usersCategorySorted.push(item);
          });
        } else {
          console.log(categorySort);
          sorted.map((item) => {
            item.jobName && item.jobName === categorySort && usersCategorySorted.push(item);
          });
        }
        console.log(usersCategorySorted);
        sorted = usersCategorySorted;
      }
      if (sortBySort) {
        console.log(sortBySort);
        if (sortBySort === 'name') {
          sorted.sort((a, b) =>
            descs
              ? b.username.toLowerCase() > a.username.toLowerCase()
                ? 1
                : a.username.toLowerCase() > b.username.toLowerCase()
                ? -1
                : 0
              : b.username.toLowerCase() > a.username.toLowerCase()
              ? -1
              : a.username.toLowerCase() > b.username.toLowerCase()
              ? 1
              : 0
          );
        } else if (sortBySort === 'date') {
          sorted.sort((a, b) => {
            if (descs) {
              return b.registrationDate.localeCompare(a.registrationDate);
            } else {
              return a.registrationDate.localeCompare(b.registrationDate);
            }
          });
        } else if (sortBySort === 'design') {
          sorted.sort((a, b) =>
            descs
              ? b.badgeDesignsOut > a.badgeDesignsOut
                ? 1
                : a.badgeDesignsOut > b.badgeDesignsOut
                ? -1
                : 0
              : b.badgeDesignsOut > a.badgeDesignsOut
              ? -1
              : a.badgeDesignsOut > b.badgeDesignsOut
              ? 1
              : 0
          );
        } else if (sortBySort === 'contest') {
          sorted.sort((a, b) =>
            descs
              ? b.contestsCount > a.contestsCount
                ? 1
                : a.contestsCount > b.contestsCount
                ? -1
                : 0
              : b.contestsCount > a.contestsCount
              ? -1
              : a.contestsCount > b.contestsCount
              ? 1
              : 0
          );
        } else if (sortBySort === 'award') {
          sorted.sort((a, b) =>
            descs
              ? b.badgeAmountIn > a.badgeAmountIn
                ? 1
                : a.badgeAmountIn > b.badgeAmountIn
                ? -1
                : 0
              : b.badgeAmountIn > a.badgeAmountIn
              ? -1
              : a.badgeAmountIn > b.badgeAmountIn
              ? 1
              : 0
          );
        } else if (sortBySort === 'reputation') {
          sorted.sort((a, b) =>
            descs
              ? b.reputationCount > a.reputationCount
                ? 1
                : a.reputationCount > b.reputationCount
                ? -1
                : 0
              : b.reputationCount > a.reputationCount
              ? -1
              : a.reputationCount > b.reputationCount
              ? 1
              : 0
          );
        }
        setUserUserFavorited(sorted);
      }
    }
  };

  useEffect(() => {
    setActiveSearch(false);
    setFavoriteDesigns(userFavoritesDesignsCopy);
    setFavoriteUsers(userFavoritesUsersCopy);
    setFavoriteContests(userFavoritesContestsCopy);
  }, [userFavoritesType]);

  useEffect(() => {
    sortFavorites();
  }, [typologySort, sortBySort, categorySort, descs]);

  return (
    <div className="row py-4 mt-4  ">
      <div className="col-12  col-lg-10   d-flex flex-column px-2 flex-lg-row justify-content-between mb-4 mb-md-0">
        <div className="me-0 me-lg-5 mb-lg-0 mb-sm-3 w-100">
          <DropDownUserFavoritesType
            label="Typology"
            reset={reload}
            options={filterType}
            onSelect={(option) => (option.sort && setTypologySort(option.sort), setActiveSearch(false))}
            thin
          />
        </div>
        <div className="me-0 me-lg-5 mb-lg-0 mb-sm-3 w-100">
          <DropDownUserFavoritesCategory
            reset={reload}
            label={`Category`}
            options={categoriesFilter}
            options2={filterProfile}
            onSelect={(options) => (options.sort && setCategorySort(options.sort), setActiveSearch(false))}
            thin
          />
        </div>
        <div className="me-0 me-lg-5 mb-lg-0 mb-sm-3 w-100">
          <DropDownUserFavoriteSort
            reset={reload}
            label="Sort by"
            options={filterSortUsers}
            options2={filterSortContestDesign}
            descs={(descs) => setDescs(descs)}
            onSelect={(option) => (option.sort && setSortBySort(option.sort), setActiveSearch(false))}
            thin
          />
        </div>
        <div className=" d-flex flex-row align-items-center justify-content-center h-100 w-100">
          <div
            // className="btn-blue rounded"
            className=" d-flex flex-row align-items-center justify-content-center btn-reset-blue   "
            onClick={() => (
              setReload(!reload),
              setFavoriteDesigns(userFavoritesDesignsCopy),
              setFavoriteUsers(userFavoritesUsersCopy),
              setFavoriteContests(userFavoritesContestsCopy),
              setSortBySort(''),
              setCategorySort(''),
              setTypologySort('')
            )}
            style={{ width: '3rem', borderRadius: '8px' }}
          >
            <img src={resetFilter} style={{ height: '25px' }} />
          </div>
        </div>
      </div>
      <div className={`col-12  col-lg-2   d-flex  px-1 justify-content-sm-start justify-content-md-end `}>
        {userFavoritesType === 0 &&
          (userFavoritesDesigns.length ? (
            <PaginationSwitchFavoriteDesigns onChangePage={setUserFavoritesPageNumber} />
          ) : (
            ''
          ))}
        {userFavoritesType === 2 &&
          (userFavoritesUsers.length ? (
            <PaginationSwitchFavoriteUsers onChangePage={setUserFavoritesPageNumber} />
          ) : (
            ''
          ))}
        {userFavoritesType === 1 &&
          (userFavoritesContests.length ? (
            <PaginationSwitchFavoriteContests onChangePage={setUserFavoritesPageNumber} />
          ) : (
            ''
          ))}
        {userFavoritesType === 3 &&
          (pageNumberLimit > 0 ? <PaginationSwitchFavoriteUsers onChangePage={setUserFavoritesPageNumber} /> : '')}
        {/* {pageNumberLimit > 0 ? <PaginationSwitch onChangePage={setUserFavoritesPageNumber} /> : ''} */}
        <div>{/* <DesignerInspirationsLayoutSwitch onChangeLayout={props.onChangeLayout} /> */}</div>
      </div>
    </div>
  );
};

export default UserFavoriteNavigation;
