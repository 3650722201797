import React from 'react';
import { ICardInfo } from '../../Community/CardInfo/CardInfo';
import SquaredImage from '../../SquaredImage/SquaredImage';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { faIndustry, faPen } from '@fortawesome/free-solid-svg-icons';

import styles from './ContestPortfolioCard.module.scss';
import ActionButton, { MultiActionButtonsWrapper } from '../../ActionButton/ActionButton';
import Spinner from '../../Spinner/spinner';
import { User, UserDesigns } from '../../../global';
import DslStore, { setCommunityUser, setCurrentContest } from '../../../store/DslStore';
import { useNavigate } from 'react-router-dom';
import axiosHelper from '../../../helpers/axiosHelper';
import { useStoreState } from 'pullstate';
import { getCurrentContest } from '../../../store/Selectors';
import { fullPathLogo } from '../../../helpers/utils';
import { ROUTEPATHS } from '../../../routes/routePaths';

interface IContestPortfolioCard extends ICardInfo {
  design?: UserDesigns;
  global: boolean;
}

const ContestPortfolioCard: React.FC<IContestPortfolioCard> = (props) => {
  const redirect = useNavigate();
  const contest = useStoreState(DslStore, getCurrentContest);

  // const goToDetail = (contest: Contest) =>{
  //   setCurrentContest(contest);
  //   ;
  // }

  const goToDetail = async (userid?: number) => {
    console.log(userid);
    if (userid) {
      let result: User = await axiosHelper.retrieveUserById(userid);
      console.log(result);
      if (contest?.contestType !== 'Link to other') {
        setCommunityUser(result);

        redirect(`/${ROUTEPATHS.COMMUNITY}/${result.nameForURL}/Profile`);
      } else {
        // alert('link to other');
      }
    }
  };

  const getContestUser = async (contestId?: number) => {
    if (contestId) {
      if (contest?.contestCategory !== 'Link to other') {
        const result = await axiosHelper.retrieveCompleteContest(contestId);
        console.log(result.data.client);
        console.log(contest);
        console.log(result);
        setCurrentContest(result.data);

        redirect(`/${ROUTEPATHS.CONTEST}/${result.data.nameForUrl}/${ROUTEPATHS.CONTEST_BRIEF}`);
      }
    }
  };

  return (
    <div className={`${styles.card} col-12  col-md-6 col-lg-3 position-relative`}>
      <div className={`${styles.cardInfoWrapper} d-flex flex-column  bg-light`}>
        <div
          className={`${
            props.design?.contest.logoAttachment?.s3Path ? styles.cardInfoImage : styles.cardImagePlaceholder
          } bg-light ${props.circle && 'px-5 pt-4'} position-relative`}
          onClick={() => (getContestUser(props.design?.contest.id), console.log('click'))}
        >
          {contest?.image ? (
            <SquaredImage
              image={fullPathLogo(props.design?.contest.logoAttachment?.s3Path)}
              altText={props.altText}
              circle={props.circle}
            />
          ) : (
            <div className={`${styles.squaredImageWrapper}`}>
              <div className={`${styles.imageInner}`}>
                <Spinner />
              </div>
            </div>
            // <FontAwesomeIcon icon={faPencil as IconProp} className="color-light" />
          )}
          <ActionButton
            label="Save as favorite"
            icon="addFavorite"
            position="top-right"
            // action={() => alert('Added as favorite')}
          />
          <MultiActionButtonsWrapper position="bottom-left">
            {props.design?.contest.isPrivate && <ActionButton label="Private gallery" icon="private" />}
            {props.global && <ActionButton label="Global contest" icon="global" />}
          </MultiActionButtonsWrapper>
        </div>

        <div className={`${styles.cardInfoContent} container bg-light`}>
          <div
            className="row h-25 mt-2 mb-3 mx-1"
            onClick={() => (getContestUser(props.design?.id), console.log('click'))}
          >
            <div className="col text-center align-self-center  ">
              <h3 className="fw-bold color-blue d-none d-sm-block ">{props.design?.name}</h3>
              <h1 className="fw-bold color-blue d-md-none d-md-block">{props.design?.name}</h1>
            </div>
          </div>

          <div className=" col align-self-center text-center pt-4 mb-3 ">qua era props.design</div>

          <div className={`${styles.infoContainer} position-static`}>
            <div className="justify-content-left mb-4 ">
              <FontAwesomeIcon icon={faIndustry as IconProp} className=" text-left color-blue me-3" />
              <span
                className="justify-content-left mb-4 color-blue"
                onClick={() => goToDetail(props.design?.contest.id)}
              >
                {/* {finalSlicedString(props.design?.contest.username)} */}
              </span>
            </div>
            <div className=" text text-start justify-content-left mb-4">
              <FontAwesomeIcon icon={faCalendar as IconProp} className=" text color-blue me-3" />
              <span className="mx-1 justify-content-left mb-4">
                {/* Ends: {startDateString(props.design?.contest.endUploadDate)} */}
              </span>
            </div>
            <div className=" text text-start  justify-content-left mb-4">
              <FontAwesomeIcon icon={faPen as IconProp} className=" text color-blue me-3" />

              {/* <span className="mx-1 justify-content-left  color-blue">{props.design?.contest.publicDesigns.length} entries</span> */}
            </div>
            {/* {props.design.awardPos != null && props.design.awardPos != 0 ? (
              <div className="d-flex justify-content-left mb-3">
                <div className="d-flex align-items-center ">
                  <FontAwesomeIcon icon={faTrophy as IconProp} className="text color-blue me-3" />
                  <FontAwesomeIcon icon={faEuroSign as IconProp} className="text color-blue me-1" />
                  <span className="color-blue">{props.design.awardPos} award</span>
                </div>
              </div>
            ) : null} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContestPortfolioCard;
