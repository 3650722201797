import { CommunityStrapiComponent } from '../global';
import Custom4Users from './StrapiCommunity/Custom4Users';
import ImageSlider from './StrapiCommunity/ImageSlider';
import Members from './StrapiCommunity/Members';

interface Props {
  item: CommunityStrapiComponent;
}

const StrapiCommunityRenderer = (props: Props) => {
  switch (props.item.__component) {
    case 'sections.image-slider':
      return <ImageSlider images={props.item.images.data} />;
    case 'sections.4-custom-users':
      return (
        <Custom4Users
          title={props.item.title}
          username1={props.item.username1}
          username2={props.item.username2}
          username3={props.item.username3}
          username4={props.item.username4}
        />
      );
    case 'community.fetched-users':
          return <Members title={props.item.title} count={props.item.count} which={props.item.which} />;
    default:
      console.log(props.item);
      return null;
  }
};
export default StrapiCommunityRenderer;
