import React, { useState } from 'react';

import styles from './PopUpInspirationImage.module.scss';

import { Modal } from 'react-bootstrap';

interface PopUpLoginProps {
  popup: boolean;
  image: string;
  close: () => void;
}

const PopUpInspirationImage: React.FC<PopUpLoginProps> = (props) => {
  const [popup, setPopup] = useState(props.popup);

  return (
    <>
      <Modal
        show={props.popup}
        onHide={() => (setPopup(!popup), props.close && props.close())}
        className={`d-flex flex-column justify-content-center `}
        contentClassName={` ${styles.rounded}`}
      >
        {/* <div className={`d-flex flex-column justify-content-center text-center  full bg-light-gray ${styles.rounded}`}>
          <Modal.Body className="d-flex justify-content-center p-3">
            <div> */}
        <div className='d-flex flex-row align-items-center justify-content-center'>
          <img src={props.image} className={`${styles.image}`} />
        </div>
        {/* </div>
          </Modal.Body>
        </div> */}
      </Modal>
    </>
  );
};

export default PopUpInspirationImage;
