// import { useStoreState } from 'pullstate';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { INavLink, User } from '../../../global';
import styles from './ProfileNavigation.module.scss';
import { ROUTEPATHS } from '../../../routes/routePaths';
import { clientNavPages, designerNavPages, personalClientNavPages, personalProfileNavPages } from '../../../mockdata/navPages';

interface Props {
  currentPage: string;
  user: User;
  loggedUser: User | null;
}

export const ProfileNavigation: React.FC<Props> = ({ currentPage, user, loggedUser }) => {
  const navigationRef = useRef<any>(null);
  const [visible, setVisible] = useState(false);
  const [links, setLinks] = useState<INavLink[]>(designerNavPages);

  useEffect(() => {
    var mine = false;
    if (loggedUser) {
      mine = loggedUser.id === user.id;
      if (loggedUser.userType === 'G') {
        mine = true;
      }
    }
    if (user.userType === 'C') {
      if (mine) {
        setLinks(personalClientNavPages);
      } else {
        setLinks(clientNavPages);
      }
    } else if (user.userType === 'D') {
      if (mine) {
        //Desginer or God
        setLinks(designerNavPages);
      } else {
        setLinks(personalProfileNavPages);
      }
      setLinks(designerNavPages);
    }
  }, [user, loggedUser]);

  const callbackFunction = () => {
    const scroll = window.scrollY;
    if (scroll > 170) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  window.addEventListener('scroll', callbackFunction);
  return (
    <div className={`${styles.navigationWrapperProfile}  container-fluid ${!visible ? '' : styles.fixed}`} ref={navigationRef} style={{ backgroundColor: '#E3E3E3' }}>
      <div className="container-md ">
        <div className="row px-md-2 ps-md-3">
          <div className={`col-12 d-flex flex-nowrap px-lg-0 ${styles.tabsWrapperProfile}`}>
            {links.map((navLink, index) => (
              <Link
                key={index}
                className={`text color-dark flex-grow-1 flex-shrink-0  text-center ${styles.pageTabProfile} ${navLink.path === '/' + currentPage && styles.activeTabProfile}`}
                to={`/${ROUTEPATHS.COMMUNITY}/${user.nameForURL}${navLink.path}`}
              >
                <span className="text-16">{navLink.text}</span>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
