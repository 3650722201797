import React, { useEffect, useState } from 'react';
import { ICardInfo } from '../../Community/CardInfo/CardInfo';
import StatusBar from '../StatusBar/StatusBar';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { faPencil, faTrophy, faIndustry } from '@fortawesome/free-solid-svg-icons';

import styles from './ContestRow.module.scss';
import ActionButton, { MultiActionButtonsWrapper } from '../../ActionButton/ActionButton';
import Spinner from '../../Spinner/spinner';
import { CoCreationContest, ContestStatus, DesignLogo, User } from '../../../global';
import axiosHelper from '../../../helpers/axiosHelper';
import DslStore, {
  setCoCreationContest,
  setCommunityUser,
  setContestTags,
  setCurrentContest,
} from '../../../store/DslStore';
import { useNavigate } from 'react-router-dom';
import { useStoreState } from 'pullstate';
import { getAllCoCreationContests, getCurrentContest, getCurrentUser } from '../../../store/Selectors';
import { Datestr } from '../../../helpers/utils';
import designDefaultImg from '../../../assets/Design.png';
import { ROUTEPATHS } from '../../../routes/routePaths';

interface IContestRow extends ICardInfo {
  contestId: number;
  category: string;
  contestTitle: string;
  startDate: string;
  endUploadDate: string;
  endVotingDate: string;
  clientName?: string;
  entries: number;
  award?: number;
  galleryImages: string[];
  status: ContestStatus;
  statusText: string;
  progress?: number;
  private: boolean;
  accesscode: boolean;
  nda: boolean;
  contestType: string;
  userId: number;
  nameforurl?: string;
  linkToUrl?: string;
  linkToAward?: string;
  liked?: boolean;
  hideGallery: boolean;
  click?: (link: string) => void;
}

const ContestRowGallery = (props: { items: string[] }) => {
  let maxItems = props.items.slice(0, 5);
  let missingItems = 5 - maxItems.length;

  for (let i = 0; i < missingItems; i++) {
    maxItems.push('');
  }

  return (
    <>
      {maxItems.map((item, index) => (
        <div
          key={index}
          className={`${styles.contestRowGalleryItem}  ${index % 2 === 0 ? 'bg-light-gray-2' : 'bg-light-gray'}`}
        >
          {item ? <img src={item} alt="Gallery Item Cover" /> : <Spinner size={5} />}
        </div>
      ))}
    </>
  );
};

const ContestRow: React.FC<IContestRow> = (props) => {
  const redirect = useNavigate();
  const loggedUser = useStoreState(DslStore, getCurrentUser);
  const contest = useStoreState(DslStore, getCurrentContest);
  const allCocreationContests = useStoreState(DslStore, getAllCoCreationContests);
  const [liked, setLiked] = useState(false);
  const [dateColor, setDateColor] = useState('');
  const [gallery, setGallery] = useState<string[]>([]);

  const cocreationTotalAward = () => {
    let awards = 0;
    console.log('cocreation award');
    allCocreationContests.map((item) => {
      if (item.mainContest.id === props.contestId) {
        console.log(item.realTotalAwards);
        awards += item.realTotalAwards;
        // item.awards.map((award) => {
        //   console.log(award.price);
        //   awards += award.price;
        // });
      }
    });
    if (!props.award) {
      return awards;
    } else {
      return;
    }
  };

  const cocreationTotalEntries = () => {
    let entries = 0;
    console.log('cocreation award');
    allCocreationContests.map((item) => {
      if (item.mainContest.id === props.contestId) {
        console.log(item.publicDesignsOrderedCount);
        entries += item.publicDesignsOrdered.length;
        // item.awards.map((award) => {
        //   console.log(award.price);
        //   awards += award.price;
        // });
      }
    });
    return entries;
  };

  const checkColor = () => {
    props.status === 'ongoing' && setDateColor('success');
    props.status === 'closed' && setDateColor('danger');
    props.status === 'client-vote' && setDateColor('dark_yellow');
    props.status === 'community-vote' && setDateColor('dark_yellow');
    props.status === 'coming-soon' && setDateColor('light_grey_40');
  };

  const date = Math.trunc(Datestr(new Date().toISOString()) / 86400000);
  const startDateNumber = Math.trunc(Datestr(props.startDate) / 86400000);
  const endDateNumber = Math.trunc(Datestr(props.endUploadDate) / 86400000);
  const endVotingDateNumber = Math.trunc(Datestr(props.endVotingDate) / 86400000);

  const progressNumber = () => {
    // console.log(endDateNumber);
    // console.log(startDateNumber);
    let progressNumber = 100;
    if (props.status === 'ongoing') {
      let tot = endDateNumber - startDateNumber;
      let passed = date - startDateNumber;
      progressNumber = (passed * 100) / tot;
      // console.log(tot, passed, progressNumber);
    } else if (props.status === 'community-vote') {
      let tot = endVotingDateNumber - endDateNumber;
      let passed = date - endDateNumber;
      progressNumber = (passed * 100) / tot;
    } else {
      const cocreationSons: CoCreationContest[] = [];
      allCocreationContests.map((cocreation) => {
        if (cocreation.mainContest.id === props.contestId) {
          cocreationSons.push(cocreation);
        }
      });
      cocreationSons.map((item) => {
        if (props.status === 'ongoing' && Math.trunc(new Date(item.endUploadDate).getTime()) / 86400000 > date) {
          let tot =
            Math.trunc(Datestr(item.endUploadDate) / 86400000) - Math.trunc(Datestr(item.startUploadDate) / 86400000);
          let passed = Math.trunc(date) - Math.trunc(Datestr(item.startUploadDate) / 86400000);
          console.log(passed);
          // console.log(Math.trunc(Datestr(item.endUploadDate) / 86400000));
          // console.log(Math.trunc(Datestr(item.startUploadDate) / 86400000));
          console.log(Math.trunc(date));
          console.log(Math.trunc(Datestr(item.startUploadDate) / 86400000));
          console.log(
            Math.trunc(Datestr(item.endUploadDate) / 86400000) - Math.trunc(Datestr(item.startUploadDate) / 86400000)
          );
          console.log(Math.trunc(date) - Math.trunc(Datestr(item.startUploadDate) / 86400000));

          console.log((passed * 100) / tot);

          // console.log(tot);

          progressNumber = Math.trunc((passed * 100) / tot);
        } else if (
          props.status === 'community-vote' &&
          Math.trunc(new Date(item.endVotingDate).getTime()) / 86400000 > date
        ) {
          let tot =
            new Date(item.endVotingDate).getTime() / 86400000 - new Date(item.endUploadDate).getTime() / 846000000;
          let passed = date - new Date(item.endUploadDate).getTime() / 846000000;
          progressNumber = (passed * 100) / tot;
        }
      });
    }
    return progressNumber;
  };

  const isLiked = () => {
    let like: boolean;

    if (!loggedUser) {
      like = false;
      setLiked(like);
    }

    if (loggedUser?.contestsILike) {
      like = loggedUser.contestsILike.some((item) => item.id === props.contestId);

      setLiked(like);
    }
  };

  const galleryImages = async () => {
    await axiosHelper.getContest5DesignImages(props.contestId)?.then((response) => {
      console.log(response);
      let ciccio: string[] = [];
      response.map((item: any) =>
        item.image1Attachment ? ciccio.push(item.image1Attachment.s3Path) : ciccio.push(designDefaultImg)
      );
      setGallery(ciccio);
    });
  };

  useEffect(() => {
    setGallery([]);
    isLiked();
    checkColor();
    !props.private && props.contestType !== 'Link to other' && galleryImages();
  }, [props.contestId]);

  const goToDetail = async (userid: number) => {
    console.log(userid);

    let result: User = await axiosHelper.retrieveUserById(userid);
    console.log(result);
    if (props.contestType !== 'Link to other') {
      setCommunityUser(result);
      redirect(`/${ROUTEPATHS.COMMUNITY}/${result.nameForURL}/Profile`);
    } else {
      // alert('link to other');
    }
  };

  const contestLiked = (contestId: number) => {
    axiosHelper.likeContest(contestId).then((response) => {
      setLiked(true);
      // alert(`${props.contestTitle} added to favorites`);
    });
  };

  const contestUnliked = (contestId: number) => {
    axiosHelper.unlikeContest(contestId).then((response) => {
      setLiked(false);
      // alert(`${props.contestTitle} removed from favorites`);
    });
  };
  const setTags = async (contestId: number) => {
    let contestTags = await axiosHelper.getContestTags(contestId);
    if (contestTags) {
      console.log(contestTags);
      setContestTags(contestTags.tags);
    }
  };

  const getCoCreationContestDetails = async (contestId: number) => {
    let cocreationContests = await axiosHelper.getCoCreationContests(contestId);
    if (cocreationContests) {
      console.log(cocreationContests);
      setCoCreationContest(cocreationContests);
    }
  };

  const getContestDetails = async (contestId?: number) => {
    if (contestId) {
      if (props.category !== 'Link to Other' && props.contestType !== 'Link to other') {
        const result = await axiosHelper.retrieveCompleteContest(contestId);
        // console.log(result.data.client);
        // console.log(contest);
        // console.log(result);
        setTags(contestId);
        if (props.contestType === 'Co-creation') {
          getCoCreationContestDetails(contestId);
        }

        setCurrentContest(result.data);

        redirect('/contest/' + props.nameforurl + '/brief');
      } else {
        props.linkToUrl && props.click && props.click(props.linkToUrl);
      }
    }
  };

  const goToContestGallery = async () => {
    await axiosHelper.retrieveCompleteContest(props.contestId).then(async (response) => {
      setCurrentContest(response.data);
      redirect(`/${ROUTEPATHS.CONTEST}/${response.data.nameForUrl}/${ROUTEPATHS.CONTEST_GALLERY}`);
    });
  };

  return (
    <div className={`${styles.contestRowWrapper} col-12 mb-5 position-relative `}>
      <MultiActionButtonsWrapper position="top-right" rounded>
        {props.private && <ActionButton label="Private gallery" icon="private" />}
        {props.accesscode && <ActionButton label="By invitation code contest" icon="accesscode" />}
        {!props.accesscode && <ActionButton label="Global contest" icon="global" />}
        {props.nda && <ActionButton label="Accept nda" icon="acceptnda" />}
      </MultiActionButtonsWrapper>
      <div className="row bg-light">
        <div className="col-12 col-lg-7 d-flex flex-nowrap px-0">
          {/* Cover Image */}
          <div
            className={`${
              props.image ? styles.contestRowImage : styles.contestRowImagePlaceholder
            } bg-light-gray-2 flex-shrink-0 position-relative`}
          >
            {props.image ? (
              <img src={props.image} alt="Contest Cover" />
            ) : // <FontAwesomeIcon icon={faPencil as IconProp} className="color-light" />
            null}
            <div className={styles.statusBar}>
              <StatusBar status={props.status} progress={progressNumber()} />
            </div>
            {!liked && (
              <ActionButton
                icon="addFavorite"
                color={!loggedUser ? 'light-gray-3' : ''}
                position="top-right"
                action={() => contestLiked(props.contestId)}
              />
            )}
            {liked && (
              <ActionButton
                icon="confirm"
                position="top-right"
                color="success"
                action={() => contestUnliked(props.contestId)}
              />
            )}
            {/* <ActionButton icon="addFavorite" position="top-right" action={() => alert('Added as favorite')} /> */}
          </div>
          {/* Project Info */}
          <div className="flex-grow-1">
            <div className="row ps-0 flex-grow-1 h-100">
              {/* Project Title */}
              <div className="col-12  col-lg-5  d-flex flex-wrap  ps-5 mt-lg-0" style={{ height: '90px' }}>
                <div className="d-flex align-items-center pt-2 mb-1  w-100">
                  <h3 onClick={() => getContestDetails(props.contestId)} className="text-xl d-flex fw-bold color-blue cursor-pointer">
                    {props.contestTitle}
                  </h3>
                </div>
                <div className="d-flex align-items-end  mb-1 ">
                  <span className="text-xl d-flex">
                    {props.contestType === 'Co-creation'
                      ? 'Co-creation'
                      : props.category === 'Link to Other'
                      ? 'Partnership'
                      : props.category}
                  </span>
                </div>
              </div>
              {/* Project Author */}
              <div className="col-6 col-lg-4 d-flex flex-column justify-content-center ps-5">
                <div className="d-flex justify-content-start">
                  <div className="d-flex flex-column justify-content-center">
                    <FontAwesomeIcon icon={faIndustry as IconProp} className=" text color-blue me-3" />
                  </div>
                  <div
                    className="text text-start w-100   color-blue"
                    style={{ overflow: 'hidden' }}
                    onClick={() => goToDetail(props.userId)}
                  >
                    <span className="cursor-pointer ">{props.clientName}</span>
                  </div>
                </div>
              </div>
              {/* Project Stats */}
              <div className="col-6 col-lg-3 d-flex flex-column justify-content-center px-2">
                <div className="d-flex justify-content-start mb-3">
                  <div className="d-flex flex-row align-items-center justify-content-center">
                    <FontAwesomeIcon icon={faCalendar as IconProp} className=" text color-blue me-3" />
                  </div>
                  <div className={`text-s text-start color-${dateColor}`}>{props.statusText}</div>
                </div>
                <div className="d-flex justify-content-start mb-3">
                  <div className="d-flex flex-row align-items-center justify-content-center">
                    <FontAwesomeIcon icon={faPencil as IconProp} className=" text color-blue me-3" />
                  </div>
                  <div
                    className={`text-s text-start ${
                      props.private || props.contestType == 'Link to other' ? '' : 'color-blue'
                    } `}
                    onClick={() => !props.private && props.contestType !== 'Link to other' && goToContestGallery()}
                  >
                    {props.contestType === 'Co-creation' ? cocreationTotalEntries() : props.entries} entries
                  </div>
                </div>
                <div className="d-flex justify-content-start">
                  <div className="d-flex flex-row align-items-center">
                    <FontAwesomeIcon icon={faTrophy as IconProp} className=" text color-blue me-3" />
                  </div>
                  <div className="text-s text-start color-blue">
                    {/* {props.contestTitle === 'Sea Design Contest 2023' && console.log(props.award, props.linkToAward)} */}
                    {props.contestType === 'Co-creation' && `€${cocreationTotalAward()}`}
                    {props.contestType !== 'Co-creation' &&
                      !props.linkToAward &&
                      props.award !== -1 &&
                      `€${props.award}`}
                    {props.linkToAward && props.linkToAward}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Project Gallery */}
        <div
          className={`${styles.contestRowGallery} col-12 col-lg-5 d-flex justify-content-center justify-content-lg-end px-0 mt-3 mt-lg-0`}
        >
          {!props.private && props.contestType !== 'Link to other' && !props.hideGallery && gallery.length > 0 && (
            <ContestRowGallery items={gallery} />
          )}
        </div>
      </div>
    </div>
  );
};

export default ContestRow;
