import React from 'react';
import styles from './UserPic.module.scss';
import { fullPathLogo, fullPathLogoUser } from '../../../helpers/utils';
import profileImg from '../../../assets/Profile.png';

interface IUserPic {
  image?: string;
  onImageAdd?: () => void;
  onImageRemove?: () => void;
}

const UserPic: React.FC<IUserPic> = (props) => {
  return (
    <div className={`${styles.userPicWrapper} `}>
      <div className={`${styles.userPicInner}`}>
        {props.image !== '' && <img src={fullPathLogoUser(props.image)} alt="" />}
        {props.image === '' && <img src={profileImg} alt="" />}
      </div>
    </div>
  );
};

export default UserPic;
