import React, { useEffect, useState } from 'react';
import { CategoryDropDown, FilterDropDown, IDropDownOption, SortDropDown } from '../../Forms/DropDown/DropDownContest';
import resetFilter from '../../../assets/Reset-Filter.svg';
import PaginationSwitcherWLF from './PaginationSwitcherWLF';
import useSWR from 'swr';
import axiosHelper from '../../../helpers/axiosHelper';
import { LayoutContestSwitchWLF } from '../../Reusable/LayoutSwitchWLF';
import { useStoreState } from 'pullstate';
import DslStore, { resetContestSearchParams, setContestSearchParamsFilterBy } from '../../../store/DslStore';
import { getContestSearchParams } from '../../../store/Selectors';
import { FilterBy } from '../../../global';

type IProjectNavigationWLF = {
  currentLayout: number;
  onChangeLayout: (val: number) => void;
  showChangeLayout?: boolean;
};

interface IDropDownItem {
  label: string;
  sort: string;
  value: string;
}

const ContestsNavigationWLF: React.FC<IProjectNavigationWLF> = (props) => {

  return (
    <div className="row py-4 mt-4 px-0 ">
      <div className="col-12    d-flex flex-row  flex-lg-row justify-content-between mb-4 mb-md-0">
        <div className="me-0 me-md-4   mb-lg-0 mb-sm-4">
          <FilterDropDown />
        </div>
        <div className="me-0 me-md-4  mb-lg-0 mb-sm-4">
          <CategoryDropDown
            thin
          />
        </div>
        <div className=" me-md-4 mb-lg-0 mb-sm-4  ">
          <SortDropDown thin />
        </div>
        <div className=" d-flex flex-row  align-items-center justify-content-center h-100 ">
          <div className=" me-lg-3">
            <div
              className=" d-flex  flex-row align-items-center  justify-content-center btn-reset-blue   "
              onClick={resetContestSearchParams}
              style={{ width: '3rem', height: '3rem', borderRadius: '8px' }}
            >
              <img src={resetFilter} style={{ height: '25px' }} alt="Reset filter" />
            </div>
          </div>
          <div
            className={`  col-lg-8 me-lg-2   col-md-3 col-sm-4    mb-lg-0  d-flex  justify-content-sm-start justify-content-xl-center   `}
            style={{ minWidth: '150px' }}
          >
            <div className="pe-4 ">
              <PaginationSwitcherWLF />
            </div>
          </div>
          {props.showChangeLayout && (
            <div className={`col-lg-2  col-md-9 col-sm-8 pe-2   mb-lg-0  d-flex  justify-content-sm-end`}>
              <div className=" d-lg-block ">
                <LayoutContestSwitchWLF activeLayout={props.currentLayout} changeLayout={props.onChangeLayout} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContestsNavigationWLF;
