import React, { useEffect, useState } from 'react';
import CardInfo, { ICardInfo } from '../CardInfo/CardInfo';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import {
  faUser,
  faTrophy,
  faHeart,
  faMapMarkerAlt,
  faDollarSign,
  faPencil,
} from '@fortawesome/free-solid-svg-icons';
import ActionButton from '../../ActionButton/ActionButton';

import styles from './DesignerCard.module.scss';
import { User } from '../../../global';
import { useNavigate } from 'react-router-dom';
import DslStore, {
  setCommunityUser,
  setPortfolioDesign,
  setPortfolioDesignCopy,
  setUserAwards,
  setUserIlike,
  setUserInspirations,
} from '../../../store/DslStore';
import axiosHelper from '../../../helpers/axiosHelper';
import { useStoreState } from 'pullstate';
import { getCurrentUser } from '../../../store/Selectors';
import { ROUTEPATHS } from '../../../routes/routePaths';

interface IDesignerCard extends ICardInfo {
  user?: User;
  circle?: boolean;
  contest?: number;
  reputation?: number;
  name?: string;
  role?: string;
  location: string;
  userType?: string;
  startDate?: string;
  awarded: number;
  entries?: number;
  image?: string;
  liked: boolean;
  nameForURL?: string;
}

const DesignerCard: React.FC<IDesignerCard> = (props) => {
  const redirect = useNavigate();
  const loggedUser = useStoreState(DslStore, getCurrentUser);
  const [liked, setLiked] = useState(props.liked);

  const goToDetail = async (userId?: number) => {
    // console.log(props.userId);
    if (userId) {
      let result: User = await axiosHelper.retrieveUserById(userId);
      console.log(result);
      setCommunityUser(result);
      console.log('redirect a ' + result.nameForURL);
      if (loggedUser?.id && loggedUser.id === result.id) {
        redirect(`/${ROUTEPATHS.COMMUNITY}/${result.nameForURL}/profile-editable`);
      } else {
        redirect(`/${ROUTEPATHS.COMMUNITY}/${result.nameForURL}/Profile`);
      }
    }
  };

  useEffect(() => {
    isLiked(props.userId);
    setLiked(props.liked);
  }, [props.liked]);

  const goToUserGallery = async () => {
    console.log('gallery');
    if (props.user?.id) {
      console.log('gallery 2');
      await axiosHelper.retrieveUserById(props.user?.id)?.then(async (response) => {
        setCommunityUser(response);
        await axiosHelper.getUserDetails(props.user?.id)?.then(async (design) => {
          await axiosHelper.getUserAwards(props.userId)?.then((awards) => {
            setUserAwards(awards.awards);
          });
          await axiosHelper.getUserInspirations(props.userId)?.then((inspirations) => {
            setUserInspirations(inspirations.inspirations);
          });
          setPortfolioDesign(design.designs);
          setPortfolioDesignCopy(design.designs);
          redirect(`/${ROUTEPATHS.COMMUNITY}/${props.nameForURL}/portfolio`);
        });
      });
    }
  };

  const userLiked = (userId?: number) => {
    console.log('clicl', userId);
    if (userId) {
      loggedUser &&
        loggedUser.id !== userId &&
        axiosHelper.likeUser(userId).then(async (response) => {
          setUserIlike(response.usersILike);
          setLiked(true);

          // alert(`${props.user?.username} added to favorites`);
        });
    }
  };

  const userUnliked = (userId?: number) => {
    console.log('clicl', userId);
    if (userId) {
      axiosHelper.unlikeUser(userId).then(async (response) => {
        setUserIlike(response.usersILike);
        setLiked(false);
      });
      // alert(`${props.user?.username} removed from favorites`);
    }
  };

  const isLiked = (idUser?: number) => {
    if (idUser) {
      let state: boolean = false;
      loggedUser &&
        loggedUser?.userType !== 'C' &&
        loggedUser.usersILike.findIndex((item) => (item.id === idUser ? (state = true) : null));
      return state;
    }
    return false;
  };

  const banUser = async () => {
    if (loggedUser?.userType === 'G' && props.user?.id) {
      await axiosHelper
        .banUser(props.user.id)
        .then((response) => {
          console.log(response);
          // redirect('/');
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <div
      className={`${styles.card} h-100  col-12 px-lg-2 px-sm-2 position-relative d-flex flex-row align-items-center justify-content-center `}
    >
      <CardInfo image={props.image} circle={props.circle} name={props.name} userId={props.user?.id}>
        <div className="text-center ">
          {!liked && (
            <ActionButton
              icon="addFavorite"
              label={loggedUser ? 'Save as favorite' : 'Login to save as favorite'}
              color={!loggedUser ? 'light-gray-3' : ''}
              position="top-right"
              action={() => loggedUser && userLiked(props.user?.id)}
            />
          )}
          {liked && (
            <ActionButton
              label="Remove from favorites"
              icon="confirm"
              position="top-right"
              color="success"
              action={() => userUnliked(props.user?.id)}
            />
          )}
          {loggedUser?.userType === 'G' && (
            <ActionButton
              label="Ban this user"
              icon="removeFavorite"
              position="top-left"
              color="danger"
              action={() => banUser()}
            />
          )}
          {/* <div className={`${styles.badges} d-flex justify-content-end`}>
            <div className={`${styles.badge} bg-red color-light`}>
              <FontAwesomeIcon icon={faFlask as IconProp} />
            </div>
            <div className={`${styles.badge} bg-blue color-light`}>
              <FontAwesomeIcon icon={faAward as IconProp} />
            </div>
          </div> */}
          <div className="container bg-light justify-content-center">
            <div className={`${styles.rowTitle} row  mb-2`}>
              <div
                className="col  text-center align-self-center  "
                style={{ overflow: 'hidden', cursor: 'pointer' }}
                onClick={() => goToDetail(props.userId)}
              >
                <span className="fw-bold text-16 color-blue d-none d-md-block ">{props.name}</span>
                <span className="fw-bold text-16 color-blue d-md-none d-sm-block ">{props.name}</span>
                {/* <h2 className="text-xl fw-bold color-blue mt-4 mb-4 px-3">{props.name}</h2> */}
              </div>
            </div>

            <div className=" row align-self-center text-center mb-5  text-capitalize">
              <span>{props.role}</span>
            </div>
            <div className="d-flex flex-column align-items-center ">
              <div className="info d-flex flex-column justify-content-center " style={{ width: '70%' }}>
                <div className="d-flex justify-content-start  align-text-top mb-3" style={{ height: '35px' }}>
                  <div className="d-flex  justify-content-center align-items-center ">
                    <FontAwesomeIcon icon={faMapMarkerAlt as IconProp} className="text color-blue me-3" />
                  </div>
                  <div className="d-flex flex-row justify-content-start align-items-center  w-100 overflow-hidden">
                    <div className="text text-start ps-1 color-blue">{props.location}</div>
                  </div>
                </div>
                <div className="d-flex justify-content-start mb-3 ">
                  <div className="d-flex flex-column justify-content-center">
                    <FontAwesomeIcon icon={faCalendar as IconProp} className=" text color-blue me-3" />
                  </div>
                  <div className="text text-start align-self-center ">
                    <span className="text">Since: {props.startDate}</span>
                  </div>
                </div>
                <div className="d-flex justify-content-start mb-3">
                  <div className="d-flex flex-column justify-content-center">
                    <FontAwesomeIcon icon={faUser as IconProp} className=" text color-blue me-3" />
                  </div>
                  <div className=" text-start color-blue  align-self-center">
                    <span className="text">{props.userType === 'Designer' ? 'User' : 'Client'}</span>
                  </div>
                </div>
                <div className="d-flex justify-content-start mb-3">
                  <div className="d-flex flex-column justify-content-center">
                    <FontAwesomeIcon icon={faPencil as IconProp} className=" text color-blue me-3" />
                  </div>
                  <div
                    className=" text-start color-blue"
                    style={{ cursor: 'pointer' }}
                    onClick={() => goToUserGallery()}
                  >
                    <span className="fw-bold text">{props.entries}</span> entries
                  </div>
                </div>
                {props.reputation ? (
                  <div className="d-flex justify-content-start mb-3">
                    <div className="d-flex flex-column justify-content-center">
                      <FontAwesomeIcon icon={faHeart as IconProp} className=" text color-blue me-3" />
                    </div>
                    <div className=" text-start color-dark">
                      <span className="fw-bold text">{props.reputation}</span> reputation
                    </div>
                  </div>
                ) : (
                  <div className="d-flex justify-content-start mb-3">
                    <div className="d-flex flex-column justify-content-center">
                      <FontAwesomeIcon icon={faHeart as IconProp} className=" text color-blue me-3" />
                    </div>
                    <div className=" text-start color-dark">
                      <span className="fw-bold text">0</span> reputation
                    </div>
                  </div>
                )}
                {props.contest && (
                  <div className="d-flex justify-content-start mb-3">
                    <div className="d-flex flex-column justify-content-center">
                      <FontAwesomeIcon icon={faTrophy as IconProp} className=" text color-blue me-3" />
                    </div>
                    <div className=" text-start color-dark">
                      <span className="fw-bold text">{props.contest}</span> contest
                    </div>
                  </div>
                )}
                <div className="d-flex justify-content-start mb-3">
                  <div className="d-flex flex-column justify-content-center">
                    <FontAwesomeIcon icon={faDollarSign as IconProp} className=" text color-blue me-3" />
                  </div>
                  <div className="text text-start color-dark">
                    <span className="fw-bold text">€ {props.awarded}</span> awarded
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CardInfo>
    </div>
  );
};

export default DesignerCard;
