import { ImgProps } from '../../../../global';
import { pathStrapiImage } from '../../../../helpers/utils';
import HorizontalSection from '../../../HorizontalSection/HorizontalSection';

function ImgComponent(props: ImgProps) {
  return (
    <>
      <HorizontalSection key={props.id} bgColor={props.bgcolor}>
        <div className="row my-5   px-1 mx-1 ">
          <div className="col-12 d-flex justify-content-center align-items-center">
            <img
              className="img-fluid "
              style={{ height: '630px', width: '1170px', borderRadius: '8px' }}
              alt="Placeholder"
              src={pathStrapiImage(props.image.data.attributes.url)}
            />
            
          </div>
        </div>
      </HorizontalSection>
    </>
  );
}

export default ImgComponent;
