import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DslStore, { setPortfolioDesign, setUserAwards, setUserInspirations } from '../../../store/DslStore';

import styles from './PopUpDeleteDraft.module.scss';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

import axiosHelper from '../../../helpers/axiosHelper';
import { Modal } from 'react-bootstrap';
import { getCurrentDesign, getCurrentUser } from '../../../store/Selectors';
import { useStoreState } from 'pullstate';
import { useNavigate } from 'react-router-dom';
import { ROUTEPATHS } from '../../../routes/routePaths';

interface PopUpLoginProps {
  popup: boolean;
  close: () => void;
}

const PopUpDeleteDraft: React.FC<PopUpLoginProps> = (props) => {
  const currentdesign = useStoreState(DslStore, getCurrentDesign);
  const loggedUser = useStoreState(DslStore, getCurrentUser);
  const [popup, setPopup] = useState(props.popup);
  const redirect = useNavigate();

  const deleteDraft = async () => {
    if (currentdesign)
      await axiosHelper
        .deleteDraft(currentdesign.id)
        .then(async (response) => {
          await axiosHelper
            .getDesignerDetails(loggedUser?.id)
            ?.then(async (designs) => {
              await axiosHelper.getDesignerAwards(loggedUser?.id)?.then((awards) => {
                setUserAwards(awards.awards);
              });
              await axiosHelper.getDesignerInspirations(loggedUser?.id)?.then((inspirations) => {
                setUserInspirations(inspirations.inspirations);
              });
              setPortfolioDesign(designs.designs);
              setTimeout(() => {
                redirect(`/${ROUTEPATHS.COMMUNITY}/${loggedUser?.nameForURL}/portfolio`);
              }, 2000);
            })
            .catch((error) => {
              props.close();
            });
        })
        .catch((error) => {
          props.close();
        });
  };

  return (
    <>
      <Modal
        show={props.popup}
        onHide={() => (setPopup(!popup), props.close && props.close())}
        className={`d-flex flex-column justify-content-center `}
        contentClassName={` ${styles.rounded}`}
      >
        <div className={`d-flex flex-column justify-content-center text-center  full bg-light-gray ${styles.rounded}`}>
          <Modal.Body className="d-flex justify-content-center p-3">
            {/* <form onSubmit={handleSubmit(onSubmit)}> */}
            <div className="row justify-content-center align-items-center  ">
              <div className="col-12 mb-3">
                <span className="text-16 fw-bold ">Are you sure to delete this design?</span>
              </div>
              <div className="d-flex col-12 py-4 flex-row align-items-center justify-content-center">
                <p className="text-16"> You can't undo this action and your design will be discarded.</p>
              </div>

              <div className="row ">
                <div className="col-12  d-flex justify-content-between ">
                  <div className="col-3 ps-1  d-flex  ">
                    <div className="d-flex justify-content-center align-items-center ">
                      <div
                        className={` ${styles.closeButton}`}
                        onClick={() => {
                          setPopup(!popup);
                          props.close();
                        }}
                      >
                        <FontAwesomeIcon icon={faXmark as IconProp} className=" text color-light " />
                      </div>
                      <span className="ps-3">Close</span>
                    </div>
                  </div>
                  <div className="col-4 pe-1 d-flex   ">
                    <button
                      className={`btn-success rounded full 
                      
                     mb-3`}
                      onClick={() => deleteDraft()}
                    >
                      Delete draft
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* </form> */}
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default PopUpDeleteDraft;
