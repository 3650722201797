import React, { useEffect, useRef, useState } from 'react';
import xLogo from '../../assets/x-twitter-01.png';
import styles from './UploadContest.module.scss';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileVideo, faFileZipper } from '@fortawesome/free-regular-svg-icons';
import { faCircleQuestion, faXmark, faCircleExclamation } from '@fortawesome/free-solid-svg-icons';

//Header Data

//Footer Data

import { getCoCreationContests, getTags } from '../../store/Selectors';
import { useStoreState } from 'pullstate';
import DslStore, { setPortfolioDesign, setUserAwards, setUserInspirations, setUserInspirationsCopy } from '../../store/DslStore';
import axiosHelper from '../../helpers/axiosHelper';
import { CoCreationContest, Contest, ContestTag, DSLTag, DesignUpload, User } from '../../global';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import UploadContestCoCreation from './UploadContestCoCreation';
import Dropzone from 'react-dropzone';
import axios from 'axios';
import { TailSpin } from 'react-loader-spinner';
import { UploadContestNaming } from './UploadContestNaming';
import PopUpSaveDraft from '../../components/PopUp/PopUpSaveDraft/PopUpSaveDraft';
import { useAuth } from '../../context/AuthContext';
import ContestSidePart from './ContestSidePart';
import { ROUTEPATHS } from '../routePaths';
import ProfileInfoWLF from '../../components/Profile/ProfileInfo/ProfileInfoWLF';

interface Props {
  contest: Contest;
  loginCallback: () => void;
  breadcrumbs: (breadcrumbs: string) => void;
  messageBanner: (message: number) => void;
}

const UploadContestWLF: React.FC<Props> = (props) => {
  const { loggedUser: user, setLoggedUser: setUser } = useAuth();

  const cocreationContests = useStoreState(DslStore, getCoCreationContests);
  const tags = useStoreState(DslStore, getTags);
  const [needUserData, setNeedUserData] = useState(false);
  const [suggestionArray, setSuggestionArray] = useState<DSLTag[]>([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<DesignUpload>();
  const redirect = useNavigate();
  const [tagsArray, setTagsArray] = useState<string[]>([]);
  const [tagString, setTagString] = useState('Desall ');
  const [designNameLength, setDesignNameLength] = useState<number>(0);
  const [designAbstractLength, setDesignAbstractLength] = useState<number>(0);
  const [uploadedImages, setUploadedImages] = useState<File[]>([]);
  const [uploadedCoverImage, setUploadedCoverImage] = useState<File>();
  // const [coverError, setCoverError] = useState(false);
  const coverError = useRef(false);
  const designNameError = useRef(false);
  const abstractError = useRef(false);
  const tagsError = useRef(false);
  const agreementError = useRef(false);
  const [fileLimit, setFileLimit] = useState(true);
  const [archiveFile, setArchiveFile] = useState<File>();
  const [videoFile, setVideoFile] = useState<File>();
  const [agreement, setAgreement] = useState(false);
  const uuidString = useRef<string>('');
  const filesTot = useRef(0);
  const formData = useRef<DesignUpload>();
  const [draft, setDraft] = useState(false);
  const [progress, setProgress] = useState(0);
  const [popUpProjName, setPopUpProjName] = useState(false);
  const [popUpPrivacy, setPopUpPrivacy] = useState(false);
  const [popUpAbstract, setPopUpAbstract] = useState(false);
  const [popUpDescription, setPopUpDescription] = useState(false);
  const [popUpArchive, setPopUpArchive] = useState(false);
  const [popUpCover, setPopUpCover] = useState(false);
  const [popUpImages, setPopUpImages] = useState(false);
  const [popUpTags, setPopUpTags] = useState(false);
  const [popUpVideo, setPopUpVideo] = useState(false);
  const [popUpAgreement, setPopUpAgreement] = useState(false);
  const [popupSaveDraft, setPopUpSaveDraft] = useState(false);
  const [sideAnchored, setSideAnchored] = useState(false);
  const [designPrivacy, setDesignPrivacy] = useState(false);
  const [upload, setUpload] = useState(false);

  useEffect(() => {
    const checkUser = (userToCheck: User) => {
      if (userToCheck) {
        console.log(userToCheck);
        if (userToCheck.userType === 'G') {
          return true;
        } else if (
          userToCheck.username &&
          userToCheck.firstName &&
          userToCheck.lastName &&
          userToCheck.mobileNumber &&
          userToCheck.city &&
          userToCheck.state &&
          userToCheck.zipCode &&
          userToCheck.country &&
          userToCheck.job?.name &&
          userToCheck.jobTitle &&
          userToCheck.school1 &&
          userToCheck.freelanceLicense
        ) {
          console.log('returning true');
          return true;
        }
      }
      console.log('returning false');
      return false;
    };
    if (user) {
      setNeedUserData(!checkUser(user));
    } else {
      props.loginCallback();
    }
  }, [props, user]);
  const handleVideoSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event?.target?.files?.[0]);
    if (event?.target?.files) {
      let sizeMb = event.target.files?.[0].size / 1024 / 1024;
      if (sizeMb < 50) {
        setVideoFile(event.target.files?.[0]);
      } else {
        // alert('Upload file up to 50MB');
      }
    }
  };

  const handleArchiveSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event?.target?.files?.[0]);
    if (event?.target?.files) {
      let sizeMb = event.target.files?.[0].size / 1024 / 1024;
      if (sizeMb < 100) {
        setArchiveFile(event.target.files?.[0]);
      } else {
        // alert('Upload file up to 100MB');
      }
    }
  };

  // useEffect(() => {
  //   if (uploadedImages) {
  //     // console.log(uploadedImages);
  //   }
  // }, [uploadedImages]);
  const accept = { 'image/png': ['.png'], 'image/jpg': ['.jpg'], 'image/jpeg': ['.jpeg'] };

  const onDrop = (e: any) => {
    // console.log(e);
    const chosenFiles = Array.prototype.slice.call(e);
    console.log(e?.[0]);
    handleUploadFiles(chosenFiles);
  };

  const onDropCover = (e: any) => {
    // console.log(e?.[0]);
    setUploadedCoverImage(e?.[0]);
    coverError.current = false;
  };

  const handleUploadFiles = (files: File[]) => {
    const uploaded: File[] = [...uploadedImages];
    let limitExceeded = false;
    files.some((file: File) => {
      if (uploaded.findIndex((f: File) => f.name === file.name) === -1) {
        uploaded.push(file);
        if (uploaded.length > 4) setFileLimit(true);
        if (uploaded.length > 4) {
          // alert('You can only add a maximum of 5 files');
          setFileLimit(false);
          limitExceeded = true;
          return true;
        }
      }
    });
    if (!limitExceeded) {
      setUploadedImages(uploaded);
    }
  };

  const deleteImg = (index: number) => {
    const uploaded: File[] = [...uploadedImages];
    uploaded.splice(index, 1);
    setUploadedImages(uploaded);
  };

  const tagstring = (e: string) => {
    setTagString(e);
  };

  const suggestedTagsString = () => {
    let tagsArr: string[] = [];
    // console.log(e);
    tagsArr = tagString.split(' ');

    setTagsArray(tagsArr);
    let suggestions: DSLTag[] = [];

    tags.map((item: DSLTag, index: number) => {
      if (item.name.includes(tagsArr[tagsArr.length - 1])) {
        // console.log(item.name);
        suggestions.push(item);
      }
    });
    setSuggestionArray(suggestions);
  };

  const addTag = (tag: string) => {
    // console.log(tag);
    let string = tagString;
    let index = string.split(' ').length - 1;
    // console.log(string.split(' ')[index]);
    let stringWithSuggestion = string.replace(new RegExp(string.split(' ')[index] + '$'), tag + ' ');
    setTagString(stringWithSuggestion);
  };

  const tagStringCheck = () => {
    let string = tagString;
    let index = string.split(' ').length - 1;
    // console.log(string.split(' ')[index]);
    if (string.split(' ')[index] !== '') {
      return true;
    } else {
      return false;
    }
  };

  const initialTagString = () => {
    let tagStr: string = 'Desall ';

    setTagString(tagStr);
  };

  useEffect(() => {
    suggestedTagsString();
    tagStringCheck();
  }, [tagString]);

  useEffect(() => {
    suggestedTagsString();
    if (tagString == '') {
      initialTagString();
    }
    console.log(props.contest?.isPrivate);
    // console.log(tags);
  }, []);

  const componiarray = () => {
    const arrayTot: File[] = [];
    if (uploadedCoverImage) {
      arrayTot.unshift(...arrayTot, uploadedCoverImage);
    }
    if (uploadedImages) {
      uploadedImages.map((item: File) => {
        arrayTot.push(item);
      });
    }
    if (videoFile) {
      arrayTot.push(videoFile);
    }
    if (archiveFile) {
      arrayTot.push(archiveFile);
    }
    filesTot.current = arrayTot.length;
  };

  const onSubmit = async (data: DesignUpload) => {
    console.log(uploadedCoverImage);
    console.log(data);
    console.log('ciao');
    designNameError.current = false;
    abstractError.current = false;
    tagsError.current = false;
    agreementError.current = false;
    coverError.current = false;
    if (!data.designName) {
      designNameError.current = true;
    }
    if (!data.abstract && !draft) {
      abstractError.current = true;
    }
    if (tagString.length < 1 && !draft) {
      tagsError.current = true;
    }
    if (!uploadedCoverImage && !draft) {
      coverError.current = true;
    }
    if (!agreement) {
      agreementError.current = true;
      return;
    }

    if (agreement) {
      formData.current = data;
      if (uploadedCoverImage) {
        componiarray();

        formData.current = data;
        formData.current.isHidden = designPrivacy;
        uuidString.current = '';
        const arrayImages: File[] = [];
        if (uploadedCoverImage) {
          arrayImages.unshift(...arrayImages, uploadedCoverImage);
        }
        if (uploadedImages) {
          uploadedImages.map((item: File) => {
            arrayImages.push(item);
          });
        }

        console.log(arrayImages);
        if (arrayImages.length > 0 || videoFile || archiveFile) {
          if (arrayImages.length > 0) {
            await imagesPresignedUrl(arrayImages);
            setProgress(15);
            if (videoFile || archiveFile) {
              if (videoFile) {
                await videoPresignedUrl(videoFile);
                setProgress(40);
                if (archiveFile) {
                  await archivePresignedUrl(archiveFile);
                  setProgress(70);
                }
              } else if (archiveFile) {
                await archivePresignedUrl(archiveFile);
                setProgress(70);
              }
            }
          } else if (videoFile) {
            await videoPresignedUrl(videoFile);
            setProgress(50);
            if (archiveFile) {
              await archivePresignedUrl(archiveFile);
              setProgress(70);
            }
          }
        } else if (archiveFile) {
          await archivePresignedUrl(archiveFile);
          setProgress(70);
        } else if (props.contest) {
          console.log('create 6');
          const uuids = uuidString.current.slice(0, -1);
          if (uuids.length > 0 && !draft) {
            axiosHelper.createDesign(data, props.contest?.id, uuids).then((response) => {
              console.log(response);
              //WLF WHY PORCA PUTTANA?              setCommunityUser(user);
            });
          } else if (draft && upload) {
            console.log('ciao');
            axiosHelper
              .saveDraft(data, props.contest?.id, uuids)
              .then(async (response) => {
                console.log(response);
                setProgress(85);
                //WLF WHY PORCA PUTTANA?                 setCommunityUser(user);
                setTimeout(async () => {
                  await axiosHelper.getDesignerDetails(user?.id)?.then(async (designs) => {
                    await axiosHelper.getDesignerAwards(user?.id)?.then((awards) => {
                      setUserAwards(awards.awards);
                    });
                    await axiosHelper.getDesignerInspirations(user?.id)?.then((inspirations) => {
                      setUserInspirations(inspirations.inspirations);
                      setUserInspirationsCopy(inspirations.inspirations);
                    });
                    setPortfolioDesign(designs.designs);
                    setTimeout(() => {
                      redirect(`/${ROUTEPATHS.COMMUNITY}/${user?.nameForURL}/portfolio`);
                    }, 1000);
                  });
                }, 1000);
              })
              .catch((e) => {
                // alert('something goes wrong');
                setProgress(0);
              });
          } else if (!draft) {
            coverError.current = true;
          }
        }
        setDraft(false);
      } else {
        if (upload) {
          if (draft && props.contest) {
            if (agreement && data.designName) {
              console.log('draft senza immagini');
              if (videoFile || archiveFile) {
                if (videoFile) {
                  console.log('draft senza immagini ma con video');

                  await videoPresignedUrl(videoFile);
                  setProgress(50);
                }
                if (archiveFile) {
                  console.log('draft senza immagini ma con archive');
                  await archivePresignedUrl(archiveFile);
                  setProgress(70);
                }
              } else {
                axiosHelper.saveDraft(data, props.contest?.id, '').then(async (response) => {
                  setProgress(85);
                  console.log(response);
                  //WLF WHY PORCA PUTTANA?                   setCommunityUser(user);
                  setPortfolioDesign([]);
                  setTimeout(async () => {
                    await axiosHelper
                      .getDesignerDetails(user?.id)
                      ?.then(async (designs) => {
                        await axiosHelper.getDesignerAwards(user?.id)?.then((awards) => {
                          setUserAwards(awards.awards);
                        });
                        await axiosHelper.getDesignerInspirations(user?.id)?.then((inspirations) => {
                          setUserInspirations(inspirations.inspirations);
                          setUserInspirationsCopy(inspirations.inspirations);
                        });
                        setPortfolioDesign(designs.designs);
                        setTimeout(() => {
                          redirect(`/${ROUTEPATHS.COMMUNITY}/${user?.nameForURL}/portfolio`);
                        }, 1000);
                      })
                      .catch((e) => {
                        setProgress(0);
                      });
                  }, 2000);
                });
              }
            } else {
              agreementError.current = true;
            }
          } else {
            coverError.current = true;
          }
        }
      }
    } else {
      agreementError.current = true;
    }
  };

  const handleName = (e: any) => {
    let nome = e.target.value;
    setDesignNameLength(nome.length);
  };

  const handleAbstract = (e: any) => {
    let nome = e.target.value;
    setDesignAbstractLength(nome.length);
  };

  async function archivePresignedUrl(file: File) {
    return await axiosHelper
      .getPresignedUrl(5, file.name, file.size, file.type)
      ?.then(async (response) => {
        return await axios
          .put(response.data.payload, file)
          .then((risposta) => {
            console.log(risposta);
            updateString(response.data.id);
          })
          .catch((error) => {
            throw error;
          });
      })
      .catch((error) => {
        throw error;
      });
  }

  async function videoPresignedUrl(file: File) {
    return await axiosHelper
      .getPresignedUrl(6, file.name, file.size, file.type)
      ?.then(async (response) => {
        return await axios
          .put(response.data.payload, file)
          .then((risposta) => {
            console.log(risposta);
            updateString(response.data.id);
          })
          .catch((error) => {
            throw error;
          });
      })
      .catch((error) => {
        throw error;
      });
  }

  const updateString = (string: string) => {
    const stringa = string + ',';
    uuidString.current += stringa;
    console.log('MI HANNO PASSATO ' + string);
    console.log(uuidString.current);
    console.log(filesTot.current);

    const virgole = uuidString.current.split(',').length - 1;
    if (virgole === filesTot.current) {
      if (agreement) {
        if (props.contest) {
          console.log('create archive');
          const uuids = uuidString.current.slice(0, -1);
          if (!draft && upload) {
            formData.current &&
              axiosHelper
                .createDesign(formData.current, props.contest?.id, uuids)
                .then(async (response) => {
                  setProgress(100);
                  //WLF WHY PORCA PUTTANA?                   setCommunityUser(user);
                  console.log(response);
                  // setCurrentDesign(response);
                  await axiosHelper
                    .getDesignerDetails(user?.id)
                    ?.then(async (designs) => {
                      await axiosHelper.getDesignerAwards(user?.id)?.then((awards) => {
                        setUserAwards(awards.awards);
                      });
                      await axiosHelper.getDesignerInspirations(user?.id)?.then((inspirations) => {
                        setUserInspirations(inspirations.inspirations);
                        setUserInspirationsCopy(inspirations.inspirations);
                      });
                      setPortfolioDesign(designs.designs);
                      redirect(`/${ROUTEPATHS.COMMUNITY}/${user?.nameForURL}/portfolio`);

                      setTimeout(() => {
                        redirect(`/${ROUTEPATHS.COMMUNITY}/${user?.nameForURL}/portfolio`);
                      }, 2000);
                    })
                    .catch((error) => {
                      setProgress(0);
                    });

                  // redirect(`/${ROUTEPATHS.CONTEST}/${contest.nameForUrl}/gallery/${response.name}`);
                })
                .catch((error) => {
                  setProgress(0);
                });
          } else {
            if (upload) {
              formData.current &&
                axiosHelper.saveDraft(formData.current, props.contest?.id, uuids).then(async (response) => {
                  setProgress(85);
                  console.log(response);
                  //WLF WHY PORCA PUTTANA?               setCommunityUser(user);
                  setPortfolioDesign([]);
                  setTimeout(async () => {
                    await axiosHelper
                      .getDesignerDetails(user?.id)
                      ?.then(async (designs) => {
                        await axiosHelper.getDesignerAwards(user?.id)?.then((awards) => {
                          setUserAwards(awards.awards);
                        });
                        await axiosHelper.getDesignerInspirations(user?.id)?.then((inspirations) => {
                          setUserInspirations(inspirations.inspirations);
                          setUserInspirationsCopy(inspirations.inspirations);
                        });
                        setPortfolioDesign(designs.designs);
                        setTimeout(() => {
                          redirect(`/${ROUTEPATHS.COMMUNITY}/${user?.nameForURL}/portfolio`);
                        }, 1000);
                      })
                      .catch((e) => {
                        setProgress(0);
                      });
                  }, 2000);
                });
            }
          }
        }
      } else {
        agreementError.current = true;
      }
    }
  };

  // const updateStringDraft

  async function imagesPresignedUrl(files: File[]) {
    let filesArr = files;

    console.log(filesArr.length);
    filesArr.map(async (item: File, index: number) => {
      await axiosHelper
        .getPresignedUrl(index, item.name, item.size, item.type)
        ?.then(async (response) => {
          // console.log(response.data.payload);
          await axios
            .put(response.data.payload, item, { headers: {} })
            .then((risposta) => {
              console.log(risposta);
              updateString(response.data.id);
              return risposta;
            })
            .catch((error) => {
              throw error;
            });
        })
        .catch((error) => {
          throw error;
        });
    });
  }

  const showSaveDraftPopUp = () => {
    uuidString.current = '';
    setDraft(true);
    setUpload(false);
    setDesignNameLength.length > 0 && agreement && setPopUpSaveDraft(true);
  };

  const saveDraft = async () => {
    console.log('save draft');
    console.log(props.contest, formData.current);
    if (props.contest && formData.current) {
      console.log('ci sono');
      setDraft(true);
      setProgress(1);
      let uuidImages = '';
      if (uuidString.current) {
        uuidImages = uuidString.current;
      }
      await axiosHelper.saveDraft(formData.current, props.contest.id, uuidImages).then(async (response) => {
        console.log(response);
        //WLF WHY PORCA PUTTANA?      setCommunityUser(user);
        setPortfolioDesign([]);
        setTimeout(async () => {
          await axiosHelper
            .getDesignerDetails(user?.id)
            ?.then(async (designs) => {
              await axiosHelper.getDesignerAwards(user?.id)?.then((awards) => {
                setUserAwards(awards.awards);
              });
              await axiosHelper.getDesignerInspirations(user?.id)?.then((inspirations) => {
                setUserInspirations(inspirations.inspirations);
                setUserInspirationsCopy(inspirations.inspirations);
              });
              setPortfolioDesign(designs.designs);
              setTimeout(() => {
                redirect(`/${ROUTEPATHS.COMMUNITY}/${user?.nameForURL}/portfolio`);
              }, 1000);
            })
            .catch((e) => {
              setProgress(0);
            });
        }, 2000);
      });
    }
  };

  const hiddenOption = () => {
    if (!props.contest?.isPrivate && props.contest?.startDate && props.contest.endUploadDate) {
      const dateNumber = new Date().getTime() / 86400000;
      const startDateNumber = new Date(props.contest?.startDate).getTime() / 86400000;
      const endDateNumber = new Date(props.contest?.endUploadDate).getTime() / 86400000;
      const halfDate = (endDateNumber - startDateNumber) / 2;
      if (dateNumber - startDateNumber < halfDate) {
        return true;
      } else {
        return false;
      }
    }
  };

  const isUpperCase = (string: string) => /^[AZ]*$/.test(string);

  if (!user) return null;
  else
    return (
      <div className="bg-light-gray ">
        <main className={`container pt-5 mb-5 px-sm-0  overflow-hidden `}>
          <div className="row ">
            <ContestSidePart
              contest={props.contest}
              disableUpload={true}
              loginCallback={function (): void {
                throw new Error('Function not implemented.');
              }}
              messageBanner={function (message: number): void {
                throw new Error('Function not implemented.');
              }}
            />
            <div className="col-12 col-md-9 pt-5  px-5 px-sm-0">
              <div className="row">
                <div className="col-12 px-4 ">
                  <div className="row-mb-5">
                    <div className="col-12 col-md-2 mb-3"></div>
                    <div className="col-12 col-md-10"></div>
                  </div>
                </div>
              </div>
              {needUserData ? (
                <ProfileInfoWLF user={user} setUser={(rst)=>{setUser(rst);setNeedUserData(false)}} />
              ) : (
                <>
                  {props.contest?.contestType !== 'Co-creation' && props.contest?.contestType !== 'Naming' ? (
                    <>
                      <div className="row ">
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <div className="col-12 px-3 ">
                            <div className="row ">
                              <div className="col-12 col-md-2 mb-3"></div>
                              <div className="col-12 col-md-10 text  py-5">
                                <i>
                                  To upload your design, you have to fill the mandatory fields (<span className="color-dark_red">*</span>)
                                </i>
                              </div>
                            </div>
                            <div className="row mb-5">
                              <div className="col-12 col-md-2 text fw-bold  mb-3 d-flex flex-row ps-md-1 ps-2 " style={{ position: 'relative', zIndex: 10 }}>
                                <div className="  col-11  ">
                                  Project name <span className="color-dark_red me-1">*</span>
                                </div>
                                <div className=" " onMouseOver={() => console.log('ciao')}>
                                  <FontAwesomeIcon
                                    icon={faCircleQuestion as IconProp}
                                    className=" text color-blue me-0"
                                    onMouseEnter={() => setPopUpProjName(true)}
                                    onMouseLeave={() => setPopUpProjName(false)}
                                  />
                                </div>
                                <div className={`${popUpProjName ? styles.message : styles.noMessage} `}>
                                  <div className={`  ${styles.freccetta} `}></div>
                                  <div className={`${styles.infoActions} text p-4`}>
                                    <i>no text</i>
                                  </div>
                                </div>
                              </div>

                              <div className="col-12 col-md-10">
                                <input
                                  id="designname"
                                  className={`${styles.designName}`}
                                  type="text"
                                  {...register('designName', { onChange: handleName, maxLength: 50 })}
                                  placeholder="Identify with a name your project"
                                />
                                <div className="row mt-2">
                                  <div className="col-1"></div>
                                  <div className="col-10">
                                    {designNameError.current && (
                                      <span className=" d-flex text flex-row justify-content-center align-items-center color-dark_red">
                                        <FontAwesomeIcon icon={faCircleExclamation as IconProp} className=" text me-0" />
                                        &nbsp; Please provide a &nbsp; <b> Project name. </b>
                                      </span>
                                    )}
                                  </div>
                                  <div className="col-1  d-flex justify-content-end">{<span>{designNameLength}/50</span>}</div>
                                </div>
                              </div>
                            </div>
                            <div className="row mb-5 ">
                              <div className="col-12 col-md-2 ps-1 text fw-bold  mb-3 d-flex  flex-row ps-md-1 ps-2 " style={{ position: 'relative', zIndex: 10 }}>
                                <div className=" col-11">
                                  <div>Design privacy</div>
                                </div>
                                <div>
                                  <FontAwesomeIcon
                                    icon={faCircleQuestion as IconProp}
                                    className=" text color-blue me-0 "
                                    onMouseEnter={() => setPopUpPrivacy(true)}
                                    onMouseLeave={() => setPopUpPrivacy(false)}
                                  />
                                </div>
                                <div className={`${popUpPrivacy ? styles.message : styles.noMessage}`}>
                                  <div className={`  ${styles.freccetta} `}></div>
                                  <div className={`${styles.infoActions} text p-4`}>
                                    <i>
                                      This option allows you to keep your entry hidden from the other participants until the start of the Community Vote. You can activate this
                                      option only if you publish your project within the first half of the Upload phase. This option is not available for private gallery contests.
                                    </i>
                                  </div>
                                </div>
                              </div>
                              <div className={`col-12 col-md-10  ${popUpPrivacy && styles.noMessage}`}>
                                <input
                                  type="radio"
                                  className="  align-middle me-4 "
                                  checked={!designPrivacy}
                                  disabled={!hiddenOption()}
                                  onClick={() => hiddenOption() && setDesignPrivacy(false)}
                                />
                                <span className={` me-5 text ${props.contest?.isPrivate === true ? 'color-dark_grey_70' : ''}`}>Visible</span>
                                &nbsp;
                                <input
                                  type="radio"
                                  className=" align-middle me-4 "
                                  checked={designPrivacy}
                                  disabled={!hiddenOption()}
                                  onClick={() => hiddenOption() && setDesignPrivacy(true)}
                                />
                                <span className={` me-5 text ${props.contest?.isPrivate === true ? 'color-dark_grey_70' : ''}`}>Hidden</span>
                              </div>
                            </div>
                            <div className="row mb-5">
                              <div className="col-12 col-md-2 text ps-1 fw-bold mb-3 d-flex flex-row ps-md-1 ps-2 " style={{ zIndex: '10', position: 'relative' }}>
                                <div className="col-11">
                                  Abstract <span className="color-dark_red">*</span>
                                </div>
                                <div className=" ml-auto">
                                  <FontAwesomeIcon
                                    icon={faCircleQuestion as IconProp}
                                    className=" text color-blue me-0 "
                                    onMouseEnter={() => setPopUpAbstract(true)}
                                    onMouseLeave={() => setPopUpAbstract(false)}
                                  />
                                  <div className={`${popUpAbstract ? styles.message : styles.noMessage}`}>
                                    <div className={`  ${styles.freccetta} `}></div>
                                    <div className={`${styles.infoActions} text p-4`}>
                                      <i>Explain in some words your project</i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 col-md-10 text-center position-relative ">
                                <textarea
                                  placeholder="Explain in some world your project"
                                  // required={true}
                                  {...register('abstract', {
                                    onChange: handleAbstract,

                                    maxLength: 250,
                                  })}
                                />

                                <div className="row ">
                                  <div className="col-sm"></div>
                                  <div className="col-sm">
                                    {abstractError.current && (
                                      <span className=" d-flex flex-row justify-content-center align-items-center color-dark_red">
                                        <FontAwesomeIcon icon={faCircleExclamation as IconProp} className=" text me-0" /> &nbsp; Please provide an &nbsp;<b>Abstract</b>
                                      </span>
                                    )}
                                  </div>
                                  <div className="col-sm d-flex justify-content-end">{<span>{designAbstractLength}/250</span>}</div>
                                </div>
                              </div>
                            </div>

                            <div className="row mb-5 ">
                              <div className="col-12 col-md-2 text fw-bold mb-3 ps-md-1 ps-2 ">
                                <div className=" d-flex justify-content-between " style={{ zIndex: '10', position: 'relative' }}>
                                  <div className="col-11">Description</div>

                                  <div className="ml-auto ">
                                    <FontAwesomeIcon
                                      icon={faCircleQuestion as IconProp}
                                      className=" text color-blue me-0 "
                                      onMouseEnter={() => setPopUpDescription(true)}
                                      onMouseLeave={() => setPopUpDescription(false)}
                                    />
                                  </div>
                                  <div className={`${popUpDescription ? styles.message : styles.noMessage}`}>
                                    <div className={`  ${styles.freccetta} `}></div>
                                    <div className={`${styles.infoActions} p-4 text`}>
                                      <i>
                                        Describe your design in a deep way. Tell us the reason of your choice, motivations and everything you think could be useful to understand
                                        your project
                                      </i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 col-md-10">
                                <textarea
                                  {...register('description')}
                                  placeholder="Describe your design in a deep way. Tell us the reason of you choice, motivation and everything you think coul be uwseful to understanf your project"
                                />
                              </div>
                            </div>

                            <div className={`row  ${!coverError.current && 'mb-5'}`}>
                              <div className="col-12 col-md-2 text fw-bold mb-3 ps-md-1 ps-2 ">
                                <div className=" d-flex justify-content-between " style={{ zIndex: '10', position: 'relative' }}>
                                  <div className="col-11 ">
                                    Cover Image <br />
                                    file <span className="color-dark_red">*</span>
                                  </div>
                                  <div className="ml-auto">
                                    <FontAwesomeIcon
                                      icon={faCircleQuestion as IconProp}
                                      className=" text color-blue me-0 "
                                      onMouseEnter={() => setPopUpCover(true)}
                                      onMouseLeave={() => setPopUpCover(false)}
                                    />
                                    <div className={`${popUpCover ? styles.message : styles.noMessage}`}>
                                      <div className={`  ${styles.freccetta} `}></div>
                                      <div className={`${styles.infoActions} text p-4`}>
                                        <i>NO TEXT </i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className={`col-12 col-md-10 px-2  d-flex flex-column align-items-center justify-content-center  ${styles.uploadCoverContainer}`}>
                                {/* <label
                              htmlFor="images"
                              className={`${styles.dropcontainercoverimages} d-flex position-relative flex-column justify-content-center align-items-center text-center px-5`}
                            > */}
                                <Dropzone onDrop={onDropCover} accept={accept} maxSize={31457280}>
                                  {({ getRootProps, getInputProps }) => (
                                    <section className={`${styles.dropzone} bg-light d-flex align-items-center  justify-content-center`}>
                                      <div className="col-12 d-flex h-100 flex-column align-items-center justify-content-center text-center" {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <span>
                                          Drag and drop an image or <span className="color-blue">choose a file</span> from your computer <br />
                                          (allowed formats: .jpg, .png)
                                        </span>
                                      </div>
                                    </section>
                                  )}
                                </Dropzone>
                              </div>
                              <div className={`d-flex  ${coverError.current && 'mb-5'} `}>
                                <div className="col-2 "></div>
                                <div className="col-10   d-flex flex-row justify-content-center">
                                  {coverError.current && (
                                    <span className="color-dark_red d-flex align-items-center ">
                                      <FontAwesomeIcon icon={faCircleExclamation as IconProp} className=" text me-0" /> &nbsp;Cover image is required
                                    </span>
                                  )}
                                  {/* <span className="color-dark_red ">Cover image is required</span> */}
                                </div>
                              </div>
                            </div>

                            <div className={`row  mb-7 ${uploadedCoverImage ? '' : 'd-none'}`}>
                              <div className="col-12 col-md-2 text fw-bold mb-3">
                                <div className=" d-flex justify-content-between "></div>
                              </div>
                              <div className={`col-12 col-md-10  d-flex flex-column align-items-center justify-content-center `}>
                                {uploadedCoverImage && (
                                  <div className=" d-flex" style={{ width: '100%' }}>
                                    <div className={`${styles.previewImg}`}>
                                      <img src={URL.createObjectURL(uploadedCoverImage)} className={`${styles.previewImg}`} />
                                    </div>
                                    <div className="d-flex flex-row w-100 align-items-center justify-content-center ">
                                      <span className="text-xl">{uploadedCoverImage.name}</span>
                                    </div>
                                    <div className=" d-flex align-items-center justify-content-end ">
                                      <div className={` ${styles.removeButton}`} onClick={() => setUploadedCoverImage(undefined)}>
                                        <FontAwesomeIcon icon={faXmark as IconProp} className=" text-16 me-0" />
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="row mb-5">
                              <div className="col-12 col-md-2 text fw-bold mb-3 ps-md-1 ps-2">
                                <div className=" d-flex justify-content-between " style={{ zIndex: '10', position: 'relative' }}>
                                  <div className="col-11">Image file</div>
                                  <div className="ml-auto">
                                    <FontAwesomeIcon
                                      icon={faCircleQuestion as IconProp}
                                      className=" text color-blue me-0 "
                                      onMouseEnter={() => setPopUpImages(true)}
                                      onMouseLeave={() => setPopUpImages(false)}
                                    />
                                    <div className={`${popUpImages ? styles.message : styles.noMessage}`}>
                                      <div className={`  ${styles.freccetta} `}></div>
                                      <div className={`${styles.infoActions} text p-4`}>
                                        <i>NO TEXT </i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className={`col-12 col-md-10 px-2  ${uploadedImages.length > 0 ? '' : 'mb-5'} d-flex flex-column align-items-center justify-content-center  ${
                                  styles.uploadImagesContainer
                                }`}
                              >
                                {/* <label
                              htmlFor="images"
                              className={`${styles.dropcontainercoverimages} d-flex position-relative flex-column justify-content-center align-items-center text-center px-5`}
                            > */}
                                <Dropzone onDrop={onDrop} accept={accept} maxSize={31457280}>
                                  {({ getRootProps, getInputProps }) => (
                                    <section className={`${styles.dropzone} bg-light d-flex align-items-center justify-content-center`}>
                                      <div className="col-12  h-100 d-flex flex-column align-items-center justify-content-center text-center" {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <span>
                                          Drag and drop an image or <span className="color-blue">choose a file</span> from your computer <br />
                                          (allowed formats: .jpg, .png). Max 4 images.
                                        </span>
                                      </div>
                                    </section>
                                  )}
                                </Dropzone>
                              </div>
                            </div>

                            <div className={`row mb-5 ${uploadedImages.length > 0 ? '' : 'd-none'} `}>
                              <div className="col-12 col-md-2 text fw-bold mb-3">
                                <div className=" d-flex justify-content-between "></div>
                              </div>
                              <div className={`col-12 col-md-10 mb-5 d-flex flex-column align-items-center justify-content-center  `}>
                                {uploadedImages?.map((item, index) => (
                                  <div className="mt-2 d-flex" style={{ width: '100%' }}>
                                    <div className={`${styles.previewImg}`}>
                                      <img src={URL.createObjectURL(item)} key={index} className={`${styles.previewImg}`} style={{ height: '100%' }} />
                                    </div>
                                    <div className="d-flex flex-row w-100 align-items-center justify-content-center ">
                                      <span className="text-xl">{item.name}</span>
                                    </div>
                                    <div className=" d-flex align-items-center justify-content-end ">
                                      <div
                                        className={`${styles.removeButton}`}
                                        // style={{ borderRadius: '8px', height: '50px', width: '50px' }}
                                        onClick={() => deleteImg(index)}
                                      >
                                        <FontAwesomeIcon icon={faXmark as IconProp} className=" text-16 me-0" />
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>

                            <div className="row mb-5">
                              <div className="col-12 col-md-2 text fw-bold mb-3 ps-md-1 ps-2">
                                <div className=" d-flex justify-content-between " style={{ position: 'relative', zIndex: 10 }}>
                                  <div className="col-11">Archive file</div>
                                  <div className=" ml-auto">
                                    <FontAwesomeIcon
                                      icon={faCircleQuestion as IconProp}
                                      className=" text color-blue me-0 "
                                      onMouseEnter={() => setPopUpArchive(true)}
                                      onMouseLeave={() => setPopUpArchive(false)}
                                    />
                                  </div>
                                  <div className={`${popUpArchive ? styles.message : styles.noMessage}`}>
                                    <div className={`  ${styles.freccetta} `}></div>
                                    <div className={`${styles.infoActions} p-4 text`}>
                                      <i>
                                        use the archive to upload client required files and/or extra content <br /> allowed format: .zip; max file size: 100 MB
                                      </i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 col-md-10 mb-5">
                                <label htmlFor="archive" className={`${styles.dropcontainerarchive} d-flex position-relative flex-column justify-content-center px-5`}>
                                  <label className="drop-title  fw-normal">
                                    <input
                                      type="file"
                                      id="archive"
                                      accept=".zip"
                                      className={`${styles.inputfile}`}
                                      required={false}
                                      multiple={true}
                                      onInput={handleArchiveSelect}
                                      // onChange={handleArchiveSelect}
                                    />
                                    <span className="color-primary fw-normal" style={{ color: '#7997c5', cursor: 'pointer' }}>
                                      Choose a file&nbsp;
                                    </span>
                                    <span>from your computer (allowed format: .zip max file size: 100 MB).</span>
                                  </label>
                                </label>
                                {archiveFile && (
                                  <div className=" d-flex mt-5 justify-content-between align-items-center">
                                    <div className="d-flex w-100 align-items-center">
                                      <div className={`bg-blue ${styles.filezip} `}>
                                        <FontAwesomeIcon icon={faFileZipper as IconProp} className=" text me-0 color-light" style={{ fontSize: '2rem' }} />
                                      </div>
                                    </div>
                                    <div className=" text-center">{archiveFile.name} </div>
                                    <div className="d-flex w-100 align-items-center justify-content-end ">
                                      <div
                                        className={`${styles.removeButton}`}
                                        // style={{ borderRadius: '8px', height: '50px', width: '50px' }}
                                        onClick={() => setArchiveFile(undefined)}
                                      >
                                        <FontAwesomeIcon icon={faXmark as IconProp} className=" text-16 me-0" />
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="row mb-5">
                              <div className="col-12 col-md-2 text fw-bold mb-3 ps-md-1 ps-2">
                                <div className=" d-flex justify-content-between " style={{ position: 'relative', zIndex: 10 }}>
                                  <div className="col-11">Video file</div>
                                  <div className=" ml-auto">
                                    <FontAwesomeIcon
                                      icon={faCircleQuestion as IconProp}
                                      className=" text color-blue me-0 "
                                      onMouseEnter={() => setPopUpVideo(true)}
                                      onMouseLeave={() => setPopUpVideo(false)}
                                    />
                                  </div>
                                  <div className={`${popUpVideo ? styles.message : styles.noMessage}`}>
                                    <div className={`  ${styles.freccetta} `}></div>
                                    <div className={`${styles.infoActions} p-4 text`}>
                                      <i>
                                        use the archive to upload client required files and/or extra content <br /> allowed format: .zip; max file size: 50 MB
                                      </i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 col-md-10 mb-5">
                                <label className={`${styles.dropcontainerarchive} d-flex position-relative flex-column justify-content-center px-5`}>
                                  <label className="drop-title  fw-normal">
                                    <input type="file" id="video" accept=".zip" className={`${styles.inputfile}`} required={false} multiple={false} onChange={handleVideoSelect} />
                                    <span className="color-primary fw-normal" style={{ color: '#7997c5', cursor: 'pointer' }}>
                                      Choose a file&nbsp;
                                    </span>
                                    <span>from your computer (allowed format: .zip max file size: 50 MB).</span>
                                  </label>
                                </label>
                                {videoFile && (
                                  <div className=" d-flex mt-5 justify-content-between align-items-center">
                                    <div className="d-flex w-100 align-items-center">
                                      <div className={`bg-blue ${styles.filezip} `}>
                                        <FontAwesomeIcon icon={faFileVideo as IconProp} className=" text me-0 color-light" style={{ fontSize: '2rem' }} />
                                      </div>
                                    </div>
                                    <div className=" text-center">{videoFile.name} </div>
                                    <div className="d-flex w-100 align-items-center justify-content-end ">
                                      <div className={`${styles.removeButton}`} onClick={() => setVideoFile(undefined)}>
                                        <FontAwesomeIcon icon={faXmark as IconProp} className=" text-16 me-0" />
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="row mb-5">
                              <div className="col-12 col-md-2 text fw-bold mb-3 ps-md-1 ps-2">
                                <div className=" d-flex justify-content-between " style={{ zIndex: '10', position: 'relative' }}>
                                  <div className="col-11">
                                    Keyword <span className="color-dark_red me-1"> * </span>
                                  </div>
                                  <div className="ml-auto">
                                    <FontAwesomeIcon
                                      icon={faCircleQuestion as IconProp}
                                      className=" text color-blue me-0 "
                                      onMouseEnter={() => setPopUpTags(true)}
                                      onMouseLeave={() => setPopUpTags(false)}
                                    />
                                    <div className={`${popUpTags ? styles.message : styles.noMessage}`}>
                                      <div className={`  ${styles.freccetta} `}></div>
                                      <div className={`${styles.infoActions} text p-4`}>
                                        <i>NO TEXT </i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 col-md-10 ">
                                <input
                                  type="text"
                                  value={tagString}
                                  style={{ height: '30px' }}
                                  {...register('tempTags', {
                                    onChange: (e) => tagstring(e.target.value),
                                  })}
                                />
                                {tagsError.current && (
                                  <span className=" d-flex text flex-row justify-content-center align-items-center color-dark_red">
                                    <FontAwesomeIcon icon={faCircleExclamation as IconProp} className=" text me-0" /> &nbsp; Please provide &nbsp; <b> keywords. </b>
                                  </span>
                                )}
                                {suggestionArray.length > 0 && tagStringCheck() && (
                                  <div className={`${styles.suggestionWrapper}`}>
                                    {suggestionArray.map((item: DSLTag, index: number) => (
                                      <div className={`text ${styles.suggestionRow}`} key={index} onClick={() => addTag(item.name)}>
                                        {item.name}
                                      </div>
                                    ))}
                                  </div>
                                )}
                                {/* <span className="  color-dark_grey_40">please separate each tag with spaces</span> */}

                                <div className="mt-3">
                                  {/* {console.log(tagsArray)} */}
                                  {tagsArray.map(
                                    (item: string, index: number) =>
                                      item !== '' && (
                                        <button disabled className="btn-tag" key={index}>
                                          <span>{item}</span>
                                        </button>
                                      )
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="row mb-5">
                              <div className="col-12 col-md-2 text fw-bold mb-3 ps-md-1 ps-2">
                                <div className="  d-flex justify-content-between " style={{ position: 'relative', zIndex: 10 }}>
                                  <div className="col-11">
                                    Agreement <span className="color-dark_red me-1"> * </span>
                                  </div>
                                  <div className=" ml-auto">
                                    <FontAwesomeIcon
                                      icon={faCircleQuestion as IconProp}
                                      className=" text color-blue me-0 "
                                      onMouseEnter={() => setPopUpAgreement(true)}
                                      onMouseLeave={() => setPopUpAgreement(false)}
                                    />
                                  </div>
                                  <div className={`${popUpAgreement ? styles.message : styles.noMessage}`}>
                                    <div className={`  ${styles.freccetta} `}></div>
                                    <div className={`${styles.infoActions} p-4 text`}>
                                      <i>no text</i>
                                    </div>
                                  </div>
                                  {/* <div className={`${agreementError.current ? styles.messageDown : styles.noMessageDown}`}>
                                <div className={`  ${styles.freccetta} `}></div>
                                <div className={`${styles.infoActions} text p-4`}>
                                  <div
                                    className="bg-warning d-flex flex-row align-items-center justify-content-center me-3"
                                    style={{ height: '30px', width: '30px' }}
                                  >
                                    <FontAwesomeIcon
                                      icon={faExclamation as IconProp}
                                      className="color-light "
                                      style={{ fontSize: '1.5rem', fontWeight: 'bolder' }}
                                    />
                                  </div>
                                  Seleziona questa casella se intendi procedere.
                                </div>
                              </div> */}
                                </div>
                              </div>
                              <div className="col-12 col-md-10 mb-5">
                                <div className="d-flex flex-row justify-content-start ">
                                  <input
                                    type="checkbox"
                                    checked={agreement}
                                    style={{ height: '30px', width: '30px' }}
                                    onChange={() => (console.log('ciao'), setAgreement(!agreement), (agreementError.current = false))}
                                  />
                                  <div className=" w-100 d-flex flex-row align-items-center justify-content-between">
                                    <label className=" ms-3 fw-normal">
                                      I'm over 18 and agree to the
                                      <a target="_blank" href={props.contest?.termsAttachment && props.contest.termsAttachment}>
                                        &nbsp; Contest agreement
                                      </a>
                                    </label>
                                    {agreementError.current && (
                                      <span className="d-flex flex-row align-items-center justify-content-center color-dark_red">
                                        <FontAwesomeIcon icon={faCircleExclamation as IconProp} className="me-1" />
                                        You must accept the conditions
                                        {/* <b className="ms-1">Contest Agreement</b> */}
                                      </span>
                                    )}
                                  </div>
                                </div>

                                {/* {agreementError.current && (
                              <span className=" d-flex text flex-row justify-content-center align-items-center color-dark_red">
                                <FontAwesomeIcon icon={faCircleExclamation as IconProp} className=" text me-0" /> &nbsp;
                                Please accept the &nbsp; <b> contest agreements. </b>
                              </span>
                            )} */}
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 col-md-3"></div>
                            <div className="col-12 col-md-3 mb-4 px-5 align-items-center ">
                              <div className="d-flex flex-row justify-content-center ">
                                {progress === 0 && (
                                  <button className={`btn-dark-blue rounded full`} onClick={() => showSaveDraftPopUp()}>
                                    Save draft
                                  </button>
                                )}
                                {progress !== 0 && !draft && <button className={`btn-dark-blue rounded full`}>Save draft</button>}
                                {progress !== 0 && draft && (
                                  <button className="btn-dark_grey_80 d-flex rounded full " disabled>
                                    <TailSpin height="15" width="15" color="#7997c5" wrapperStyle={{}} wrapperClass="me-2" visible={true} ariaLabel="tail-spin-loading" />
                                    &nbsp;
                                    <span>Uploading</span>
                                  </button>
                                )}
                              </div>
                            </div>
                            <div className="col-12 col-md-3 mb-4 px-5 align-items-center">
                              <div className="d-flex flex-row justify-content-center ">
                                {progress === 0 && (
                                  <button className="btn-blue rounded full" onClick={() => setUpload(true)}>
                                    Publish
                                  </button>
                                )}
                                {progress !== 0 && draft && <button className="btn-blue rounded full">Publish</button>}
                                {progress !== 0 && !draft && (
                                  <button className="btn-dark_grey_80 d-flex rounded full " disabled>
                                    <TailSpin height="15" width="15" color="#7997c5" wrapperStyle={{}} wrapperClass="me-2" visible={true} ariaLabel="tail-spin-loading" />
                                    &nbsp;
                                    <span>Uploading</span>
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </>
                  ) : (
                    props.contest?.contestType !== 'Co-creation' && <UploadContestNaming />
                  )}
                  {props.contest?.contestType === 'Co-creation' &&
                    cocreationContests &&
                    cocreationContests.map((item: CoCreationContest, index: number) => (
                      <>
                        <div className="px-2">
                          <UploadContestCoCreation contest={item} />
                        </div>
                      </>
                    ))}
                </>
              )}
            </div>
          </div>

          <PopUpSaveDraft
            popup={popupSaveDraft}
            close={() => (setPopUpSaveDraft(false), setProgress(0), console.log(formData.current, uuidString.current))}
            accept={() => (console.log('accept'), setPopUpSaveDraft(false), setProgress(0), saveDraft())}
          />
        </main>
      </div>
    );
};

export default UploadContestWLF;
