import React from 'react';
import { bgCardComponent, RowProps, SmallCardComponent, SmallCardSection, SmallHalfImgProps } from '../../../global';
import { bgcomponent, pathStrapiImage } from '../../../helpers/utils';
import SplitSectionImage from '../../SplitSectionImage/SplitSectionImage';
import CardSmall from '../../CardSmall/CardSmall';
import HorizontalSection from '../../HorizontalSection/HorizontalSection';

function RowComponent(props: RowProps) {
  return (
    <>
      <HorizontalSection bgColor={props.bgcolor ? props.bgcolor : '#fff'} key={props.id}>
        <div className="container py-7 px-0">
          <div className="">
            <div className="row ">
              <div className=" col-12  col-md-5 col-lg-3 ms-3  ">
                <a href={props.link && props.link} target={`${props.target ? props.target : '_self'}`}>
                  <img
                    className="  img-270"
                    src={pathStrapiImage(props.image && props.image.data.attributes.url)}
                    alt="Placeholder"
                  />
                </a>
              </div>
              <div className=" col-10  col-md-7 col-lg-8 ">
                {props.title && (
                  <div className={`row ${props.titlealign ? `text-${props.titlealign}` : `text-start`}`}>
                    <span
                      className={` bold-title mb-5 ms-md-5  ms-lg-4 color-${props.titlecolor}`}
                      style={{ transform: 'translateY(-7px)' }}
                    >
                      {props.title}
                    </span>
                  </div>
                )}
                {props.boldtext && (
                  <div className={`row  w-75 ${props.boldtextalign ? `text-${props.boldtextalign} ` : `text-start`}  `}>
                    <span className={`sub-title mb-5  ms-md-5 ms-lg-4 color-${props.boldtextcolor}`}>
                      {props.boldtext}
                    </span>
                  </div>
                )}
                {props.text && (
                  <div className={`row  ${props.textalign ? `text-${props.textalign}` : `text-start`} `}>
                    <span className={`text-16 w-100 ms-md-5 ms-lg-4 color-${props.textcolor}`}>{props.text}</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </HorizontalSection>
    </>
  );
}

export default RowComponent;
