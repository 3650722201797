import React, { useEffect, useState } from 'react';
import styles from './SearchNavigation.module.scss';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { useStoreState } from 'pullstate';
import resetFilter from '../../assets/Reset-Filter.svg';
import {
  getContestCategories,
  getContestSearchPageNumber,
  getContestSearchPageNumberLimit,
  getSearchResultContest,
  getSearchResultContestCopy,
} from '../../store/Selectors';
import DslStore, { setContestSearchPageNumber, setContestSorted, setSearchResultContest, setSearchResultContestFiltered } from '../../store/DslStore';
import { Contest } from '../../global';
import { DropDownContestCategory, DropDownContestSort } from '../Forms/DropDown/DropDownContest';
import { LayoutContestSwitch } from '../Reusable/LayoutSwitch';

type IProjecNavigation = {
  onChangeLayout?: (val: number) => void;
};

type PaginationSwitchProps = {
  onChangePage?: (val: number) => void;
};

export const ContestSearchPaginationSwitch: React.FC<PaginationSwitchProps> = ({ onChangePage = () => {} }) => {
  const pageNumber = useStoreState(DslStore, getContestSearchPageNumber);
  const limit = useStoreState(DslStore, getContestSearchPageNumberLimit);

  //   const pageNumberLimit = Math.ceil(contests.length / contestPerPage);
  const [array, setArray] = useState<Array<string>>(['1', '2', '3', '...', `${limit}`]);

  useEffect(() => {
    console.log('cambiato numero page');
    // setArray([1, 2, 3, 4, 5]);

    if (limit < 5) {
      let numbers: string[] = [];
      for (var i = 0; i < limit ; i++) {
        let ciccio = i + 1;
        numbers.push(ciccio.toString());
      }
      setArray(numbers);
    }
    if (pageNumber < 3 && limit >= 5) {
      setArray([`1`, `2`, `3`, `...`, `${limit}`]);
    } else if (pageNumber >= 3 && pageNumber <= limit - 3 && limit >= 5) {
      setArray([`${pageNumber - 2}`, `${pageNumber - 1}`, `${pageNumber}`, `...`, `${limit}`]);
    } else if (pageNumber === limit - 2 && limit >= 5) {
      setArray([`${pageNumber - 2}`, `${pageNumber - 1}`, `${pageNumber}`, `${pageNumber + 1}`, `${limit}`]);
    } else if (pageNumber === limit - 1 && limit >= 5) {
      setArray([`${pageNumber - 3}`, `${pageNumber - 2}`, `${pageNumber - 1}`, `${pageNumber}`, `${limit}`]);
    } else if (pageNumber === limit && limit >= 5) {
      setArray([`${pageNumber - 4}`, `${pageNumber - 3}`, `${pageNumber - 2}`, `${pageNumber - 1}`, `${pageNumber}`]);
    }
  }, [limit, pageNumber]);

  const handleChange = (value: number) => {
    console.log(value);
    if (value > 0 && value <= limit) {
      onChangePage(value);
      if (pageNumber >= 3 && pageNumber <= limit - 2) {
        setArray([`${pageNumber - 2}`, `${pageNumber - 1}`, `${pageNumber}`, `${pageNumber + 1}`, `${pageNumber + 2}`]);
      }
    } else {
      console.log('0 o max++');
    }
  };

  return (
    <div className={`${styles.paginationSwitch}  d-flex align-items-center justify-content-center bg-light py-2 px-2 `}>
      <button className="btn-icon" onClick={() => handleChange(pageNumber - 1)}>
        <FontAwesomeIcon icon={faAngleLeft as IconProp} className="btn-icon active " />
      </button>

      {/* <button className={'btn-icon active'} onClick={() => handleChange(pageNumber - 1)}>
        <strong> &lt; </strong>
      </button> */}

      {array.map((item: string, index: number) => (
        <button
          key={index}
          className={pageNumber.toString() === item ? 'btn-icon active' : 'btn-icon'}
          onClick={() => handleChange(Number(item))}
        >
          <small>{item}</small>
        </button>
      ))}

      {/* <button className={'btn-icon active'} onClick={() => handleChange(pageNumber + 1)}>
        <strong> &gt; </strong>
      </button> */}

      <button className="btn-icon" onClick={() => handleChange(pageNumber + 1)}>
        <FontAwesomeIcon icon={faAngleRight as IconProp} className="btn-icon active " />
      </button>

      {/* 
      <button className={"btn-icon active"} 
      //  onClick={() => handleClick(1)}
      > <b> &lt; </b> </button>
       <button className={pageNumber == 1 ? "btn-icon active" : "btn-icon"} 
      //  onClick={() => handleClick(1)}
      >
        1
      </button>
      <button className={pageNumber == 2 ? "btn-icon active" : "btn-icon"} 
      // onClick={() => handleClick(2)}
      >
        2
      </button>
      <button className={pageNumber == 3 ? "btn-icon active" : "btn-icon"} 
      // onClick={() => handleClick(3)}
      >
        3
      </button>
      <button className={pageNumber == 4 ? "btn-icon active" : "btn-icon"} 
      // onClick={() => handleClick(4)}
      >
        4
      </button>
      <button className={pageNumber == 5 ? "btn-icon active" : "btn-icon"} 
      // onClick={() => handleClick(5)}
      >
        5
      </button>  */}

      {/* <button className="btn-icon">
         <FontAwesomeIcon icon={faAngleRight as IconProp} className="text" />
       </button> */}

      {/* <Pagination count={numberOfPages} page={pageNumber} color='primary'  hideNextButton={numberOfPages > 1 ? false : true} hidePrevButton={numberOfPages > 1 ? false : true} size="small" onChange={handleChange} /> */}
    </div>
  );
};

let filterStatus = [
  {
    label: 'Active',
    sort: 'active',
    value: '0',
  },
  {
    label: 'Closed',
    sort: 'closed',
    value: '1',
  },
];

let filterSort = [
  {
    label: 'Name',
    sort: 'name',
    value: '1',
  },
  {
    label: 'Date',
    sort: 'date',
    value: '2',
  },

  {
    label: 'Entries',
    sort: 'entries',
    value: '3',
  },
  {
    label: 'Award',
    sort: 'award',
    value: '4',
  },
];

interface IDropDownItem {
  label: string;
  sort: string;
  value: string;
}

const ContestSearchNavigation: React.FC<IProjecNavigation> = (props) => {
  const contests = useStoreState(DslStore, getSearchResultContestCopy);
  const categories = useStoreState(DslStore, getContestCategories);
  const [reload, setReload] = useState(false);
  const [categoriesFilter, setCategoriesFilter] = useState<IDropDownItem[]>([]);
  const [statusSort, setStatusSort] = useState('');
  const [categorySort, setCategorySort] = useState('');
  const [sortBySort, setSortBySort] = useState('');
  const [descs, setDescs] = useState(false);

  useEffect(() => {
    let newItems: IDropDownItem[] = [];
    if (categories) {
      console.log(categories);
      categories.map((item, index: number) => {
        let name = item.name === 'Link to Other' ? 'Partnership' : item.name;

        if (!newItems.includes({ label: `${name}`, sort: `${item.name}`, value: `${index}` })) {
          newItems.push({ label: `${name}`, sort: `${item.name}`, value: `${index}` });
          // setTypologyFilter(newItems);
          // console.log(newItems);
        }
      });
      setCategoriesFilter(newItems);
    }
  }, [categories]);

  const sortContests = () => {
    let sorted: Contest[] = [...contests];
    console.log(statusSort);
    console.log(categorySort);
    console.log(sortBySort);
    console.log(descs);
    if (statusSort) {
      if (statusSort === 'active') {
        let contestsActive: Contest[] = [];
        sorted.map((item) => !item.isClosed && contestsActive.push(item));
        sorted = contestsActive;
      } else {
        let contestClosed: Contest[] = [];
        sorted.map((item) => item.isClosed && contestClosed.push(item));
        sorted = contestClosed;
      }
    }
    if (categorySort) {
      let categoriesContests: Contest[] = [];
      sorted.map((item) => item.category === categorySort && categoriesContests.push(item));
      sorted = categoriesContests;
    }
    if (sortBySort) {
      if (sortBySort === 'name') {
        if (descs) {
          sorted.sort((a, b) =>
            b.name.toLowerCase() > a.name.toLowerCase() ? 1 : a.name.toLowerCase() > b.name.toLowerCase() ? -1 : 0
          );
        } else {
          sorted.sort((a, b) =>
            b.name.toLowerCase() > a.name.toLowerCase() ? -1 : a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0
          );
          // setContestInspirations(sorted);
        }
      }
      if (sortBySort === 'date') {
        if (descs) {
          sorted.sort((a, b) => {
            return b.creationDate.localeCompare(a.creationDate);
          });
        } else {
          sorted.sort((a, b) => {
            return a.creationDate.localeCompare(b.creationDate);
          });
        }
      }
      if (sortBySort === 'entries') {
        if (descs) {
          sorted.sort((a, b) => (b.entries > a.entries ? 1 : a.entries > b.entries ? -1 : 0));
        } else {
          sorted.sort((a, b) => (b.entries > a.entries ? -1 : a.entries > b.entries ? 1 : 0));
        }
      }
      if (sortBySort === 'award') {
        if (descs) {
          sorted.sort((a, b) => (b.prices > a.prices ? 1 : a.prices > b.prices ? -1 : 0));
        } else {
          sorted.sort((a, b) => (b.prices > a.prices ? -1 : a.prices > b.prices ? 1 : 0));
        }
      }
    }
    setSearchResultContestFiltered(sorted);
  };

  useEffect(() => {
    // isLiked();
    sortContests();
  }, [sortBySort, statusSort, categorySort, descs]);

  return (
    <div className="row py-4 mt-4 px-0">
      <div
        className="col-12  col-lg-9 col-xl-9  d-flex flex-column  flex-lg-row justify-content-between mb-4 mb-md-0"
        // style={{ height: '3rem' }}
      >
        <div className="me-0 me-md-5 w-100  mb-lg-0 mb-sm-4">
          <DropDownContestCategory
            label="Status"
            reload={reload}
            options={filterStatus}
            onSelect={(option) => option.sort && setStatusSort(option.sort)}
            // thin
          />
        </div>
        <div className="me-0 me-md-5 w-100 mb-lg-0 mb-sm-4">
          <DropDownContestCategory
            label="Category"
            reload={reload}
            options={categoriesFilter}
            onSelect={(option) => option.sort && setCategorySort(option.sort)}
            thin
          />
        </div>
        <div className="w-100 me-md-5 mb-lg-0 mb-sm-4 ">
          <DropDownContestSort
            label="Sort by"
            reload={reload}
            options={filterSort}
            onSelect={(option) => option.sort && setSortBySort(option.sort)}
            descs={(descs) => setDescs(descs)}
            thin
          />
        </div>
        <div className=" d-flex flex-row align-items-center justify-content-center h-100 w-100">
          <div
            // className="btn-blue rounded"
            className=" d-flex flex-row align-items-center justify-content-center btn-reset-blue   "
            onClick={() => (
              setReload(!reload), setContestSorted(contests), setSortBySort(''), setCategorySort(''), setStatusSort('')
            )}
            style={{ width: '3rem', borderRadius: '8px' }}
          >
            <img src={resetFilter} style={{ height: '25px' }} />
          </div>
          {/* <div className="btn-blue rounded" onClick={() => (setReload(!reload), setPortfolioDesign(correctDesignCopy))}>
            reset
          </div> */}
        </div>
      </div>
      <div
        className={`col-12  col-md-12  mb-sm-5 mb-lg-0 col-lg-3 col-xl-3  d-flex  justify-content-sm-start justify-content-lg-end  `}
      >
        <div
          className={`  col-lg-9 me-lg-2   col-md-3 col-sm-4    mb-lg-0  d-flex  justify-content-sm-start justify-content-xl-center   `}
        >
          <ContestSearchPaginationSwitch onChangePage={setContestSearchPageNumber} />
        </div>
        <div className={`col-lg-2  col-md-9 col-sm-8   mb-lg-0  d-flex  justify-content-sm-end`}>
          <div className=" d-lg-block ">
            <LayoutContestSwitch onChangeLayout={props.onChangeLayout} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContestSearchNavigation;
