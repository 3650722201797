import React, { useEffect, useState } from 'react';

//Header Data

import designDefaultImage from '../../assets/Design.png';
import designNamingDefaultImage from '../../assets/Naming.png';

import { getCoCreationContestGallery, getCoCreationContests, getContestGallery, getGalleryPageNumber, getLayoutGallery } from '../../store/Selectors';
import DslStore, { setGalleryPageNumber, setLayoutGallery } from '../../store/DslStore';
import { useStoreState } from 'pullstate';

import GalleryCard from '../../components/Contest/GalleryCard/GalleryCard';
import { finalString, startDateString, numberVotes } from '../../helpers/utils';
import GalleryNavigation, { PaginationSwitch } from '../../components/Contest/GalleryNavigation/GalleryNavigation';
import GalleryRow from '../../components/Contest/GalleryRow/GalleryRow';
import { Contest, GalleryDesign } from '../../global';

import axiosHelper from '../../helpers/axiosHelper';
import { useNavigate, useParams } from 'react-router-dom';
import { getContestDesign } from '../../store/Selectors';
import { set } from 'react-hook-form';
import GalleryNavigationWLF from '../../components/Contest/GalleryNavigation/GalleryNavigationWLF';
import useSWR from 'swr';
import GalleryCardWLF from '../../components/Contest/GalleryCard/GalleryCardWLF';
import GalleryRowWLF from '../../components/Contest/GalleryRow/GalleryRowWLF';

interface Props {
  contest: Contest;
  breadcrumbs: (breadcrumbs: string) => void;
}

const GalleryWLF: React.FC<Props> = ({ contest }) => {
const [numberOfPages, setNumberOfPages]=useState(1);
  const [layoutGallery,setLayoutGallery] = useState(1);
  const [pageNumber, setPageNumber]=useState(1);
  const [designs, setDesigns] = useState<GalleryDesign[]>([]);
  // const designs = useStoreState(DslStore, getContestGallery);
  const cocreationContests = useStoreState(DslStore, getCoCreationContests);
  const cocreationDesigns = useStoreState(DslStore, getCoCreationContestGallery);
  const [visibleDesign, setVisibleDesign] = useState<GalleryDesign[]>([]);
  const [visibleCoCreationDesign, setVisibleCoCreationDesign] = useState<GalleryDesign[]>([]);
  const { data, error, isLoading } = useSWR('id|'+contest.id, axiosHelper.galleryFetcher);

  useEffect(() => {
    if (data) {
      setDesigns(data);
    }
  },[data]);

  useEffect(() => {
    console.log(designs);
    setNumberOfPages(Math.ceil(designs.length / 20));
  }, [designs]);


  const arraysfill = () => {
    let chunkSize = 20;
    let startchunk = (pageNumber - 1) * chunkSize;
    let tempArray: GalleryDesign[] = [];
    let correctArray: GalleryDesign[] = [];
    let chunk: GalleryDesign[] = [];

    for (let i = 0; i < designs.length; i += chunkSize) {
      chunk = designs.slice(startchunk, startchunk + chunkSize);
      // console.log('chunk');
      // console.log(chunk);
    }
    for (let i = 0; i < chunkSize; i++) {
      // console.log(chunk[i]);
      // console.log(chunk[i] === undefined);
      if (chunk[i] !== undefined) {
        tempArray.push(chunk[i]);
      } else {
        break;
      }
    }
    // console.log("array1:  ");
    console.log(tempArray);

    setVisibleDesign(tempArray);
  };

  const arraysfillCoCreation = () => {
    let chunkSize = 20;
    let startchunk = (pageNumber - 1) * chunkSize;
    let tempArray: GalleryDesign[] = [];
    let chunk: GalleryDesign[] = [];

    for (let i = 0; i < cocreationDesigns.length; i += chunkSize) {
      chunk = cocreationDesigns.slice(startchunk, startchunk + chunkSize);
      // console.log('chunk');
      // console.log(chunk);
    }
    for (let i = 0; i < chunkSize; i++) {
      // console.log(chunk[i]);
      // console.log(chunk[i] === undefined);
      if (chunk[i] !== undefined) {
        tempArray.push(chunk[i]);
      } else {
        break;
      }
    }
    // console.log("array1:  ");
    console.log(tempArray);

    setVisibleCoCreationDesign(tempArray);
  };

  useEffect(() => {
    contest?.contestType === 'Co-creation' && arraysfillCoCreation();
  }, [cocreationDesigns]);

  useEffect(() => {
    contest?.contestType !== 'Co-creation' && arraysfill();
    // contest?.contestType === 'Co-creation' && arraysfillCoCreation();
    console.log(designs);
  }, [designs]);

  useEffect(() => {
    console.log(pageNumber);

    console.log(visibleDesign);
    // fillContests(pageNumber -1);
    contest?.contestType !== 'Co-creation' && arraysfill();
    contest?.contestType === 'Co-creation' && arraysfillCoCreation();
  }, [pageNumber]);

  useEffect(() => {
    console.log(cocreationDesigns.length);
  }, []);

  return (
    <div className="bg-light-gray">
      <main className={`container pt-5 mb-5 px-sm-0 overflow-hidden`}>
        <div className="row ">
          <GalleryNavigationWLF designs={designs} contest={contest} numberOfPages={numberOfPages} onChangeLayout={setLayoutGallery} />
        </div>
        <div className="row me-2 ">
          {layoutGallery === 1
            ? visibleDesign.length > 0
              ? visibleDesign.map(
                  (aPublicDesign: GalleryDesign, index: number) => (
                    console.log(aPublicDesign),
                    (
                      <div className=" col-12 col-md-6 col-lg-4 col-xl-3">
                        <GalleryCardWLF
                          key={index}
                          contest={contest}
                          design={aPublicDesign}
                          title={aPublicDesign.name}
                          typology={aPublicDesign.contest.contestType.name}
                          category={aPublicDesign.contest.category ? aPublicDesign.contest.category.name : ''}
                          creationDate={startDateString(aPublicDesign.uploadDate)}
                          username={aPublicDesign.designer.username}
                          award={contest?.name}
                          awarded={aPublicDesign.hasAwards}
                          firstname={aPublicDesign.designer.firstname}
                          lastname={aPublicDesign.designer.lastName}
                          private={aPublicDesign.contest.isPrivate}
                          image={
                            aPublicDesign.contest.contestType.name !== 'Naming'
                              ? aPublicDesign.image1Attachment
                                ? aPublicDesign.image1Attachment.s3Path
                                : designDefaultImage
                              : designNamingDefaultImage
                          }
                          votes={aPublicDesign.votesCount}
                          idUser={aPublicDesign.designer.id}
                          idContest={aPublicDesign.contest.id}
                          idDesign={aPublicDesign.id}
                          type={aPublicDesign.contest.contestType.name}
                          hidden={aPublicDesign.isHidden}
                          idClient={aPublicDesign.contest.client.id}
                          startDate={aPublicDesign.contest.startUploadDate}
                          endUploadDate={aPublicDesign.contest.endUploadDate}
                        />
                      </div>
                    )
                  )
                )
              : visibleCoCreationDesign.length > 0 &&
                visibleCoCreationDesign.map(
                  (aPublicDesign: GalleryDesign, index: number) => (
                    (
                      <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                        <GalleryCardWLF
                          key={index}
                          contest={contest}
                          design={aPublicDesign}
                          title={aPublicDesign.name}
                          typology={contest?.contestType}
                          category={finalString(contest!.contestCategory)}
                          creationDate={startDateString(aPublicDesign.uploadDate)}
                          username={aPublicDesign.designer.username}
                          award={contest?.name}
                          awarded={false}
                          firstname={aPublicDesign.designer.firstname}
                          lastname={aPublicDesign.designer.lastName}
                          // private={aPublicDesign.isPrivate}
                          private={contest?.isPrivate}
                          image={aPublicDesign.image1Attachment !== null ? aPublicDesign.image1Attachment.s3Path : ''}
                          votes={aPublicDesign.votesCount}
                          idUser={aPublicDesign.designer.id}
                          idContest={contest?.id}
                          idDesign={aPublicDesign.id}
                          hidden={aPublicDesign.isHidden}
                          idClient={aPublicDesign.contest.client.id}
                          startDate={aPublicDesign.contest.startUploadDate}
                          endUploadDate={aPublicDesign.contest.endUploadDate}
                        />
                      </div>
                    )
                  )
                )
            : ''}
        </div>
        <div className="row mt-3 px-sm-1">
          <div className="px-sm-3">
            {layoutGallery === 2
              ? visibleDesign.length > 0
                ? visibleDesign.map((aPublicDesign: GalleryDesign, index: number) => (
                    <GalleryRowWLF
                      key={index}
                      contest={contest}
                      design={aPublicDesign}
                      username={aPublicDesign.designer.username}
                      contestTitle={aPublicDesign.contest.name}
                      creationDate={aPublicDesign.uploadDate}
                      name={aPublicDesign.contest.client.username}
                      userId={aPublicDesign.designer.id}
                      votes={aPublicDesign.votesCount}
                      galleryImages={[
                        aPublicDesign.image1Attachment ? aPublicDesign.image1Attachment.s3Path : '',
                        aPublicDesign.image2Attachment ? aPublicDesign.image2Attachment.s3Path : '',
                        aPublicDesign.image3Attachment ? aPublicDesign.image3Attachment.s3Path : '',
                        aPublicDesign.image4Attachment ? aPublicDesign.image4Attachment.s3Path : '',
                        aPublicDesign.image5Attachment ? aPublicDesign.image5Attachment.s3Path : '',
                      ]}
                      idContest={aPublicDesign.contest.id}
                      image={
                        aPublicDesign.contest.contestType.name !== 'Naming'
                          ? aPublicDesign.image1Attachment
                            ? aPublicDesign.image1Attachment.s3Path
                            : ''
                          : designNamingDefaultImage
                      }
                      designName={aPublicDesign.name}
                      idDesign={aPublicDesign.id}
                      private={aPublicDesign.contest.isPrivate}
                      hidden={aPublicDesign.isHidden}
                      idClient={aPublicDesign.contest.client.id}
                      endUploadDate={aPublicDesign.contest.endUploadDate}
                      startDate={aPublicDesign.contest.startUploadDate}
                    ></GalleryRowWLF>
                  ))
                : visibleCoCreationDesign.length > 0 &&
                  visibleCoCreationDesign.map(
                    (aPublicDesign: GalleryDesign, index: number) => (
                      console.log(aPublicDesign),
                      (
                        <GalleryRowWLF
                          key={index}
                          contest={contest}
                          design={aPublicDesign}
                          contestTitle={contest?.name}
                          username={aPublicDesign.designer.username}
                          creationDate={aPublicDesign.uploadDate}
                          name={aPublicDesign.designer.username}
                          votes={aPublicDesign.votesCount}
                          galleryImages={[
                            aPublicDesign.image1Attachment && aPublicDesign.image1Attachment.s3Path,
                            aPublicDesign.image2Attachment && aPublicDesign.image2Attachment.s3Path,
                            aPublicDesign.image3Attachment && aPublicDesign.image3Attachment.s3Path,
                            aPublicDesign.image4Attachment && aPublicDesign.image4Attachment.s3Path,
                            aPublicDesign.image5Attachment && aPublicDesign.image5Attachment.s3Path,
                          ]}
                          idContest={contest?.id}
                          image={aPublicDesign.image1Attachment && aPublicDesign.image1Attachment.s3Path}
                          designName={aPublicDesign.name}
                          userId={aPublicDesign.designer.id}
                          idDesign={aPublicDesign.id}
                          private={contest?.isPrivate}
                          hidden={aPublicDesign.isHidden}
                          idClient={aPublicDesign.contest.client.id}
                          endUploadDate={aPublicDesign.contest.endUploadDate}
                          startDate={aPublicDesign.contest.startUploadDate}
                        ></GalleryRowWLF>
                      )
                    )
                  )
              : ''}
          </div>
        </div>
        <div className="row px-2">
          <div className="col-12 px-4 d-flex justify-content-sm-start justify-content-md-end ">
            {visibleDesign.length > 0 ? <PaginationSwitch onChangePage={setGalleryPageNumber} /> : ''}
            {visibleCoCreationDesign.length > 0 ? <PaginationSwitch onChangePage={setGalleryPageNumber} /> : ''}
          </div>
        </div>
      </main>
    </div>
  );
};

export default GalleryWLF;
