import React, { useEffect, useState } from 'react';
import axiosHelper from '../../helpers/axiosHelper';
import inspirationPlaceholder from '../../assets/Inspiration.png';


import {
  getCommunityUser,
  getInspirationsPageNumber,
  getLayoutInspirations,
} from '../../store/Selectors';
import DslStore, {
  setLayoutInspirations,
  setContestInspirationsPageNumber,
  setCommunityUser,
  setContestInspirations,
  setContestInspirationsCopy,
} from '../../store/DslStore';
import { useStoreState } from 'pullstate';

import { AddInspirationCard, InspirationCard } from '../../components/Contest/InspirationCard/InspirationCard';
import  { PaginationSwitch } from '../../components/Contest/InspirationsNavigation/InspirationsNavigationWLF';
import { Contest, ContestInspirations, User } from '../../global';
import { useNavigate } from 'react-router-dom';
import InspirationsNavigationWLF from '../../components/Contest/InspirationsNavigation/InspirationsNavigationWLF';
import { useAuth } from '../../context/AuthContext';
import { ROUTEPATHS } from '../routePaths';

interface ContestWLFProps {
  contest: Contest;
  loginCallback: () => void;
  breadcrumbs: (breadcrumbs: string) => void;
}

const InspirationsWLF: React.FC<ContestWLFProps> = ({contest, loginCallback}) => {
  const [allInspirations, setAllInspirations] = useState<ContestInspirations[]>([]);
  const layoutInspirations = useStoreState(DslStore, getLayoutInspirations);
  const {loggedUser: user}=useAuth();
  const pageNumber = useStoreState(DslStore, getInspirationsPageNumber);
  const communityUser = useStoreState(DslStore, getCommunityUser);
  const redirect = useNavigate();

  const [visibleInspirations, setVisibleInspirations] = useState<ContestInspirations[]>([]);

  const firstInspiration: ContestInspirations = {
    id: 0,
    isPrivate: false,
    textDescription: 'Share with the community what inspires you in your design and earn extra reputation points for your profile',
    user: {
      id: 0,
      username: 'Add inspiration',
    },
    inspirationVotes: [],
    uploadDate: '',
  };

  const arraysfill = () => {
    let chunkSize = 19;
    let startchunk = (pageNumber - 1) * chunkSize;
    let tempArray: ContestInspirations[] = [];
    let removePrivate: ContestInspirations[] = [];
    if (allInspirations) {
      allInspirations.map((item) => {
        if (item.isPrivate) {
          if (item.user.id === user?.id || user?.userType === 'G' || user?.id === contest.userId) {
            removePrivate.push(item);
          }
        } else {
          removePrivate.push(item);
        }
      });

      let chunk: ContestInspirations[] = removePrivate !== undefined ? removePrivate.slice(startchunk, startchunk + chunkSize) : [];

      for (let i = 0; i < removePrivate!.length; i += chunkSize) {
        chunk = removePrivate!.slice(startchunk, startchunk + chunkSize);
        // console.log('chunk');
        // console.log(chunk);
      }
      for (let i = 0; i < chunkSize; i++) {
        // console.log(chunk[i]);
        // console.log(chunk[i] === undefined);
        if (chunk[i] !== undefined) {
          tempArray.push(chunk[i]);
        } else {
          break;
        }
      }

      // console.log("array1:  ");
      // console.log(tempArray);
      tempArray.unshift(firstInspiration);
      setVisibleInspirations(tempArray);
    }
  };

  const reloadContestInspirations = () => {
    setContestInspirations(contest.inspirations);
    setContestInspirationsCopy(contest.inspirations);

    // axiosHelper.getContestInspirations(props.contest?.id)?.then((response) => {
    //   setContestInspirations(response.inspirations);
    //   setContestInspirationsCopy(response.inspirations);
    // });
  };

  const goToDetail = async (userid: number) => {
    console.log(userid);
    try {
      let result: User = await axiosHelper.retrieveUserById(userid);
      console.log(result);

      setCommunityUser(result);
      if (communityUser) {
        redirect(`/${ROUTEPATHS.COMMUNITY}/${result.nameForURL}/Profile`);
      }
    } catch (e) {
      console.log(e);
    }
  };

  // const fetchContestByName = (contestName: string) => {
  //   console.log('BY NAME');
  //   if (contestName) {
  //     axiosHelper
  //       .retrieveContestByName(contestName)
  //       .then((response) => {
  //         // console.log(response);
  //         setCurrentContest(response.data);
  //         setContestTags(response.data.tags);
  //       })
  //       .catch((error) => {
  //         throw error;
  //       });
  //   }
  // };

  function youtube_parser(url?: string) {
    if (url) {
      var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
      var match = url.match(regExp);
      // console.log( match && match[7].length == 11 ? match[7] : false);
      console.log(match && match[7].length == 11 && `https://img.youtube.com/vi/${match[7]}/default.jpg`);
      return match && match[7].length == 11 ? `https://img.youtube.com/vi/${match[7]}/default.jpg` : inspirationPlaceholder;
    } else {
      return inspirationPlaceholder;
    }
  }

  // useEffect(() => {
  //   console.log(nameForUrl);
  //   nameForUrl && nameForUrl !== contest?.nameForUrl && fetchContestByName(nameForUrl);
  // }, [nameForUrl]);

  useEffect(() => {
    arraysfill();
  }, [pageNumber]);

  useEffect(() => {
    setAllInspirations(contest.inspirations);
    // axiosHelper.getContestInspirations(props.contest?.id)?.then((response) => {
    //   setAllInspirations(response.inspirations);
    // });
  }, [contest]);

  useEffect(() => {
    arraysfill();
  }, [allInspirations]);
  return (
    <div className="bg-light-gray">
      <main className={`container pt-5 mb-5 px-sm-0 `}>
        <div className="row">
          <div className="col-12  ">
            <InspirationsNavigationWLF onChangeLayout={setLayoutInspirations} />
          </div>
        </div>
        <div className="row d-flex flex-row ">
          {layoutInspirations === 1
            ? visibleInspirations?.map((aInspiration: ContestInspirations, index: number) =>
                aInspiration.id > 0 ? (
                  (console.log(aInspiration),
                  (
                    <>
                      <div className="col-12 col-md-6  d-flex flex-row  ps-4   col-lg-4 col-xl-3 mb-4">
                        <InspirationCard
                          key={index + Math.random()}
                          id={aInspiration.id}
                          image={aInspiration.imageAttachment ? aInspiration.imageAttachment.s3Path : youtube_parser(aInspiration.websiteURL)}
                          fullImage={aInspiration.imageAttachment}
                          name={aInspiration.user?.username}
                          text={aInspiration.textDescription}
                          userId={aInspiration.user?.id}
                          url={aInspiration.websiteURL}
                          inspirationVotes={aInspiration.inspirationVotes.length}
                          contestId={contest.id}
                          liked={reloadContestInspirations}
                          private={aInspiration.isPrivate}
                        />
                      </div>
                    </>
                  ))
                ) : (
                  <div className="col-12 col-md-6  d-flex flex-row ps-3 col-lg-4 col-xl-3 mb-4">
                    <AddInspirationCard
                      contestId={contest.id}
                      id={aInspiration.id}
                      text={aInspiration.textDescription}
                      name={aInspiration.user?.username}
                      private={false}
                      image={''}
                      loginCallback={loginCallback}
                    ></AddInspirationCard>
                  </div>
                )
              )
            : ''}
        </div>

        <div className="row mt-3 px-4"></div>
        <div className="row ">
          <div className="col-12 px-4 d-flex justify-content-sm-start justify-content-md-end ">
            {visibleInspirations.length > 0 ? <PaginationSwitch onChangePage={setContestInspirationsPageNumber} /> : ''}
          </div>
        </div>
      </main>
    </div>
  );
};

export default InspirationsWLF;
