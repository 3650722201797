import { Contest, ContestStatus } from '../global';

export function dateColor(contest: Contest): string {
  const status = computeContestStatus(contest);
  switch (status) {
    case 'closed':
      return 'danger';
    case 'ongoing':
      return 'success';
    case 'client-vote':
      return 'dark_yellow';
    case 'community-vote':
      return 'dark_yellow';
    case 'coming-soon':
      return 'light_grey_40';
    case 'ERROR':
      return 'error';
    default:
      return 'black';
  }
}
export function contestPhaseString(contest: Contest) {
  const status = computeContestStatus(contest);
  return status.replace('-', ' ');
}

export function contestPhaseExplanation(contest: Contest) {
const status = computeContestStatus(contest);
  switch (status) {
    case 'closed':
      return `Closed: ${localizedDateString(contest.closeDate)}`;
    case 'coming-soon':
      return `Starts: ${localizedDateString(contest.startDate)}`;
    case 'upload-phase':
      return `Ends: ${localizedDateString(contest.endUploadDate)}`;
    case 'client-vote':
      return ``;
    case 'community-vote':
      return `Ends: ${localizedDateString(contest.endVotingDate)}`;
    default:
      return 'UNKNOWN';
  }
}
export function computeContestStatus(contest: Contest): ContestStatus {
  if (contest.isCoCreation) {
    return 'ERROR';
  }
  if (contest.isClosed) {
    return 'closed';
  }
  const date = new Date().getTime();
  if (date < new Date(contest.startDate).getTime()) {
    return 'coming-soon';
  } else if (date < new Date(contest.endUploadDate).getTime()) {
    return 'upload-phase';
  } else if (hasCommunityVote(contest) && date < new Date(contest.endVotingDate).getTime()) {
    return 'community-vote';
  } else {
    return 'client-vote';
  }
}

function uploadDuration(contest: Contest): number {
  return Math.trunc((new Date(contest.endUploadDate).getTime() - new Date(contest.startDate).getTime()) / 86400000);
}
function passedUpload(contest: Contest): number {
  return Math.trunc((new Date().getTime() - new Date(contest.startDate).getTime()) / 86400000);
}
export function hasCommunityVote(contest: Contest): boolean {
  return contest.endVotingDate != null && new Date(contest.endUploadDate).getTime() < new Date(contest.endVotingDate).getTime();
}

export function timelineInfo(contest: Contest) {
  var result = { uploadActive: false, clientVoteActive: false, communityVoteActive: false, closed: false, stateProgress: 0, targetSeconds: undefined as number | undefined };
  const status = computeContestStatus(contest);
  switch (status) {
    case 'closed':
      result.closed = true;
      result.stateProgress = 100;
      break;
    case 'upload-phase':
      result.uploadActive = true;
      result.stateProgress = (passedUpload(contest) / uploadDuration(contest)) * 100;
      result.targetSeconds = new Date(contest.endUploadDate).getTime() / 1000;
      break;
    case 'client-vote':
      result.clientVoteActive = true;
      result.stateProgress = 50;
      break;
    case 'community-vote':
      result.communityVoteActive = true;
      break;
    default:
      console.log('status not found: ' + status);
      break;
  }
  return result;
}

export const localizedDateString = (dateString?: string) => {
  if (dateString) {
    var startDate: Date = new Date(Date.parse(dateString));
    return startDate.toLocaleDateString('en', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });
  }
};
