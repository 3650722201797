import React from 'react';
import { bgCardComponent, SmallCardComponent, SmallCardSection, SmallHalfImgProps } from '../../../../global';
import { bgcomponent, pathStrapiImage } from '../../../../helpers/utils';
import SplitSectionImage from '../../../SplitSectionImage/SplitSectionImage';

function SmallHalfImgComponent(props: SmallHalfImgProps) {
  return (
    <>
      <SplitSectionImage
        key={props.id}
        reverse={props.imagefloat === 'right' ? true : false}
        bgColor={props.color ? props.color : '#3b3c3c'}
        bgImage={props.image && pathStrapiImage(props.image.data.attributes.url)}
        content={
          <div className="row py-7">
            <div className="col-12">
              {props.title && (
                <div className="row">
                  <div className="col-12 col-md-10  ">
                    <h1 className={`bold-title ${props.titlealign? `text-${props.titlealign}` : `text-start`} color-${props.titlecolor} mb-7`}>{props.title}</h1>
                  </div>
                </div>
              )}
              {props.text && (
                <div className="row ">
                  <div className={`col-12 col-lg-10 text-16 ${props.textalign? `text-${props.textalign}` : `text-start`}  color-${props.textcolor}`}>
                    <span className="text-16 ">{props.text}</span>
                  </div>
                </div>
              )}
              {props.buttontext && (
                <div className='row '>
                  <a href={props.link} target={`${props.target ? props.target : '_self'}`}>
                    <button className={`btn-${props.buttoncolor} rounded mt-5`}>{props.buttontext}</button>
                  </a>
                </div>
              )}
            </div>
          </div>
        }
      />
    </>
  );
}

export default SmallHalfImgComponent;
