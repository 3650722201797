import React, { useState } from 'react';
import styles from './Gallery.module.scss';
import ImageGallery from 'react-image-gallery';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faChevronLeft, faChevronRight, faExpand, faCompress } from '@fortawesome/free-solid-svg-icons';

interface IGalleryImage {
  original: string;
  thumbnail: string;
}

interface IGallery {
  images: IGalleryImage[];
  onZoom?: (zoom: boolean) => void;
}

const renderLeftNav = (onClick: () => void, disabled: boolean) => {
  return (
    <button
      type="button"
      className="image-gallery-icon image-gallery-left-nav"
      aria-label="Next Slide"
      disabled={disabled}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faChevronLeft as IconProp} />
    </button>
  );
};

const renderRightNav = (onClick: () => void, disabled: boolean) => {
  return (
    <button
      type="button"
      className="image-gallery-icon image-gallery-right-nav"
      aria-label="Next Slide"
      disabled={disabled}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faChevronRight as IconProp} />
    </button>
  );
};

/**
 * React Gallery Wrapper
 * @returns
 */
const Gallery: React.FC<IGallery> = (props) => {
  const [galleryZoom, setGalleryZoom] = useState<boolean>(false);

  const handleGalleryZoom = () => {
    setGalleryZoom(!galleryZoom);
    props.onZoom && props.onZoom(!galleryZoom);
  };

  return (
    <div className={`${styles.galleryWrapper}`}>
      <button
        className={`d-none d-lg-block btn-icon squared bg-blue color-light ${styles.enlargeButton}`}
        onClick={() => handleGalleryZoom()}
      >
        <FontAwesomeIcon icon={(galleryZoom ? faCompress : faExpand) as IconProp} className="text" />
      </button>
      {/* <ImageGallery
        items={props.images}
        showFullscreenButton={false}
        showPlayButton={false}
        renderLeftNav={renderLeftNav}
        renderRightNav={renderRightNav}
        showBullets={true}
        additionalClass="desall-gallery"
      /> */}
    </div>
  );
};

export default Gallery;
