import React, { useEffect, useState } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import xLogo from '../../assets/square-x-twitter_azure.png';

//Header Data
//Footer Data
//Styles
import styles from './Profile.module.scss';

import { birthDateString, finalString } from '../../helpers/utils';
import { getUserBlackList } from '../../store/Selectors';
import { useStoreState } from 'pullstate';
import DslStore, { setClientContest, setClientContestNumber, setBlackList } from '../../store/DslStore';
import {
  faBehanceSquare,
  faDribbbleSquare,
  faFacebookSquare,
  faFlickr,
  faGooglePlusSquare,
  faInstagramSquare,
  faLinkedin,
  faPinterestSquare,
  faSquareXTwitter,
  faTumblrSquare,
  faVimeoSquare,
  faYoutubeSquare,
} from '@fortawesome/free-brands-svg-icons';
import { Contest, ContestTag, SocialNetwork, User, UserDetails, UserFavorites } from '../../global';
import axiosHelper from '../../helpers/axiosHelper';
import { useNavigate, useParams } from 'react-router-dom';
import PopUpLogin from '../../components/PopUp/PopUpLogin/PopUpLogin';
import PopUpSendMessage from '../../components/PopUp/PopUpSendMessage/PopUpSendMessage';

interface ProfileProps {
  user: User;
  loggedUser: User|null;
  setBreadcrumbs: (breadcrumbs: string) => void;
  setMessageBanner: (message: number) => void;
}

const ProfileWLF: React.FC<ProfileProps> = ({user, loggedUser, setMessageBanner}) => {
  // const user = useStoreState(DslStore, getCommunityUser);
  // const loggedUser = useStoreState(DslStore, getCurrentUser);
  const blackList = useStoreState(DslStore, getUserBlackList);
  const [usersFavorites, setUserFavorites] = useState<UserFavorites>();
  const [liked, setLiked] = useState(false);
  const [popUpLogin, setPopupLogin] = useState(false);
  const [popUpMessage, setPopUpMessage] = useState(false);
  const path = window.location.pathname;
  const [black, setBlack] = useState(false);
  const redirect = useNavigate();

  const isLiked = () => {
    if (loggedUser && user) {
      let state: boolean = false;

      loggedUser?.userType !== 'C' &&
        loggedUser.usersILike.findIndex((item) => (item.id === user.id ? (state = true) : null));
      setLiked(state);
    }
  };

  const setClientContests = async () => {
    let result = await axiosHelper.getContestsByClientId(user?.id);
    const clientContests: Contest[] = result.data;
    if (clientContests) {
      setClientContest(clientContests);
    }
  };

  const userContestNumber = () => {
    if (user) {
      let clientContestLength: number = user.contests.length;
      console.log(clientContestLength);
      setClientContestNumber(clientContestLength);
    }
  };

  const goTo = (link?: string) => {
    if (link) window.open(link, '_blank');
  };

  useEffect(() => {
    if (blackList) {
      isInBlackList();
    }
  }, [blackList]);

  const banUser = async () => {
    if (loggedUser?.userType === 'G' && user?.id) {
      await axiosHelper
        .banUser(user.id)
        .then((response) => {
          console.log(response);
          // redirect('/');
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const removeFromBlackList = async () => {
    user?.id &&
      (await axiosHelper
        .removeUserFromBlackList(user.id)
        .then((response) => {
          console.log(response);
          setBlackList(response);
        })
        .catch((error) => {
          console.log(error);
        }));
  };

  const addToBlackList = async () => {
    user?.id &&
      (await axiosHelper
        .addUserToBlackList(user.id)
        .then((response) => {
          console.log(response);
          setBlackList(response);
        })
        .catch((error) => {
          console.log(error);
        }));
  };

  useEffect(() => {
    // console.log(user);
    isInBlackList();
    if (user?.userType === 'C') {
      userContestNumber();
      setClientContests();
    }
    // console.log();
    // setUserDetails(user?.id);

    // userFavorites();
  }, []);

  // useEffect(() => {
  //   if (usersFavorites) {
  //     console.log('userfavoriteset');
  //     setFavoriteUsers(usersFavorites.usersILike);
  //   }
  // }, [usersFavorites]);

  const userLiked = () => {
    if (user?.id && loggedUser) {
      loggedUser.id !== user.id &&
        axiosHelper.likeUser(user.id).then((response) => {
          setLiked(true);
          // alert(`${props.user?.username} added to favorites`);
        });
    }
  };

  const userUnliked = () => {
    if (user?.id) {
      axiosHelper.unlikeUser(user.id).then((response) => {
        setLiked(false);
        // alert(`${props.user?.username} removed from favorites`);
      });
    }
  };

  // useEffect(() => {

  //   setUserDetails(user?.id);
  //   setClientContests();
  //   userFavorites();
  // }, [user]);

  const isInBlackList = () => {
    let blacklist: boolean = false;
    if (user?.id) {
      blacklist = blackList.some((item) => item.id === user.id);
      setBlack(blacklist);
    }
  };

  // useEffect(() => {
  //   let pathSplitted = path.split('/');

  //   axiosHelper.retrieveUserByName(pathSplitted[2])?.then((response) => {
  //     setCommunityUser(response.data);
  //   });

  //   loggedUser &&
  //     user &&
  //     loggedUser.id === user?.id &&
  //     redirect(`/${ROUTEPATHS.COMMUNITY}/${loggedUser.nameForURL}/profile-editable`);
  // }, []);

  return (
    <div className="bg-light-gray px-md-0 ">
      <main className={`${styles.profileWrapper}  container pt-5 mb-5 px-sm-0  overflow-hidden `}>
        <div className={`row  `}>
          {/* Left column  ====================================================== */}
          <div className="col-12  col-md-3 ">
            {loggedUser?.id !== user?.id && (
              <div className="buttons ">
                <button
                  className={`${liked ? 'btn-danger' : 'btn-blue'} rounded full  mb-3`}
                  onClick={() => (loggedUser ? (liked ? userUnliked() : userLiked()) : setPopupLogin(true))}
                >
                  {liked ? 'Remove from favorites' : 'Add to favorite'}
                </button>
                {loggedUser && user?.userType === 'D' && user?.hasPrivateMessagesNotification && (
                  <button className="btn-blue rounded full mb-3" onClick={() => setPopUpMessage(true)}>
                    Send message
                  </button>
                )}
                {loggedUser && user?.userType === 'D' && !user?.hasPrivateMessagesNotification && (
                  <button className="btn-blue rounded full mb-3">Do not disturb</button>
                )}
                {loggedUser && (
                  <button
                    className={`${black ? `btn-warning` : `btn-red`} rounded big full mb-3`}
                    onClick={() => (black ? removeFromBlackList() : addToBlackList())}
                  >
                    {black ? 'Unblock user' : 'Block user'}
                  </button>
                )}
                {loggedUser?.userType === 'G' && (
                  <button className="btn-red rounded big full mb-3" onClick={() => banUser()}>
                    Ban user
                  </button>
                )}
                {popUpMessage && user && (
                  <PopUpSendMessage
                    popup={popUpMessage}
                    close={() => setPopUpMessage(false)}
                    message={(message) => (setMessageBanner(message), setPopUpMessage(false))}
                    recipient={user.username}
                  />
                )}

                {popUpLogin && (
                  <PopUpLogin
                    popup={popUpLogin}
                    close={() => setPopupLogin(false)}
                    login={(e: boolean) => setPopupLogin(e)}
                  />
                )}
              </div>
            )}
            <div className={`tags ${loggedUser?.id !== user?.id && 'mt-5'}  d-flex flex-wrap`}>
              {user?.tags &&
                user?.tags.map((item: ContestTag, index: number) => (
                  <>
                    <button className="btn-tag mb-4 px-0 me-5">
                      <span>{item.name.replace(',', '')}</span>
                    </button>
                  </>
                ))}
              {/* <button className="btn-tag mb-4 ">
                <span>Even more looong stuff</span>
              </button>
              <button className="btn-tag  mb-4">
                <span>Short</span>
              </button>
              <button className="btn-tag  mb-4">
                <span>The</span>
              </button>
              <button className="btn-tag  mb-4">
                <span>The medium</span>
              </button> */}
            </div>
          </div>
          <div className="col-12 col-md-9 ">
            <div className="row">
              <div className="col-12 ps-4 ps-sm-5">
                {/*                 
                <div className="row mb-5 mt-4">
                  <div className="col-12 col-md-2 text text-left mb-2 fw-bold">Username</div>
                  <div className="col-12 col-md-10">{finalString(user?.username)}</div>
                </div> */}
                {user?.hasPublicName && (
                  <div className="row mb-5 ">
                    <div className="col-12 col-md-2 text text-left mb-2 fw-bold">Name</div>
                    <div className="col-12 col-md-10">
                      {finalString(user?.firstName, user?.lastName)}
                    </div>
                  </div>
                )}
                {/* {user?.hasPublicBirthDate && (
                  <div className="row mb-5 ">
                    <div className="col-12 col-md-2 text text-left mb-2 fw-bold">BirthDate</div>
                    <div className="col-12 col-md-10">{user.birthDate}</div>
                  </div>
                )} */}
                {/* {user?.addressLine1 && user.hasPublicCity && (
                  <div className="row mb-5 ">
                    <div className="col-12 col-md-2 text text-left mb-2 fw-bold">Address</div>
                    <div className="col-12 col-md-10">{user.addressLine1}</div>
                  </div>
                )}
                {user?.zipCode && user.hasPublicCity && (
                  <div className="row mb-5 ">
                    <div className="col-12 col-md-2 text text-left mb-2 fw-bold">Zip code</div>
                    <div className="col-12 col-md-10">{user.zipCode}</div>
                  </div>
                )} */}
                {user?.phoneNumber && (
                  <div className="row mb-5 ">
                    <div className="col-12 col-md-2 text text-left mb-2 fw-bold">Phone number</div>
                    <div className="col-12 col-md-10">{user.phoneNumber}</div>
                  </div>
                )}
                {user?.contactLanguage && (
                  <div className="row mb-5 ">
                    <div className="col-12 col-md-2 text text-left mb-2 fw-bold">Contact language</div>
                    <div className="col-12 col-md-10">{user.contactLanguage}</div>
                  </div>
                )}
                {user?.description && (
                  <div className="row mb-5 ">
                    <div className="col-12 col-md-2 text text-left mb-2 fw-bold">Description</div>
                    <div className="col-12 col-md-10">{user?.description}</div>
                  </div>
                )}
                {/* {user?.sector && (
                  <div className="row mb-5 ">
                    <div className="col-12 col-md-2 text text-left mb-2 fw-bold">Sector</div>
                    <div className="col-12 col-md-10">{user?.sector}</div>
                  </div>
                )} */}
                {user?.userType === 'D' && user.biography && (
                  <div className="row mb-5">
                    <div className="col-12 col-md-2 text text-left mb-2 fw-bold">Biography</div>
                    <div className="col-12 col-md-10">
                      <p>{finalString(user?.biography)}</p>
                    </div>
                  </div>
                )}
                {user?.userType === 'D' && (
                  <>
                    <div className="row mb-5">
                      <div className="col-12 col-md-2 text text-left mb-2 fw-bold">Who are you</div>

                      {user?.jobTitle && (
                        <div className="col-12 col-md-10">
                          {finalString(user?.jobTitle)},&nbsp; {finalString(user?.job?.name)}
                        </div>
                      )}
                    </div>
                    {user?.companyName && (
                      <div className="row mb-5">
                        <div className="col-12 col-md-2 text text-left mb-2 fw-bold">Who are you</div>

                        <div className="col-12 col-md-10">{user.companyName}</div>
                      </div>
                    )}
                  </>
                )}
                {user?.school1 && user.hasPublicFirstSchool && (
                  <div className="row mb-5">
                    <div className="col-12 col-md-2 text text-left mb-2 fw-bold">School</div>
                    <div className="col-12 col-md-10">{user?.school1}</div>
                  </div>
                )}
                {user?.school2 && user.hasPublicSecondSchool && (
                  <div className="row mb-5">
                    <div className="col-12 col-md-2 text text-left mb-2 fw-bold">School</div>
                    <div className="col-12 col-md-10">{user?.school2}</div>
                  </div>
                )}
                {user?.freelanceLicense && user.freelanceLicense !== 'N' && (
                  <div className="row mb-5">
                    <div className="col-12 col-md-2 text text-left mb-2 fw-bold">Freelance license</div>
                    <div className="col-12 col-md-10">{user?.freelanceLicense === 'Y' ? 'yes' : 'no'}</div>
                  </div>
                )}

                {user?.gender && (
                  <div className="row mb-5">
                    <div className="col-12 col-md-2 text text-left mb-2 fw-bold">Gender</div>
                    <div className="col-12 col-md-10">
                      {user?.gender === 'M'
                        ? 'He/Him'
                        : user?.gender === 'F'
                        ? 'She/Her'
                        : user?.gender === 'N'
                        ? 'N/A'
                        : 'other'}
                    </div>
                  </div>
                )}
                {user?.userType === 'D' && user.hasPublicBirthDate && (
                  <div className="row mb-5">
                    <div className="col-12 col-md-2 text text-left mb-2 fw-bold">Birth date</div>

                    <div className="col-12 col-md-10">{birthDateString(user?.birthDate)}</div>
                  </div>
                )}
                {user?.socialNetworks && user.socialNetworks.length > 0 && (
                  <div className="row mb-5">
                    <div className="col-12 col-md-2 text text-left mb-2 fw-bold">Social network</div>
                    <div className="col-12 col-md-10 d-flex flex-row align-items-center">
                      {user?.socialNetworks && user.socialNetworks.length < 1 ? (
                        <span> - </span>
                      ) : null}
                      {user?.socialNetworks &&
                        user.socialNetworks.map((aSocialNetwork: SocialNetwork) =>
                          aSocialNetwork.socialNetworkFinder.comparisonString === 'facebook.' ? (
                            <span>
                              <FontAwesomeIcon
                                icon={faFacebookSquare as IconProp}
                                className=" text color-blue me-3"
                                style={{ fontSize: '2rem', cursor: 'pointer' }}
                                onClick={() => goTo(aSocialNetwork.url)}
                              />
                            </span>
                          ) : null
                        )}
                      {user?.socialNetworks &&
                        user.socialNetworks.map((aSocialNetwork: SocialNetwork) =>
                          aSocialNetwork.socialNetworkFinder.comparisonString === 'twitter.' ? (
                            <span>
                              <FontAwesomeIcon
                                icon={faSquareXTwitter as IconProp}
                                className=" text color-blue me-3"
                                style={{ fontSize: '2rem', cursor: 'pointer' }}
                                onClick={() => goTo(aSocialNetwork.url)}
                              />
                            </span>
                          ) : null
                        )}
                      {user?.socialNetworks &&
                        user.socialNetworks.map((aSocialNetwork: SocialNetwork) =>
                          aSocialNetwork.socialNetworkFinder.comparisonString === 'behance.' ? (
                            <span>
                              <FontAwesomeIcon
                                icon={faBehanceSquare as IconProp}
                                className=" text color-blue me-3"
                                style={{ fontSize: '2rem', cursor: 'pointer' }}
                                onClick={() => goTo(aSocialNetwork.url)}
                              />
                            </span>
                          ) : null
                        )}
                      {user?.socialNetworks &&
                        user.socialNetworks.map((aSocialNetwork: SocialNetwork) =>
                          aSocialNetwork.socialNetworkFinder.comparisonString === 'dribbble.' ? (
                            <span>
                              <FontAwesomeIcon
                                className=" text color-blue me-3"
                                icon={faDribbbleSquare as IconProp}
                                style={{ fontSize: '2rem', cursor: 'pointer' }}
                                onClick={() => goTo(aSocialNetwork.url)}
                              />
                            </span>
                          ) : null
                        )}
                      {user?.socialNetworks &&
                        user.socialNetworks.map((aSocialNetwork: SocialNetwork) =>
                          aSocialNetwork.socialNetworkFinder.comparisonString === 'flickr.' ? (
                            <span>
                              <FontAwesomeIcon
                                icon={faFlickr as IconProp}
                                className=" text color-blue me-3"
                                style={{ fontSize: '2rem', cursor: 'pointer' }}
                                onClick={() => goTo(aSocialNetwork.url)}
                              />
                            </span>
                          ) : null
                        )}
                      {user?.socialNetworks &&
                        user.socialNetworks.map((aSocialNetwork: SocialNetwork) =>
                          aSocialNetwork.socialNetworkFinder.comparisonString === 'google.' ? (
                            <span>
                              <FontAwesomeIcon
                                icon={faGooglePlusSquare as IconProp}
                                style={{ fontSize: '2rem', cursor: 'pointer' }}
                                className=" text  color-blue me-3"
                                onClick={() => goTo(aSocialNetwork.url)}
                              />
                            </span>
                          ) : null
                        )}
                      {user?.socialNetworks &&
                        user.socialNetworks.map((aSocialNetwork: SocialNetwork) =>
                          aSocialNetwork.socialNetworkFinder.comparisonString === 'linkedin.' ? (
                            <span>
                              <FontAwesomeIcon
                                icon={faLinkedin as IconProp}
                                className=" text  color-blue me-3"
                                style={{ fontSize: '2rem', cursor: 'pointer' }}
                                onClick={() => goTo(aSocialNetwork.url)}
                              />
                            </span>
                          ) : null
                        )}
                      {user?.socialNetworks &&
                        user.socialNetworks.map((aSocialNetwork: SocialNetwork) =>
                          aSocialNetwork.socialNetworkFinder.comparisonString === 'pinterest.' ? (
                            <span>
                              <FontAwesomeIcon
                                icon={faPinterestSquare as IconProp}
                                className=" text color-blue me-3"
                                style={{ fontSize: '2rem', cursor: 'pointer' }}
                                onClick={() => goTo(aSocialNetwork.url)}
                              />
                            </span>
                          ) : null
                        )}
                      {user?.socialNetworks &&
                        user.socialNetworks.map((aSocialNetwork: SocialNetwork) =>
                          aSocialNetwork.socialNetworkFinder.comparisonString === 'tumblr.' ? (
                            <span>
                              <FontAwesomeIcon
                                icon={faTumblrSquare as IconProp}
                                className=" text color-blue me-3"
                                style={{ fontSize: '2rem', cursor: 'pointer' }}
                                onClick={() => goTo(aSocialNetwork.url)}
                              />
                            </span>
                          ) : null
                        )}
                      {user?.socialNetworks &&
                        user.socialNetworks.map((aSocialNetwork: SocialNetwork) =>
                          aSocialNetwork.socialNetworkFinder.comparisonString === 'vimeo.' ? (
                            <span>
                              <FontAwesomeIcon
                                icon={faVimeoSquare as IconProp}
                                className=" text color-blue me-3"
                                style={{ fontSize: '2rem', cursor: 'pointer' }}
                                onClick={() => goTo(aSocialNetwork.url)}
                              />
                            </span>
                          ) : null
                        )}
                      {user?.socialNetworks &&
                        user.socialNetworks.map((aSocialNetwork: SocialNetwork) =>
                          aSocialNetwork.socialNetworkFinder.comparisonString === 'instagram.' ? (
                            <span>
                              <FontAwesomeIcon
                                icon={faInstagramSquare as IconProp}
                                className=" text color-blue me-3"
                                style={{ fontSize: '2rem', cursor: 'pointer' }}
                                onClick={() => goTo(aSocialNetwork.url)}
                              />
                            </span>
                          ) : null
                        )}
                      {user?.socialNetworks &&
                        user.socialNetworks.map((aSocialNetwork: SocialNetwork) =>
                          aSocialNetwork.socialNetworkFinder.comparisonString === 'youtube.' ? (
                            <span>
                              <FontAwesomeIcon
                                icon={faYoutubeSquare as IconProp}
                                className=" text color-blue me-3"
                                style={{ fontSize: '2rem', cursor: 'pointer' }}
                                onClick={() => goTo(aSocialNetwork.url)}
                              />
                            </span>
                          ) : null
                        )}
                    </div>
                  </div>
                )}
                {user?.website && (
                  <div className="row mb-5">
                    <div className="col-12 col-md-2 text text-left mb-2 fw-bold">Website</div>
                    <div className="col-12 col-md-10">
                      <a href={user?.website}>{finalString(user?.website)}</a>
                    </div>
                  </div>
                )}

                {/* <div className="row mb-5">
                  <div className="col-12 col-md-2 text text-left mb-2 fw-bold">Groups</div>
                  <div className="col-12 col-md-10">
                    <a href="#">Dream Team - Shenkar College</a>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </main>
      {/* <AppFooter links={footerMenu} bgColor="#304053" logoImg={headerLogo} bgImage={footerImg} /> */}
    </div>
  );
};

export default ProfileWLF;
