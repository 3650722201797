import React, { useEffect, useState } from 'react';
import { ElementComponent, FourElementSectionProps } from '../../global';
import { pathStrapiImage } from '../../helpers/utils';
import HorizontalSection from '../HorizontalSection/HorizontalSection';

const TwoThreeFourElements: React.FC<FourElementSectionProps> = (props) => {
  const [lgCol, setLgCol] = useState(3);
  useEffect(() => {
    if (props.elementcomponent.length === 2) {
      setLgCol(6);
    } else if (props.elementcomponent.length === 3) {
      setLgCol(4);
    }
  }, []);
  return (
    <HorizontalSection bgColor={props.bgcolor ? `${props.bgcolor}` : '#f6f6f6'} key={props.id}>
      <div className="container py-7 px-0 ">
        <div className="row justify-content-center pb-5">
          {props.title || props.subtitle || props.text ? (
            <div className="col-12 col-md-10">
              {props.title && <h2 className={`text-center bold-title color-${props.titlecolor}`}>{props.title}</h2>}
              {props.subtitle && (
                <h3 className={`text-center sub-title color-${props.subtitlecolor}`}>{props.subtitle}</h3>
              )}
              {props.text && <p className={`text-16 text-center mt-4 color-${props.textcolor}`}>{props.text}</p>}
            </div>
          ) : null}
        </div>
        <div className="row">
          {props.elementcomponent.map((item: ElementComponent) => (
            <div
              className={`col-12 col-sm-6 col-md-6 mt-sm-5 col-lg-${lgCol} mt-lg-0 text-center d-flex flex-column align-items-stretch justify-content-start pb-lg-3 mb-4`}
              key={item.id}
            >
              {/* {console.log(item.buttontext, item.buttcolor)} */}
              {item.image?.data && (
                <div className="d-flex justify-content-center flex-column w-100">
                  {item.link ? (
                    <img
                      className="img-fluid w-100"
                      src={pathStrapiImage(item.image.data.attributes.url)}
                      alt="Product"
                    />
                  ) : (
                    <a href={item.link && item.link} target={`${item.target ? item.target : '_self'}`}>
                      <img
                        className="img-fluid w-100"
                        src={pathStrapiImage(item.image.data.attributes.url)}
                        alt="Product"
                      />
                    </a>
                  )}
                </div>
              )}

              {item.title && (
                <span
                  className={`bold-title ${item.titlealign ? `text-${item.titlealign}` : `text-center`} mt-4 color-${
                    item.titleCol
                  }`}
                >
                  {item.title}
                </span>
              )}
              {item.bigtext && (
                <div
                  className={` mt-4 d-flex align-items-center ${
                    item.bigtextalign
                      ? `justify-content-${item.bigtextalign} text-${item.bigtextalign}`
                      : `justify-content-center text-center`
                  }   ${item.boldtext ? '' : 'mb-3'} `}
                  style={{ height: '35px' }}
                >
                  <span className={`sub-title  color-${item.bigtextColor} `}>{item.bigtext}</span>
                </div>
              )}
              {item.boldtext && (
                <div
                  className={` mb-3  d-flex align-items-center ${
                    item.boldtextalign
                      ? `justify-content-${item.boldtextalign} text-${item.boldtextalign}`
                      : `justify-content-center text-center`
                  } `}
                  style={{ height: '35px' }}
                >
                  <span className={`fw-bold text-16 color-${item.boldtextColor} `}>{item.boldtext}</span>
                </div>
              )}

              {item.text && (
                <span
                  className={` ${item.textalign ? `text-${item.textalign}` : `text-center`} text-16 mb-3 mx-5 color-${
                    item.txtColor
                  }`}
                >
                  {item.text}
                </span>
              )}
              {item.buttontext && (
                <div className="col-12 col-md-4 col-lg-6 align-self-center mt-2 ">
                  <a href={item.link && item.link} target={`${item.target ? item.target : '_self'}`}>
                    <button className={`btn-${item.buttcolor} ${lgCol !== 3 ? 'full' : ''} rounded mt-2`}>
                      {item.buttontext}
                    </button>
                  </a>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </HorizontalSection>
  );
};

export default TwoThreeFourElements;
