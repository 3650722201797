import { SmallCardComponent, SmallCardSection } from '../../../global';
import { pathStrapiImage } from '../../../helpers/utils';

import CardSmall from '../../CardSmall/CardSmall';
import { SmallCardSlider } from '../../Slider/Slider';

function SmallCardSliderComponent(props: SmallCardSection) {
  return (
    <section key={props.id} className='py-3'  style={{ backgroundColor: `${props.bgcolor}` }} >
      <div className="container my-7 ">
        <div className="row">
          <div className="col-12 text-center mb-3">
            {props.title && <h3 className={`bold-title color-${props.titlecolor} text-center mb-3`}>{props.title}</h3>}
            {props.subtitle && (
              <div className={`col-12 text-center sub-title color-${props.subtitlecolor}`}>{props.subtitle}</div>
            )}
          </div>
        </div>
      </div>
      <div className="mb-7">
        <SmallCardSlider timeInterval={1200} slides={props.smallcardcomponent}>
          {/* {props.smallcardcomponent &&
            props.smallcardcomponent.map((item: SmallCardComponent, index: number) => {
              return (
                <CardSmall
                  key={index}
                  onClick={() => item.link && window.open(item.link, `${item.target ? item.target : '_self'}`)}
                  topText={item.toptext}
                  bottomText={item.bottomtext}
                  backGround={pathStrapiImage(item.image.data.attributes.url)}
                ></CardSmall>
              );
            })} */}
        </SmallCardSlider>
      </div>
    </section>
  );
}

export default SmallCardSliderComponent;
