import React, { useCallback, useEffect, useRef, useState } from 'react';
import Dropzone from 'react-dropzone';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import styles from './FileUpload.module.scss';
import axios from 'axios';
import axiosHelper from '../../../../helpers/axiosHelper';

interface IFileUpload {
  onImageAdd: (e: any) => void;
  uuidPresigned: (uuid: any) => void;
}

const FileUpload: React.FC<IFileUpload> = (props) => {
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [fileToUpload, setFileToUpload] = useState<File>();
  const [progress, setProgress] = useState<number>(0);

  const uuidAdded = useRef<any>();

  const mockProgress = () =>
    new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(true);
      }, 2000);
    });

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      console.log('aggiunto banner');
      let file = acceptedFiles[0];

      handleDrop(file);

      //mocked behavior
      await mockProgress().then(() => props.onImageAdd(file));
    },
    [props]
  );

  const handleDrop = async (files: any) => {
    // console.log(files);
    let file = files;
    setIsUploading(true);
    uuidAdded.current = await getPresignedUrl(file);
  };
  const getPresignedUrl = async (file: File) => {
    console.log('getPresignedUrl');
    // console.log(fileName);
    await axiosHelper.getPresignedUrl(1, file.name, file.size, file.type)?.then((response) => {
      console.log(response.data);
      console.log('uuid: ' + response.data.id);

      sendImage(response.data.payload, response.data.id, file).then((risposta) => {
        console.log('risposta');
        console.log(risposta);
        props.uuidPresigned(response.data.id);
        setIsUploading(true);
        setProgress(100);
        return;
      });
    });
  };

  async function sendImage(link: any, id: string, file: any) {
    console.log('sendImage');
    if (file) {
      await axios
        .put(link, file)
        .then((res) => {
          console.log(res);
          if (res) {
            console.log(file);
          }
        })
        .catch((error) => {
          console.log(error);
          // setIsUploading(false);
        });
    }
  }

  const DropZoneView = (props: { onDrop: (acceptedFiles: File[]) => void }) => {
    return (
      <Dropzone onDrop={onDrop}>
        {({ getRootProps, getInputProps }) => (
          <section className={`row ${styles.dropArea}`}>
            <div className="col-12 d-flex flex-column align-items-center justify-content-center " {...getRootProps()}>
              <input {...getInputProps()} />
              <button className={`${styles.addButton} bg-blue`}>
                <FontAwesomeIcon icon={faPlus as IconProp} className=" text color-light" />
              </button>

              <div>
                .jpg, .png <br /> 1170x270 px
              </div>
            </div>
          </section>
        )}
      </Dropzone>
    );
  };

  const UploadingView = (props: { progress: number }) => {
    const [barWidth, setBarWidth] = useState<number>(0);

    useEffect(() => {
      setBarWidth(props.progress);
    }, [props.progress]);

    return (
      <div className={`row ${styles.progressArea}`}>
        <div className="col-12 mb-5">{fileToUpload && fileToUpload.name}</div>
        <div className="col-12">
          <div className={`${styles.progressBarWrapper}`}>
            <div className={`${styles.progressBar}`} style={{ width: `${barWidth}%` }}></div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={`${styles.uploadContainer} ${isUploading && styles.inProgress}`}>
        {isUploading ? <UploadingView progress={progress} /> : <DropZoneView onDrop={onDrop} />}
        {/* <UploadingView progress={progress} />  */}
      </div>
    </>
  );
};

export default FileUpload;
