import React from 'react';
import { useStoreState } from 'pullstate';
//Header Data
import headerLogo from '../assets/desall_logo.png';

//Footer Data
import footerImg from '../assets/footer_placeholder_transparent.png';
//Assets
import factory from '../assets/factory_placeholder.png';
//Components

import AppFooter from '../components/AppFooter/AppFooter';

import DslStore from '../store/DslStore';
import { getFooterMenu, getHeaderMenu } from '../store/Selectors';

import AppHeader from '../components/AppHeader/AppHeader';

const PageNotFound: React.FC = () => {
  const footerMenu = useStoreState(DslStore, getFooterMenu);
  const headerMenu = useStoreState(DslStore, getHeaderMenu);

  return (
    <>
      <AppHeader links={headerMenu} bgColor={'#7997c5'} logoImg={headerLogo} bgImage={factory} />
      {/* <PageNavigation bgColor="#E3E3E3" links={footerMenu[footerIndexSelected].data} /> */}
      {/* {navigation && <PageNavigation bgColor="#E3E3E3" links={navigation.data} />} */}
      {/* {pageNav && <PageNavigation bgColor="#E3E3E3" links={pageNav && pageNav.data} />} */}

      <div className="container " style={{ height: '70vh' }}>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div className="mt-7">
            <span className="bold-title ">Page not found</span>
          </div>
          <div className='mt-5'>
            <span className="sub-title ">The requested URL was not found on this server.</span>
          </div>
        </div>
      </div>

      <AppFooter links={footerMenu} bgColor="#304053" logoImg={headerLogo} bgImage={footerImg} />
    </>
  );
};

export default PageNotFound;
