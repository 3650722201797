import React from 'react';
import AppHeader from '../components/AppHeader/AppHeader';
import AppFooter from '../components/AppFooter/AppFooter';

//Header Data
import headerimg from '../assets/header_placeholder_transparent.png';
import headerLogo from '../assets/desall_logo.png';
import { topNavPages } from '../mockdata/libraryPages';
import PageNavigation from '../components/PageNavigation/PageNavigation';

//Footer Data
import footerImg from '../assets/footer_placeholder_transparent.png';
import { useStoreState } from 'pullstate';
import { getFooterMenu, getHeaderMenu } from '../store/Selectors';
import DslStore from '../store/DslStore';

const Images: React.FC = () => {
  const footerMenu = useStoreState(DslStore, getFooterMenu);
  const headerMenu = useStoreState(DslStore, getHeaderMenu);
  return (
    <>
      <AppHeader links={headerMenu} bgColor="#7997c5" logoImg={headerLogo} bgImage={headerimg} pageTitle="Images" />
      <PageNavigation bgColor="#E3E3E3" links={topNavPages} />
      <main className="container page-container mb-5">
        <div className="row">
          <div className="col-12">
            <h1 className="bold-title">270 x 270</h1>
          </div>
        </div>
        <div className="d-flex justify-content-between flex-wrap">
          <div>
            <img className="img-270" src="https://source.unsplash.com/collection/928423/480x480" alt="Placeholder" />
          </div>
          <div>
            <img className="img-270" src="https://source.unsplash.com/collection/928423/480x480" alt="Placeholder" />
          </div>
          <div>
            <img
              className="img-270 squared"
              src="https://source.unsplash.com/collection/928423/480x480"
              alt="Placeholder"
            />
          </div>
          <div>
            <img
              className="img-270 circle"
              src="https://source.unsplash.com/collection/928423/480x480"
              alt="Placeholder"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <h1 className="bold-title pt-5">370 x 370</h1>
          </div>
        </div>

        <div className="d-flex justify-content-between flex-wrap">
          <div>
            <img className="img-370" src="https://source.unsplash.com/collection/928423/480x480" alt="Placeholder" />
          </div>
          <div>
            <img className="img-370" src="https://source.unsplash.com/collection/928423/480x480" alt="Placeholder" />
          </div>
          <div>
            <img
              className="img-370 circle"
              src="https://source.unsplash.com/collection/928423/480x480"
              alt="Placeholder"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <h1 className="bold-title pt-5">570 x 570</h1>
          </div>
        </div>

        <div className="d-flex justify-content-between flex-wrap">
          <div>
            <img className="img-570" src="https://source.unsplash.com/collection/928423/480x480" alt="Placeholder" />
          </div>
          <div>
            <img className="img-570" src="https://source.unsplash.com/collection/928423/480x480" alt="Placeholder" />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h1 className="bold-title pt-5">1170 x 330</h1>
          </div>
        </div>

        <div className="d-flex justify-content-between flex-wrap">
          <div>
            <img
              className="img-1170-wide"
              src="https://source.unsplash.com/collection/928423/480x480"
              alt="Placeholder"
            />
          </div>
        </div>
      </main>
      <AppFooter links={footerMenu} bgColor="#304053" logoImg={headerLogo} bgImage={footerImg} />
    </>
  );
};

export default Images;
