import React, { useEffect } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//Icons
import { IconDefinition, faPencil, faLightbulb, faDollarSign, faTrophy, faStar, faHeart, faBookmark, faComment } from '@fortawesome/free-solid-svg-icons';

import styles from './contestStats.module.scss';
import { useStoreState } from 'pullstate';
import DslStore from '../../../store/DslStore';
import { getCoCreationContests, getCommunityUser, getCurrentContest } from '../../../store/Selectors';
import { useNavigate } from 'react-router-dom';
import { CoCreationContest } from '../../../global';
import { ROUTEPATHS } from '../../../routes/routePaths';
import { minWidth } from '@mui/system';

interface IContestStats {
  entries?: number;
  inspirations?: number;
  awards?: number;
  contests?: number;
  reputation?: number;
  favorites?: number;
  votes?: number;
  comments?: number;
  ndaAuth?: boolean;
  accessCodeAuth?: boolean;
  isPrivate?: boolean;
  userType?: string;
}

interface IStatsItem {
  icon: IconDefinition;
  name: string;
  value: number | string;
  clickable?: boolean;
}

const StatsItem = (props: IStatsItem) => {
  const contest = useStoreState(DslStore, getCurrentContest);
  const redirect = useNavigate();

  const goTo = () => {
    if (props.clickable) {
      console.log(props.name);
      if (props.name === 'entries') {
        redirect(`/${ROUTEPATHS.CONTEST}/${contest?.nameForUrl}/${ROUTEPATHS.CONTEST_GALLERY}`);
      } else if (props.name === 'inspirations') {
        redirect(`/${ROUTEPATHS.CONTEST}/${contest?.nameForUrl}/${ROUTEPATHS.CONTEST_INSPIRATIONS}`);
      } else if (props.name === 'total awards') {
        redirect(`/${ROUTEPATHS.CONTEST}/${contest?.nameForUrl}/${ROUTEPATHS.CONTEST_AWARDS}`);
      } else if (props.name === 'votes received') {
        redirect(`/${ROUTEPATHS.CONTEST}/${contest?.nameForUrl}/${ROUTEPATHS.CONTEST_GALLERY}`);
      }
    }
  };

  return (
    <div className={`d-flex flex-column justify-content-center align-items-center text-center mb-4 px-4`} style={{ minWidth: '100px' }}>
      <div className="w-100 d-flex justify-content-center align-items-center text-center" style={{ height: '35px' }}>
        <FontAwesomeIcon icon={props.icon as IconProp} className="text color-blue" />
      </div>
      <div className={`title ${props.clickable ? `color-blue cursor-pointer` : ``}  d-flex justify-content-center align-items-center text-center`} onClick={() => goTo()}>
        {props.value}
      </div>
      <div className="text-12 text-center">{props.name}</div>
    </div>
  );
};
const ContestStats: React.FC<IContestStats> = (props) => {
  const contest = useStoreState(DslStore, getCurrentContest);
  const cocreationContest = useStoreState(DslStore, getCoCreationContests);
  const clickableEntries = () => {
    // console.log('CIAO DA CONTESTSTATS ENTRIES');
    if (contest?.contestType !== 'Co-creation') {
      if (props.accessCodeAuth && props.ndaAuth) {
        // console.log('CIAO DA CONTESTSTATS ENTRIES');
        if (!props.isPrivate) {
          return true;
        }
      }
      return false;
    } else {
      // console.log('CIAO DA CONTESTSTATS ENTRIES COCREATION');
      let visible: boolean = true;
      cocreationContest.map((item: CoCreationContest) => {
        if (item.isPrivate || item.hideGalleryTab) {
          visible = false;
        }
      });
      return visible;
    }
  };

  const clickableInspirations = () => {
    // console.log('CIAO DA CONTESTSTATS INSPIRATIONS');
    if (contest?.contestType !== 'Co-creation') {
      if (props.accessCodeAuth && props.ndaAuth) {
        // console.log('CIAO DA CONTESTSTATS INSPIRATIONS');
        if (!contest?.hideInspirationTab) {
          return true;
        }
      }
      return false;
    } else {
      console.log('CIAO DA CONTESTSTATS INSPIRATIONS COCREATION');
      let visible: boolean = true;
      cocreationContest.map((item) => {
        if (item.isPrivate || item.hideInspirationTab) {
          visible = false;
        }
      });
      return visible;
    }
  };

  const clickableAwards = () => {
    // console.log('CIAO DA CONTESTSTATS AWARDS');
    if (contest?.contestType !== 'Co-creation') {
      if (props.accessCodeAuth && props.ndaAuth) {
        // console.log('CIAO DA CONTESTSTATS AWARDS');
        if (!contest?.hideAwardsTab) {
          return true;
        }
      }
      return false;
    } else {
      // console.log('CIAO DA CONTESTSTATS AWARDS COCREATION');
      let visible: boolean = true;
      cocreationContest.map((item) => {
        if (item.isPrivate || item.hideAwardsTab) {
          visible = false;
        }
      });
      return visible;
    }
  };

  return (
    <div className={`d-flex flex-row justify-content-end ${styles.statsWrapper}`}>
      <div className="d-flex flex-row justify-content-end bg-light radius-m">
        {props.awards !== 0 ? (
          props.awards && <StatsItem name="total awards" value={'€ ' + props.awards} icon={faDollarSign} />//clickable={clickableAwards()}
        ) : (
          <StatsItem name="total awards" value={'€ ' + 0}  icon={faDollarSign} />//clickable={clickableAwards()}
        )}

        {props.entries !== 0 ? (
          props.entries && <StatsItem name="entries" value={props.entries}  icon={faPencil} />//clickable={clickableEntries()}
        ) : (
          <StatsItem name="entries" value={0} icon={faPencil} />//clickable={clickableEntries()} 
        )}
        {props.inspirations !== 0 && props.inspirations && <StatsItem name="inspirations"  value={props.inspirations} icon={faLightbulb} />//clickable={clickableInspirations()}
        }
        {props.votes !== 0 && props.votes && <StatsItem name="votes received"  value={props.votes} icon={faHeart} />//clickable={clickableEntries()}
        }
      </div>
    </div>
  );
};

export default ContestStats;

export const ProfileStats: React.FC<IContestStats> = (props) => {
  if (props.userType) {
    return (
      <div className={`d-flex flex-row justify-content-end ${styles.statsWrapper}`}>
        <div className="d-flex flex-row justify-content-end bg-light radius-m">
          {props.awards ? (
            props.userType === 'D' ? (
              <StatsItem name="awards won" value={'€ ' + props.awards} icon={faDollarSign} />
            ) : (
              <StatsItem name="awarded" value={'€ ' + props.awards} icon={faDollarSign} />
            )
          ) : props.userType === 'D' ? (
            <StatsItem name="awards won" value={'€ ' + 0} icon={faDollarSign} />
          ) : (
            <StatsItem name="awarded" value={'€ ' + 0} icon={faDollarSign} />
          )}
          {props.contests ? (
            props.userType === 'D' ? (
              props.contests && <StatsItem name="contests won" value={props.contests} icon={faTrophy} />
            ) : (
              props.contests && <StatsItem name="contests launched " value={props.contests} icon={faTrophy} />
            )
          ) : props.userType === 'D' ? (
            <StatsItem name="contests won" value={0} icon={faTrophy} />
          ) : (
            <StatsItem name="contests launched" value={0} icon={faTrophy} />
          )}
          {props.entries !== 0 ? props.entries && <StatsItem name="entries" value={props.entries} icon={faPencil} /> : <StatsItem name="entries" value={0} icon={faPencil} />}

          {props.reputation ? <StatsItem name="reputation" value={props.reputation} icon={faStar} /> : <StatsItem name="reputation" value={0} icon={faStar} />}
        </div>
      </div>
    );
  } else {
    return <>MEEEEH</>;
  }
};
