import { SmallCardComponent, SmallCardSection } from '../../../global';
import { pathStrapiImage } from '../../../helpers/utils';
import CardSmall from '../../CardSmall/CardSmall';

function SmallCardSectionComponent(props: SmallCardSection) {
  return (
    <>
      <section className=" py-7" style={{ backgroundColor: `${props.bgcolor}` }} key={props.id}>
        <div className="container">
          <div className="row">
            <div className="col-12 text-center mt-5">
              <h3 className={`bold-title color-${props.titlecolor} text-center`}>{props.title}</h3>
              <h4 className={`sub-title color-${props.subtitlecolor} text-center pb-5`}>
                {props.subtitle && props.subtitle}
              </h4>
            </div>
          </div>
          <div className="row ">
            {props.smallcardcomponent.map((item: SmallCardComponent, index: number) => {
              return (
                <div
                  className="col-12 col-sm-6 col-md-6 col-lg-3 text-center d-flex flex-column align-items-center pb-3 "
                  key={index}
                >
                  <CardSmall
                    onClick={() => item.link && window.open(item.link, `${item.target ? item.target : "_self"}`)}
                    topText={item.toptext}
                    bottomText={item.bottomtext}
                    backGround={pathStrapiImage(item.image.data.attributes.url)}
                  ></CardSmall>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
}

export default SmallCardSectionComponent;
