import React from 'react';
import { SlicedComponentProps } from '../../../global';
import { pathStrapiImage } from '../../../helpers/utils';
import HeroSection from '../../HeroSection/HeroSection';
import SplitSectionImage from '../../SplitSectionImage/SplitSectionImage';

const SlicedSection: React.FC<SlicedComponentProps> = (props) => {
  return (
    <section className="">
      <SplitSectionImage
        key={props.id}
        skewed
        bgColor={props.bgcolor ? props.bgcolor : '#7997c5'}
        bgImage={props.image && pathStrapiImage(props.image.data.attributes.url)}
        content={
          <div className="row py-7">
            <div className="col-12 mb-3">
              <h1
                className={`bold-title  ${props.titlealign ? `text-${props.titlealign}` : `text-start`}color-${
                  props.titlecolor
                }`}
              >
                {props.title}
              </h1>
            </div>
            <div className={`col-11 mt-3 text-16  ${props.textalign ? `text-${props.textalign}` : `text-start`} color-${props.textcolor}`}>{props.text}</div>
            <div className="col-12 pt-7 pb-3">
              <a href={props.link} target={`${props.target ? props.target : '_self'}`}>
                <button className={`btn-${props.buttoncolor} rounded`}>{props.buttontext}</button>
              </a>
            </div>
          </div>
        }
      />
    </section>
  );
};

export default SlicedSection;
