import { BigCardComponent, BigCardSection} from '../../../../global';
import { pathStrapiImage } from '../../../../helpers/utils';
import CardLarge from '../../../CardLarge/CardLarge';
import HorizontalSection from '../../../HorizontalSection/HorizontalSection';

function OnTheMarketCardComponent(props: BigCardSection) {
  return (
    <HorizontalSection bgColor={props.bgcolor ? props.bgcolor : 'f6f6f6'} key={props.id}>
      <div className="container mt-7">
        <div className="row mb-7 gy-3  text-center ">
          {props.bigcardcomponent?.map((item: BigCardComponent, index: number) => (
            <div className="col-10  col-sm-4 mb-4 mx-5 mx-sm-0 " key={index}>
              <a href={item.link} target={`${item.target ? item.target : "_self"}`} rel="noreferrer"> 
                <CardLarge backGround={pathStrapiImage(item.image.data.attributes.url)} bottomText={item.bottomtext} middleText={item.middletext} topText={item.toptext} >
                  <div className="row justify-content-center ">
                    <div className="col-8 h-100 w-100 d-flex flex-column justify-content-center align-items-center ">
                      {item.toptext && <div className="text-xxl fw-bold h-25  color-light mb-5">{item.toptext}</div>}
                      <div className="color-light h-50 text-16">{item.middletext}</div>
                      <div className="text-xxl fw-bold h-25 color-light mt-5">{item.bottomtext}</div>
                    </div>
                  </div>
                </CardLarge>
              </a>
            </div>
          ))}
        </div>
      </div>
    </HorizontalSection>
  );
}

export default OnTheMarketCardComponent;
