import React from 'react';
import AppHeader from '../components/AppHeader/AppHeader';
import AppFooter from '../components/AppFooter/AppFooter';
import HorizontalSection from '../components/HorizontalSection/HorizontalSection';

//Header Data
import headerimg from '../assets/header_placeholder_transparent.png';
import headerLogo from '../assets/desall_logo.png';
import { forCreativesPages } from '../mockdata/forCreativesPages';
import PageNavigation from '../components/PageNavigation/PageNavigation';

//Footer Data
import footerImg from '../assets/footer_placeholder_transparent.png';
import { getFooterMenu, getHeaderMenu } from '../store/Selectors';
import DslStore from '../store/DslStore';
import { useStoreState } from 'pullstate';

const Partners: React.FC = () => {
  const footerMenu = useStoreState(DslStore, getFooterMenu);
  const headerMenu = useStoreState(DslStore, getHeaderMenu);
  return (
    <>
      <AppHeader
        links={headerMenu}
        bgColor="#eab95a"
        logoImg={headerLogo}
        bgImage={headerimg}
        alpha={0.4}
        pageTitle="Info for Creatives"
      />
      <PageNavigation bgColor="#E3E3E3" links={forCreativesPages} />

      <HorizontalSection bgColor="#f6f6f6">
        <div className="container py-7 px-0">
          <div className="row justify-content-center pb-3">
            <div className="COL-12 col-md-10">
              <h2 className="text-center bold-title">Our team</h2>
              <p className="text text-center mt-5">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nesciunt iste quod saepe dolor, blanditiis
                odit eaque velit molestiae quae corporis nulla nihil id accusantium. Quae laudantium magnam ab alias
                voluptates. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Incidunt ullam harum aut facere
                itaque, necessitatibus asperiores ea voluptatum hic
              </p>
            </div>
          </div>
        </div>

        <section className="container mb-7">
          <div className="row mb-5">
            <div className="col-12 col-md-5 col-lg-3">
              <img
                className="img-270 "
                src="https://source.unsplash.com/collection/s5luL_mdpkM/480x480"
                alt="Placeholder"
              />
            </div>
            <div className="col-12 col-md-7 col-lg-9 ps-4">
              <h3 className="text-xl fw-bold pt-4 pt-md-0 pb-4">Infoxmation</h3>
              <p className="text">
                Infoxmation is established by the team of Foxconn Group,is developed by collecting manufacturing expert
                resources to establish a crowd-sourcing platform for professional technology sharing to serve small and
                medium-sized enterprises.Our market positioning is that Infoxmation will become an industrial ecological
                platform with resources of industrial enterprises and internet thinking.
              </p>
              <div className="text fw-bold">Introduction of key products</div>
              <p className="text">
                Infox-Factory:Customized Manufacturing Service for Industrial Products
                <br />
                Infox-Training: Enterprise Customized Industrial Training Course Service
                <br />
                Infox-Design: Industrial design crowdsourcing service Infox-Equipment: Industrial Equipment Rental and
                R&D Entrusted Service
                <br />
                Infox-Transfer:Industrial Technology Transfer Transaction Services
                <br />
                Infox-App store: Industrial software & Application Market
                <br />
              </p>
              <p>
                <a href="https://www.infoxmation.com/">https://www.infoxmation.com/</a>
              </p>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-12 col-md-5 col-lg-3">
              <img
                className="img-270 "
                src="https://source.unsplash.com/collection/4871363/480x480"
                alt="Placeholder"
              />
            </div>
            <div className="col-12 col-md-7 col-lg-9 ps-4">
              <h3 className="text-xl fw-bold pt-4 pt-md-0 pb-4">MIT ID</h3>
              <p className="text">
                MAEER’s MIT Institute of Design, a premier design institute has been successfully transforming design
                students into professionals, since August 2006. Guided by today’s leading minds of Design Education and
                sharing their real world and hands-on experience, MIT ID has become a leading design institute in India.
                This intellectually vigorous institute is shaping the future of students in Industrial, Communication &
                Fashion Design. It offers B.Des and M.Des programs in Design, providing high quality education at
                undergraduate and postgraduate levels, the institute aims to build a generation of creative professional
                designers, who can take on the world’s multifaceted and unpredictable challenges.
              </p>
              <p>
                <a href="https://mitid.edu.in">mitid.edu.in</a>
                <br />
                <a href="facebook.com/MITInstituteofDesig">facebook.com/MITInstituteofDesig</a>
                <br />
                <a href="facebook.com/MITInstituteofDesig">@mitidpune</a>
              </p>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-12 col-md-5 col-lg-3">
              <img
                className="img-270 "
                src="https://source.unsplash.com/collection/4871363/480x480"
                alt="Placeholder"
              />
            </div>
            <div className="col-12 col-md-7 col-lg-9 ps-4">
              <h3 className="text-xl fw-bold pt-4 pt-md-0 pb-4">Lorem Ipsum</h3>
              <p className="text">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates, commodi? Itaque alias omnis officia
                ex, ut ducimus odio cupiditate, porro saepe minima debitis, aspernatur magnam repudiandae? Error
                voluptas esse omnis!
              </p>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-12 col-md-5 col-lg-3">
              <img
                className="img-270 "
                src="https://source.unsplash.com/collection/4871363/480x480"
                alt="Placeholder"
              />
            </div>
            <div className="col-12 col-md-7 col-lg-9 ps-4">
              <h3 className="text-xl fw-bold pt-4 pt-md-0 pb-4">Lorem Ipsum</h3>
              <p className="text">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates, commodi? Itaque alias omnis officia
                ex, ut ducimus odio cupiditate, porro saepe minima debitis, aspernatur magnam repudiandae? Error
                voluptas esse omnis!
              </p>
            </div>
          </div>
        </section>
      </HorizontalSection>

      <AppFooter links={footerMenu} bgColor="#304053" logoImg={headerLogo} bgImage={footerImg} />
    </>
  );
};

export default Partners;
