import React from 'react';
import styles from '../Community/ProjectsNavigation/ProjectsNavigation.module.scss';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faTableCells, faTableList } from '@fortawesome/free-solid-svg-icons';
import { getLayoutClientContest, getLayoutCommunity, getLayoutContest, getLayoutContestPortfolio, getLayoutGallery, getLayoutInspirations, getLayoutPortfolio } from '../../store/Selectors';
import DslStore from '../../store/DslStore';
import { useStoreState } from 'pullstate';

type LayoutSwitchProps = {
  onChangeLayout?: (number: number) => void;
};

export const LayoutContestSwitch: React.FC<LayoutSwitchProps> = ({ onChangeLayout = () => {} }) => {
  const layoutContest = useStoreState(DslStore, getLayoutContest);

  return (
    <div className={`${styles.layoutSwitch} bg-light py-2 px-2  d-none d-sm-block`}>
      <button className={layoutContest === 1 ? 'btn-icon active' : 'btn-icon'} onClick={() => onChangeLayout(1)}>
        <FontAwesomeIcon icon={faTableCells as IconProp} className="text" />
      </button>
      <button className={layoutContest === 2 ? 'btn-icon active' : 'btn-icon'} onClick={() => onChangeLayout(2)}>
        <FontAwesomeIcon icon={faTableList as IconProp} className="text" />
      </button>
    </div>
  );
};

export const LayoutClientContestSwitch: React.FC<LayoutSwitchProps> = ({ onChangeLayout = () => {} }) => {
  const layoutClientContest = useStoreState(DslStore, getLayoutClientContest);

  return (
    <div className={`${styles.layoutSwitch} bg-light py-2 px-2 d-none d-sm-block`}>
      <button className={layoutClientContest === 1 ? 'btn-icon active' : 'btn-icon'} onClick={() => onChangeLayout(1)}>
        <FontAwesomeIcon icon={faTableCells as IconProp} className="text" />
      </button>
      <button className={layoutClientContest === 2 ? 'btn-icon active' : 'btn-icon'} onClick={() => onChangeLayout(2)}>
        <FontAwesomeIcon icon={faTableList as IconProp} className="text" />
      </button>
    </div>
  );
};

export const LayoutInspirationSwitch: React.FC<LayoutSwitchProps> = ({ onChangeLayout = () => {} }) => {
  const layoutInspirations = useStoreState(DslStore, getLayoutInspirations);

  return (
    <div className={`${styles.layoutSwitch} bg-light py-2 px-2 d-none d-sm-block`}>
      <button className={layoutInspirations === 1 ? 'btn-icon active' : 'btn-icon'} onClick={() => onChangeLayout(1)}>
        <FontAwesomeIcon icon={faTableCells as IconProp} className="text" />
      </button>
      <button className={layoutInspirations === 2 ? 'btn-icon active' : 'btn-icon'} onClick={() => onChangeLayout(2)}>
        <FontAwesomeIcon icon={faTableList as IconProp} className="text" />
      </button>
    </div>
  );
};

export const PortfolioLayoutSwitch : React.FC<LayoutSwitchProps> = ({ onChangeLayout = () => {} }) => {
  const layoutPortfolio = useStoreState(DslStore, getLayoutPortfolio);

  return (
    <div className={`${styles.layoutSwitch} bg-light py-2 px-2 d-none d-sm-block`}>
      <button className={layoutPortfolio === 1 ? 'btn-icon active' : 'btn-icon'} onClick={() => onChangeLayout(1)}>
        <FontAwesomeIcon icon={faTableCells as IconProp} className="text" />
      </button>
      <button className={layoutPortfolio === 2 ? 'btn-icon active' : 'btn-icon'} onClick={() => onChangeLayout(2)}>
        <FontAwesomeIcon icon={faTableList as IconProp} className="text" />
      </button>
    </div>
  );
};

export const ContestPortfolioLayoutSwitch : React.FC<LayoutSwitchProps> = ({ onChangeLayout = () => {} }) => {
  const layoutPortfolio = useStoreState(DslStore, getLayoutContestPortfolio);

  return (
    <div className={`${styles.layoutSwitch} bg-light py-2 px-2 d-none d-sm-block`}>
      <button className={layoutPortfolio === 1 ? 'btn-icon active' : 'btn-icon'} onClick={() => onChangeLayout(1)}>
        <FontAwesomeIcon icon={faTableCells as IconProp} className="text" />
      </button>
      <button className={layoutPortfolio === 2 ? 'btn-icon active' : 'btn-icon'} onClick={() => onChangeLayout(2)}>
        <FontAwesomeIcon icon={faTableList as IconProp} className="text" />
      </button>
    </div>
  );
};

export const GalleryInspirationSwitch: React.FC<LayoutSwitchProps> = ({ onChangeLayout = () => {} }) => {
  const layoutGallery = useStoreState(DslStore, getLayoutGallery);

  return (
    <div className={`${styles.layoutSwitch} bg-light py-2 px-2  d-none d-sm-block`}>
      <button className={layoutGallery === 1 ? 'btn-icon active' : 'btn-icon'} onClick={() => onChangeLayout(1)}>
        <FontAwesomeIcon icon={faTableCells as IconProp} className="text" />
      </button>
      <button className={layoutGallery === 2 ? 'btn-icon active' : 'btn-icon'} onClick={() => onChangeLayout(2)}>
        <FontAwesomeIcon icon={faTableList as IconProp} className="text" />
      </button>
    </div>
  );
};

export const LayoutCommunitySwitch: React.FC<LayoutSwitchProps> = ({ onChangeLayout = () => {} }) => {
  const layoutCommunity = useStoreState(DslStore, getLayoutCommunity);

  return (
    <div className={`${styles.layoutSwitch} bg-light py-2 px-2 d-none d-sm-block`}>
      <button className={layoutCommunity === 1 ? 'btn-icon active' : 'btn-icon'} onClick={() => onChangeLayout(1)}>
        <FontAwesomeIcon icon={faTableCells as IconProp} className="text" />
      </button>
      <button className={layoutCommunity === 2 ? 'btn-icon active' : 'btn-icon'} onClick={() => onChangeLayout(2)}>
        <FontAwesomeIcon icon={faTableList as IconProp} className="text" />
      </button>
    </div>
  );
};
