import React, { useEffect, useRef, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DslStore from '../../../store/DslStore';

import styles from './PopUpInappropriate.module.scss';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faEnvelope, faXmark } from '@fortawesome/free-solid-svg-icons';

import axiosHelper from '../../../helpers/axiosHelper';
import { Modal } from 'react-bootstrap';
import { faFacebookF, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { getCurrentDesign, getCurrentUser } from '../../../store/Selectors';
import { useStoreState } from 'pullstate';
import { useForm } from 'react-hook-form';
import { InappropriateForm } from '../../../global';

interface PopUpLoginProps {
  popup: boolean;
  close: () => void;
  inappropriate: (inappropriate: number) => void;
}

const PopUpInappropriate: React.FC<PopUpLoginProps> = (props) => {
  const currentUser = useStoreState(DslStore, getCurrentUser);
  const currentdesign = useStoreState(DslStore, getCurrentDesign);
  const [reason, setReason] = useState();
  const [popup, setPopup] = useState(props.popup);
  const [motivation, setMotivation] = useState<number | undefined>(0);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<InappropriateForm>();

  useEffect(() => {
    console.log(motivation);
  }, [motivation]);

  const onSubmit = (data: InappropriateForm) => {
    console.log(data);
    if (currentUser && currentdesign) {
      axiosHelper
        .flagDesign(currentdesign?.id, data)
        .then(
          (response) => (
            console.log(response),
            // alert(response.name + ' flagged as inappropriate'),
            props.inappropriate(1),
            props.close && props.close,
            setPopup(false)
          )
        )
        .catch((error) => (console.log(error), props.inappropriate && props.inappropriate(2)));
    }
  };

  return (
    <>
      <Modal
        show={popup}
        onHide={() => (setPopup(!popup), props.close && props.close())}
        className={`d-flex  flex-column justify-content-center `}
        contentClassName={`${styles.rounded}`}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div
            className={`d-flex flex-column justify-content-center text-center  full bg-light-gray ${styles.rounded}`}
          >
            <Modal.Body className="d-flex justify-content-center  p-3">
              {/* <form onSubmit={handleSubmit(onSubmit)}> */}
              <div className="row justify-content-center align-items-center  ">
                <div className="col-12 mb-3">
                  <span className="text-16 fw-bold ">Why are you going to flag this design?</span>
                </div>
                <div className="d-flex col-12">
                  <div className="col-3 mb-5 mt-3 d-flex flex-column align-items-start ">
                    <span className="text-16 ps-3"> Motivation</span>
                  </div>
                  <div className="col-9 mb-5 ">
                    <div className="d-flex flex-column align-items-start ms-5">
                      <div className="d-flex flex-row align-items-center mt-3 ">
                        <input
                          type="radio"
                          value={'Inappropriate'}
                          checked={motivation === 1}
                          onClick={() => (motivation === 1 ? setMotivation(0) : setMotivation(1))}
                          {...register('motivation')}
                        />
                        <span className="text ms-3"> Inappropriate</span>
                      </div>
                      <div className="d-flex flex-row align-items-center mt-3">
                        <input
                          type="radio"
                          value={'Copyrighted'}
                          checked={motivation === 2}
                          onClick={() => (motivation === 2 ? setMotivation(0) : setMotivation(2))}
                          {...register('motivation')}
                        />
                        <span className="text ms-3"> Copyrighted</span>
                      </div>
                      <div className="d-flex flex-row align-items-center mt-3">
                        <input
                          type="radio"
                          checked={motivation === 3}
                          onClick={() => (motivation === 3 ? setMotivation(0) : setMotivation(3))}
                          value={'Off topic'}
                          {...register('motivation')}
                        />
                        <span className="text ms-3"> Off topic</span>
                      </div>
                      <div className="d-flex flex-row align-items-center mt-3">
                        <input
                          type="radio"
                          checked={motivation === 4}
                          value={'Duplicated'}
                          onClick={() => (motivation === 4 ? setMotivation(0) : setMotivation(4))}
                          {...register('motivation')}
                        />
                        <span className="text ms-3"> Duplicated</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="d-flex  col-12  mb-5  ">
                  <div className="d-flex col-3  flex-column align-items-start ">
                    <span className="text-16 ps-3">Reason</span>
                  </div>
                  <div className="d-flex col-9 pe-3">
                    <textarea value={reason} {...register('reason', { onChange: (e) => setReason(e.target.value) })} />
                  </div>
                </div>

                <div className="row ">
                  <div className="col-12  d-flex justify-content-between ">
                    <div className="col-3 ps-1  d-flex  ">
                      <div className="d-flex justify-content-center align-items-center ">
                        <div
                          className={` ${styles.closeButton}`}
                          onClick={() => {
                            setPopup(!popup);
                            props.close();
                          }}
                        >
                          <FontAwesomeIcon icon={faXmark as IconProp} className=" text color-light " />
                        </div>
                        <span className="ps-3">Close</span>
                      </div>
                    </div>
                    <div className="col-4 pe-1 d-flex   ">
                      <button
                        className={`btn-warning rounded full 
                      
                     mb-3`}
                        onClick={() => console.log('manda inappropriate')}
                      >
                        flag design
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* </form> */}
            </Modal.Body>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default PopUpInappropriate;
