import React, { useEffect, useRef, useState } from 'react';
import styles from './DesignerInspirationNavigation.module.scss';

import resetFilter from '../../../assets/Reset-Filter.svg';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import DslStore, {
  setFavoriteInspirations,
  setUserInspirations,
  setUserInspirationsPageNumber,
} from '../../../store/DslStore';
import {
  getContestCategories,
  getCurrentUser,
  getInspirationsContestName,
  getUserInspirationPageNumber,
  getUserInspirations,
  getUserInspirationsCopy,
  getUserInspirationsFavorites,
  getUserInspirationsFavoritesCopy,
} from '../../../store/Selectors';
import { useStoreState } from 'pullstate';
import {
  DropDownInspirationCategory,
  DropDownInspirationSort,
  DropDownInspirationTypology,
} from '../../Forms/DropDown/DropDownInspirations';
import { InspirationVotes, UserInspiration, inspirationVote } from '../../../global';

type IProjecNavigation = {
  onChangeLayout?: (val: number) => void;
};

type PaginationSwitchProps = {
  onChangePage?: (val: number) => void;
};

export const PaginationSwitch: React.FC<PaginationSwitchProps> = ({ onChangePage = () => {} }) => {
  const pageNumber = useStoreState(DslStore, getUserInspirationPageNumber);
  const userInspirations = useStoreState(DslStore, getUserInspirations);
  let designsPerPage = 20;
  const [pagernumber, setPagerNumber] = useState(0);
  const pageNumberLimit = Math.ceil(userInspirations.length / designsPerPage);
  const [array, setArray] = useState<Array<number>>([1, 2, 3, 4, 5]);

  const arrayCorrectPage = () => {
    let arrayCorrect: Array<number> = [];
    if (pageNumberLimit < 5) {
      for (let i = 1; i <= pageNumberLimit; i++) {
        arrayCorrect[i] = i;
        console.log(arrayCorrect);
      }
      setArray(arrayCorrect);
    }
  };

  useEffect(() => {
    console.log('cambiato numero page');
    if (pageNumber >= 3 && pageNumber <= pageNumberLimit - 2) {
      setArray([pageNumber - 2, pageNumber - 1, pageNumber, pageNumber + 1, pageNumber + 2]);
    }
  }, [pageNumber]);

  useEffect(() => {
    console.log('inspirations: ' + JSON.stringify(userInspirations.length));
    console.log('pagine: ' + Math.ceil(userInspirations.length / designsPerPage));
    setPagerNumber(pageNumberLimit);
    arrayCorrectPage();
    // fillContests(pageNumber -1);
  }, [userInspirations]);

  const handleChange = (value: number) => {
    console.log(value);
    if (value > 0 && value <= pageNumberLimit) {
      onChangePage(value);
      if (pageNumber >= 3 && pageNumber <= pageNumberLimit - 2) {
        setArray([pageNumber - 2, pageNumber - 1, pageNumber, pageNumber + 1, pageNumber + 2]);
      }
    } else {
      console.log('0 o max++');
    }
  };

  return (
    <div className={`${styles.paginationSwitch} bg-light py-2 px-2 `}>
      <button className="btn-icon" onClick={() => handleChange(pageNumber - 1)}>
        <FontAwesomeIcon icon={faAngleLeft as IconProp} className="btn-icon active " />
      </button>

      {/* <button className={'btn-icon active'} onClick={() => handleChange(pageNumber - 1)}>
        <strong> &lt; </strong>
      </button> */}

      {array.map((index: number) => (
        <button
          key={index}
          className={pageNumber == index ? 'btn-icon active' : 'btn-icon'}
          onClick={() => handleChange(index)}
        >
          <small>{index}</small>
        </button>
      ))}

      {/* <button className={'btn-icon active'} onClick={() => handleChange(pageNumber + 1)}>
        <strong> &gt; </strong>
      </button> */}

      <button className="btn-icon" onClick={() => handleChange(pageNumber + 1)}>
        <FontAwesomeIcon icon={faAngleRight as IconProp} className="btn-icon active " />
      </button>

      {/* 
      <button className={"btn-icon active"} 
      //  onClick={() => handleClick(1)}
      > <b> &lt; </b> </button>
       <button className={pageNumber == 1 ? "btn-icon active" : "btn-icon"} 
      //  onClick={() => handleClick(1)}
      >
        1
      </button>
      <button className={pageNumber == 2 ? "btn-icon active" : "btn-icon"} 
      // onClick={() => handleClick(2)}
      >
        2
      </button>
      <button className={pageNumber == 3 ? "btn-icon active" : "btn-icon"} 
      // onClick={() => handleClick(3)}
      >
        3
      </button>
      <button className={pageNumber == 4 ? "btn-icon active" : "btn-icon"} 
      // onClick={() => handleClick(4)}
      >
        4
      </button>
      <button className={pageNumber == 5 ? "btn-icon active" : "btn-icon"} 
      // onClick={() => handleClick(5)}
      >
        5
      </button>  */}

      {/* <button className="btn-icon">
         <FontAwesomeIcon icon={faAngleRight as IconProp} className="text" />
       </button> */}

      {/* <Pagination count={numberOfPages} page={pageNumber} color='primary'  hideNextButton={numberOfPages > 1 ? false : true} hidePrevButton={numberOfPages > 1 ? false : true} size="small" onChange={handleChange} /> */}
    </div>
  );
};

let filterSort = [
  {
    label: 'Name',
    sort: 'name',
    value: '1',
  },
  {
    label: 'Votes',
    sort: 'votes',
    value: '2',
  },

  {
    label: 'Date',
    sort: 'date',
    value: '3',
  },
];

interface IDropDownItem {
  label: string;
  sort: string;
  value: string;
}

const DesignerInspirationNavigation: React.FC<IProjecNavigation> = (props) => {
  const userInspirations = useStoreState(DslStore, getUserInspirations);
  const userInspirationsCopy = useStoreState(DslStore, getUserInspirationsCopy);
  const categories = useStoreState(DslStore, getContestCategories);
  const userFavoriteInspirations = useStoreState(DslStore, getUserInspirationsFavorites);
  const userFavoriteInspirationsCopy = useStoreState(DslStore, getUserInspirationsFavoritesCopy);

  const inspirationsContestName = useStoreState(DslStore, getInspirationsContestName);

  // const [typologyFilter, setTypologyFilter] = useState<IDropDownItem[]>([]);
  const [typologyFilter, setTypologyFilter] = useState<IDropDownItem[]>([]);
  const [categoriesFilter, setCategoriesFilter] = useState<IDropDownItem[]>([]);
  const [typologySort, setTypologySort] = useState('');
  const [categoriesSort, setCategoriesSort] = useState('');
  const [sortBySort, setSortBySort] = useState('');

  const [descs, setDescs] = useState(false);
  let designsPerPage = 20;
  const [reload, setReload] = useState(false);

  const pageNumberLimit = Math.ceil(userInspirations.length / designsPerPage);

  let filterCategories = [
    {
      label: 'See all',
      sort: 'all',
      value: '0',
    },
    {
      label: 'My wall',
      sort: 'My Wall',
      value: '1',
    },
    {
      label: 'Liked',
      sort: 'liked',
      value: '2',
    },
    {
      label: '',
      sort: '',
      value: '3',
    },
    {
      label: 'Contest',
      sort: '',
      value: '4',
    },
  ];

  useEffect(() => {
    let newItems: IDropDownItem[] = filterCategories;
    if (inspirationsContestName) {
      console.log(inspirationsContestName);
      inspirationsContestName.map((item) => {
        if (!newItems.includes({ label: `${item}`, sort: `${item}`, value: `${item}` })) {
          newItems.push({ label: `${item}`, sort: `${item}`, value: `${item}` });
          // setTypologyFilter(newItems);
          // console.log(newItems);
          setTypologyFilter(newItems);
        }
      });
    }
  }, [inspirationsContestName]);

  useEffect(() => {
    let newItems: IDropDownItem[] = [];
    if (categories) {
      console.log(categories);
      categories.map((item, index: number) => {
        let name = item.name === 'Link to Other' ? 'Partnership' : item.name;

        if (!newItems.includes({ label: `${name}`, sort: `${item.name}`, value: `${index}` })) {
          newItems.push({ label: `${name}`, sort: `${item.name}`, value: `${index}` });
          // setTypologyFilter(newItems);
          // console.log(newItems);
        }
      });
      setCategoriesFilter(newItems);
    }
  }, [categories]);

  const sortUserInspirationsFavorites = () => {
    let sortedFavorite: InspirationVotes[] = [...userFavoriteInspirationsCopy];
    if (typologySort) {
      console.log('abbiamo typology');
      if (typologySort === 'all') {
        sortedFavorite = sortedFavorite;
      } else if (typologySort === 'liked') {
        sortedFavorite = sortedFavorite;
      } else if (typologySort === 'My Wall') {
        let MyWallInspirationsFavorites: InspirationVotes[] = [];

        sortedFavorite.map((item) => {
          !item.inspiration.contest && MyWallInspirationsFavorites.push(item);
        });
        sortedFavorite = MyWallInspirationsFavorites;
      } else {
        console.log(typologySort);
        let favoritesTypologySorted: InspirationVotes[] = [];
        sortedFavorite.map(
          (item) => item.inspiration.contest?.name === typologySort && favoritesTypologySorted.push(item)
        );
        sortedFavorite = favoritesTypologySorted;
      }
    }
    if (categoriesSort) {
      console.log('abbiamo category');
      let categoriesSortedFavorites: InspirationVotes[] = [];

      sortedFavorite.map((item) => {
        if (item.inspiration.contest) {
          if (item.inspiration.contest.category) {
            item.inspiration.contest.category.name === categoriesSort && categoriesSortedFavorites.push(item);
          }
        }
      });

      sortedFavorite = categoriesSortedFavorites;
    }
    if (sortBySort) {
      console.log('abbiamo sortby');
      if (sortBySort === 'date') {
        if (descs) {
          sortedFavorite.sort((a, b) => {
            return b.inspiration.uploadDate.localeCompare(a.inspiration.uploadDate);
          });
        } else {
          sortedFavorite.sort((a, b) => {
            return a.inspiration.uploadDate.localeCompare(b.inspiration.uploadDate);
          });
        }
      }
      if (sortBySort === 'votes') {
        if (descs) {
          sortedFavorite.sort((a, b) =>
            b.inspiration.inspirationVotes?.length > a.inspiration.inspirationVotes?.length
              ? 1
              : a.inspiration.inspirationVotes?.length > b.inspiration.inspirationVotes?.length
              ? -1
              : 0
          );
        } else {
          sortedFavorite.sort((a, b) =>
            b.inspiration.inspirationVotes?.length > a.inspiration.inspirationVotes?.length
              ? -1
              : a.inspiration.inspirationVotes?.length > b.inspiration.inspirationVotes?.length
              ? 1
              : 0
          );
        }
      }
      if (sortBySort === 'name') {
        if (descs) {
          sortedFavorite.sort((a, b) => {
            let aName = a.inspiration.contest?.name ? a.inspiration.contest.name.toLowerCase() : 'my wall';
            let bName = b.inspiration.contest?.name ? b.inspiration.contest.name.toLowerCase() : 'my wall';
            return aName.localeCompare(bName);
          });
        } else {
          sortedFavorite.sort((a, b) => {
            let aName = a.inspiration.contest?.name ? a.inspiration.contest.name.toLowerCase() : 'my wall';
            let bName = b.inspiration.contest?.name ? b.inspiration.contest.name.toLowerCase() : 'my wall';
            return bName.localeCompare(aName);
          });
        }
      }
    }

    setFavoriteInspirations(sortedFavorite);
  };

  const sortUserInspirations = () => {
    let sorted: UserInspiration[] = [...userInspirationsCopy];

    if (typologySort) {
      console.log('abbiamo typology');
      if (typologySort === 'all') {
      } else if (typologySort === 'liked') {
        let inspirationsLiked: UserInspiration[] = [];
        sorted = inspirationsLiked;
      } else if (typologySort === 'My Wall') {
        let MyWallInspirations: UserInspiration[] = [];

        sorted.map((item) => {
          !item.contest && MyWallInspirations.push(item);
        });
        sorted = MyWallInspirations;
      } else {
        console.log(typologySort);
        let typologySorted: UserInspiration[] = [];
        sorted.map((item) => item.contest?.name === typologySort && typologySorted.push(item));

        sorted = typologySorted;
      }
    }
    if (categoriesSort) {
      console.log('abbiamo category');
      let categoriesSorted: UserInspiration[] = [];

      // sorted.map((item) => {
      //   if (item.contest) {
      //     item.contest.category.name === categoriesSort && categoriesSorted.push(item);
      //   }
      // });

      sorted.map((item) => {
        if (item.contest) {
          if (item.contest.category) {
            console.log(item.contest.category);
            item.contest.category.name === categoriesSort && categoriesSorted.push(item);
          }
        }
      });

      sorted = categoriesSorted;
    }
    if (sortBySort) {
      console.log('abbiamo sortby');
      if (sortBySort === 'date') {
        if (descs) {
          sorted.sort((a, b) => {
            return b.uploadDate.localeCompare(a.uploadDate);
          });
        } else {
          sorted.sort((a, b) => {
            return a.uploadDate.localeCompare(b.uploadDate);
          });
        }
      }
      if (sortBySort === 'votes') {
        if (descs) {
          sorted.sort((a, b) =>
            b.inspirationVotes?.length > a.inspirationVotes?.length
              ? 1
              : a.inspirationVotes?.length > b.inspirationVotes?.length
              ? -1
              : 0
          );
        } else {
          sorted.sort((a, b) =>
            b.inspirationVotes?.length > a.inspirationVotes?.length
              ? -1
              : a.inspirationVotes?.length > b.inspirationVotes?.length
              ? 1
              : 0
          );
        }
      }
      if (sortBySort === 'name') {
        if (descs) {
          sorted.sort((a, b) => {
            let aName = a.contest?.name ? a.contest.name.toLowerCase() : 'my wall';
            let bName = b.contest?.name ? b.contest.name.toLowerCase() : 'my wall';
            return bName.localeCompare(aName);
          });
        } else {
          sorted.sort((a, b) => {
            let aName = a.contest?.name ? a.contest.name.toLowerCase() : 'my wall';
            let bName = b.contest?.name ? b.contest.name.toLowerCase() : 'my wall';
            return aName.localeCompare(bName);
          });
        }
      }
    }
    console.log(userInspirationsCopy);
    setUserInspirations(sorted);
  };

  useEffect(() => {
    sortUserInspirations();
    sortUserInspirationsFavorites();
  }, [typologySort, sortBySort, categoriesSort, descs]);

  return (
    <div className="row ">
      <div className="col-12  col-lg-10 d-flex flex-column flex-lg-row justify-content-between mb-4 mb-md-0">
        <div className="me-0 me-md-5 w-100 mb-sm-4 mb-lg-0">
          <DropDownInspirationTypology
            label="Typology"
            options={typologyFilter}
            reset={reload}
            onSelect={(option) => option.sort && setTypologySort(option.sort)}
            thin
          />
        </div>
        <div className="me-0 me-md-5 w-100 mb-sm-4 mb-lg-0">
          <DropDownInspirationCategory
            label="Category"
            options={categoriesFilter}
            reset={reload}
            onSelect={(option) => option.sort && setCategoriesSort(option.sort)}
            thin
          />
        </div>
        <div className="me-0 me-md-5  mb-sm-4 mb-lg-0 w-100" >
          
          <DropDownInspirationSort
            label="Sort by"
            options={filterSort}
            reset={reload}
            onSelect={(option) => option.sort && setSortBySort(option.sort)}
            descs={(descs) => setDescs(descs)}
            thin
          />
        </div>

        <div className=" d-flex flex-row align-items-center justify-content-center h-100 w-100">
          <div
            // className="btn-blue rounded"
            className=" d-flex flex-row align-items-center justify-content-center btn-reset-blue  "
            onClick={() => (
              setReload(!reload),
              setTypologySort(''),
              setCategoriesSort(''),
              setSortBySort(''),
              setUserInspirations(userInspirationsCopy),
              setFavoriteInspirations(userFavoriteInspirationsCopy)
            )}
            style={{ width: '3rem', height: '3rem', borderRadius: '8px' }}
          >
            <img src={resetFilter} style={{ height: '25px' }} />
          </div>
        </div>
      </div>
      <div className={`col-12 col-md-6 col-lg-2  d-flex  justify-content-sm-start justify-content-lg-end `}>
        {pageNumberLimit > 0 ? <PaginationSwitch onChangePage={setUserInspirationsPageNumber} /> : ''}
        <div>{/* <DesignerInspirationsLayoutSwitch onChangeLayout={props.onChangeLayout} /> */}</div>
      </div>
    </div>
  );
};

export default DesignerInspirationNavigation;
