import React, { useState, useRef, useEffect } from 'react';
import styles from './DropDown.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface IDropDown {
  options?: IDropDownItem[];
  onSelect?: (o: IDropDownItem) => void;
  label?: string;
  thin?: boolean;
  contestName?: string;
}

interface IDropDownItem {
  label: string;
  value: string;
  contestId?: number;
}

export const DropDownContactUs: React.FC<IDropDown> = (props) => {
  const dropDownContainer = useRef<any>(null);
  let [open, setOpen] = useState<boolean>(false);
  let [itemSelected, setItemSelected] = useState<IDropDownItem>();

  const generateOptionsList = (options: IDropDownItem[]) => {
    // console.log(itemSelected);
    let indexedOptions: IDropDownItem[] = [];
    options.forEach((item, index) => {
      indexedOptions.push({
        ...item,
      });
    });
    return indexedOptions;
  };

  const globalClickListener = (e: MouseEvent) => {
    if (dropDownContainer.current && dropDownContainer.current.contains(e.target)) {
      // setDropDownAnimation(true);
      return;
    } else {
      setOpen(false);
    }
  };

  // useEffect(() => {
  //   setDropDownAnimation(true);
  // }, [open]);

  const handleOnClick = () => {
    if (!open) {
      document.addEventListener('click', (e) => globalClickListener(e));
    } else {
      document.removeEventListener('click', (e) => globalClickListener(e));
      
    }
    setOpen(!open);
  };

  const handleOnSelect = (option: IDropDownItem) => {
    console.log(option);
    setItemSelected(option);
    setOpen(!open);
    // setDropDownAnimation(true);
    props.onSelect && props.onSelect(option);
  };
  const getSelectedStyle = (valueselected: string) => {
    return itemSelected && itemSelected.label === valueselected ? styles.selected : '';
  };

  const optionsList = props.options ? generateOptionsList(props.options) : [];

  // const [dropdownAnimation, setDropDownAnimation] = useState(false);

  return (
    <div
      ref={dropDownContainer}
      className={`${styles.dropdown_wrapper}    ${props.thin && styles.thin_ui}`}
      style={{ borderRadius: '8px' }}
    >
      <div className={`${styles.dropdown} d-flex  ${open ? styles.open : ''}`}>
        <div className={`${styles.dropdown__toggle}   `} onClick={handleOnClick}>
          {props.label && <div className="fw-bold  text-uppercase pe-3"> {props.label}</div>}
          <span className={`${styles.dropdown__label} d-flex  w-75 ${props.label && 'text-center flex-grow-1'}`}>
            {itemSelected ? itemSelected.label : props.contestName ? props.contestName : 'Choose One'}
          </span>
          <div className={`${styles.arrow}`}></div>
          {/* <div
            className="d-flex w-50   flex-row-reverse "
            // onClick={handleOnClick}
            // onAnimationEnd={() => setDropDownAnimation(false)}
          >
            {!open && (
              <div
                className={`${styles.dropdown_angle}  ${dropdownAnimation ? styles.transitionIn : null}`}
                style={{ right: 0 }}
                onAnimationEnd={() => setDropDownAnimation(false)}
              >
                <FontAwesomeIcon icon={faAngleDown as IconProp} className={`text color-blue `} />
              </div>
            )}
            {open && (
              <div
                className={`${styles.dropdown_angle} ${dropdownAnimation ? styles.transitionIn : null}`}
                onAnimationEnd={() => setDropDownAnimation(false)}
              >
                <FontAwesomeIcon icon={faAngleUp as IconProp} className={`text color-blue `} />
              </div>
            )}
          </div> */}
        </div>

        <div className={`${styles.dropdown__optionslist}`}>
          {optionsList.map((option, index) => (
            <div
              key={index + option.value}
              className={`${styles.dropdown__option} ${getSelectedStyle(option.label)}`}
              data-value={option.value}
              onClick={() => handleOnSelect(option)}
            >
              {option.label}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
