import React, { useEffect, useState } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//Icons
import { IconDefinition, faPencil, faDollarSign, faTrophy, faStar } from '@fortawesome/free-solid-svg-icons';

import styles from './contestStats.module.scss';
import { useStoreState } from 'pullstate';
import DslStore from '../../../store/DslStore';
import { getCurrentContest } from '../../../store/Selectors';
import { useNavigate } from 'react-router-dom';
import { ROUTEPATHS } from '../../../routes/routePaths';
import { User } from '../../../global';
import { set } from 'react-hook-form';

interface Props {
  user: User;
}

interface IStatsItem {
  icon: IconDefinition;
  name: string;
  value: number | string;
  clickable?: boolean;
}

const StatsItem = (props: IStatsItem) => {
  const contest = useStoreState(DslStore, getCurrentContest);
  const redirect = useNavigate();

  const goTo = () => {
    if (props.clickable) {
      console.log(props.name);
      if (props.name === 'entries') {
        redirect(`/${ROUTEPATHS.CONTEST}/${contest?.nameForUrl}/${ROUTEPATHS.CONTEST_GALLERY}`);
      } else if (props.name === 'inspirations') {
        redirect(`/${ROUTEPATHS.CONTEST}/${contest?.nameForUrl}/${ROUTEPATHS.CONTEST_INSPIRATIONS}`);
      } else if (props.name === 'total awards') {
        redirect(`/${ROUTEPATHS.CONTEST}/${contest?.nameForUrl}/${ROUTEPATHS.CONTEST_AWARDS}`);
      } else if (props.name === 'votes received') {
        redirect(`/${ROUTEPATHS.CONTEST}/${contest?.nameForUrl}/${ROUTEPATHS.CONTEST_GALLERY}`);
      }
    }
  };

  return (
    <div className={`d-flex flex-column justify-content-center align-items-center text-center mb-4 px-4`} style={{ minWidth: '100px' }}>
      <div className="w-100 d-flex justify-content-center align-items-center text-center" style={{ height: '35px' }}>
        <FontAwesomeIcon icon={props.icon as IconProp} className="text color-blue" />
      </div>
      <div className={`title ${props.clickable ? `color-blue cursor-pointer` : ``}  d-flex justify-content-center align-items-center text-center`} onClick={() => goTo()}>
        {props.value}
      </div>
      <div className="text-12 text-center">{props.name}</div>
    </div>
  );
};

export const ProfileStatsWLF: React.FC<Props> = ({ user }) => {
  const [entries, setEntries] = useState<number>();
  const [awards, setAwards] = useState<number>();
  const [awardsString, setAwardsString] = useState<string>('');
  const [contestsString, setContestsString] = useState<string>('');
  useEffect(() => {
    if (user.userType === 'D') {
      setEntries(user.badgeDesignsOut);
      setAwards(user.badgeAmountIn);
      setAwardsString('awards won');
      setContestsString('contests won');
    } else {
      setEntries(user.badgeDesignsIn);
      setAwards(user.badgeAmountOut);
      setAwardsString('awarded');
      setContestsString('contests launched');
    }
  }, [user]);

  return (
    <div className={`d-flex flex-row justify-content-end ${styles.statsWrapper}`}>
      <div className="d-flex flex-row justify-content-end bg-light radius-m">
        {awards ? <StatsItem name={awardsString} value={'€ ' + awards} icon={faDollarSign} /> : <StatsItem name={awardsString} value={'€ ' + 0} icon={faDollarSign} />}
        {user.contestsCount ? <StatsItem name={contestsString} value={user.contestsCount} icon={faTrophy} /> : <StatsItem name={contestsString} value={0} icon={faTrophy} />}
        {entries !== 0 ? entries && <StatsItem name="entries" value={entries} icon={faPencil} /> : <StatsItem name="entries" value={0} icon={faPencil} />}
        {user.reputationCount ? <StatsItem name="reputation" value={user.reputationCount} icon={faStar} /> : <StatsItem name="reputation" value={0} icon={faStar} />}
      </div>
    </div>
  );
};
