import { INavLink } from "../global";

export const topNavPages: INavLink[] = [
  {
    text: 'Home',
    path: '/',
    id: 0,
  },
  {
    text: 'Typography',
    path: '/typography',
    id: 1,
  },
  {
    text: 'Images',
    path: '/images',
    id: 2,
  },
  {
    text: 'Buttons',
    path: '/buttons',
    id: 3,
  },
  {
    text: 'Cards',
    path: '/cards',
    id: 4,
  },
  {
    text: 'Forms',
    path: '/forms',
    id: 5,
  },
  {
    text: 'Components',
    path: '/components',
    id: 6,
  },
];
