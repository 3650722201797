import React from 'react';
import { GifComponent, TwoElementsComponent } from '../../../global';
import { pathStrapiImage } from '../../../helpers/utils';
import HeroSection from '../../HeroSection/HeroSection';
import HorizontalSection from '../../HorizontalSection/HorizontalSection';
import SplitSection from '../../SplitSection/SplitSection';
import styles from './SplittedComponent.module.scss';

const SplittedComponent: React.FC<TwoElementsComponent> = (props) => {
  return (
    <SplitSection
      key={props.id}
      bgColorLeft={`${props.leftside.bgcolor}`}
      bgColorRight={`${props.rightside.bgcolor}`}
      itemLeft={
        <>
          <div>
            <div className="row">
              <div className="col-12   ms-3">
                <h1
                  className={`bold-title mb-3  ${
                    props.leftside.titlealign ? `text-${props.leftside.titlealign}` : `text-start`
                  } color-${props.leftside.titleColor} `}
                >
                  {props.leftside.title}
                </h1>
                <p
                  className={`sub-title  ${
                    props.leftside.subtitlealign ? `text-${props.leftside.subtitlealign}` : `text-start`
                  } color-${props.leftside.subtitlecol}`}
                >
                  {props.leftside.subtitle}
                </p>
              </div>
            </div>
            <div className={`row py-4 ms-1   ${styles.textwrapper}  `}>
              <div
                className={`col-12 col-lg-6 pe-md-5 text-16 color-dark ${
                  props.leftside.textalign ? `text-${props.leftside.textalign}` : `text-start`
                }  `}
              >
                <span className={`pe-3 pe-md-5   color-${props.leftside.colText}`}>{props.leftside.text}</span>
                <div className="col-12 mt-lg-6 mt-sm-4  d-flex justify-content-start align-items-end ">
                  <a
                    href={props.leftside.link && props.leftside.link}
                    target={`${props.leftside.target ? props.leftside.target : '_self'}`}
                  >
                    <button className={`btn-${props.leftside.buttoncolor} rounded`}>{props.leftside.buttontext}</button>
                  </a>
                </div>
              </div>
              <div className="col-12 col-lg-6 mt-md-4 mt-lg-0 d-flex justify-content-end pe-3 ">
                <img
                  className="img-270 "
                  src={pathStrapiImage(props.leftside.image.data.attributes.url)}
                  alt="Placeholder"
                />
              </div>
            </div>
          </div>
        </>
        // ))
      }
      itemRight={
        <>
          <div>
            <div className="row">
              <div className="col-12 ms-4 ps-4  ">
                <h1
                  className={`bold-title  mb-3 ${
                    props.rightside.titlealign ? `text-${props.rightside.titlealign}` : `text-start`
                  } color-${props.rightside.titleColor}`}
                >
                  {props.rightside.title}
                </h1>
                <p
                  className={`sub-title ${
                    props.rightside.subtitlealign ? `text-${props.rightside.subtitlealign}` : `text-start`
                  } color-${props.rightside.subtitlecol}`}
                >
                  {props.rightside.subtitle}
                </p>
              </div>
            </div>
            <div className={`row py-4 ms-2 ${styles.textwrapper}  `}>
              <div className="col-12 col-lg-6 ms-3  pe-5 me-1 ">
                <div
                  className={`me-3 pe-2  ${
                    props.rightside.textalign ? `text-${props.rightside.textalign}` : `text-start`
                  } `}
                >
                  <span className={` text-16 color-${props.rightside.colText}`}>{props.rightside.text}</span>
                </div>
                <div className="col-12 d-flex justify-content-start align-items-end mt-md-4 mt-sm-4 mb-5 ">
                  <a
                    href={props.rightside.link && props.rightside.link}
                    target={`${props.rightside.target ? props.rightside.target : '_self'}`}
                  >
                    <button className={`btn-${props.rightside.buttoncolor} rounded`}>
                      {props.rightside.buttontext}
                    </button>
                  </a>
                </div>
              </div>
              <div className="col-12 col-lg-5 mt-md-4 mt-lg-0 pe-lg-4  d-flex  justify-content-center ">
                <img
                  className="img-270 me-3"
                  src={pathStrapiImage(props.rightside.image.data.attributes.url)}
                  alt="Placeholder"
                />
              </div>
            </div>
            <div className="row"></div>
          </div>
        </>
      }
    />
  );
};

export default SplittedComponent;
