import { CaseStudiesSmallRowProps } from '../../../../global';
import { bgcomponent, pathStrapiImage } from '../../../../helpers/utils';

import SplitSection from '../../../SplitSection/SplitSection';

function CaseStudiesSmallRowComponent(props: CaseStudiesSmallRowProps) {
  return (
    <>
      <SplitSection
        key={props.id}
        bgColorLeft={props.bgcolor}
        bgColorRight={props.bgcolor}
        itemLeft={
          <div className="row">
            {props.image1 && (
              <div className="col-12 col-md-6">
                <img
                  src={props.image1 && pathStrapiImage(props.image1.data.attributes.url)}
                  className="img-fluid"
                  alt="Placeholder"
                />
              </div>
            )}
            {props.image2 && (
              <div className="col-12 col-md-6">
                <img
                  src={pathStrapiImage(props.image2 && props.image2.data.attributes.url)}
                  className="img-fluid"
                  alt="Placeholder"
                />
              </div>
            )}
          </div>
        }
        itemRight={
          <div className="row h-100 d-flex flex-column justify-content-center">
            <h2
              className={`bold-title color-${props.titlecol}  ${
                props.titlealign ? `text-${props.titlealign}` : `text-start`
              } mb-7`}
            >
              {props.title}
            </h2>
            <p
              className={`text-16 color-${props.textcolor}  ${
                props.textalign ? `text${props.textalign}` : `text-start`
              } mt-5`}
            >
              {props.text}
            </p>
          </div>
        }
      />
    </>
  );
}

export default CaseStudiesSmallRowComponent;
