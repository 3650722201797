import React, { useEffect, useRef, useState } from 'react';
import styles from './ProfilePic.module.scss';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faXmark } from '@fortawesome/free-solid-svg-icons';
import UserPic from '../../Community/UserPic/UserPic';
import FileUpload from './FileUpload/FileUpload';
import { Attachment } from '../../../global';
import axiosHelper from '../../../helpers/axiosHelper';
import { useStoreState } from 'pullstate';
import DslStore, { setCommunityUser, setCurrentUser } from '../../../store/DslStore';
import { getCurrentUser } from '../../../store/Selectors';

interface IUserPic {
  image?: string;
  onImageAdd?: (e: any) => void;
  onImageRemove?: () => void;
  editable?: boolean;
  children?: React.ReactNode;
}
export const ProfilePic: React.FC<IUserPic> = (props) => {
  const currentUser = useStoreState(DslStore, getCurrentUser);
  const uuidImage = useRef<string>('');

  const uploadedImage = useRef(props.image);

  const handleImageAdd = (e: any) => {
    if (props.onImageAdd) {
      console.log('aggiunta immagine');
      console.log(e);
    } else return false;
  };
  

  const handleUuidPresigned = (e: any) => {
    console.log('uuid: ' + e);
    uuidImage.current = e;
    setTimeout(async () => {
      if (currentUser?.userType === 'D') {
        await axiosHelper.updateDesignerImages(e, currentUser?.id)?.then((response) => {
          console.log(response);
          setCurrentUser(response);
          setCommunityUser(response);
          uploadedImage.current = response.logoAttachment.s3Path;
          props.onImageAdd && props.onImageAdd(e);
        });
      } else if (currentUser?.userType === 'C') {
        await axiosHelper.updateClientImages(e, currentUser?.id)?.then((response) => {
          console.log(response);
          setCurrentUser(response);
          setCommunityUser(response);
          uploadedImage.current = response.logoAttachment.s3Path;
          props.onImageAdd && props.onImageAdd(e);
        });
      }
    }, 1500);
  };

  const handleImageRemove = async () => {
    if (currentUser?.userType === 'D') {
      await axiosHelper.removeLogoDesigner(currentUser?.id)?.then((response) => {
        console.log(response);
        setCurrentUser(response);
        setCommunityUser(response);
        uploadedImage.current = '';
      });
      props.onImageRemove && props.onImageRemove();
    }
    if (currentUser?.userType === 'C') {
      await axiosHelper.removeLogoClient(currentUser?.id)?.then((response) => {
        console.log(response);
        setCurrentUser(response);
        uploadedImage.current = '';
      });
      props.onImageRemove && props.onImageRemove();
    }
  };

  const AddButton = () => {
    return (
      <button className={`${styles.addButton} bg-blue`} onClick={handleImageAdd}>
        <FontAwesomeIcon icon={faPlus as IconProp} className=" text color-light " />
      </button>
    );
  };

  const AddArea = () => {
    return (
      <div className={`${styles.addArea}`}>
        <FileUpload onImageAdd={handleImageAdd} uuidPresigned={handleUuidPresigned} />
      </div>
    );
  };

  const RemoveButton = () => {
    return (
      <button className={`${styles.removeButton} bg-danger`} onClick={() => handleImageRemove()}>
        <FontAwesomeIcon icon={faXmark as IconProp} className=" text color-light" />
      </button>
    );
  };

  return (
    <div className={`${styles.profilePicWrapper}  position-relative `}>
      <div className="position-relative ">
        {/* <UserPic image={props.image} /> */}

        <UserPic image={uploadedImage.current} />
        {/* area per grafica per aggiungere immagine */}
        {!uploadedImage.current && props.editable && <AddArea />}
      </div>
      {uploadedImage.current && props.editable && <RemoveButton />}
      {/* {!props.image && props.editable ? 'justify-content-between' : 'justify-content-end'} */}
      {uploadedImage ? (
        // (console.log('image', uploadedImage.current),
        <div className={`${styles.badges}`}>
          {props.children ? <div className="d-flex ">{props.children}</div> : ''}
          {!uploadedImage && props.editable && <AddButton />}
        </div>
      ) : (
        <div className={`${styles.badgesTransformed} d-flex justify-content-end`}>
          {props.children && props.editable ? (
            <div className="d-flex ">{props.children}</div>
          ) : (
            <div className="d-flex pt-7"></div>
          )}
          {/* {!props.image && props.editable && <AddButton />} */}
        </div>
      )}
    </div>
  );
};

export const ProfilePicDesign: React.FC<IUserPic> = (props) => {
  const uuidImage = useRef<string>('');

  const uploadedImage = useRef(props.image);

  const handleImageAdd = (e: any) => {
    if (props.onImageAdd) {
      console.log('aggiunta immagine');
      console.log(e);
      const localURL = URL.createObjectURL(e);
      uploadedImage.current = localURL;
    } else return false;
  };

  useEffect(() => {
    // console.log(props.image);
    uploadedImage.current = props.image;
  }, [props]);

  const handleUuidPresigned = (e: any) => {
    console.log('uuid: ' + e);
    uuidImage.current = e;
    props.onImageAdd && props.onImageAdd(e);
    setTimeout(() => {}, 2000);
  };

  const handleImageRemove = () => {
    if (props.onImageRemove) {
      uploadedImage.current = '';
      return props.onImageRemove();
    } else return false;
  };

  const AddButton = () => {
    return (
      <button className={`${styles.addButton} bg-blue`} onClick={handleImageAdd}>
        <FontAwesomeIcon icon={faPlus as IconProp} className=" text color-light " />
      </button>
    );
  };

  const AddArea = () => {
    return (
      <div className={`${styles.addArea}`}>
        <FileUpload onImageAdd={handleImageAdd} uuidPresigned={handleUuidPresigned} />
      </div>
    );
  };

  const RemoveButton = () => {
    return (
      <button className={`${styles.removeButton} bg-blue`} onClick={() => handleImageRemove()}>
        <FontAwesomeIcon icon={faXmark as IconProp} className=" text color-light" />
      </button>
    );
  };

  return (
    <div className={`${styles.profilePicWrapper} position-relative `}>
      <div className="position-relative ms-2">
        {/* <UserPic image={props.image} /> */}

        <UserPic image={uploadedImage.current} />
        {/* area per grafica per aggiungere immagine */}
        {!uploadedImage && props.editable && <AddArea />}
      </div>
      {uploadedImage && props.editable && <RemoveButton />}
      {/* {!props.image && props.editable ? 'justify-content-between' : 'justify-content-end'} */}
      {uploadedImage ? (
        (console.log('image', uploadedImage.current),
        (
          <div className={`${styles.badges} d-flex justify-content-end`}>
            {props.children ? <div className="d-flex">{props.children}</div> : ''}
            {!uploadedImage && props.editable && <AddButton />}
          </div>
        ))
      ) : (
        <div className={`${styles.badgesTransformed} d-flex justify-content-end`}>
          {props.children && props.editable ? (
            <div className="d-flex">{props.children}</div>
          ) : (
            <div className="d-flex pt-7"></div>
          )}
          {/* {!props.image && props.editable && <AddButton />} */}
        </div>
      )}
    </div>
  );
};
