import React, { useEffect, useRef, useState } from 'react';

import AppHeader from '../../components/AppHeader/AppHeader';
import AppFooter from '../../components/AppFooter/AppFooter';
import { ReactImageGalleryItem } from 'react-image-gallery';

import cacheHelper from '../../helpers/cacheHelper';

import designDefaultLogo from '../../assets/Private-Design.png';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FadeInOut from '../../components/FadeInOut/FadeInOut';

import {
  faFileArrowDown,
  faAngleUp,
  faAngleDown,
  faExpand,
  faMedal,
  faCompress,
  faXmark,
  faHeart,
} from '@fortawesome/free-solid-svg-icons';

//Header Data
import headerimg from '../../assets/header_placeholder_transparent.png';
import headerLogo from '../../assets/desall_logo.png';

//Footer Data
import footerImg from '../../assets/footer_placeholder_transparent.png';

import {
  getCoCreationContestGallery,
  getContestGallery,
  getCurrentContest,
  getCurrentDesign,
  getCurrentUser,
  getFooterMenu,
  getHeaderMenu,
} from '../../store/Selectors';
import { useStoreState } from 'pullstate';
import DslStore, {
  setCoCreationContest,
  setContestGallery,
  setContestGalleryCopy,
  setCurrentContest,
  setCurrentDesign,
  setDesignChargingError,
  setDesignsILike,
} from '../../store/DslStore';
import styles from './DesignDetails.module.scss';

import { DesignPager } from '../../components/Design/DesignPager/DesignPager';

import { DesignUserCard } from '../../components/Design/DesignUserCard/DesignUserCard';
import { DesignComment, DesignCommentMaker } from '../../components/Design/DesignComment/DesignComment';
import { designUploadDateString, fullPathLogo, fullPathLogoUser } from '../../helpers/utils';
import { CommentDesign, ContestTag, DesignDetails, DesignVotes } from '../../global';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';
import { Navigation } from 'swiper/modules';
import axiosHelper from '../../helpers/axiosHelper';
import { useNavigate, useParams } from 'react-router-dom';
import PopUpShare from '../../components/PopUp/PopUpShare/PopUpShare';
import PopUpInappropriate from '../../components/PopUp/PopUpInappropriate/PopUpInappropriate';
import { response } from 'express';
import PopUpDeleteDraft from '../../components/PopUp/PopUpDeleteDraft/PopUpDeleteDraft';
import { faFacebookF, faPinterest, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import PopUpPublishDraft from '../../components/PopUp/PopUpEditDraft/PopUpPublishDraft';
import PopUpErrorUploadDesign from '../../components/PopUp/PopUpErrorUploadDesign/PopUpErrorUpladDesign';
import PopUpLogin from '../../components/PopUp/PopUpLogin/PopUpLogin';
import { ROUTEPATHS } from '../routePaths';

const DesignDetailsPage: React.FC = () => {
  const currentdesign = useStoreState(DslStore, getCurrentDesign);

  const contest = useStoreState(DslStore, getCurrentContest);
  const currentUser = useStoreState(DslStore, getCurrentUser);
  const [designIndex, setDesignIndex] = useState(0);
  const [cocreationDesignIndex, setCocreationDesignIndex] = useState(0);
  const designs = useStoreState(DslStore, getContestGallery);
  const user = useStoreState(DslStore, getCurrentUser);
  const footerMenu = useStoreState(DslStore, getFooterMenu);
  const headerMenu = useStoreState(DslStore, getHeaderMenu);
  const [bigGallery, setBigGallery] = useState(false);
  const [userImage, setUserImage] = useState(fullPathLogoUser(currentdesign?.designer.logoAttachment?.s3Path));
  const [visibility, setVisibility] = useState(0);
  const [designImages, setDesignImages] = useState<ReactImageGalleryItem[]>([]);
  const [hasAwards, setHasAwards] = useState(0);
  const [activeIndex, setActiveIndex] = useState<any>(0);
  const swiperNavPrevRef = useRef(null);
  const swiperNavNextRef = useRef(null);
  const [newDesign, setNewDesign] = useState<DesignDetails>();
  const cocreationContestGallery = useStoreState(DslStore, getCoCreationContestGallery);
  const [design, setDesign] = useState(currentdesign);
  const [liked, setLiked] = useState(false);
  const [popupShare, setPopupShare] = useState(false);
  const [popupInappropriate, setPopupInappropriate] = useState(false);
  const [voted, setVoted] = useState(false);
  const [flagBanner, setFlagBanner] = useState(0);
  const [flagCommentBanner, setFlagCommentBanner] = useState(0);
  const [deleteCommentBanner, setDeleteCommentBanner] = useState(0);
  const [popUpDeleteDraft, setPopUpDeleteDraft] = useState(false);
  const [popUpPublishDraft, setPopUpPublishDraft] = useState(false);
  const [popUpErrorUploadDesign, setPopUpErrorUploadDesign] = useState(false);
  const [popUpLogin, setPopUpLogin] = useState(false);

  const name = useParams();
  const date = new Date().toISOString();
  const redirect = useNavigate();
  const [daysLeft, setDaysLeft] = useState(0);
  const awardName = useRef('');

  useEffect(() => {
    console.log(design);
    console.log(name.name);
    // console.log(window.location.href);

    if (design?.nameForURL !== name.name) {
      searchDesign();
    }
  }, []);

  const contestGallery = async (contestId: number) => {
    await axiosHelper.getContestGallery(contestId)?.then((response) => {
      setContestGallery(response);
      setContestGalleryCopy(response);
    });
  };

  const getcocreationContestGallery = async (contestId: number) => {
    await axiosHelper.getCoCreationContests(contestId)?.then((response) => {
      setCoCreationContest(response);
    });
  };

  useEffect(() => {
    if (design) {
      if (design.contest.contestType.name !== 'Co-creation') {
        contestGallery(design.contest.id);
      } else {
        getcocreationContestGallery(design.contest.id);
      }
    }
  }, [design]);

  const searchDesign = async () => {
    await axiosHelper.retrieveDesignByName(name.name)?.then((response) => {
      if (response) {
        console.log(response);
        if (response.nameForURL) {
          if (!response.contest.isPrivate) {
            setDesign(response);
            setCurrentDesign(response);
          } else if (currentUser?.username === response.designer.username) {
            console.log('ao dio porco');
            setDesign(response);
            setCurrentDesign(response);
          } else {
            redirect(`/${ROUTEPATHS.CONTEST}/${response.contest.nameForURL}/${ROUTEPATHS.CONTEST_BRIEF}`);
          }
        } else {
          setDesignChargingError(response.contest.name);
          redirect(`/${ROUTEPATHS.CONTEST}/${response.contest.nameForURL}/${ROUTEPATHS.CONTEST_BRIEF}`);
        }
      } else {
        redirect(`/contest`);
      }
    });
  };

  const isLiked = () => {
    let like: boolean;
    if (!user) {
      like = false;
      setLiked(like);
    }
    if (user?.designsILike) {
      like = user.designsILike.some((item: any) => item.id === currentdesign?.id);
      setLiked(like);
    }
  };

  const likeDesign = (idDesign?: number) => {
    if (idDesign !== undefined) {
      axiosHelper.likeDesign(idDesign)?.then((response) => {
        if (response.data.username === user?.username) {
          setLiked(true);
          setDesignsILike(response.data.designsILike);
          // alert('Added as favorite');
        }
      });
    }
  };

  const unlikedDesign = (idDesign?: number) => {
    if (idDesign !== undefined) {
      axiosHelper.unlikeDesign(idDesign)?.then((response) => {
        if (response.data.username === user?.username) {
          setLiked(false);
          setDesignsILike(response.data.designsILike);
          // alert('Removed from favorites');
        }
      });
    }
  };

  const voteDesign = () => {
    if (currentdesign && currentUser) {
      axiosHelper.voteDesign(currentdesign.id).then((response) => {
        console.log(response);
        setCurrentDesign(response);
      });
    } else {
      setPopUpLogin(true);
    }
  };

  const canPublishDraft = () => {
    if (currentdesign) {
      if (currentdesign.contest.contestType.name === 'Naming') {
        console.log('ciao');
        if (currentdesign.payoff && currentdesign.name && currentdesign.textDescription) {
          setPopUpPublishDraft(true);
        } else {
          setPopUpErrorUploadDesign(true);
        }
      } else {
        if (
          currentdesign.name &&
          currentdesign.textAbstract &&
          currentdesign.image1Attachment &&
          currentdesign.image1Attachment.s3Path &&
          currentdesign.tags.length > 0 &&
          currentdesign.agreement
        ) {
          setPopUpPublishDraft(true);
        } else {
          setPopUpErrorUploadDesign(true);
        }
      }
    }
  };

  useEffect(() => {
    setDesign(currentdesign);
    containMyVote();
    axiosHelper.retrieveCompleteDesign(designIndex);
    isLiked();
    isCommunityVotePhase();
    if (currentdesign?.contest.endVotingDate && currentdesign.contest.endUploadDate) {
      daysLeftToVote();
    }
    if (currentUser?.userType !== 'G') {
      if (design?.contest.isPrivate) {
        if (currentUser) {
          if (
            design.designer.username !== currentUser?.username ||
            design.contest.client.username === currentUser.username ||
            currentUser.userType === 'G'
          ) {
            redirect(`/${ROUTEPATHS.CONTEST}/${design.contest.nameForURL}/${ROUTEPATHS.CONTEST_BRIEF}`);
          }
        } else {
          redirect(`/${ROUTEPATHS.CONTEST}/${design.contest.nameForURL}/${ROUTEPATHS.CONTEST_BRIEF}`);
        }
      }
    }
  }, [currentdesign]);

  function fillImagesArray() {
    let array: ReactImageGalleryItem[] = [];
    if (design?.image1Attachment) {
      array.push({
        original: fullPathLogo(design?.image1Attachment.s3Path),
        thumbnail: fullPathLogo(design?.image1Attachment.s3Path),
      });
    } else {
      array.push({
        original: designDefaultLogo,
        thumbnail: designDefaultLogo,
      });
    }
    if (design?.image2Attachment) {
      array.push({
        original: fullPathLogo(design?.image2Attachment.s3Path),
        thumbnail: fullPathLogo(design?.image2Attachment.s3Path),
      });
    }
    if (design?.image3Attachment) {
      array.push({
        original: fullPathLogo(design?.image3Attachment.s3Path),
        thumbnail: fullPathLogo(design?.image3Attachment.s3Path),
      });
    }
    if (design?.image4Attachment) {
      array.push({
        original: fullPathLogo(design?.image4Attachment.s3Path),
        thumbnail: fullPathLogo(design?.image4Attachment.s3Path),
      });
    }
    if (design?.image5Attachment) {
      array.push({
        original: fullPathLogo(design?.image5Attachment.s3Path),
        thumbnail: fullPathLogo(design?.image5Attachment.s3Path),
      });
    }

    // console.log(array);
    setDesignImages(array);
  }

  const downloadArchive = () => {
    if (design?.archiveAttachment.s3Path) {
      const element = document.createElement('a');
      element.href = `${design?.archiveAttachment.s3Path}`;
      element.target = '_self';
      element.click();
    }
  };

  const downloadVideo = () => {
    if (design?.videoAttachment.s3Path) {
      const element = document.createElement('a');
      element.href = `${design?.videoAttachment.s3Path}`;
      element.target = '_self';
      element.click();
    }
  };

  const isCommunityVotePhase = () => {
    if (currentdesign?.contest.endUploadDate && currentdesign.contest.endVotingDate) {
      const endUploadNumber = new Date(currentdesign?.contest.endUploadDate).getTime();
      const endVotingNumber = new Date(currentdesign?.contest.endVotingDate).getTime();
      const dateNumber = new Date().getTime();

      if (dateNumber > endUploadNumber && dateNumber < endVotingNumber) {
        return true;
      }
      return false;
    }
    return false;
  };

  const daysLeftToVote = () => {
    // console.log(currentdesign);
    if (currentdesign?.contest?.endUploadDate && currentdesign?.contest.endVotingDate) {
      const dateN = new Date().getTime();
      const enddateN = new Date(currentdesign.contest.endUploadDate).getTime();
      const endvotingN = new Date(currentdesign.contest.endVotingDate).getTime();

      const total = (endvotingN - enddateN) / 86400000;
      const passed = (dateN - enddateN) / 86400000;

      // console.log(total);
      // console.log(passed);
      // console.log(total - passed);
      setDaysLeft(Math.trunc(total - passed));
    }
  };

  // useEffect(() => {
  //   console.log(designIndex);
  // }, [designIndex]);

  useEffect(() => {
    fillImagesArray();
    awardTitle();
    // console.log(designs);
    setDesignIndex(designRigthIndex);
    setCocreationDesignIndex(cocreationDesignRightIndex);
    // console.log(design?.designer);
    setUserImage(fullPathLogo(design?.designer.logoAttachment?.s3Path));

    if (design) {
      setHasAwards(design?.awards.length);
    }
  }, [user, design]);

  function designRigthIndex(): any {
    let rightIndex: number = -1;
    designs.map((item: any, index: number) => item.id === design?.id && (rightIndex = index));
    return rightIndex;
  }

  function cocreationDesignRightIndex(): any {
    let rightIndex: number = -1;
    cocreationContestGallery.map((item: any, index: number) => item.id === design?.id && (rightIndex = index));
    return rightIndex;
  }

  function findDesign(number: number) {
    let calculatedIndex: number = designIndex + number;
    let result: DesignDetails | undefined;
    // console.log('calc:' + calculatedIndex);
    if (calculatedIndex < 0 || calculatedIndex > designs.length + 1) {
      return;
    } else {
      designs.map(async (item: any, index: number) => {
        // console.log(index);
        if (calculatedIndex === index) {
          // console.log(item.id);
          result = await axiosHelper.retrieveCompleteDesign(item.id);
        }
        if (result) {
          // console.log(result);
          setNewDesign(result);
          redirect(`/${ROUTEPATHS.CONTEST}/${result.contest.nameForURL}/gallery/${result.nameForURL}`);
        }
      });
    }
  }

  function findCoCreationDesign(number: number) {
    let calculatedIndex: number = cocreationDesignIndex + number;
    let result: DesignDetails | undefined;
    // console.log('coCalc' + calculatedIndex);
    if (calculatedIndex < 0 || calculatedIndex > cocreationContestGallery.length + 1) {
      return;
    } else {
      cocreationContestGallery.map(async (item: any, index: number) => {
        if (calculatedIndex === index) {
          // console.log(item.id);
          result = await axiosHelper.retrieveCompleteDesign(item.id);
        }
        if (result) {
          setNewDesign(result);
          redirect(`/${ROUTEPATHS.CONTEST}/${result.contest.nameForURL}/gallery/${result.nameForURL}`);
        }
      });
    }
  }

  useEffect(() => {
    if (newDesign) {
      setCurrentDesign(newDesign);
      setUserImage(fullPathLogo(design?.designer.logoAttachment?.s3Path));
    }
  }, [newDesign]);

  useEffect(() => {
    // console.log(userImage);
  }, [userImage]);

  function handlePager(number: number) {
    // console.log(number);
    contest?.contestType !== 'Co-creation' && findDesign(number);
    contest?.contestType === 'Co-creation' && findCoCreationDesign(number);
  }

  const goToEditDraft = async () => {
    if (design) {
      await axiosHelper.retrieveContestByName(design?.contest.nameForURL).then((response) => {
        setCurrentContest(response.data);
        redirect(`/${ROUTEPATHS.COMMUNITY}/${user?.username}/portfolio/${design?.nameForURL}`);
      });
    }
  };

  const deleteDesign = async () => {
    if (currentUser?.userType === 'G' && currentdesign) {
      await axiosHelper
        .deleteDesign(currentdesign.id)
        .then(async (response) => {
          await axiosHelper.getContestGallery(currentdesign.contest.id)?.then((response) => {
            setContestGalleryCopy(response);
            setContestGallery(response);
            redirect(`/${ROUTEPATHS.CONTEST}/${currentdesign.contest.nameForURL}/${ROUTEPATHS.CONTEST_GALLERY}`);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const containMyVote = () => {
    console.log('contain my vote');
    setVoted(false);
    currentdesign?.votes.map((item: DesignVotes) => {
      if (item.user.id === currentUser?.id) {
        console.log('voto');
        setVoted(true);
      }
    });
  };

  const awardTitle = () => {
    console.log('awardtitle');
    // awardName.current = '';
    if (currentdesign) {
      if (currentdesign.awards.length > 0) {
        console.log(hasAwards);
        console.log(currentdesign);
        currentdesign.awards.map((item) => {
          if (item.title) {
            awardName.current = item.title;
          } else {
            awardName.current = 'Pos ' + item.pos;
          }
        });
      } else {
        awardName.current = 'None';
      }
    }
  };

  useEffect(() => {
    if (design) {
      if (design?.mainCommentsOrdered.length > 0) {
        design.mainCommentsOrdered.map((comment) => {
          cacheHelper.getUserImage(comment.username);
          if (comment.childrenComments.length > 0) {
            comment.childrenComments.map((childrenComment) => {
              cacheHelper.getUserImage(childrenComment.username);
            });
          }
        });
      }
    }
  }, [design]);

  useEffect(() => {
    console.log(awardName.current);
  }, [awardName.current]);

  return (
    <div className="bg-light-gray">
      <AppHeader
        links={headerMenu}
        bgColor="#C3DE72"
        logoImg={headerLogo}
        bgImage={headerimg}
        alpha={0.4}
        breadcrumbs={`Contest / ${design?.contest?.name} / gallery  `}
        pageTitle={`${design?.name}`}
      />
      <div
        className={`  d-flex flex-row align-items-center w-100 justify-content-center  ${
          flagBanner ? `${styles.successBackground}` : 'd-none'
        }`}
      >
        <div className="container d-flex flex-row align-items-center justify-content-center">
          <div className="row col-12 ">
            <div className="col-12  d-flex flex-row align-items-center justify-content-end ">
              <div className="col-12 text-center align-self-end ">
                <span className="sub-title color-light">Flag succeded! </span>
              </div>
              <div>
                <button className={`${styles.removeBannerButton} bg-blue`} onClick={() => setFlagBanner(0)}>
                  <FontAwesomeIcon icon={faXmark as IconProp} className=" text color-light" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        key={flagBanner || deleteCommentBanner}
        className={`  d-flex flex-row align-items-center w-100 justify-content-center  ${
          flagCommentBanner === 1
            ? `${styles.successBackground}`
            : flagCommentBanner === 2
            ? `${styles.errorBackground}`
            : 'd-none'
        }
        ${flagBanner !== 0 ? `${styles.translateY60}` : ''}
        `}
      >
        <div className="container d-flex flex-row align-items-center justify-content-center">
          <div className="row col-12 ">
            <div className="col-12  d-flex flex-row align-items-center justify-content-end ">
              <div className="col-12 text-center align-self-end ">
                <span className="sub-title color-light ">
                  {flagCommentBanner === 1 ? 'Flag succeded!' : 'Problem! '}
                </span>
              </div>
              <div>
                <button className={`${styles.removeBannerButton} bg-blue`} onClick={() => setFlagCommentBanner(0)}>
                  <FontAwesomeIcon icon={faXmark as IconProp} className=" text color-light" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        key={flagBanner || flagCommentBanner}
        className={`  d-flex flex-row w-100 align-items-center justify-content-center  ${
          deleteCommentBanner === 1
            ? `${styles.successBackground}`
            : deleteCommentBanner === 2
            ? `${styles.errorBackground}`
            : 'd-none'
        }
        ${
          flagCommentBanner !== 0
            ? flagBanner !== 0
              ? `${styles.translateY120}`
              : `${styles.translateY60}`
            : flagBanner !== 0
            ? `${styles.translateY60}`
            : ``
        }
        `}
      >
        <div className="container d-flex flex-row align-items-center justify-content-center">
          <div className="row col-12 ">
            <div className="col-12  d-flex flex-row align-items-center justify-content-end ">
              <div className="col-12 text-center align-self-end ">
                <span className="sub-title color-light">
                  {deleteCommentBanner === 1 ? 'Comment deleted!' : 'Delete comment Problem! '}
                </span>
              </div>
              <div>
                <button className={`${styles.removeBannerButton} bg-blue`} onClick={() => setDeleteCommentBanner(0)}>
                  <FontAwesomeIcon icon={faXmark as IconProp} className=" text color-light" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {popUpLogin && (
        <PopUpLogin
          popup={popUpLogin}
          close={() => setPopUpLogin(false)}
          login={(e: boolean) => setPopUpLogin(false)}
        />
      )}
      <main className={`${styles.designWrapper} container  mt-5 px-3 overflow-hidden`}>
        <div className="d-flex flex-row justify-content-end mb-5  ">
          {design?.isTemporary === false && <DesignPager click={handlePager} />}
        </div>
        <div className="row ">
          {/* <HorizontalSection> */}

          <div className="pe-lg-3">
            {bigGallery === true ? (
              <FadeInOut show={bigGallery} duration={500}>
                <div className="row  mb-7 d-sm-none d-lg-block">
                  <div className=" ">
                    <Swiper
                      modules={[Navigation]}
                      navigation={{
                        prevEl: swiperNavPrevRef.current,
                        nextEl: swiperNavNextRef.current,
                      }}
                      effect="cards"
                      slidesPerView={1}
                      // speed={800}
                      loop={false}
                      onActiveIndexChange={(swiper:any) => setActiveIndex(swiper.activeIndex)}
                      className={`${styles.mybigswiper}`}
                    >
                      {designImages.map((item: any, index: number) => (
                        <div>
                          <SwiperSlide className={`${styles.swiperslide} `} key={index}>
                            {activeIndex != null ? (
                              <>
                                {hasAwards > 0 ? (
                                  <div className={`${styles.swiperTrophy} `}>
                                    <FontAwesomeIcon
                                      icon={faMedal as IconProp}
                                      className={` ${styles.trophyDiv}`}
                                    ></FontAwesomeIcon>
                                  </div>
                                ) : null}
                                <img src={designImages[activeIndex].original} alt="" />
                                <div
                                  className={`${styles.swiperNavNext} bg-blue `}
                                  onClick={() => setBigGallery(!bigGallery)}
                                >
                                  <FontAwesomeIcon
                                    icon={faCompress as IconProp}
                                    className="color-light"
                                    style={{ fontSize: '20px' }}
                                  ></FontAwesomeIcon>
                                </div>
                                {/* <div className={` ${styles.nextButtonContainer}`}>
                                  <div className={` ${styles.nextButton}`} onClick={() => nextImage()}></div>
                                </div>
                                <div className={` ${styles.prevButtonContainer}`}>
                                  <div className={` ${styles.prevButton}`} onClick={() => prevImage()}></div>
                                </div> */}
                              </>
                            ) : (
                              <>
                                {hasAwards > 0 ? (
                                  <div className={`${styles.swiperTrophy} `}>
                                    <FontAwesomeIcon
                                      icon={faMedal as IconProp}
                                      style={{ height: '30px', width: '30px' }}
                                      className="btn-green rounded full"
                                    ></FontAwesomeIcon>
                                  </div>
                                ) : null}
                                <img src={item.original} alt="" />
                                <div
                                  className={`${styles.swiperNavNext} bg-blue`}
                                  onClick={() => setBigGallery(!bigGallery)}
                                >
                                  <FontAwesomeIcon
                                    icon={faCompress as IconProp}
                                    className="color-light"
                                    style={{ fontSize: '20px' }}
                                  ></FontAwesomeIcon>
                                </div>
                              </>
                            )}
                          </SwiperSlide>
                        </div>
                      ))}
                    </Swiper>
                  </div>

                  <div
                    className={`d-flex  ${
                      designImages.length < 5 ? 'justify-content-start' : 'justify-content-between '
                    } mt-5 `}
                  >
                    {designImages.length > 0 ? (
                      designImages.map((item: any, index: number) => (
                        <div
                          className={` ${designImages.length < 5 ? 'me-5' : ' '} ${styles.thumbnaildivBig}  `}
                          key={index}
                          onClick={() => {
                            setActiveIndex(index);
                          }}
                        >
                          <img
                            src={item.original}
                            alt=""
                            className={` ${styles.thumbnailimg}  ${activeIndex !== index && styles.border}`}
                          />
                        </div>
                      ))
                    ) : (
                      <div className="d-flex flex-row justify-content-between  w-100">
                        <div className={`${styles.thumbnaildivBig}`}> </div>
                        <div className={`${styles.thumbnaildivBig}`}> </div>
                        <div className={`${styles.thumbnaildivBig}`}> </div>
                        <div className={`${styles.thumbnaildivBig}`}> </div>
                        <div className={`${styles.thumbnaildivBig}`}> </div>
                      </div>
                    )}
                  </div>
                </div>
              </FadeInOut>
            ) : null}
          </div>
          {/* </HorizontalSection> */}

          <div className={`col-12 col-md-12 col-lg-3 `}>
            <div className="row ">
              <div className="col-12   d-flex flex-column align-items-center justify-content-center ">
                {/* Vote section */}
                {currentdesign?.contest.endVotingDate && daysLeft > -1 && (
                  <>
                    <div
                      className=" d-flex flex-column align-items-center justify-content-center w-100 bg-light mb-4"
                      style={{ borderRadius: '8px', height: '220px' }}
                    >
                      <div
                        className="  d-flex flex-column align-items-center justify-content-center w-100"
                        style={{ height: '110px' }}
                      >
                        <span className="sub-title-brief color-blue mt-4 mb-3">{currentdesign.votesCount} votes</span>
                        <span className={`text-16 `}>{daysLeft} days to vote</span>
                      </div>
                      {!voted && (
                        <div
                          className=" w-100  d-flex flex-row align-items-center justify-content-center"
                          style={{ height: '110px' }}
                        >
                          <button
                            disabled={!isCommunityVotePhase()}
                            className={`${
                              isCommunityVotePhase() ? `btn-success` : `btn-dark_grey_70_disabled`
                            } rounded col-10 mt-5 d-flex flex-row align-items-center mb-4 justify-content-center`}
                            style={{ height: '50px' }}
                            onClick={() => (voted ? null : voteDesign())}
                          >
                            <div className="col-1 ">
                              <FontAwesomeIcon icon={faHeart as IconProp} style={{ fontSize: '2.8rem' }} />
                            </div>
                            <span className="col-11 ">{isCommunityVotePhase() ? 'vote' : 'coming soon'}</span>
                          </button>
                        </div>
                      )}
                      {voted && (
                        <div className="d-flex  flex-column align-items-center justify-content-center   h-50 w-100">
                          <FontAwesomeIcon
                            icon={faHeart as IconProp}
                            className="color-success "
                            style={{ fontSize: '5.5rem' }}
                          />
                          <span className="text-16 fw-bold color-success">VOTED!</span>
                        </div>
                      )}
                    </div>
                  </>
                )}
                <div>
                  {/* {console.log(arrayComments)}
                  {console.log(arrayComments?.length)}
                  {console.log(design?.awards.length)} */}

                  <DesignUserCard
                    userImage={currentdesign?.designer.logoAttachment?.s3Path}
                    designTitle={design?.name}
                    contestCategory={design?.contest.category ? design?.contest.category.name : ''}
                    contestType={design?.contest.contestCategory}
                    firstname={design?.designer.firstname}
                    lastname={design?.designer.lastname}
                    uploadDate={designUploadDateString(design?.uploadDate)}
                    contestTitle={design?.contest.name}
                    votes={design?.votesCount}
                    username={design?.designer.username}
                    userId={design?.designer.id}
                    contestId={design?.contest.id}
                    awardsWon={awardName.current}
                    contestNameForUrl={design?.contest.nameForURL}
                  />
                </div>
              </div>
            </div>

            <div className="row buttons">
              {currentdesign?.isTemporary === false && (
                <div className="col-12">
                  {(currentUser?.userType === 'G' || currentUser?.id === design?.contest.client.id) && (
                    <button className={`btn-danger rounded full mt-7 mb-3`} onClick={() => deleteDesign()}>
                      delete design
                    </button>
                  )}
                  {/* {!contest?.isPrivate && communityVote(design?.contest.endVotingDate) && !voted && (
                    <button className={`btn-success rounded full mt-7 mb-3`} onClick={() => voteDesign()}>
                      Vote
                    </button>
                  )}
                  {!contest?.isPrivate && communityVote(design?.contest.endVotingDate) && voted && (
                    <button className={`btn-danger rounded full mt-7 mb-3`} onClick={() => removeVote()}>
                      remove vote
                    </button>
                  )} */}
                  <button
                    className={`btn-warning rounded full ${
                      currentUser?.userType === 'G' || !contest?.isPrivate ? 'mt-3' : 'mt-7'
                    } mb-3`}
                    onClick={() => setPopupInappropriate(true)}
                  >
                    flag as inappropriate
                  </button>
                  {popupInappropriate && (
                    <PopUpInappropriate
                      inappropriate={(inappropriate) => setFlagBanner(inappropriate)}
                      popup={popupInappropriate}
                      close={() => setPopupInappropriate(false)}
                    />
                  )}
                  {!liked && (
                    <button className="btn-blue rounded full  mb-3" onClick={() => likeDesign(currentdesign.id)}>
                      Add to favorite
                    </button>
                  )}
                  {liked && (
                    <button className="btn-danger rounded full mb-3" onClick={() => unlikedDesign(currentdesign.id)}>
                      Remove from favorites
                    </button>
                  )}

                  <button className="btn-blue rounded  full mb-3" onClick={() => setPopupShare(true)}>
                    share design
                  </button>
                  {popupShare && (
                    <PopUpShare popup={popupShare} shortUrl={window.location.href} close={() => setPopupShare(false)} />
                  )}
                </div>
              )}
              {currentdesign?.isTemporary === true && currentUser && currentUser.id === currentdesign.designer.id && (
                <div className="col-12">
                  <button className="btn-blue rounded full mt-7 mb-3" onClick={() => goToEditDraft()}>
                    Edit draft
                  </button>
                  <button className="btn-success rounded full mb-3" onClick={() => canPublishDraft()}>
                    Publish Design
                  </button>
                  <button
                    className="btn-red rounded  full mb-3"
                    onClick={() => (setPopUpDeleteDraft(true), console.log(popUpDeleteDraft))}
                  >
                    Delete Draft
                  </button>
                  {/* <button className="btn-blue rounded big full mb-3">
                    <div className="d-flex col-12 flex-row align-items-center justify-content-center">
                      <div className="col-1 justify-content-start d-flex ">
                        <FontAwesomeIcon
                          icon={faEnvelope as IconProp}
                          className={`${styles.trophyDiv}`}
                        ></FontAwesomeIcon>
                      </div>
                      <span className="col-11">Request Revision</span>
                    </div>
                  </button> */}
                </div>
              )}
            </div>
            <PopUpPublishDraft popup={popUpPublishDraft} close={() => setPopUpPublishDraft(false)} />
            <PopUpErrorUploadDesign popup={popUpErrorUploadDesign} close={() => setPopUpErrorUploadDesign(false)} />
            <PopUpDeleteDraft
              popup={popUpDeleteDraft}
              close={() => (console.log('close'), setPopUpDeleteDraft(false))}
            />
            <div className="tags mt-7  d-flex flex-wrap">
              {design?.tags &&
                design.tags.map((item: ContestTag, index: number) => (
                  <>
                    <button className="btn-tag mb-4 me-4">
                      <span>{item.name}</span>
                    </button>
                  </>
                ))}
            </div>
            <div className="col-12 col-md-6 mt-7  position-relative">
              <a
                href={`https://www.facebook.com/sharer/sharer.php?u=https://desall.com/contest/${design?.contest?.nameForURL}/gallery/${design?.nameForURL}`}
                target="_blank"
              >
                <button className="btn-social__facebook full rounded mb-3 ">
                  <div className="btn-icon">
                    <FontAwesomeIcon icon={faFacebookF as IconProp} className="text color-light" />
                  </div>
                  <span className="text-lowercase">like</span>
                </button>
              </a>
            </div>
            <div className="col-12 col-md-6   position-relative">
              <a
                href={`http://twitter.com/share?hashtags=desallcom&text=${design?.name} by ${design?.dslUser?.username}. Check it out on Desall.&url=https://desall.com/contest/${design?.contest?.nameForURL}/gallery/${design?.nameForURL}&via=desallcom`}
                target="_blank"
              >
                <button className="btn-social__twitter full rounded mb-3">
                  <div className="btn-icon">
                    <FontAwesomeIcon icon={faXTwitter as IconProp} className="text color-light" />
                    {/* <img src={xLogo} className="" style={{ height: '15px', width: '15px' }} /> */}
                  </div>
                  <span className="text-lowercase">post</span>
                </button>
              </a>
            </div>
            <div className="col-12 col-md-6   position-relative">
              <a
                href={`http://pinterest.com/pin/create/button/?url=desall.com/contest/${contest?.nameForUrl}/gallery/${design?.nameForURL}&media=${design?.image1Attachment?.s3Path}&description=${design?.dslUser?.username} on Desall.Check it out on Desall.`}
                target="_blank"
              >
                <button className="btn-social__pinterest full rounded mb-3">
                  <div className="btn-icon">
                    <FontAwesomeIcon icon={faPinterest as IconProp} className="text color-light" />
                  </div>
                  <span className="text-lowercase">save</span>
                </button>
              </a>
            </div>
          </div>
          {design?.contest.contestType.name !== 'Naming' && (
            <div className={`col-12 col-sm-12 col-md-12 col-lg-9 ps-lg-5 pe-lg-3 `}>
              {/* <ImageGallery items={designImages} showPlayButton={false}  */}
              {bigGallery === false ? (
                <FadeInOut show={!bigGallery} duration={500}>
                  <Swiper
                    modules={[Navigation]}
                    navigation={{
                      prevEl: swiperNavPrevRef.current,
                      nextEl: swiperNavNextRef.current,
                    }}
                    effect="cards"
                    slidesPerView={1}
                    // speed={800}
                    loop={false}
                    onActiveIndexChange={(swiper:any) => setActiveIndex(swiper.activeIndex)}
                    className={`${styles.myswiper} `}
                  >
                    {designImages.map((item: any, index: number) => (
                      <div>
                        <SwiperSlide className={`${styles.swiperslide} `} key={index}>
                          {activeIndex != null ? (
                            <>
                              {hasAwards > 0 ? (
                                <div className={`${styles.swiperTrophy} `}>
                                  <FontAwesomeIcon
                                    icon={faMedal as IconProp}
                                    className={`${styles.trophyDiv}`}
                                  ></FontAwesomeIcon>
                                </div>
                              ) : null}
                              <img src={designImages && designImages[activeIndex].original} alt="" />
                              <div
                                className={`${styles.swiperNavNext} bg-blue d-sm-none d-lg-flex`}
                                onClick={() => setBigGallery(!bigGallery)}
                              >
                                <FontAwesomeIcon
                                  icon={faExpand as IconProp}
                                  className="color-light"
                                  style={{ fontSize: '18px' }}
                                ></FontAwesomeIcon>
                              </div>

                              {/* <div className={` ${styles.nextButtonContainer}`}>
                                <div className={` ${styles.nextButton}`} onClick={() => nextImage()}></div>
                              </div>
                              <div className={` ${styles.prevButtonContainer}`}>
                                <div className={` ${styles.prevButton}`} onClick={() => prevImage()}></div>
                              </div> */}
                            </>
                          ) : (
                            <>
                              {hasAwards > 0 ? (
                                <div className={`${styles.swiperTrophy} `}>
                                  <FontAwesomeIcon
                                    icon={faMedal as IconProp}
                                    className={`${styles.trophyDiv}`}
                                  ></FontAwesomeIcon>
                                </div>
                              ) : null}
                              <img src={item.original} alt="" />
                              <div
                                className={`${styles.swiperNavNext} bg-blue`}
                                onClick={() => setBigGallery(!bigGallery)}
                              >
                                <FontAwesomeIcon
                                  icon={faExpand as IconProp}
                                  className="color-light"
                                  style={{ fontSize: '20px' }}
                                ></FontAwesomeIcon>
                              </div>
                            </>
                          )}
                        </SwiperSlide>
                      </div>
                    ))}
                  </Swiper>
                </FadeInOut>
              ) : null}
              {/* <div className="d-flex flex-row-reverse ">
              <FontAwesomeIcon
                icon={faUpRightAndDownLeftFromCenter as IconProp}
                className="border rounded"
                style={{ height: '30px', width: '30px' }}
              ></FontAwesomeIcon>
            </div> */}
              {bigGallery === false ? (
                <div
                  className={` d-flex flex-row ${
                    designImages.length < 5 ? 'justify-content-start' : 'justify-content-between '
                  } mt-5 mb-7`}
                >
                  {designImages.length > 0 ? (
                    designImages.map((item: any, index: number) => (
                      <div
                        className={` ${designImages.length < 5 ? 'me-5' : ' '} ${styles.thumbnaildiv}  `}
                        key={index}
                        onClick={() => {
                          setActiveIndex(index);
                        }}
                      >
                        <img
                          alt=""
                          src={item.original}
                          className={` ${styles.thumbnailimg}  ${activeIndex !== index && styles.border}`}
                        />
                      </div>
                    ))
                  ) : (
                    <div className="d-flex flex-row justify-content-between  w-100">
                      <div className={`${styles.thumbnaildiv}`}> </div>
                      <div className={`${styles.thumbnaildiv}`}> </div>
                      <div className={`${styles.thumbnaildiv}`}> </div>
                      <div className={`${styles.thumbnaildiv}`}> </div>
                      <div className={`${styles.thumbnaildiv}`}> </div>
                    </div>
                  )}
                </div>
              ) : null}

              {/* <DesignImages images={designImages} award={hasAwards} /> */}
              {/* <DesignImagesRow images={designImages} /> */}
              <div className="row mb-5  ">
                {design?.archiveAttachment || design?.videoAttachment ? (
                  <div className="col-12 col-sm-12 col-md-3 fw-bold mb-3 ">Attachments</div>
                ) : null}
                {design?.archiveAttachment ? (
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4 me-4">
                    <button
                      className="btn-dark-blue-materials full mb-4 rounded"
                      onClick={downloadArchive}
                      disabled={design?.archiveAttachment ? false : true}
                    >
                      <div className="btn-icon">
                        <FontAwesomeIcon
                          icon={faFileArrowDown as IconProp}
                          style={{ fontSize: '1.75rem' }}
                          className="text color-light"
                        />
                      </div>
                      <div>ARCHIVE (.zip)</div>
                    </button>
                  </div>
                ) : null}
                {design?.videoAttachment ? (
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4 ms-md-3">
                    <button
                      className="btn-dark-blue-materials full mb-4 rounded"
                      onClick={downloadVideo}
                      disabled={design?.videoAttachment ? false : true}
                    >
                      <div className="btn-icon">
                        <FontAwesomeIcon
                          icon={faFileArrowDown as IconProp}
                          style={{ fontSize: '1.75rem' }}
                          className="text color-light"
                        />
                      </div>
                      <div>ARCHIVE VIDEO (.zip)</div>
                    </button>
                  </div>
                ) : null}
              </div>
              <div className="row mb-5">
                <div className="col-12 col-md-3 fw-bold  ">Abstract</div>
                <div className="col-12 col-md-9  "> {design?.textAbstract ? design.textAbstract : '-'} </div>
              </div>
              <div className="row mb-5">
                <div className="col-12 col-md-3 fw-bold  ">Description</div>
                <div className="col-12 col-md-9 mb-5 ">{design?.textDescription ? design.textDescription : '-'}</div>
              </div>
              {design?.isTemporary === false && (
                <div className="row mb-2 ">
                  <div className="col-2 col-md-1 "> </div>
                  <div className="col-10 col-md-10  ps-5 fw-bold d-flex flex-row align-items-center">
                    This design has {design?.mainCommentsOrdered ? design?.mainCommentsOrdered.length : 0} comment
                    &nbsp; &nbsp;&nbsp;&nbsp;
                    {design &&
                      design.mainCommentsOrdered.length > 0 &&
                      (visibility === 1 ? (
                        <span
                          className="color-blue d-flex flex-row align-items-center cursor-pointer "
                          onClick={() => setVisibility(0)}
                        >
                          <FontAwesomeIcon
                            style={{ fontSize: '1.5rem' }}
                            icon={faAngleUp as IconProp}
                            className="text color-blue  "
                          />
                          &nbsp; hide
                        </span>
                      ) : (
                        <span
                          className="color-blue d-flex flex-row align-items-center cursor-pointer"
                          onClick={() => setVisibility(1)}
                        >
                          <FontAwesomeIcon
                            style={{ fontSize: '1.5rem' }}
                            icon={faAngleDown as IconProp}
                            className="text color-blue"
                          />
                          &nbsp; show
                        </span>
                      ))}
                  </div>
                </div>
              )}
              {design?.isTemporary === false && (
                <div>
                  <DesignCommentMaker comments={design?.mainCommentsOrdered} />
                </div>
              )}
              {visibility === 1 ? (
                <div className="mb-5">
                  {design?.mainCommentsOrdered
                    ? design.mainCommentsOrdered.map((aComment: CommentDesign, index: number) => (
                        <>
                          {aComment.isCensored === false && aComment.isPublished === true && (
                            <DesignComment
                              delete={(deleted) => (setDeleteCommentBanner(deleted), window.scrollTo(0, 0))}
                              flag={(flag) => (setFlagCommentBanner(flag), window.scrollTo(0, 0))}
                              comment={aComment}
                              key={index}
                            />
                          )}
                        </>
                      ))
                    : ''}
                </div>
              ) : (
                ''
              )}
            </div>
          )}
          {design?.contest.contestType.name === 'Naming' && (
            <div className={`col-12 col-sm-12 col-md-12 col-lg-9 ps-lg-5 pe-lg-3 `}>
              <div className="row mb-5 ">
                <div className="col-12 col-md-3 fw-bold  ">Naming</div>
                <div className="col-12 col-md-9  ">
                  <span className="light-title">{design?.name} </span>
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-12 col-md-3 fw-bold  ">Payoff</div>
                <div className="col-12 col-md-9  ">
                  <span className="bigger-text-bold"> {design?.payoff}</span>{' '}
                </div>
              </div>

              <div className="row mb-5">
                {design?.archiveAttachment || design?.videoAttachment ? (
                  <div className="col-12 col-sm-12 col-md-3 fw-bold mb-3 ">Attachments</div>
                ) : null}
                {design?.archiveAttachment ? (
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4 me-4">
                    <button
                      className="btn-dark-blue-materials full mb-4 rounded"
                      onClick={downloadArchive}
                      disabled={design?.archiveAttachment ? false : true}
                    >
                      <div className="btn-icon">
                        <FontAwesomeIcon
                          icon={faFileArrowDown as IconProp}
                          style={{ fontSize: '1.75rem' }}
                          className="text color-light"
                        />
                      </div>
                      <div>ARCHIVE (.zip)</div>
                    </button>
                  </div>
                ) : null}
                {design?.videoAttachment ? (
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4 ms-md-3">
                    <button
                      className="btn-dark-blue-materials full mb-4 rounded"
                      onClick={downloadVideo}
                      disabled={design?.videoAttachment ? false : true}
                    >
                      <div className="btn-icon">
                        <FontAwesomeIcon
                          icon={faFileArrowDown as IconProp}
                          style={{ fontSize: '1.75rem' }}
                          className="text color-light"
                        />
                      </div>
                      <div>ARCHIVE VIDEO(.zip)</div>
                    </button>
                  </div>
                ) : null}
              </div>
              <div className="row mb-5">
                <div className="col-12 col-md-3 fw-bold  ">Description</div>
                <div className="col-12 col-md-9  "> {design?.textDescription} </div>
              </div>
              {design?.textAbstract && (
                <div className="row mb-5">
                  <div className="col-12 col-md-3 fw-bold  ">Abstract</div>
                  <div className="col-12 col-md-9  "> {design?.textAbstract} </div>
                </div>
              )}
              <div className="row mb-2">
                <div className="col-2 col-md-1 "> </div>
                <div className="col-10 col-md-10  ps-5 fw-bold  d-flex flex-row align-items-center ">
                  This design has {design?.mainCommentsOrdered ? design?.mainCommentsOrdered.length : 0} comment &nbsp;
                  &nbsp;&nbsp;&nbsp;
                  {visibility === 1 && design.mainCommentsOrdered.length > 0
                    ? design.mainCommentsOrdered.length > 0 && (
                        <span
                          className="color-blue  d-flex flex-row align-items-center cursor-pointer"
                          onClick={() => setVisibility(0)}
                        >
                          <FontAwesomeIcon
                            style={{ fontSize: '1.5rem' }}
                            icon={faAngleUp as IconProp}
                            className="text color-blue "
                          />
                          &nbsp; hide
                        </span>
                      )
                    : design.mainCommentsOrdered.length > 0 && (
                        <span
                          className="color-blue d-flex flex-row align-items-center cursor-pointer"
                          onClick={() => setVisibility(1)}
                        >
                          <FontAwesomeIcon
                            style={{ fontSize: '1.5rem' }}
                            icon={faAngleDown as IconProp}
                            className="text color-blue"
                          />
                          &nbsp; show
                        </span>
                      )}
                </div>
              </div>
              <DesignCommentMaker comments={design?.mainCommentsOrdered} />
              {visibility === 1
                ? design?.mainCommentsOrdered
                  ? design.mainCommentsOrdered.map((aComment: CommentDesign, index: number) => (
                      <DesignComment
                        delete={(deleted) => (setDeleteCommentBanner(deleted), window.scrollTo(0, 0))}
                        flag={(flag) => (setFlagCommentBanner(flag), window.scrollTo(0, 0))}
                        comment={aComment}
                        key={index}
                      />
                    ))
                  : ''
                : ''}
            </div>
          )}
        </div>
      </main>
      <AppFooter links={footerMenu} bgColor="#304053" logoImg={headerLogo} bgImage={footerImg} />
    </div>
  );
};

export default DesignDetailsPage;
