import axiosHelper from './axiosHelper';

class CacheHelper {
  getUserImage = async (username: string) => {
    const date = new Date();
    const dateN = new Date().getTime() / 86400000;
    console.log(username);
    let image = localStorage.getItem(username);
    console.log(image);
    if (image) {
      console.log(image);
      let parsed = JSON.parse(image);
      console.log(parsed);
      if (dateN - new Date(parsed.date).getTime() / 86400000 > 86400000) {
        localStorage.removeItem(username);
        await axiosHelper.getUserImage(username).then((response) => {
          console.log(response);
          const data = { profilePic: response.profilePic, date: new Date().toISOString() };
          localStorage.setItem(response.username, JSON.stringify(data));
          return response.profilePic;
        });
      } else if (24 - date.getHours() !== 24 - new Date(parsed.date).getHours()) {
        localStorage.removeItem(username);
        await axiosHelper.getUserImage(username).then((response) => {
          console.log(response);
          const data = { profilePic: response.profilePic, date: new Date().toISOString() };
          localStorage.setItem(response.username, JSON.stringify(data));
          return response.profilePic;
        });
      } else {
        return parsed.profilePic;
      }
    } else {
      await axiosHelper.getUserImage(username).then((response) => {
        console.log(response);
        const data = { profilePic: response.profilePic, date: new Date() };
        localStorage.setItem(response.username, JSON.stringify(data));
        return response.profilePic;
      });
    }
  };
}

export default new CacheHelper();
