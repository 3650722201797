import React from 'react';
import AppHeader from '../components/AppHeader/AppHeader';
import AppFooter from '../components/AppFooter/AppFooter';
import PageNavigation from '../components/PageNavigation/PageNavigation';

//Header Data
import headerimg from '../assets/header_placeholder_transparent.png';
import headerLogo from '../assets/desall_logo.png';
import { topNavPages } from '../mockdata/libraryPages';

//Footer Data
import footerImg from '../assets/footer_placeholder_transparent.png';
import { useStoreState } from 'pullstate';
import DslStore from '../store/DslStore';
import { getFooterMenu, getHeaderMenu } from '../store/Selectors';

const Typography: React.FC = () => {
  const footerMenu = useStoreState(DslStore, getFooterMenu);
  const headerMenu = useStoreState(DslStore, getHeaderMenu);
  return (
    <>
      <AppHeader
        links={headerMenu}
        bgColor="#7997c5"
        logoImg={headerLogo}
        bgImage={headerimg}
        pageTitle="Typography"
      />
      <PageNavigation bgColor="#E3E3E3" links={topNavPages} />
      <main className="container page-container">
        <div className="row">
          <div className="col-12">
            <h1 className="bold-title">H1 Bold Title Heading</h1>
            <h1 className="light-title">H1 Light Title Heading</h1>
            <h2 className="sub-title">H2 Sub Title Heading</h2>

            <p className="text">
              Pariatur irure commodo eu sint anim ad aliquip enim. Minim eiusmod cillum laboris dolore et amet esse sit
              ea. Aliquip occaecat consectetur ea pariatur esse. Reprehenderit ea Lorem culpa occaecat ipsum deserunt
              elit culpa ex eu ad amet enim. Velit sunt ad do laboris nostrud eiusmod consectetur eiusmod do anim Lorem
              pariatur quis. Nisi dolore dolore anim et irure amet irure eu. <strong>Ut duis voluptate</strong> minim
              cupidatat aute deserunt mollit aliqua eu aliquip sit commodo Lorem. Adipisicing anim ut dolor occaecat
              eiusmod ullamco amet eiusmod ullamco eu. Proident pariatur cupidatat magna reprehenderit nulla. Ea sunt
              officia amet dolor proident esse. Incididunt excepteur aute labore ad tempor. Mollit enim occaecat sit
              laboris ad consequat veniam aliqua laborum occaecat duis cupidatat cillum. Ullamco elit enim anim ut duis
              ex voluptate mollit Lorem.
            </p>

            <ul>
              <li>
                Veniam exercitation ipsum aliquip enim commodo aliquip esse officia voluptate ipsum ex minim velit elit.
              </li>
              <li>
                Veniam exercitation ipsum aliquip enim commodo aliquip esse officia voluptate ipsum ex minim velit elit.
              </li>
              <li>
                Veniam exercitation ipsum aliquip enim commodo aliquip esse officia voluptate ipsum ex minim velit elit.
              </li>
              <li>
                Veniam exercitation ipsum aliquip enim commodo aliquip esse officia voluptate ipsum ex minim velit elit.
              </li>
            </ul>
          </div>
        </div>
      </main>
      <AppFooter links={footerMenu} bgColor="#304053" logoImg={headerLogo} bgImage={footerImg} />
    </>
  );
};

export default Typography;
