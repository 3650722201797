import { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ROUTEPATHS } from './routes/routePaths';
import { useStoreState } from 'pullstate';
import DslStore from './store/DslStore';
import { getStrapiPaths } from './store/Selectors';
import Signup from './routes/signup';
import GenericStrapi from './routes/GenericStrapi';
import Library from './routes/library';
import Typography from './routes/typography';
import Images from './routes/images';
import Buttons from './routes/buttons';
import Cards from './routes/cards';
import Forms from './routes/forms';
import Components from './routes/components';
import Community from './routes/community';
import DesignDetails from './routes/contest/DesignDetails';
import Partners from './routes/partners';
import UploadDesign from './routes/profile/Design';
import CommunitySearch from './routes/communitySearch';
import ClientContest from './routes/profile/ClientContest';
import ContestPortfolio from './routes/profile/ContestPortfolio';
import Home from './routes/Home';
import PageNotFound from './routes/PageNotFound';
import ProfilePreview from './routes/profile/ProfilePreview';
import ContactUs from './routes/ContactUs';
import EditDraft from './routes/contest/EditDraft';
import ProfileSkeleton from './routes/profile/ProfileSkeleton';
import ChooseUsername from './routes/chooseUsername';
import SearchResults from './routes/SearchResults';
import StrapiPage from './routes/StrapiPage';
import ProfilePage from './routes/profile/ProfilePage';
import ContestListWLF from './routes/contest/ContestListWLF';
import Login from './routes/Login';
import ContestSkeletonWLF from './routes/contest/ContestSkeletonWLF';
import ProfileSkeletonWLF from './routes/profile/ProfileSkeletonWLF';

const Container = () => {
  const strapiPath = useStoreState(DslStore, getStrapiPaths);
  const [strapiMenu, setStrapiMenu] = useState(['']);

  useEffect(() => {
    let tempMenu: string[] = [];
    // console.log(strapiPath);
    strapiPath.map((item: string) => tempMenu.push(item.replace('/', '')));
    setStrapiMenu(tempMenu);
    // console.log(tempMenu);
  }, [strapiPath]);

  return (
    <Routes>

      <Route path={ROUTEPATHS.HOME} element={<Home />} />

      <Route path={ROUTEPATHS.CONTACT_US} element={<ContactUs />} />
      <Route path={ROUTEPATHS.SIGNUP} element={<Signup />} />
      <Route path={`${ROUTEPATHS.SIGNUP}/:huntercode`} element={<Signup />} />
      <Route path={`${ROUTEPATHS.CHOOSEUSERNAME}`} element={<ChooseUsername />} />

      <Route path={ROUTEPATHS.SIGNIN} element={<Login />} />

      {/* Component Library */}
      <Route path={ROUTEPATHS.LIBRARY} element={<Library />} />
      <Route path={ROUTEPATHS.TYPOGRAPHY} element={<Typography />} />
      <Route path={ROUTEPATHS.IMAGES} element={<Images />} />
      <Route path={ROUTEPATHS.BUTTONS} element={<Buttons />} />
      <Route path={ROUTEPATHS.CARDS} element={<Cards />} />
      <Route path={ROUTEPATHS.FORMS} element={<Forms />} />
      <Route path={ROUTEPATHS.COMPONENTS} element={<Components />} />

      {/* Main pages */}
      <Route path={ROUTEPATHS.COMMUNITY} element={<Community />} />

      {/* Contest */}
      <Route path={ROUTEPATHS.CONTEST} element={<ContestListWLF />} />

      <Route path={`${ROUTEPATHS.CONTEST}/:nameForUrl/:pageName`} element={<ContestSkeletonWLF />} />
      {/* <Route path={`${ROUTEPATHS.CONTEST}/:nameForUrl/${ROUTEPATHS.CONTEST_BRIEF}`} element={<ContestSkeletonWLF />} />

      <Route path={`${ROUTEPATHS.CONTEST}/:nameForUrl/${ROUTEPATHS.CONTEST_INSPIRATIONS}`} element={<ContestSkeletonWLF />} />
      <Route path={`${ROUTEPATHS.CONTEST}/:nameForUrl/${ROUTEPATHS.CONTEST_INSPIRATIONS}/edit`} element={<ContestSkeletonWLF />} />
      <Route path={`${ROUTEPATHS.CONTEST}/:nameForUrl/${ROUTEPATHS.CREATE_INSPIRATION}`} element={<ContestSkeletonWLF />} />
     
      <Route path={`${ROUTEPATHS.CONTEST}/:nameForUrl/${ROUTEPATHS.CONTEST_AWARDS}`} element={<ContestSkeletonWLF />} />
      <Route path={`${ROUTEPATHS.CONTEST}/:nameForUrl/${ROUTEPATHS.CONTEST_GALLERY}`} element={<ContestSkeletonWLF />} />
      <Route path={`${ROUTEPATHS.CONTEST}/:nameForUrl/${ROUTEPATHS.CONTEST_UPLOAD}`} element={<ContestSkeletonWLF />} /> */}

      {/* Design */}
      <Route path={`${ROUTEPATHS.CONTEST}/:nameForUrl/${ROUTEPATHS.CONTEST_GALLERY}/:name`} element={<DesignDetails />}
      />

      {/* Creatives */}
      <Route path={ROUTEPATHS.INFO_CREATIVES_PARTNERS} element={<Partners />} />

      {/* Companies */}
      {/* <Route path={ROUTEPATHS.COMPANIES_CASE_STUDIES} element={<CompaniesCaseStudies />} /> */}
      {/* <Route path={ROUTEPATHS.COMPANIES_MARKET} element={<OnMarket />} /> */}

      {/* Community */}

      <Route path={`NewCommunity/:username`} element={<ProfilePage />} />
      <Route path={`NewCommunity/:username/:subpage`} element={<ProfilePage />} />
      <Route path={`${ROUTEPATHS.COMMUNITY}/:username/`} element={<ProfileSkeletonWLF />} />
      <Route path={`${ROUTEPATHS.COMMUNITY}/:username/:pageName`} element={<ProfileSkeletonWLF />} />
      {/* <Route path={`${ROUTEPATHS.COMMUNITY}/:username/${ROUTEPATHS.COMMUNITY_PROFILE}`} element={<ProfileSkeletonWLF />} />
      <Route path={`${ROUTEPATHS.COMMUNITY}/:username/${ROUTEPATHS.COMMUNITY_PORTFOLIO}`} element={<ProfileSkeletonWLF />}/>
      <Route path={`${ROUTEPATHS.COMMUNITY}/:username/${ROUTEPATHS.USER_FAVORITES}`} element={<ProfileSkeletonWLF />} />
      <Route path={`${ROUTEPATHS.COMMUNITY}/:username/${ROUTEPATHS.USER_AWARDS}`} element={<ProfileSkeletonWLF />} />
      <Route path={`${ROUTEPATHS.COMMUNITY}/:username/${ROUTEPATHS.USER_AMBASSADOR}`} element={<ProfileSkeletonWLF />} />
      <Route path={`${ROUTEPATHS.COMMUNITY}/:username/${ROUTEPATHS.USER_BADGE}`} element={<ProfileSkeletonWLF />} />
      <Route path={`${ROUTEPATHS.COMMUNITY}/:username/${ROUTEPATHS.DESIGNER_INSPIRATIONS}`} element={<ProfileSkeletonWLF />} />
      <Route path={`${ROUTEPATHS.COMMUNITY}/:username/${ROUTEPATHS.DESIGNER_INSPIRATIONS}/New`} element={<ProfileSkeletonWLF />} />
      <Route path={`${ROUTEPATHS.COMMUNITY}/:username/${ROUTEPATHS.DESIGNER_INSPIRATIONS}/Edit`} element={<ProfileSkeletonWLF />} />
      <Route path={`${ROUTEPATHS.COMMUNITY}/:username/${ROUTEPATHS.COMMUNITY_PROFILE_EDITABLE}`} element={<ProfileSkeletonWLF />} /> */}

      {/* <Route
        path={`${ROUTEPATHS.COMMUNITY}/:username/${ROUTEPATHS.COMMUNITY_PROFILE_EDITABLE}`}
        element={<ProfileEditable />}
      /> */}
      {/* <Route path={`${ROUTEPATHS.COMMUNITY}/:username/${ROUTEPATHS.COMMUNITY_PORTFOLIO}`} element={<Portfolio />} /> */}
      
      <Route path={`${ROUTEPATHS.COMMUNITY}/:username/${ROUTEPATHS.COMMUNITY_DESIGN}`} element={<UploadDesign />} />
      <Route path={`${ROUTEPATHS.COMMUNITY}${ROUTEPATHS.COMMUNITY_SEARCH}`} element={<CommunitySearch />} />
      <Route path={`${ROUTEPATHS.COMMUNITY}/:username/${ROUTEPATHS.COMMUNITY_PORTFOLIO}/:design`} element={<EditDraft />} />


      {/* Client */}
      <Route path={`${ROUTEPATHS.COMMUNITY}/:username/${ROUTEPATHS.CLIENT_CONTESTS}`} element={<ClientContest />} />

      {/* Designer */}
      <Route path={`${ROUTEPATHS.COMMUNITY}/:username/${ROUTEPATHS.DESIGNER_CONTEST_PORTFOLIO}`} element={<ContestPortfolio />} />
      <Route path={`${ROUTEPATHS.COMMUNITY}/:username/${ROUTEPATHS.COMMUNITY_PROFILE_PREVIEW}`} element={<ProfilePreview />} />

      <Route path={`/Search`} element={<SearchResults />} />
      <Route path={`/Search/community`} element={<SearchResults />} />
      <Route path={`/Search/design`} element={<SearchResults />} />
      <Route path={`/Search/contest`} element={<SearchResults />} />
      {/* Unknown path */}

      {strapiPath.map((item: string, index: number) => (
          <Route path={`${item}/:title`} element={<StrapiPage />} key={index}>
            {strapiMenu.map((menu: string, index: number) => (
              <Route path={`${menu}`} element={<GenericStrapi />} key={index}>
                <Route path={`*`} element={<GenericStrapi />} />
              </Route>
            ))}
          </Route>
      ))}

      <Route path={`/PageNotFound`} element={<PageNotFound />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};
export default Container;
