import React from 'react';
import { Tooltip } from 'react-tooltip'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';

interface ILabel {
  id: string;
  for?: string;
  tooltip?: string;
  mandatory?: boolean;
  children?: React.ReactNode;
}

const Label: React.FC<ILabel> = (props) => {
  return (
    <div className="d-flex justify-content-between">
      <div className="d-flex justify-content-start">
        <div className="label">{props.children}</div>
        {props.mandatory && <div className="color-mandatory">*</div>}
      </div>
      <div>
        {props.tooltip && (
          <div
            className="label-tooltip"
            data-tooltip-html={props.tooltip}
            // data-multiline={true}
            data-tooltip-id={props.id}
            data-tooltip-place="right"
            data-tooltip-variant="light"
            data-effect="solid"
            data-class="form-tooltip"
          >
            <FontAwesomeIcon icon={faCircleQuestion as IconProp} className="text-xl color-blue" />
            <Tooltip id={props.id} className="form-tooltip"/>
          </div>
        )}
      </div>
    </div>
  );
};

export default Label;
