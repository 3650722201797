import { IconProp } from '@fortawesome/fontawesome-svg-core';
import React from 'react';
import AppFooter from '../components/AppFooter/AppFooter';
import AppHeader from '../components/AppHeader/AppHeader';

//Header Data
import headerLogo from '../assets/desall_logo.png';
import headerimg from '../assets/header_placeholder_transparent.png';

//Footer Data
import { useStoreState } from 'pullstate';
import footerImg from '../assets/footer_placeholder_transparent.png';
import { CommunityStrapiComponent } from '../global';
import DslStore, { setCommunity } from '../store/DslStore';
import { getFooterMenu, getHeaderMenu, getStrapiCommunity, getUsersCopy } from '../store/Selectors';

import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import StrapiCommunityRenderer from '../components/StrapiCommunityRenderer';

const Community: React.FC = () => {
  const footerMenu = useStoreState(DslStore, getFooterMenu);
  const headerMenu = useStoreState(DslStore, getHeaderMenu);
  const communitycopy = useStoreState(DslStore, getUsersCopy);
  const strapiCommunity = useStoreState(DslStore, getStrapiCommunity);

  const redirect = useNavigate();

  const goToCommunitySearch = async () => {
    if (communitycopy.length > 0) {
      setCommunity(communitycopy);
      redirect('/community/community-search');
    } else {
      console.log('redirecta');
      redirect('/community/community-search');
    }
  };

  return (
    <div className="bg-light-gray">
      <AppHeader
        links={headerMenu}
        bgColor="#eab95a"
        logoImg={headerLogo}
        bgImage={headerimg}
        alpha={0.4}
        pageTitle="Community"
        breadcrumbs="Home/Community"
      />
      <main className="container mb-5 nogutter">
        <div className="row mt-4">
          <div>
            <>
              {strapiCommunity.map((item: CommunityStrapiComponent, index: number) => (
                <StrapiCommunityRenderer item={item} key={index} />
              ))}
            </>
          </div>
        </div>

        <div className="row d-flex flex-row justify-content-center">
          <div className="col-12 col-md-4 px-4 ">
            <div className="btn-blue rounded full text-center" onClick={() => goToCommunitySearch()}>
              <FontAwesomeIcon icon={faMagnifyingGlass as IconProp} className="color-light me-3" /> advanced search
            </div>
          </div>
        </div>
      </main>
      <AppFooter links={footerMenu} bgColor="#304053" logoImg={headerLogo} bgImage={footerImg} />
    </div>
  );
};

export default Community;
