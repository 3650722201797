import React, { useEffect, useState } from 'react';
import { ICardInfo } from '../../Community/CardInfo/CardInfo';
import ActionButton from '../../ActionButton/ActionButton';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StatusBar from '../StatusBar/StatusBar';
import useSWR, { preload } from 'swr';

import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import {
  faPencil,
  faTrophy,
  faIndustry,
  faEarthAmericas,
  faEyeSlash,
  faKey,
  faLock,
} from '@fortawesome/free-solid-svg-icons';

import styles from './ContestRowBig.module.scss';
import { Contest } from '../../../global';
import DslStore, {
  setCoCreationContest,
  setCommunityUser,
  setContestAwards,
  setContestTags,
  setCurrentContest,
} from '../../../store/DslStore';
import axiosHelper from '../../../helpers/axiosHelper';
import { useNavigate } from 'react-router-dom';
import { useStoreState } from 'pullstate';
import { getCurrentUser } from '../../../store/Selectors';
import {
  computeContestStatus,
  dateColorForStatus,
  getContestStatusText,
  progressNumber,
} from '../../../helpers/utilities';
import { useAuth } from '../../../context/AuthContext';
import { ROUTEPATHS } from '../../../routes/routePaths';

interface IContestRowBigWLF extends ICardInfo {
  contest: Contest;
  clickHandler?: (link: string, popupText: string) => void;
}

const ContestRowBigWLF: React.FC<IContestRowBigWLF> = ({ contest, clickHandler }) => {
  const { loggedUser: user, isContestLiked, toggleContestLiked } = useAuth();
  const redirect = useNavigate();
  const [dateColor, setDateColor] = useState(() => dateColorForStatus(contest));

  const goToClientPage = async () => {
    if (contest.contestType !== 'Link to other') {
      await axiosHelper.retrieveUserById(contest.userId)?.then((response) => {
        console.log(response);
        setCommunityUser(response);
        console.log('ORA FACCIO REDIRECT');
        redirect(`/${ROUTEPATHS.COMMUNITY}/${response.nameForURL}/profile`);
      });
    }
  };

  const goToContestAwards = async () => {
    if (contest.contestType !== 'Link to other') {
      await axiosHelper.retrieveCompleteContest(contest.id).then(async (response) => {
        setCurrentContest(response.data);
        await axiosHelper.getContestAwards(contest.id)?.then((awards) => {
          setContestAwards(awards.awards);
          redirect(`/${ROUTEPATHS.CONTEST}/${response.data.nameForUrl}/${ROUTEPATHS.CONTEST_AWARDS}`);
        });
      });
    }
  };

  const goToContestGallery = async () => {
    if (contest.contestType !== 'Link to other') {
      await axiosHelper.retrieveCompleteContest(contest.id).then(async (response) => {
        setCurrentContest(response.data);
        redirect(`/${ROUTEPATHS.CONTEST}/${response.data.nameForUrl}/${ROUTEPATHS.CONTEST_GALLERY}`);
      });
    }
  };

  const getCoCreationContestDetails = async (contestId: number) => {
    let cocreationContests = await axiosHelper.getCoCreationContests(contestId);
    if (cocreationContests) {
      console.log(cocreationContests);
      setCoCreationContest(cocreationContests);
    }
  };

  const setTags = async (contestId: number) => {
    let contestTags = await axiosHelper.getContestTags(contestId);
    if (contestTags) {
      console.log(contestTags);
      setContestTags(contestTags.tags);
    }
  };

  const getContestDetails = async (contestId?: number) => {
    if (contestId) {
      if (contest.contestType !== 'Link to other') {
        preload('contestname|' + contest.nameForUrl, axiosHelper.contestsFetcher);
        if (contest.contestType === 'Co-creation') {
          getCoCreationContestDetails(contestId);
        }
        redirect(`/${ROUTEPATHS.CONTEST}/${contest.nameForUrl}/${ROUTEPATHS.CONTEST_BRIEF}`);
      } else {
        if (clickHandler) {
          clickHandler(contest.linkToUrl, contest.brief);
        }
      }
    }
  };

  return (
    <div>
      <div className={`${styles.ContestRowBigWrapper} col-12 mb-5  bg-light position-relative`}>
        {!isContestLiked(contest) && (
          <ActionButton
            position="top-right"
            label={user ? 'Save as favorite' : 'Login to save as favorite'}
            color={!user ? 'light-gray-3' : ''}
            action={() => toggleContestLiked(contest)}
            icon="addFavorite"
          />
        )}
        {isContestLiked(contest) && (
          <ActionButton
            label="Remove from favorites"
            icon="confirm"
            position="top-right"
            color="success"
            action={() => toggleContestLiked(contest)}
          />
        )}
        <div className="row">
          <div className="col-3 ps-0  d-flex ">
            <div
              onClick={() => getContestDetails(contest.id)}
              className={`${
                contest.image ? styles.ContestRowBigImage : styles.ContestRowBigImagePlaceholder
              } bg-light-gray  w-100  position-relative `}
            >
              {contest.image ? <img src={contest.image} style={{ cursor: 'pointer' }} alt="Project Cover" /> : null}
              <div className={styles.statusBar}>
                <StatusBar status={computeContestStatus(contest)} progress={progressNumber(contest)} />
              </div>
            </div>
          </div>
          {/* Content Info  ====================================================================*/}
          <div className="col-9 p-5 ps-4">
            <div className="row">
              <div className="col-12">
                <h2
                  className="text-xl fw-bold color-blue"
                  style={{ cursor: 'pointer' }}
                  onClick={() => getContestDetails(contest.id)}
                >
                  {contest.name}
                </h2>
                <div className="mt-3 text">
                  {contest.contestType === 'Link to other' && 'Partnership'}
                  {contest.contestType === 'Co-creation' && 'Co-creation'}
                  {contest.contestType !== 'Link to other' && contest.contestType !== 'Co-creation' && contest.category}
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-12 " style={{ cursor: 'pointer' }}>
                <FontAwesomeIcon icon={faIndustry as IconProp} className=" text color-blue me-3" />
                <span className="text text-start color-blue">{contest.userName || contest.linkToClient}</span>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-12 d-flex flex-wrap">
                {!contest.accessCode && (
                  <div className="pe-5 me-3 mb-3">
                    <button
                      className="btn-icon squared bg-blue color-light me-3"
                      style={{ height: '30px', width: '30px' }}
                    >
                      <FontAwesomeIcon icon={faEarthAmericas as IconProp} className="text" />
                    </button>
                    <span className="text text-start">global contest</span>
                  </div>
                )}
                {contest.isPrivate ? (
                  <div className="pe-5 me-3 mb-3">
                    <button
                      className="btn-icon squared bg-blue color-light me-3"
                      style={{ height: '30px', width: '30px' }}
                    >
                      <FontAwesomeIcon icon={faEyeSlash as IconProp} className="text" />
                    </button>
                    <span className="text text-start">private gallery</span>
                  </div>
                ) : null}
                {contest.accessCode ? (
                  <div className="pe-5 me-3 mb-3">
                    <button
                      className="btn-icon squared bg-blue color-light me-3"
                      style={{ height: '30px', width: '30px' }}
                    >
                      <FontAwesomeIcon icon={faKey as IconProp} className="text" />
                    </button>
                    <span className="text text-start">contest by invitation code </span>
                  </div>
                ) : null}
                {contest.nda ? (
                  <div className="pe-5 me-3 mb-3">
                    <button
                      className="btn-icon squared bg-blue color-light me-3"
                      style={{ height: '30px', width: '30px' }}
                    >
                      <FontAwesomeIcon icon={faLock as IconProp} className="text" />
                    </button>
                    <span className="text text-start">accept nda</span>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-12 d-flex flex-wrap">
                <div className="pe-5 me-3 mb-3 ">
                  <FontAwesomeIcon icon={faCalendar as IconProp} className=" text color-blue me-3" />
                  <span className={`text-xl  text-start color-${dateColor}`}>{getContestStatusText(contest)}</span>
                </div>
                <div className="pe-5 me-3 mb-3 ">
                  <FontAwesomeIcon icon={faPencil as IconProp} className=" text color-blue me-3" />
                  <span
                    className={`text-xl text-start ${
                      contest.isPrivate || contest.contestType === 'Link to other' ? '' : 'color-blue'
                    } `}
                    onClick={() =>
                      !contest.isPrivate && contest.contestType !== 'Link to other' && goToContestGallery()
                    }
                  >
                    {contest.entries | 0} entries
                  </span>
                </div>
                <div className=" pe-5 me-3 mb-3" style={{ cursor: 'pointer' }} >
                  <FontAwesomeIcon icon={faTrophy as IconProp} className=" text color-blue me-3" />
                  <span className="text-xl  text-start color-blue">
                    {contest.linkToAward ? `${contest.linkToAward}` : `€ ${contest.prices} award`}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContestRowBigWLF;
