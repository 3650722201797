import React from 'react';
import { bgCardComponent } from '../../../../global';
import { bgcomponent, pathStrapiImage } from '../../../../helpers/utils';
import HeroSection from '../../../HeroSection/HeroSection';
import SplitSection from '../../../SplitSection/SplitSection';

function CaseStudiesRowComponent(props: bgCardComponent) {
  return (
    <>
      {props.imagepos == 'left' ? (
        <SplitSection
          key={props.id}
          bgColorLeft={props.bgcolor}
          itemLeft={
            <div className="ms-3">
              <img src={pathStrapiImage(props.image.data.attributes.url)} className="img-fluid " alt="Placeholder" />
            </div>
          }
          itemRight={
            <div className="row h-100 d-flex flex-column justify-content-center me-4">
              {props.title && (
                <h2
                  className={`bold-title mb-4 ${props.titlealign ? `text-${props.titlealign}` : `text-start`} color-${
                    props.titlecolor
                  }`}
                >
                  {props.title}
                </h2>
              )}
              {props.boldtext && (
                <span
                  className={`sub-title pb-4 ${
                    props.boldtextalign ? `text-${props.boldtextalign}` : `text-start`
                  } color-${props.boldtextcolor}`}
                >
                  {props.boldtext}
                </span>
              )}
              {props.text && (
                <span
                  className={`text-16  ${props.textalign ? `text-${props.textalign}` : 'text-start'} color-${
                    props.textcolor
                  } `}
                >
                  {props.text}
                </span>
              )}
              {props.buttontext && (
                <div className="mt-4">
                  <a href={props.link} target="_blank">
                    <button className={`btn-${props.buttoncolor} rounded `}>{props.buttontext}</button>
                  </a>
                </div>
              )}
            </div>
          }
        />
      ) : (
        <SplitSection
          key={props.id}
          bgColorLeft={props.bgcolor}
          itemLeft={
            <div className="row h-100 d-flex flex-column justify-content-center me-2 ps-3  ">
              {props.title && (
                <h2
                  className={`bold-title  ${props.titlealign ? `text-${props.titlealign}` : `text-start`} pb-5 color-${
                    props.titlecolor
                  }`}
                >
                  {props.title}
                </h2>
              )}
              {props.boldtext && (
                <span
                  className={`sub-title pb-4  ${
                    props.boldtextalign ? `text-${props.boldtextalign}` : `text-start`
                  } color-${props.boldtextcolor}`}
                >
                  {props.boldtext}
                </span>
              )}
              {props.text && (
                <span
                  className={`text-16  ${props.textalign ? `text-${props.textalign}` : `text-start`} color-${
                    props.textcolor
                  } pb-md-4 pb-4 `}
                >
                  {props.text}
                </span>
              )}
              {props.buttontext && (
                <div className="  ">
                  <a href={props.link} target="_blank">
                    <button className={`btn-${props.buttoncolor} rounded `}>{props.buttontext}</button>
                  </a>
                </div>
              )}
            </div>
          }
          itemRight={
            <div className=" d-flex flex-row justify-content-lg-end justify-content-center me-2 pe-2 ">
              <img src={pathStrapiImage(props.image.data.attributes.url)} className="img-fluid  " alt="Placeholder" />
            </div>
          }
        />
      )}
    </>
  );
}

export default CaseStudiesRowComponent;
