import axios from 'axios';
import {
  Contest,
  Count,
  User,
  UserFavorites,
  Comment,
  DesignDetails,
  ContestDesign,
  UserDetails,
  DesignFavorites,
  ContestFavorites,
  DesignLogo,
  ContestAwards,
  InspirationContest,
  SignUpData,
  UpdateUserData,
  Country,
  Job,
  SocialNetworkFinder,
  Tag,
  DesignUpload,
  InspirationUpload,
  Counters,
  DSLTag,
  Liked,
  InspirationsFavorite,
  UserInspirationUpload,
  UserInspiration,
  Inappropriate,
  UploadContestUserData,
  HunterRequest,
  HunterRequestData,
  ContestCategory,
  CoCreationContest,
  InappropriateForm,
  ContactUsMessage,
  UpdateUserProfile,
  UpdateProfessionalProfile,
  UpdateNewsletterAndNotification,
  CreateFacebookUser,
  CreateTwitterUser,
  CreateTwitterToken,
  CreateFacebookToken,
  FacebookTokenLogin,
  TwitterTokenLogin,
  SendMessageData,
  BlackListUser,
  ContestGuideline,
  UpdateContestInspiration,
  GalleryDesign,
  NDAConfirm,
  SearchForm,
  UserFavorited,
  UserBadge,
  UserILikeResponse,
  ContestLikeResponse,
} from '../global';
import { generateUniqueID } from './utils';
import { NamingUpload } from '../global';
const DSL_TOKEN = 'dslToken';
const UNIQUE_ID = 'uniqueID';

class AxiosHelper {
  isLoggedIn = false;
  hasToken = false;
  batchSize = 20;
  // profileCache: UserProfile | null = null;
  savePermanently = false;
  profileCache: User | null = null;

  constructor() {
    // console.log('CONSTRUCTOR DI AXIOS HELPER');
    this.getUniqueID();
  }

  checkSession = async (): Promise<User> => {
    const token = localStorage.getItem(DSL_TOKEN);
    if (!token) {
      throw new Error('No token found');
    }
    const response = await this.tokLogin(token);
    if (response) {
      return response;
    }
    throw new Error('Token Invalid');
  };

  login(username: string, password: string, rememberMe: boolean) {
    localStorage.removeItem(DSL_TOKEN);
    var bodyFormData = new FormData();
    bodyFormData.append('username', username);
    bodyFormData.append('password', password);

    return axios
      .post<User>(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser/login`, bodyFormData)
      .then((response) => {
        if (response.data) {
          this.setAuthToken(response.data.dslToken, rememberMe);
        }
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  }

  loginClient<User>(username: string, password: string) {
    localStorage.removeItem(DSL_TOKEN);
    var bodyFormData = new FormData();
    bodyFormData.append('username', username);
    bodyFormData.append('password', password);
    return axios
      .post<User>(`${process.env.REACT_APP_API_ENDPOINT}/Client/login`, bodyFormData)
      .then((response) => {
        console.log(response.data);
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }

  loginDesigner<User>(username: string, password: string) {
    localStorage.removeItem(DSL_TOKEN);
    var bodyFormData = new FormData();
    bodyFormData.append('username', username);
    bodyFormData.append('password', password);
    return axios
      .post<User>(`${process.env.REACT_APP_API_ENDPOINT}/Designer/login`, bodyFormData)
      .then((response) => {
        console.log(response.data);
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }

  sendContactUsMessage(data: ContactUsMessage) {
    return axios
      .post<User>(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser/contactUs`, data)
      .then((response) => {
        console.log(response);
        // return response;
      })
      .catch((error) => {
        throw error;
      });
  }

  signUpDesigner(data: SignUpData) {
    if (data) {
      console.log(data.hunter);
      return axios.post(`${process.env.REACT_APP_API_ENDPOINT}/Designer/create`, data);
    }
  }

  signUpClient(data: SignUpData) {
    if (data) {
      return axios.post(`${process.env.REACT_APP_API_ENDPOINT}/Client/create`, data);
    }
  }

  tokLogin(token: string) {
    // console.log(axios.defaults.headers);
    return axios
      .get<User>(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser/tokLogin`, { headers: { dslToken: token } })
      .then((response) => {
        console.log('TOK LOGIN OK');
        // console.log(response.data);

        // setUserLogged(true);
        // setCurrentUser(response.data);
        this.setAuthToken(response.data.dslToken, true);
        return response.data;
      })
      .catch((e) => {
        console.log('TOK LOGIN ERROR ' + e);
        localStorage.removeItem(DSL_TOKEN);
        // setUserLogged(false);
        // setCurrentUser(undefined);
      });
  }

  getStrapiPage(id: number) {
    return axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/pages/` + id + `?populate=*`).then((data) => {
      console.log(data);
    });
  }

  retrieveNumberOfContest() {
    return axios.get<Count>(`${process.env.REACT_APP_API_ENDPOINT}/ContestMini/count`);
  }

  retrieveNumberOfUser() {
    return axios.get<Count>(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser/count`);
  }

  retrieveUserProfile<UserProfile>() {
    if (axios.defaults.headers.common['dslToken']) {
      return axios
        .get<UserProfile>(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser/check`)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          if (error.response.status === 401) {
            // this.clearUserProfile();
            // console.log('check error');
          }
          throw error;
        });
    }
  }

  counters() {
    return axios
      .get<Counters>(`${process.env.REACT_APP_API_ENDPOINT}/Counters`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getUserImage(username: string) {
    return axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser/imageFromUsername`, { params: { username: username } })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  updateUser(data?: UpdateUserData, idUser?: number) {
    if (idUser && data) {
      return axios
        .put<User>(`${process.env.REACT_APP_API_ENDPOINT}/Designer/` + idUser, data)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    }
  }

  updateUserProfile(data: UpdateUserProfile, idUser: number) {
    return axios
      .put<User>(`${process.env.REACT_APP_API_ENDPOINT}/Designer/${idUser}/updateProfile`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  }

  updateClientProfile(data: UpdateUserProfile, idUser: number) {
    return axios
      .put<User>(`${process.env.REACT_APP_API_ENDPOINT}/Client/${idUser}/updateProfile`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  }

  updateUserProfessionalProfile(data: UpdateProfessionalProfile, idUser: number) {
    return axios
      .put<User>(`${process.env.REACT_APP_API_ENDPOINT}/Designer/${idUser}/updateProfessionalProfile`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  }

  updateClientProfessionalProfile(data: UpdateProfessionalProfile, idUser: number) {
    return axios
      .put<User>(`${process.env.REACT_APP_API_ENDPOINT}/Client/${idUser}/updateProfessionalProfile`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  }

  updateUserNotifications(data: UpdateNewsletterAndNotification, idUser: number) {
    return axios
      .put<User>(`${process.env.REACT_APP_API_ENDPOINT}/Designer/${idUser}/updateNotifications`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  }

  updateClientNotifications(data: UpdateNewsletterAndNotification, idUser: number) {
    return axios
      .put<User>(`${process.env.REACT_APP_API_ENDPOINT}/Client/${idUser}/updateNotifications`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  }

  updateUserForUploadContest(data?: UploadContestUserData, idUser?: number) {
    if (idUser && data) {
      return axios
        .put<User>(`${process.env.REACT_APP_API_ENDPOINT}/Designer/${idUser}`, data)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    }
  }

  getHunterRequests() {
    return axios.get<HunterRequest[]>(`${process.env.REACT_APP_API_ENDPOINT}/Designer/ShowHunterRequest`).then((response) => {
      return response.data;
    });
  }

  inviteFriendByEmail(email: string, idUser?: number) {
    if (idUser) {
      return axios
        .post(`${process.env.REACT_APP_API_ENDPOINT}/Designer/hunterInvitation`, { email: email })
        .then((response) => {
          console.log(response.status);
          return response.status;
        })
        .catch(function (e) {
          if (e.response) {
            // console.log(e.response.status);
            return e.response.status;
          }
        });
    }
  }

  createClientHunterRequest(data?: HunterRequestData) {
    if (data) {
      return axios
        .post<HunterRequest>(`${process.env.REACT_APP_API_ENDPOINT}/Designer/CreateHunterRequest`, {
          reason: data.reason,
          fullName: data.fullName,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    }
  }

  removeLogoDesigner(userId?: number) {
    if (userId) {
      return axios
        .post<User>(`${process.env.REACT_APP_API_ENDPOINT}/Designer/${userId}/removeUserLogo`)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    }
  }

  removeBannerDesigner(userId?: number) {
    if (userId) {
      return axios
        .post<User>(`${process.env.REACT_APP_API_ENDPOINT}/Designer/${userId}/removeUserBanner`)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    }
  }

  removeLogoClient(userId?: number) {
    if (userId) {
      return axios
        .post<User>(`${process.env.REACT_APP_API_ENDPOINT}/Client/${userId}/removeUserLogo`)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    }
  }

  removeBannerClient(userId?: number) {
    if (userId) {
      return axios
        .post<User>(`${process.env.REACT_APP_API_ENDPOINT}/Client/${userId}/removeUserBanner`)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    }
  }

  getContest5DesignImages(contestId?: number) {
    if (contestId) {
      return axios
        .get(`${process.env.REACT_APP_API_ENDPOINT}/Contest/${contestId}/fiveDesigns`)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    }
  }

  updateDesignerImages(tempUploads: string, idUser?: number) {
    console.log('update :' + tempUploads);
    if (idUser) {
      return axios
        .put<User>(`${process.env.REACT_APP_API_ENDPOINT}/Designer/` + idUser, {
          tempUploads: tempUploads,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    }
  }

  createDesignerHunter(idDesigner?: number) {
    if (idDesigner) {
      return axios
        .get<User>(`${process.env.REACT_APP_API_ENDPOINT}/Designer/${idDesigner}/createHunter`)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          throw error;
        });
    }
  }

  createDSLUserHunter(idUser?: number) {
    if (idUser) {
      return axios
        .get<User>(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser/${idUser}/createHunter`)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          throw error;
        });
    }
  }

  createClientHunter(idClient?: number) {
    if (idClient) {
      return axios
        .get<User>(`${process.env.REACT_APP_API_ENDPOINT}/Designer/${idClient}/createHunter`)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          throw error;
        });
    }
  }

  updateClientImages(tempUploads: string, idUser?: number) {
    console.log('update :' + tempUploads);
    if (idUser) {
      return axios
        .put<User>(`${process.env.REACT_APP_API_ENDPOINT}/Client/` + idUser, {
          tempUploads: tempUploads,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    }
  }

  addSocial(url?: string, idSocialNetworkFinder?: number) {
    if (url && idSocialNetworkFinder) {
      return axios
        .post(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser/addSocial`, {}, { params: { url: url, socialNetworkFinderId: idSocialNetworkFinder } })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          throw error;
        });
    }
  }

  voteDesign(idDesign: number) {
    return axios
      .post<DesignDetails>(`${process.env.REACT_APP_API_ENDPOINT}/Design/${idDesign}/vote`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  removeDesignVote(idDesign: number) {
    return axios
      .post<DesignDetails>(`${process.env.REACT_APP_API_ENDPOINT}/Design/${idDesign}/removeVote`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  flagDesign(idDesign: number, data: InappropriateForm) {
    return axios
      .post<DesignDetails>(`${process.env.REACT_APP_API_ENDPOINT}/Design/${idDesign}/flagDesign`, {
        motivation: data.motivation,
        reason: data.reason,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  removeSocial(idSocial?: number) {
    if (idSocial) {
      return axios
        .post(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser/removeSocial`, {}, { params: { idSocial: idSocial } })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          throw error;
        });
    }
  }

  getCountries() {
    return axios
      .get<Country[]>(`${process.env.REACT_APP_API_ENDPOINT}/DSLCountry`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  }

  getJobs() {
    return axios
      .get<Job[]>(`${process.env.REACT_APP_API_ENDPOINT}/Job`)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        throw err;
      });
  }

  getSocialNetworkFinder() {
    return axios
      .get<SocialNetworkFinder[]>(`${process.env.REACT_APP_API_ENDPOINT}/SocialNetworkFinder`)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        throw err;
      });
  }

  getOngoingContest() {
    return axios
      .get<Contest[]>(`${process.env.REACT_APP_API_ENDPOINT}/ContestMini/ongoing`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  saveUserProfile(data: User) {
    // console.log('saveUserProfile');
    // console.log(data);
    if (data !== null) {
      this.isLoggedIn = true;
    }
    this.profileCache = data;
    // console.log('profileCache', JSON.stringify(this.profileCache));
  }

  retrieveUserById = (idUser?: number) => {
    if (idUser) {
      return axios
        .get<User>(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser/` + idUser)
        .then((response: any) => {
          console.log(response);
          // console.log('responsedata' + JSON.stringify(response.data));
          return response.data;
        })
        .catch((error) => {
          throw error;
        });
    } else {
      console.log('non ho idUser');
    }
  };

  getUserBadges = (idUser?: number) => {
    if (idUser) {
      return axios
        .get<UserBadge[]>(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser/${idUser}/badge`)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          throw error;
        });
    }
  };

  retrieveLastMembers = (users: number) => {
    return axios.get<User[]>(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser/lastUsers`, { params: { users: users } }).then((response) => {
      return response.data;
    });
  };

  retrieveTopOfAllTimes = (users: number) => {
    return axios.get<User[]>(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser/topUsersAllTime`, { params: { users: users } }).then((response) => {
      // console.log(response.data);
      return response.data;
    });
  };

  retrieveTopOfTheWeek = (users: number) => {
    return axios.get<User[]>(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser/topUsersWeek`, { params: { users: users } }).then((response) => {
      return response.data;
    });
  };

  retrieveCustom4Users = (username1: string, username2: string, username3: string, username4: string) => {
    return axios
      .get<User[]>(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser/custom4Users`, {
        params: { username1: username1, username2: username2, username3: username3, username4: username4 },
      })
      .then((response) => {
        console.log(response.data);
        return response.data;
      });
  };

  getTags = () => {
    return axios
      .get<DSLTag[]>(`${process.env.REACT_APP_API_ENDPOINT}/DSLTag`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  retrieveDesignByContestId = (idContest?: number) => {
    if (idContest) {
      return axios.get<ContestDesign>(`${process.env.REACT_APP_API_ENDPOINT}/Contest/` + idContest).then((response: any) => {
        console.log(response.data);
        return response.data;
      });
    }
  };

  retrieveDesignsByUserId = (idUser?: number) => {
    if (idUser) {
      return axios.get<DesignDetails>(`${process.env.REACT_APP_API_ENDPOINT}/Design?qualifier=designer.id%3D` + idUser).then((response: any) => {
        return response.data;
      });
    }
  };

  // getContestGallery = (idContest?: number) => {
  //   if (idContest) {
  //     return axios
  //       .get<DesignLogo[]>(`${process.env.REACT_APP_API_ENDPOINT}/DesignLogo?qualifier=idContest%3D` + idContest)
  //       .then((response) => {
  //         return response.data;
  //       });
  //   }
  // };

  getContestGallery = (idContest?: number) => {
    if (idContest) {
      return axios
        .get<GalleryDesign[]>(`${process.env.REACT_APP_API_ENDPOINT}/Contest/${idContest}/gallery`)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          throw error;
        });
    }
  };

  getUserGallery = (idUser?: number) => {
    if (idUser) {
      return axios.get<DesignLogo[]>(`${process.env.REACT_APP_API_ENDPOINT}/DesignLogo?qualifier=idUser%3D` + idUser).then((response) => {
        return response.data;
      });
    }
  };

  getContestAwards = (idContest?: number) => {
    if (idContest) {
      return axios.get<ContestAwards>(`${process.env.REACT_APP_API_ENDPOINT}/Contest/` + idContest + '/Awards').then((response) => {
        return response.data;
      });
    }
  };

  getContestInspirations = (idContest?: number) => {
    if (idContest) {
      return axios.get<InspirationContest>(`${process.env.REACT_APP_API_ENDPOINT}/Contest/` + idContest + '/Inspirations').then((response) => {
        return response.data;
      });
    }
  };

  searchContest = (data: SearchForm) => {
    return axios
      .get<Contest[]>(`${process.env.REACT_APP_API_ENDPOINT}/Contest/globalSearch`, {
        params: {
          search: data.search,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  };

  searchUser = (data: SearchForm) => {
    return axios
      .get<User[]>(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser/globalSearch`, {
        params: {
          search: data.search,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  };

  searchDesign = (data: SearchForm) => {
    return axios
      .get<GalleryDesign[]>(`${process.env.REACT_APP_API_ENDPOINT}/Design/globalSearch`, {
        params: {
          search: data.search,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  };

  getContestAuth = (idContest?: number) => {
    if (idContest) {
      return axios
        .get(`${process.env.REACT_APP_API_ENDPOINT}/Contest/${idContest}/auth`)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          throw error;
        });
    }
  };

  getContestNdaAuth = (idContest?: number) => {
    if (idContest) {
      return axios
        .get(`${process.env.REACT_APP_API_ENDPOINT}/Contest/${idContest}/contestNdaAuth`)
        .then((response) => {
          console.log('response positiva di nda auth');
          return response;
        })
        .catch((error) => {
          console.log('response negativa di nda auth');
          throw error;
        });
    }
  };

  getContestAccessCodeAuth = (idContest?: number) => {
    if (idContest) {
      return axios
        .get(`${process.env.REACT_APP_API_ENDPOINT}/Contest/${idContest}/contestAccessCodeAuth`)
        .then((response) => {
          console.log('response positiva di access code auth');
          return response;
        })
        .catch((error) => {
          console.log('response negativa di access code auth');
          throw error;
        });
    }
  };

  createInspiration = (data: InspirationUpload, idContest: number) => {
    if (data) {
      return axios
        .post<InspirationContest>(`${process.env.REACT_APP_API_ENDPOINT}/Contest/` + idContest + `/createInspiration`, {
          textDescription: data.textDescription,
          tempUploads: data.tempUploads,
          isPrivate: data.isPrivate,
          websiteUrl: data.url,
        })
        .then((response) => {
          return response.data;
        });
    }
  };

  createUserInspiraiton = (data: UserInspirationUpload) => {
    if (data) {
      return axios
        .post<UserInspiration[]>(`${process.env.REACT_APP_API_ENDPOINT}/Inspiration`, {
          textDescription: data.textDescription,
          tempUploads: data.tempUploads,
          isPrivate: data.isPrivate,
          websiteURL: data.url,
          contest: {
            id: data.contestId,
          },
        })
        .then((response) => {
          return response.data;
        });
    }
  };

  updateUserInspiration = (data: UserInspirationUpload, idInspiration: number, removeImage: string) => {
    return axios
      .put<UserDetails>(
        `${process.env.REACT_APP_API_ENDPOINT}/Inspiration/${idInspiration}`,
        {
          textDescription: data.textDescription,
          tempUploads: data.tempUploads,
          isPrivate: data.isPrivate,
          websiteURL: data.url,
          contest: {
            id: data.contestId,
          },
        },
        { params: { deleteImage: removeImage } }
      )
      .then((response) => {
        return response.data;
      });
  };

  updateContestInspiration = (data: UpdateContestInspiration, idInspiration: number, removeImage: string) => {
    return axios
      .put<InspirationContest>(
        `${process.env.REACT_APP_API_ENDPOINT}/Inspiration/${idInspiration}/updateContestInspiration`,
        {
          textDescription: data.textDescription,
          tempUploads: data.tempUploads,
          isPrivate: data.isPrivate,
          websiteURL: data.url,
        },
        {
          params: {
            deleteImage: removeImage,
          },
        }
      )
      .then((response) => {
        return response.data;
      });
  };

  inspirationLiked = (idInspiration: number) => {
    return axios
      .post<InspirationsFavorite>(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser/inspirationLiked`, {
        idInspiration: idInspiration,
      })
      .then((response) => {
        return response.data;
      });
  };

  inspirationUnliked = (idInspiration: number) => {
    return axios
      .post<InspirationsFavorite>(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser/inspirationUnliked`, {
        idInspiration: idInspiration,
      })
      .then((response) => {
        return response.data;
      });
  };

  getContestTags = (idContest?: number) => {
    if (idContest) {
      return axios.get<Tag>(`${process.env.REACT_APP_API_ENDPOINT}/Contest/` + idContest + '/contestTags').then((response) => {
        return response.data;
      });
    }
  };

  getCoCreationContests = (idContest?: number) => {
    if (idContest) {
      return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/Contest/${idContest}/contestCoCreation`).then((response) => {
        return response.data;
      });
    }
  };

  deleteInspiration = (idInspiration: number) => {
    return axios.post(`${process.env.REACT_APP_API_ENDPOINT}/Inspiration/${idInspiration}/delete`).then((response) => {
      return response.data.inspirations;
    });
  };

  createDesignNaming = (data: NamingUpload, idContest?: number) => {
    if (idContest) {
      return axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/Design/naming`,
        {},
        { params: { idContest: idContest, name: data.designName, descr: data.description, payoff: data.payoff } }
      );
    }
  };

  createDraftNaming = (data: NamingUpload, idContest?: number) => {
    if (idContest) {
      return axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/Design/draftNaming`,
        {},
        { params: { idContest: idContest, name: data.designName, descr: data.description, payoff: data.payoff } }
      );
    }
  };

  createLinkedinToken = async (data: CreateTwitterToken) => {
    return axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser/linkedinToken`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  };

  createGoogleToken = async (data: CreateFacebookToken) => {
    return axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser/googleToken`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  };

  createTwitterToken = async (data: CreateTwitterToken) => {
    return axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser/twitterToken`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  };

  createFacebookToken = async (data: CreateFacebookToken) => {
    return axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser/facebookToken`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  };

  createLinkedinUser = async (data: CreateTwitterUser) => {
    return axios
      .post<User>(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser/createLinkedinUser`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  };

  createGoogleUser = async (data: CreateFacebookUser) => {
    return axios
      .post<User>(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser/createGoogleUser`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  };

  createFacebookUser = async (data: CreateFacebookUser) => {
    return axios
      .post<User>(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser/createFacebookUser`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  };

  createTwitterUser = async (data: CreateTwitterUser) => {
    return axios
      .post<User>(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser/createTwitterUser`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  };

  twitterTokenLogin = (data: TwitterTokenLogin) => {
    return axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser/linkedinLogin`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  };

  googleTokenLogin = (data: FacebookTokenLogin) => {
    return axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser/googleLogin`, data)
      .then((response) => {
        if (response.data) {
          this.setAuthToken(response.data.dslToken, true);
        }
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  };

  facebookTokenLogin = (data: FacebookTokenLogin) => {
    return axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser/facebookLogin`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  };

  linkedinTokenLogin = (data: TwitterTokenLogin) => {
    return axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser/twitterLogin`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  };

  // facebookLogin = (
  //   token: string,
  //   email: string,
  //   uid: string,
  //   firstName?: string,
  //   lastName?: string,
  //   userType?: string,
  //   hunter?: string
  // ) => {
  //   return axios
  //     .post(
  //       `${
  //         process.env.REACT_APP_API_ENDPOINT
  //       }/DSLUser/facebookLogin?token=${token}&uid=${uid}&email=${email}&firstname=${
  //         firstName ? firstName : ''
  //       }&lastname=${lastName ? lastName : ''}&userType=${userType ? userType : ''}&hunter=${hunter ? hunter : ''}`
  //     )
  //     .then((response) => {
  //       return response.data;
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  deleteDraft = (idDesign: number) => {
    return axios
      .put(`${process.env.REACT_APP_API_ENDPOINT}/Design/${idDesign}/deleteDraft`)
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
  };

  deleteDesign = (idDesign: number) => {
    return axios
      .put(`${process.env.REACT_APP_API_ENDPOINT}/Design/${idDesign}/delete`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  };

  getUserFavorited = (idUser?: number) => {
    if (idUser) {
      return axios
        .get<UserFavorited[]>(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser/${idUser}/userFavorited`)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          throw error;
        });
    }
  };

  retrieveCompleteDesign = (idDesign?: number) => {
    if (idDesign) {
      return axios.get<DesignDetails>(`${process.env.REACT_APP_API_ENDPOINT}/Design/` + idDesign).then((response) => {
        return response.data;
      });
    }
  };

  retrieveCompleteContest = (idContest: number) => {
    return axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/ContestMini/` + idContest)
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };

  getUploadPermission = (idContest?: number) => {
    if (idContest) {
      return axios
        .get(`${process.env.REACT_APP_API_ENDPOINT}/Contest/${idContest}/canUpload`)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          throw error;
        });
    }
  };

  getContestGuidelines = (idContest: number) => {
    return axios
      .get<ContestGuideline[]>(`${process.env.REACT_APP_API_ENDPOINT}/Contest/${idContest}/contestGuidelines`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  };

  retrieveContestByName(contestName: string) {
    return axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/Contest/contestByName?name=` + contestName)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }

  retrieveUserByName(username?: string) {
    if (username) {
      return axios
        .get<User>(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser/userByName?username=` + username)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          throw error;
        });
    }
  }

  retrieveDesignByName(designName?: string) {
    if (designName) {
      return axios
        .get<DesignDetails>(`${process.env.REACT_APP_API_ENDPOINT}/Design/designByName?name=` + designName)
        .then((response) => {
          return response.data;
        })
        .catch((e) => {
          throw e;
        });
    }
  }

  getDesignComments = (idDesign?: number) => {
    if (idDesign) {
      return axios.get<Comment>(`${process.env.REACT_APP_API_ENDPOINT}/Design/` + idDesign).then((response) => {
        if (response.data) {
          return response.data;
        }
      });
    }
  };

  postComment = (text: string, designId?: number) => {
    if (designId) {
      return axios
        .post(`${process.env.REACT_APP_API_ENDPOINT}/Design/` + designId + `/comment?commenttext=` + text)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          throw error;
        });
    }
  };

  replyComment = (text: string, idMainComment?: number, designId?: number) => {
    if (designId && idMainComment) {
      return axios
        .post(`${process.env.REACT_APP_API_ENDPOINT}/Design/` + designId + `/replyComment?commenttext=` + text + `&mastercomment=` + idMainComment)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          throw error;
        });
    }
  };

  deleteComment = (commentId?: number, designId?: number) => {
    if (commentId && designId) {
      return axios
        .put(`${process.env.REACT_APP_API_ENDPOINT}/Design/` + designId + `/deleteComment?commentid=` + commentId)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          throw error;
        });
    }
  };

  flagComment = (commentId?: number) => {
    if (commentId) {
      return axios
        .post(`${process.env.REACT_APP_API_ENDPOINT}/Design/flagComment?commentid=` + commentId)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          throw error;
        });
    }
  };

  likeContest = (contestId: number) => {
    return axios
      .post<ContestLikeResponse>(
        `${process.env.REACT_APP_API_ENDPOINT}/DSLUser/contestLiked`,
        {},
        {
          headers: {
            idContest: contestId,
          },
        }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };

  likeUser = (userId: number) => {
    return axios
      .post<UserILikeResponse>(
        `${process.env.REACT_APP_API_ENDPOINT}/DSLUser/userLiked`,
        {},
        {
          headers: {
            idUser: userId,
          },
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  };

  likeDesign = (designId: number) => {
    return axios
      .post<Liked>(
        `${process.env.REACT_APP_API_ENDPOINT}/DSLUser/designLiked`,
        {},
        {
          headers: {
            idDesign: designId,
          },
        }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };

  unlikeDesign = (designId: number) => {
    return axios
      .post<Liked>(
        `${process.env.REACT_APP_API_ENDPOINT}/DSLUser/designUnliked`,
        {},
        {
          headers: {
            idDesign: designId,
          },
        }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };

  createDesign(data: DesignUpload, contestId: number, uuids: string) {
    console.log(uuids);
    return axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/Design`, {
        contest: {
          id: contestId,
        },
        textDescription: data.description,
        textAbstract: data.abstract,
        name: data.designName,
        tempUploads: uuids,
        tempTags: data.tempTags,
        isHidden: data.isHidden,
      })
      .then((response) => {
        console.log(response);
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  saveDraft(data: DesignUpload, contestId: number, uuids: string) {
    console.log(uuids);

    return axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/Design/saveDraft`, {
        contest: {
          id: contestId,
        },
        textDescription: data.description,
        textAbstract: data.abstract,
        name: data.designName,
        tempUploads: uuids,
        tempTags: data.tempTags,
      })
      .then((response) => {
        console.log(response);
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  publishDraft = (idDesign: number, uuids: string, data?: DesignUpload) => {
    if (data) {
      return axios
        .post(`${process.env.REACT_APP_API_ENDPOINT}/Design/${idDesign}/publishDraft`, {
          textDescription: data.description,
          textAbstract: data.abstract,
          name: data.designName,
          tempUploads: uuids,
          tempTags: data.tempTags,
        })
        .then((response) => {
          return response.data;
        })
        .catch((e) => {
          throw e;
        });
    } else {
      return axios
        .post(`${process.env.REACT_APP_API_ENDPOINT}/Design/${idDesign}/publishDraft`)
        .then((response) => {
          return response.data;
        })
        .catch((e) => {
          throw e;
        });
    }
  };

  updateDraft = (idDesign: number, data: DesignUpload, draft: boolean, uuids?: string) => {
    return axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/Design/${idDesign}/updateDraft?draft=${draft}`, {
        textDescription: data.description,
        textAbstract: data.abstract,
        name: data.designName,
        tempUploads: uuids,
        tempTags: data.tempTags,
        isTemporary: draft,
      })
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        throw e;
      });
  };

  updateDraftNaming = (idDesign: number, data: NamingUpload, draft: boolean) => {
    return axios
      .put(`${process.env.REACT_APP_API_ENDPOINT}/Design/${idDesign}/updateDraftNaming`, {
        textDescription: data.description,
        name: data.designName,
        payoff: data.payoff,
        isTemporary: draft,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  };

  removeDesignVideo = (idDesign: number) => {
    return axios.put(`${process.env.REACT_APP_API_ENDPOINT}/Design/${idDesign}/removeVideo`).then((response) => {
      return response.data;
    });
  };
  removeDesignArchive = (idDesign: number) => {
    return axios.put(`${process.env.REACT_APP_API_ENDPOINT}/Design/${idDesign}/removeArchive`).then((response) => {
      return response.data;
    });
  };

  removeDraftImage(idDesign: number, imageNumber: number) {
    return axios
      .put(`${process.env.REACT_APP_API_ENDPOINT}/Design/${idDesign}/removeImg`, {
        imgNumber: imageNumber,
      })
      .then((response) => {
        return response.data;
      });
  }

  getPresignedUrl(uploadNumber: number, fileName?: string, size?: number, type?: string) {
    if (fileName && size && type) {
      if (uploadNumber > -1) {
        return axios
          .post(`${process.env.REACT_APP_API_ENDPOINT}/S3PresignedUrl`, {
            fileName: fileName,
            size: size,
            mimeType: type,
            externalPk: uploadNumber,
          })
          .then((response) => {
            // console.log(response.data);
            return response;
          })
          .catch((error) => {
            throw error;
          });
      }
    }
  }

  changePath(id: string, contestId?: number) {
    if (contestId) {
      return axios
        .put(`${process.env.REACT_APP_API_ENDPOINT}/s3PresignedUrl/` + id + `/changeS3Path?idContest=` + contestId)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          throw error;
        });
    } else {
      return axios
        .put(`${process.env.REACT_APP_API_ENDPOINT}/s3PresignedUrl/` + id + `/changeS3Path`)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          throw error;
        });
    }
  }

  unlikeUser = (userId: number) => {
    return axios
      .post<UserILikeResponse>(
        `${process.env.REACT_APP_API_ENDPOINT}/DSLUser/userUnliked`,
        {},
        {
          headers: {
            idUser: userId,
          },
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  };

  unlikeContest = (contestId: number) => {
    return axios
      .post<ContestLikeResponse>(
        `${process.env.REACT_APP_API_ENDPOINT}/DSLUser/contestUnliked`,
        {},
        {
          headers: {
            idContest: contestId,
          },
        }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };

  revision1Contest = (revision: string, contestId?: number) => {
    if (contestId) {
      return axios
        .post(`${process.env.REACT_APP_API_ENDPOINT}/Contest/` + contestId + `/revision`, {}, { params: { revision1: revision } })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          throw error;
        });
    }
  };

  revision2Contest = (revision: string, contestId?: number) => {
    return axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/Contest/` + contestId + `/revision`, {}, { params: { revision2: revision } })
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  };

  verifyAccessCode = (contestId: number, code: string) => {
    console.log(code);
    return axios
      .post<Contest>(`${process.env.REACT_APP_API_ENDPOINT}/Contest/` + contestId + `/verifyAccessCode`, {
        accesscode: code,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };

  sendNDAConfirm = (data: NDAConfirm, contestId?: number) => {
    return axios
      .post<Contest>(
        `${process.env.REACT_APP_API_ENDPOINT}/Contest/${contestId}/nda`,

        { email: data.email, firstname: data.firstName, lastname: data.lastName }
      )
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  };

  changePassword(oldPassword: string, newPassword: string) {
    // axios.defaults.headers.post['password'] = oldPassword;
    // axios.defaults.headers.post['newPassword'] = newPassword;

    const headers = {
      password: oldPassword,
      newpassword: newPassword,
    };
    //   bodyFormData.append('email', this.profileCache!.email);

    return axios
      .post<User>(
        `${process.env.REACT_APP_API_ENDPOINT}/DSLUser/changePassword`,
        {},
        {
          headers: headers,
        }
      )
      .then((response) => {
        // delete axios.defaults.headers.post['password'];
        // delete axios.defaults.headers.post['newPassword'];
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }

  logoutCall() {
    return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser/logout`);
  }

  logout() {
    this.logoutCall().then(() => {
      localStorage.removeItem(DSL_TOKEN);

      this.hasToken = false;
      this.isLoggedIn = false;
      this.profileCache = null;
      delete axios.defaults.headers.common['dslToken'];
    });

    // console.log("headerCommon: ");
    // console.log(axios.defaults.headers.common["dslToken"]);
    // console.log("localStorage TOKEN: ")
    // console.log(localStorage.getItem(DSL_TOKEN));
  }

  resetPassword(email: string) {
    return axios(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser/resetPassword?email=${email}`);
  }

  getUserBlackList() {
    return axios
      .get<BlackListUser[]>(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser/blackList`)
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }

  banUser(userId: number) {
    return axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser/${userId}/banUser`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  unBanUser(userId: number) {
    return axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser/${userId}/unBanUser`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  removeUserFromBlackList(userId: number) {
    return axios
      .post<BlackListUser[]>(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser/${userId}/removeFromBlackList`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  addUserToBlackList(userId: number) {
    return axios
      .post<BlackListUser[]>(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser/${userId}/addToBlackList`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  getContests() {
    console.log('######### CHIAMATA A ContestMini #########');
    // return axios.get<Contest[]>(`${process.env.REACT_APP_API_ENDPOINT}/ContestMini?sort=endUploadDate%7Cdesc`);
    return axios.get<Contest[]>(`${process.env.REACT_APP_API_ENDPOINT}/Contest`);
  }
  contestsFetcher(what: string) {
    console.log('contestsFetcher ' + what);
    if (what === 'all') {
      return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/Contest`).then((res) => res.data);
    } else if (what.startsWith('contestname')) {
      return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/Contest/contestByName?name=${what.split('|')[1]}`).then((res) => res.data);
    }
  }
  usersFetcher(what: string) {
    console.log('usersFetcher ' + what);
    if (what === 'all') {
      return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/Contest`).then((res) => res.data);
    } else if (what.startsWith('user|')) {
      return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser/userByName?username=${what.split('|')[1]}`).then((res) => res.data);
    }
  }
  badgesFetcher(what: string) {
    console.log('badgesFetcher ' + what);
    if (what.startsWith('ub')) {
      return axios.get<UserBadge[]>(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser/${what.split('|')[1]}/badge`).then((response) => {
        return response.data;
      });
    }
  }
  userDetailsFetcher(what: string) {
    if (what.startsWith('ud')) {
      return axios.get<UserDetails>(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser/${what.split('|')[1]}/awards`).then((response) => {
        return response.data;
      });
    }
  }
  galleryFetcher(what: string) {
    console.log('galleryFetcher ' + what);
    if (what.startsWith('id')) {
      return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/Contest/${what.split('|')[1]}/gallery`).then((res) => res.data);
    }
  }
  categoriesFetcher(what: string) {
    console.log('categoriesFetcher ' + what);
    if (what === 'allCategories') {
      return axios.get<ContestCategory[]>(`${process.env.REACT_APP_API_ENDPOINT}/Category`).then((res) => res.data);
    }
  }

  getUsers(batch: number) {
    // return axios.get<User[]>(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser?batchSize=` + this.batchSize + `&batch=` + batch);
    return axios.get<User[]>(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser?batch=${batch}&sort=registrationDate%7Cdesc`);
  }

  sendMessage(data: SendMessageData) {
    return axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser/sendMessage`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  getUsersSorted(desc: boolean, batch: number, sort?: string) {
    if (sort) {
      if (desc) {
        return axios
          .get<User[]>(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser`, {
            params: {
              batchSize: `100`,
              sort: `${sort}|desc`,
              batch: `${batch}`,
            },
          })
          .then((response) => {
            return response.data;
          });
      } else {
        return axios
          .get<User[]>(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser`, {
            params: {
              batchSize: `100`,
              sort: `${sort}|asc`,
              batch: `${batch}`,
            },
          })
          .then((response) => {
            return response.data;
          });
      }
    }
    return axios
      .get<User[]>(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser`, {
        params: {
          batchSize: `100`,
          sort: `registrationDate|desc`,
          batch: `${batch}`,
        },
      })
      .then((response) => {
        return response.data;
      });
  }

  getDesignersSorted(desc: boolean, batch: number, sort?: string) {
    if (sort) {
      if (desc) {
        return axios
          .get<User[]>(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser`, {
            params: {
              batchSize: `100`,
              qualifier: `userType='D'`,
              sort: `${sort}|desc`,
              batch: `${batch}`,
            },
          })
          .then((response) => {
            return response.data;
          });
      } else {
        return axios
          .get<User[]>(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser`, {
            params: {
              batchSize: `100`,
              qualifier: `userType='D'`,
              sort: `${sort}|asc`,
              batch: `${batch}`,
            },
          })
          .then((response) => {
            return response.data;
          });
      }
    } else {
      return axios
        .get<User[]>(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser`, {
          params: {
            batchSize: `100`,
            qualifier: `userType='D'`,
            sort: `registrationDate|desc`,
            batch: `${batch}`,
          },
        })
        .then((response) => {
          return response.data;
        });
    }
  }

  getClientsSorted(desc: boolean, batch: number, sort?: string) {
    if (sort) {
      if (desc) {
        return axios
          .get<User[]>(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser`, {
            params: {
              batchSize: `100`,
              qualifier: `userType='C'`,
              sort: `${sort}|desc`,
              batch: `${batch}`,
            },
          })
          .then((response) => {
            return response.data;
          });
      } else {
        return axios
          .get<User[]>(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser`, {
            params: {
              batchSize: `100`,
              qualifier: `userType='C'`,
              sort: `${sort}|asc`,
              batch: `${batch}`,
            },
          })
          .then((response) => {
            return response.data;
          });
      }
    } else {
      return axios
        .get<User[]>(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser`, {
          params: {
            batchSize: `100`,
            qualifier: `userType='C'`,
            sort: `registrationDate|desc`,
            batch: `${batch}`,
          },
        })
        .then((response) => {
          return response.data;
        });
    }
  }

  getCompanySorted(desc: boolean, batch: number, sort?: string) {
    if (sort) {
      if (desc) {
        return axios
          .get<User[]>(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser`, {
            params: {
              batchSize: `100`,
              qualifier: `userType='C' AND isBrand=1`,
              sort: `${sort}|desc`,
              batch: `${batch}`,
            },
          })
          .then((response) => {
            return response.data;
          });
      } else {
        return axios
          .get<User[]>(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser`, {
            params: {
              batchSize: `100`,
              qualifier: `userType='C' AND isBrand=1`,
              sort: `${sort}|asc`,
              batch: `${batch}`,
            },
          })
          .then((response) => {
            return response.data;
          });
      }
    } else {
      return axios
        .get<User[]>(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser`, {
          params: {
            batchSize: `100`,
            qualifier: `userType='C' AND isBrand=1`,
            sort: `registrationDate|desc`,
            batch: `${batch}`,
          },
        })
        .then((response) => {
          return response.data;
        });
    }
  }

  getIndividualSorted(desc: boolean, batch: number, sort?: string) {
    if (sort) {
      if (desc) {
        return axios
          .get<User[]>(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser`, {
            params: {
              batchSize: `100`,
              qualifier: `userType='C' AND isBrand=0`,
              sort: `${sort}|desc`,
              batch: `${batch}`,
            },
          })
          .then((response) => {
            return response.data;
          });
      } else {
        return axios
          .get<User[]>(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser`, {
            params: {
              batchSize: `100`,
              qualifier: `userType='C' AND isBrand=0`,
              sort: `${sort}|desc`,
              batch: `${batch}`,
            },
          })
          .then((response) => {
            return response.data;
          });
      }
    } else {
      return axios
        .get<User[]>(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser`, {
          params: {
            batchSize: `100`,
            qualifier: `userType='C' AND isBrand=0`,
            sort: `registrationDate|desc`,
            batch: `${batch}`,
          },
        })
        .then((response) => {
          return response.data;
        });
    }
  }

  getDesignersJobSorted(desc: boolean, job: string, batch: number, sort?: string) {
    if (sort) {
      if (desc) {
        return axios
          .get<User[]>(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser`, {
            params: {
              batchSize: `100`,
              qualifier: `userType='D' AND jobName='${job}'`,
              sort: `${sort}|desc`,
              batch: `${batch}`,
            },
          })
          .then((response) => {
            return response.data;
          });
      } else {
        return axios
          .get<User[]>(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser`, {
            params: {
              batchSize: `100`,
              qualifier: `userType='D' AND jobName='${job}'`,
              sort: `${sort}|asc`,
              batch: `${batch}`,
            },
          })
          .then((response) => {
            return response.data;
          });
      }
    } else {
      return axios
        .get<User[]>(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser`, {
          params: {
            batchSize: `100`,
            qualifier: `userType='D' AND jobName='${job}'`,
            sort: `registrationDate|desc`,
            batch: `${batch}`,
          },
        })
        .then((response) => {
          return response.data;
        });
    }
  }

  getAllUsersSorted(desc: boolean, batch: number, sort: string) {
    if (sort) {
      if (desc) {
        return axios
          .get<User[]>(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser`, {
            params: {
              batchSize: `100`,
              sort: `${sort}|desc`,
              batch: `${batch}`,
            },
          })
          .then((response) => {
            return response.data;
          });
      } else {
        return axios
          .get<User[]>(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser`, {
            params: {
              batchSize: `100`,
              sort: `${sort}|asc`,
              batch: `${batch}`,
            },
          })
          .then((response) => {
            return response.data;
          });
      }
    } else {
      return axios
        .get<User[]>(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser`, {
          params: {
            batchSize: `100`,
            sort: `registrationDate|desc`,
            batch: `${batch}`,
          },
        })
        .then((response) => {
          return response.data;
        });
    }
  }

  getContestsSortBy(sort?: string) {
    if (sort === 'startDate' || sort === 'sumVotesCount') {
      return axios.get<Contest[]>(`${process.env.REACT_APP_API_ENDPOINT}/ContestMini?sort=` + sort + `%7Cdesc`).then((response) => {
        // console.log(response);
        return response;
      });
    } else {
      return axios.get<Contest[]>(`${process.env.REACT_APP_API_ENDPOINT}/ContestMini?sort=` + sort + `%7Cascs`).then((response) => {
        // console.log(response);
        return response;
      });
    }
  }

  getUserDetails(userId?: number) {
    if (userId) {
      return axios.get<UserDetails>(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser/` + userId + `/details`).then((response) => {
        return response.data;
      });
    }
  }

  getUserAwards(userId?: number) {
    if (userId) {
      return axios.get<UserDetails>(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser/${userId}/awards`).then((response) => {
        return response.data;
      });
    }
  }

  getDesignerAwards(userId?: number) {
    if (userId) {
      return axios.get<UserDetails>(`${process.env.REACT_APP_API_ENDPOINT}/Designer/${userId}/awards`).then((response) => {
        return response.data;
      });
    }
  }

  getDesignerDetails(userId?: number) {
    if (userId) {
      return axios.get<UserDetails>(`${process.env.REACT_APP_API_ENDPOINT}/Designer/` + userId + `/details`).then((response) => {
        return response.data;
      });
    }
  }

  getDesignerInspirations(userId?: number) {
    if (userId) {
      return axios.get<UserDetails>(`${process.env.REACT_APP_API_ENDPOINT}/Designer/${userId}/inspirations`).then((response) => {
        return response.data;
      });
    }
  }

  getUserInspirations(userId?: number) {
    if (userId) {
      return axios.get<UserDetails>(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser/${userId}/inspirations`).then((response) => {
        return response.data;
      });
    }
  }

  designInappropriate(idDesign: number) {
    return axios.post<Inappropriate>(`${process.env.REACT_APP_API_ENDPOINT}/Design/${idDesign}/inappropriate`).then((response) => {
      return response.data;
    });
  }

  getUserFavoritestByFollowerId(followerId?: number) {
    return axios.get<UserFavorites[]>(`${process.env.REACT_APP_API_ENDPOINT}/UserFavorites?qualifier=followerId%3D` + followerId).then((response) => {
      return response.data;
    });
  }

  getDesignerFavoriteInspirations(idDesigner?: number) {
    if (idDesigner) {
      return axios.get<InspirationsFavorite>(`${process.env.REACT_APP_API_ENDPOINT}/Designer/${idDesigner}/Favorites/Inspirations`).then((response) => {
        return response.data;
      });
    }
  }

  getContestCategories() {
    return axios.get<ContestCategory[]>(`${process.env.REACT_APP_API_ENDPOINT}/Category`).then((response) => {
      return response.data;
    });
  }

  removeInspirationImage(inspirationId: number) {
    return axios.put(`${process.env.REACT_APP_API_ENDPOINT}/Inspiration/${inspirationId}/removeImage`).then((response) => {
      return response.data;
    });
  }

  getFavoriteDesign(idDesigner?: number) {
    if (idDesigner)
      return axios.get<DesignFavorites>(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser/` + idDesigner + '/favDesigns').then((response) => {
        return response.data;
      });
  }

  getFavoriteContest(idDesigner?: number) {
    if (idDesigner)
      return axios.get<ContestFavorites>(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser/` + idDesigner + '/favContests').then((response) => {
        return response.data;
      });
  }

  indexCoCreationContests() {
    return axios.get<CoCreationContest[]>(`${process.env.REACT_APP_API_ENDPOINT}/Contest/IndexCoCreation`).then((response) => {
      return response.data;
    });
  }

  getFavoriteUsers(idDesigner?: number) {
    if (idDesigner)
      return axios.get<UserFavorites>(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser/` + idDesigner + '/favUsers').then((response) => {
        return response.data;
      });
  }

  getContestsByClientId(clientId?: number) {
    return axios.get<Contest[]>(`${process.env.REACT_APP_API_ENDPOINT}/ContestMini?qualifier=userId%3D` + clientId).then((response) => {
      return response;
    });
  }

  get5Design(contestId?: number) {
    if (contestId) {
      return axios
        .get<DesignLogo[]>(`${process.env.REACT_APP_API_ENDPOINT}/Design/fiveDesignImg`, {
          params: {
            idContest: contestId,
          },
        })
        .then((response) => {
          return response.data;
        });
    }
  }

  getContestsCategory(sort?: string) {
    return axios.get<Contest[]>(`${process.env.REACT_APP_API_ENDPOINT}/ContestMini?qualifier=contestType%3D'` + sort + `'`).then((response) => {
      // console.log(response);
      return response;
    });
  }

  getCommunitySortBy(sort?: string) {
    return axios.get<User[]>(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser?sort=` + sort + `%7Cdesc`).then((response) => {
      // console.log(response);
      return response;
    });
  }

  getCommunityType(sort?: string) {
    return axios.get<User[]>(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser?qualifier=userType%3D'` + sort + `'`).then((response) => {
      // console.log(response);
      return response;
    });
  }

  strapiFetcher(what: string) {
    // console.log('strapiFetcher ' + what);
    if (what === 'menus') {
      return axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/menus?populate=*`).then((res) => res.data);
    }
    if (what === 'pages') {
      return axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/pages?populate=deep`).then((res) => res.data);
    }
  }

  fetchStrapiMenus() {
    return axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/menus?populate=*`).then((response) => {
      return response.data;
    });
  }

  fetchStrapiPage(pageTitle?: string) {
    if (pageTitle) {
      // console.log(pageTitle);
      return axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/api/pages?populate=deep&filters%5Blink%5D=` + pageTitle)
        .then((response) => {
          // console.log(response.data);
          return response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      console.log('fetchStrapiPage NO TITLE');
    }
  }

  fetchStrapiCommunity() {
    return axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/community?populate%5Bcontent%5D%5Bpopulate%5D=*`).then((response) => {
      return response.data;
    });
  }

  loginWithToken<UserProfile>() {
    if (axios.defaults.headers.common['dslToken']) {
      return axios
        .get<UserProfile>(`${process.env.REACT_APP_API_ENDPOINT}/DSLUser/check`)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          if (error.response.status === 401) {
            // this.clearUserProfile();
          }
          throw error;
        });
    }
  }

  // clearUserProfile() {
  //   // localStorage.clear();
  //   localStorage.removeItem(AUTH_TOKEN);

  //   this.isLoggedIn = false;
  //   this.profileCache = null;
  //   this.savePermanently = false;
  // }

  savePassword<UserProfile>(id: number, password: string, token: string) {
    return axios.put<UserProfile>(
      `${process.env.REACT_APP_API_ENDPOINT}/Attendee/${id}`,
      {
        cleartextPassword: password,
      },
      {
        headers: { tmpToken: token },
      }
    );
  }

  // updateAccount(profile: UserProfile) {
  //   return axios.put<UserProfile>(
  //     `${process.env.REACT_APP_API_ENDPOINT}/Attendee/${profile.id}`,
  //     profile
  //   );
  // }

  setAuthToken(token: string, permanently: boolean) {
    this.hasToken = true;
    axios.defaults.headers.common['dslToken'] = token;
    if (permanently) {
      localStorage.setItem(DSL_TOKEN, token);
    }
  }

  getUniqueID(): string {
    let result = localStorage.getItem(UNIQUE_ID);
    if (result !== null) {
      this.setClientId(result);
      return result;
    } else {
      const id = generateUniqueID('dsl-');
      this.saveUniqueID(id);
      return id;
    }
  }

  saveUniqueID(id: string) {
    localStorage.setItem(UNIQUE_ID, id);
    this.setClientId(id);
  }

  setClientId(id: string) {
    axios.defaults.headers.common['dslClientId'] = id;
  }
}

export default new AxiosHelper();
