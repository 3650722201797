import React, { useState, useRef, useEffect } from 'react';

import DslStore, {
  invertContestSearchParamsOrder,
  setContest,
  setContestPageNumber,
  setContestSearchParamsCategory,
  setContestSearchParamsFilterBy,
  setContestSearchParamsSearch,
  setContestSorted,
} from '../../../store/DslStore';

import styles from './DropDown.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useStoreState } from 'pullstate';
import { getContestSearchParams, getContests, getContestsFiltered } from '../../../store/Selectors';
import { FilterBy, SortBy } from '../../../global';
import useSWR from 'swr';
import axiosHelper from '../../../helpers/axiosHelper';

interface IDropDown {
  options?: IDropDownItem[];
  onSelect?: (o: IDropDownOption) => void;
  descs?: (descs: boolean) => void;
  reload?: boolean;
  label?: string;
  thin?: boolean;
}

interface IDropDownItem {
  label: string;
  value?: string;
}
export interface IDropDownOption extends IDropDownItem {
  index: number;
  sort?: string;
}

export const DropDownContestSort: React.FC<IDropDown> = (props) => {
  const dropDownContainer = useRef<any>(null);
  const [descs, setDescs] = useState(true);
  const [prevChoice, setPrevChoice] = useState<IDropDownOption>({
    label: 'Date',
    value: '2',
    sort: 'date',
    index: 1,
  });
  const [open, setOpen] = useState<boolean>(false);
  const [itemSelected, setItemSelected] = useState<IDropDownOption>({
    label: 'Date',
    value: '2',
    sort: 'date',
    index: 1,
  });

  const generateOptionsList = (options: IDropDownItem[]) => {
    // console.log(itemSelected);
    let indexedOptions: IDropDownOption[] = [];
    options.forEach((item, index) => {
      indexedOptions.push({
        ...item,
        index: index,
      });
    });
    return indexedOptions;
  };

  const globalClickListener = (e: MouseEvent) => {
    if (dropDownContainer.current && dropDownContainer.current.contains(e.target)) {
      // setDropDownAnimation(true);
      return;
    } else {
      setOpen(false);
    }
  };

  const handleOnClick = () => {
    if (!open) {
      document.addEventListener('click', (e) => globalClickListener(e));
    } else {
      document.removeEventListener('click', (e) => globalClickListener(e));
    }

    setOpen(!open);
  };

  // useEffect(() => {
  //   setDropDownAnimation(true);
  // }, [open]);

  useEffect(() => {
    setItemSelected({ label: 'Date', value: '2', sort: 'date', index: 1 });
  }, [props.reload]);

  const handleOnSelect = (option: IDropDownOption) => {
    console.log(option);
    setItemSelected(option);
    setOpen(!open);
    setPrevChoice(option);
    // setDescs(!descs);
    // setContestFiltered(option);
    props.onSelect && props.onSelect(option);
    // props.descs && props.descs(descs);
  };
  const getSelectedStyle = (index: number) => {
    return itemSelected && itemSelected.index === index ? styles.selected : '';
  };

  const optionsList = props.options ? generateOptionsList(props.options) : [];
  // const [dropdownAnimation, setDropDownAnimation] = useState(false);

  return (
    <div ref={dropDownContainer} className={`${styles.dropdown_wrapper} ${props.thin && styles.thin_ui}`}>
      <div className={`${styles.dropdown} ${open ? styles.open : ''}`}>
        <div className={`${styles.dropdown__toggle}`} onClick={handleOnClick}>
          {props.label && <div className="fw-bold text-uppercase pe-3"> {props.label}</div>}
          <span className={`${styles.dropdown__label} ${props.label && 'text-center flex-grow-1'}`}>
            {/* {itemSelected ? itemSelected.label : 'Date'} */}
            <div className="d-flex flex-row align-items-center justify-content-center ">
              <span className="me-2">{itemSelected.label}</span>
              <div className="d-flex flex-column  justify-content-center   ">
                <div className="d-flex flex-row align-items-start  " style={{ height: '1rem' }}>
                  <FontAwesomeIcon
                    icon={faCaretUp as IconProp}
                    className={` ${!descs ? `color-blue` : `color-light_grey_30`} `}
                    style={{ fontSize: '1.2rem' }}
                  />
                </div>
                <div className="d-flex flex-row align-items-end" style={{ height: '1rem' }}>
                  <FontAwesomeIcon
                    icon={faCaretDown as IconProp}
                    className={` ${descs ? `color-blue` : `color-light_grey_30`}`}
                    style={{ fontSize: '1.2rem' }}
                  />
                </div>
              </div>
            </div>
          </span>
          <div className={`${styles.arrow}`}></div>
        </div>

        <div className={`${styles.dropdown__optionslist}`}>
          {optionsList.map((option, index) => (
            <div
              key={index + ''}
              className={`${styles.dropdown__option} ${getSelectedStyle(index)}`}
              data-value={option.value}
              onClick={() =>
                prevChoice
                  ? prevChoice.label !== option.label
                    ? (handleOnSelect(option), props.descs && props.descs(false), setDescs(false))
                    : (handleOnSelect(option), props.descs && props.descs(!descs), setDescs(!descs))
                  : (handleOnSelect(option), props.descs && props.descs(false), setDescs(false))
              }
            >
              <div className=" w-100 d-flex flex-row algin-items-center justify-content-between ">
                {option.label}
                {itemSelected && option.label === itemSelected.label && (
                  <div className="d-flex flex-column  justify-content-center  ">
                    <div className="d-flex flex-row align-items-start  " style={{ height: '1.2rem' }}>
                      <FontAwesomeIcon
                        icon={faCaretUp as IconProp}
                        className={` ${!descs ? `color-blue` : `color-light_grey_30`} `}
                        style={{ fontSize: '1.4rem' }}
                      />
                    </div>
                    <div className="d-flex flex-row align-items-end" style={{ height: '1.2rem' }}>
                      <FontAwesomeIcon
                        icon={faCaretDown as IconProp}
                        className={` ${descs ? `color-blue` : `color-light_grey_30`}`}
                        style={{ fontSize: '1.4rem' }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export const DropDownContestCategory: React.FC<IDropDown> = (props) => {
  const dropDownContainer = useRef<any>(null);
  const contests = useStoreState(DslStore, getContests);
  let [open, setOpen] = useState<boolean>(false);
  let [itemSelected, setItemSelected] = useState<IDropDownOption>();

  const generateOptionsList = (options: IDropDownItem[]) => {
    // console.log(itemSelected);
    let indexedOptions: IDropDownOption[] = [];
    options.forEach((item, index) => {
      indexedOptions.push({
        ...item,
        index: index,
      });
    });
    return indexedOptions;
  };

  const globalClickListener = (e: MouseEvent) => {
    if (dropDownContainer.current && dropDownContainer.current.contains(e.target)) {
      // setDropDownAnimation(true);
      return;
    } else {
      setOpen(false);
    }
  };

  useEffect(() => {
    setItemSelected(undefined);
  }, [props.reload]);

  useEffect(() => {
    console.log(open);
  }, [open]);

  const handleOnClick = () => {
    if (!open) {
      document.addEventListener('click', (e) => globalClickListener(e));
    } else if (open) {
      document.removeEventListener('click', (e) => globalClickListener(e));
    }

    setOpen(!open);
  };

  const handleOnSelect = (option: IDropDownOption) => {
    console.log(option);
    setItemSelected(option);
    setOpen(!open);
    // setContestFiltered(option);
    props.onSelect && props.onSelect(option);
  };
  const getSelectedStyle = (index: number) => {
    return itemSelected && itemSelected.index === index ? styles.selected : '';
  };

  const optionsList = props.options ? generateOptionsList(props.options) : [];

  // const [dropdownAnimation, setDropDownAnimation] = useState(false);

  return (
    <div ref={dropDownContainer} className={`${styles.dropdown_wrapper} ${props.thin && styles.thin_ui}`}>
      <div className={`${styles.dropdown} ${open ? styles.open : ''}`}>
        <div className={`${styles.dropdown__toggle}`} onClick={handleOnClick}>
          {props.label && <div className="fw-bold text-uppercase pe-3"> {props.label}</div>}
          <span className={`${styles.dropdown__label} ${props.label && 'text-center flex-grow-1'}`}>
            {itemSelected ? itemSelected.label : 'All'}
          </span>
          <div className={`${styles.arrow}`}>{/* <img src={arrow} className={`${styles.freccia}`}  /> */}</div>
          {/* <div
            className="d-flex flex-row-reverse"
            onClick={handleOnClick}
            onAnimationEnd={() => setDropDownAnimation(false)}
          >
            {!open && (
              <div
                
                className={`${styles.dropdown_angle} ${dropdownAnimation ? styles.transitionIn : null}`}
                onAnimationEnd={() => setDropDownAnimation(false)}
              >
                <img src={arrow} className={`${styles.freccia}`} onClick={() => (setClickFreccia(true), handleOnClick)} />
                <FontAwesomeIcon icon={faAngleDown as IconProp} className={`text color-blue `} />
              </div>
            )}
            {open && (
              <div
                className={`${styles.dropdown_angle} ${dropdownAnimation ? styles.transitionIn : null}`}
                onAnimationEnd={() => setDropDownAnimation(false)}
              >
                <img src={arrow} className={`${styles.frecciaRuotata}`} onClick={() => setClickFreccia(true)} />
                <FontAwesomeIcon icon={faAngleUp as IconProp} className={`text color-blue `} />
              </div>
            )}
          </div> */}
        </div>

        <div className={`${styles.dropdown__optionslist}`}>
          {optionsList.map((option, index) => (
            <div
              key={index + ''}
              className={`${styles.dropdown__option} ${getSelectedStyle(index)}`}
              data-value={option.value}
              onClick={() => handleOnSelect(option)}
            >
              {option.label}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export const FilterDropDown: React.FC<IDropDown> = (props) => {
  const dropDownContainer = useRef<any>(null);
  const contestSearchParams = useStoreState(DslStore, getContestSearchParams);
  const [open, setOpen] = useState<boolean>(false);
  const [itemSelected, setItemSelected] = useState<IDropDownItem>();
  const label = 'Status';
  const [options] = useState([
    {
      label: 'All',
      value: 'all',
    },
    {
      label: 'Active',
      value: 'active',
    },
    {
      label: 'Closed',
      value: 'closed',
    },
  ]);

  useEffect(() => {
    setItemSelected(options?.find((item) => item.value === (contestSearchParams.filterBy as string)));
  }, [itemSelected, contestSearchParams.filterBy, options]);

  const globalClickListener = (e: MouseEvent) => {
    if (dropDownContainer.current && dropDownContainer.current.contains(e.target)) {
      // setDropDownAnimation(true);
      return;
    } else {
      setOpen(false);
    }
  };

  const handleOnClick = () => {
    if (!open) {
      document.addEventListener('click', (e) => globalClickListener(e));
    } else if (open) {
      document.removeEventListener('click', (e) => globalClickListener(e));
    }
    setOpen(!open);
  };

  const handleOnSelect = (option: IDropDownItem) => {
    setItemSelected(option);
    setOpen(false);
    setContestSearchParamsFilterBy(option.value as FilterBy);
  };
  const getSelectedStyle = (option: IDropDownItem) => {
    if (itemSelected) {
      return itemSelected === option ? styles.selected : '';
    }
    return '';
  };

  return (
    <div ref={dropDownContainer} className={`${styles.dropdown_wrapper} ${props.thin && styles.thin_ui}`}>
      <div className={`${styles.dropdown} ${open ? styles.open : ''}`}>
        <div className={`${styles.dropdown__toggle}`} onClick={handleOnClick}>
          {label && <div className="fw-bold text-uppercase pe-3"> {label}</div>}
          <span className={`${styles.dropdown__label} ${label && 'text-center flex-grow-1'}`}>
            {itemSelected ? itemSelected.label : 'All'}
          </span>
          <div className={`${styles.arrow}`}></div>
        </div>
        <div className={`${styles.dropdown__optionslist}`}>
          {options?.map((option, index) => (
            <div
              key={index + option.value}
              className={`${styles.dropdown__option} ${getSelectedStyle(option)}`}
              data-value={option.value}
              onClick={() => handleOnSelect(option)}
            >
              {option.label}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export const CategoryDropDown: React.FC<IDropDown> = (props) => {
  const dropDownContainer = useRef<any>(null);
  const contestSearchParams = useStoreState(DslStore, getContestSearchParams);
  const [open, setOpen] = useState<boolean>(false);
  const [itemSelected, setItemSelected] = useState<IDropDownItem>();
  const [categoriesFilter, setCategoriesFilter] = useState<IDropDownItem[]>([]);
  const label = 'Category';

  const { data, error, isLoading } = useSWR('allCategories', axiosHelper.categoriesFetcher);

  useEffect(() => {
    console.log(data);
    if (data) {
      let newItems: IDropDownItem[] = [];
      newItems.push({ label: 'All', value:undefined });
      data.map((item, index: number) => {
        let name = item.name; // === 'Link to Other' ? 'Partnership' : item.name;

        newItems.push({ label: `${name}`, value: `${item.name}` });
      });
      setCategoriesFilter(newItems);
    }
  }, [data]);

  useEffect(() => {
    setItemSelected(categoriesFilter?.find((item) => item.value === contestSearchParams.category));
  }, [itemSelected, contestSearchParams.category, categoriesFilter]);

  const globalClickListener = (e: MouseEvent) => {
    if (dropDownContainer.current && dropDownContainer.current.contains(e.target)) {
      // setDropDownAnimation(true);
      return;
    } else {
      setOpen(false);
    }
  };

  const handleOnClick = () => {
    if (!open) {
      document.addEventListener('click', (e) => globalClickListener(e));
    } else if (open) {
      document.removeEventListener('click', (e) => globalClickListener(e));
    }
    setOpen(!open);
  };

  const handleOnSelect = (option: IDropDownItem) => {
    console.log(option);
    setItemSelected(option);
    setOpen(!open);
    setContestSearchParamsCategory(option.value);
  };
  
  const getSelectedStyle = (option: IDropDownItem) => {
    if (itemSelected) {
      return itemSelected === option ? styles.selected : '';
    }
    return '';
  };

  return (
    <div ref={dropDownContainer} className={`${styles.dropdown_wrapper} ${props.thin && styles.thin_ui}`}>
      <div className={`${styles.dropdown} ${open ? styles.open : ''}`}>
        <div className={`${styles.dropdown__toggle}`} onClick={handleOnClick}>
          {label && <div className="fw-bold text-uppercase pe-3"> {label}</div>}
          <span className={`${styles.dropdown__label} ${label && 'text-center flex-grow-1'}`}>
            {itemSelected ? itemSelected.label : 'All'}
          </span>
          <div className={`${styles.arrow}`}></div>
        </div>
        <div className={`${styles.dropdown__optionslist}`}>
          {categoriesFilter?.map((option, index) => (
            <div
              key={index + ''}
              className={`${styles.dropdown__option} ${getSelectedStyle(option)}`}
              data-value={option.value}
              onClick={() => handleOnSelect(option)}
            >
              {option.label}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export const SortDropDown: React.FC<IDropDown> = (props) => {
  const label = 'Sort by';
  const contestSearchParams = useStoreState(DslStore, getContestSearchParams);
  const [itemSelected, setItemSelected] = useState<IDropDownItem>();

  const [options] = useState([
    {
      label: 'Name',
      value: 'name',
    },
    {
      label: 'Date',
      value: 'date',
    },

    {
      label: 'Entries',
      value: 'entries',
    },
    {
      label: 'Award',
      value: 'award',
    },
  ]);
  const dropDownContainer = useRef<any>(null);
  const [descs, setDescs] = useState(true);
  const [prevChoice, setPrevChoice] = useState<IDropDownItem>({
    label: 'Date',
    value: 'date',
  });
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    setItemSelected(options?.find((item) => item.value === contestSearchParams.sortBy));
  }, [itemSelected, contestSearchParams.sortBy, options]);

  useEffect(() => {
    setDescs(contestSearchParams.orderBy === 'desc');
  }, [descs, contestSearchParams.orderBy]);

  const generateOptionsList = (opt: IDropDownItem[]) => {
    let indexedOptions: IDropDownOption[] = [];
    opt.forEach((item, index) => {
      indexedOptions.push({
        ...item,
        index: index,
      });
    });
    return indexedOptions;
  };

  const globalClickListener = (e: MouseEvent) => {
    if (dropDownContainer.current && dropDownContainer.current.contains(e.target)) {
      return;
    } else {
      setOpen(false);
    }
  };

  const handleOnClick = () => {
    if (!open) {
      document.addEventListener('click', (e) => globalClickListener(e));
    } else {
      document.removeEventListener('click', (e) => globalClickListener(e));
    }
    setOpen(!open);
  };

  const handleOnSelect = (option: IDropDownItem) => {
    console.log(option);
    setItemSelected(option);
    if (prevChoice === option) {
      console.log('SAME CHOICE');
      invertContestSearchParamsOrder();
    } else {
      setContestSearchParamsSearch(option.value as SortBy);
    }
    setOpen(!open);
    setPrevChoice(option);
    // props.onSelect && props.onSelect(option);
  };
  const getSelectedStyle = (option: IDropDownItem) => {
    if (itemSelected) {
      return itemSelected === option ? styles.selected : '';
    }
    return '';
  };

  return (
    <div ref={dropDownContainer} className={`${styles.dropdown_wrapper} ${props.thin && styles.thin_ui}`}>
      <div className={`${styles.dropdown} ${open ? styles.open : ''}`}>
        <div className={`${styles.dropdown__toggle}`} onClick={handleOnClick}>
          {label && <div className="fw-bold text-uppercase pe-3"> {label}</div>}
          <span className={`${styles.dropdown__label} ${label && 'text-center flex-grow-1'}`}>
            <div className="d-flex flex-row align-items-center justify-content-center ">
              <span className="me-2">{itemSelected ? itemSelected.label : 'All'}</span>
              <div className="d-flex flex-column  justify-content-center   ">
                <div className="d-flex flex-row align-items-start  " style={{ height: '1rem' }}>
                  <FontAwesomeIcon
                    icon={faCaretUp as IconProp}
                    className={` ${!descs ? `color-blue` : `color-light_grey_30`} `}
                    style={{ fontSize: '1.2rem' }}
                  />
                </div>
                <div className="d-flex flex-row align-items-end" style={{ height: '1rem' }}>
                  <FontAwesomeIcon
                    icon={faCaretDown as IconProp}
                    className={` ${descs ? `color-blue` : `color-light_grey_30`}`}
                    style={{ fontSize: '1.2rem' }}
                  />
                </div>
              </div>
            </div>
          </span>
          <div className={`${styles.arrow}`}></div>
        </div>

        <div className={`${styles.dropdown__optionslist}`}>
          {options.map((option, index) => (
            <div
              key={index + option.value}
              className={`${styles.dropdown__option} ${getSelectedStyle(option)}`}
              data-value={option.value}
              onClick={() => handleOnSelect(option)}
            >
              <div className=" w-100 d-flex flex-row algin-items-center justify-content-between ">
                {option.label}
                {itemSelected && option.label === itemSelected.label && (
                  <div className="d-flex flex-column  justify-content-center  ">
                    <div className="d-flex flex-row align-items-start  " style={{ height: '1.2rem' }}>
                      <FontAwesomeIcon
                        icon={faCaretUp as IconProp}
                        className={` ${contestSearchParams.orderBy === 'asc' ? `color-blue` : `color-light_grey_30`} `}
                        style={{ fontSize: '1.4rem' }}
                      />
                    </div>
                    <div className="d-flex flex-row align-items-end" style={{ height: '1.2rem' }}>
                      <FontAwesomeIcon
                        icon={faCaretDown as IconProp}
                        className={` ${contestSearchParams.orderBy === 'desc' ? `color-blue` : `color-light_grey_30`}`}
                        style={{ fontSize: '1.4rem' }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
