import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { faHeart, faLock, faTrophy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DesignContestType } from '../../../../global';
import axiosHelper from '../../../../helpers/axiosHelper';
import { designUploadDateString, fullPathLogo, fullPathLogoDesign } from '../../../../helpers/utils';
import DslStore, { setCurrentContest, setCurrentDesign } from '../../../../store/DslStore';

import { ICardInfo } from '../../../Community/CardInfo/CardInfo';
import Spinner from '../../../Spinner/spinner';
import styles from './DesignRow.module.scss';
import { useStoreState } from 'pullstate';
import { getCurrentUser } from '../../../../store/Selectors';
import designDefaultImage from '../../../../assets/Design.png';
import { Console } from 'console';
import ActionButton from '../../../ActionButton/ActionButton';
import DesignDefaultImg from '../../../../assets/Design.png';
import { ROUTEPATHS } from '../../../../routes/routePaths';

interface IContestRow extends ICardInfo {
  uploadDate?: string;
  name?: string;
  votes: number;
  galleryImages: string[];
  image?: string;
  designName: string;
  contest: DesignContestType;
  idDesign: number;
  isPrivateContest: boolean;
  draft: boolean;
  hidden: boolean;
  idClient: number;
}

const DesignRowGallery = (props: {
  items: string[];
  contestNameForUrl: string;
  idDesign: number;
  private: boolean;
}) => {
  const redirect = useNavigate();

  const goToDetails = async () => {
    if (!props.private) {
      const design = await axiosHelper.retrieveCompleteDesign(props.idDesign);
      if (design) {
        console.log(design);
        setCurrentDesign(design);
        redirect(`/${ROUTEPATHS.CONTEST}/${props.contestNameForUrl}/gallery/${design.nameForURL}`);
      }
    }
  };

  return (
    <>
      {props.items.map((item, index) => (
        <div
          className={`${styles.contestRowGalleryItem} cursor-pointer ${
            index % 2 === 0 ? 'bg-light-gray-2' : 'bg-light-gray'
          }`}
        >
          {item ? (
            <img src={fullPathLogo(item)} alt="Gallery Item Cover" onClick={() => goToDetails()} />
          ) : (
            <Spinner size={5} />
          )}
        </div>
      ))}
    </>
  );
};

const DesignRow: React.FC<IContestRow> = (props) => {
  const redirect = useNavigate();
  const currentUser = useStoreState(DslStore, getCurrentUser);
  const [imagesPath, setImagesPath] = useState<string[]>(['']);

  const fillImagesPath = () => {
    let array: string[] = [];

    props.galleryImages.map((item, index) => {
      if (item != '') {
        array.push(item);
      }
    });
    // console.log(array);
    if (props.isPrivateContest === false) {
      setImagesPath(array);
    } else {
      if (currentUser?.id === props.userId) {
        setImagesPath(array);
      }
    }
  };

  const hiddenOptionActive = () => {
    if (!props.contest.isPrivate && props.contest.startUploadDate && props.contest.endUploadDate) {
      const dateNumber = new Date().getTime() / 86400000;
      const startDateNumber = new Date(props.contest.startUploadDate).getTime() / 86400000;
      const endDateNumber = new Date(props.contest.endUploadDate).getTime() / 86400000;
      const halfDate = (endDateNumber - startDateNumber) / 2;
      if (dateNumber - startDateNumber < halfDate) {
        return true;
      } else {
        return false;
      }
    }
  };

  const goToContestDetails = async () => {
    if (props.contest.id) {
      const result = await axiosHelper.retrieveCompleteContest(props.contest.id);
      // console.log(result.data.client);

      console.log(result);
      setCurrentContest(result.data);

      redirect(`/${ROUTEPATHS.CONTEST}/${result.data.nameForUrl}/${ROUTEPATHS.CONTEST_BRIEF}`);
    }
  };

  const goToDesignDetails = async () => {
    if (currentUser) {
      if (currentUser && currentUser.id === props.userId) {
        const design = await axiosHelper.retrieveCompleteDesign(props.idDesign);
        if (design) {
          console.log(design);
          setCurrentDesign(design);

          redirect(`/${ROUTEPATHS.CONTEST}/${design.contest.nameForURL}/gallery/${design?.nameForURL}`);
        }
      }
    }
    if (!props.contest.isPrivate && !props.hidden) {
      const design = await axiosHelper.retrieveCompleteDesign(props.idDesign);
      if (design) {
        console.log(design);
        setCurrentDesign(design);

        redirect(`/${ROUTEPATHS.CONTEST}/${design.contest.nameForURL}/gallery/${design?.nameForURL}`);
      }
    }
    if (!props.contest.isPrivate && props.hidden && !hiddenOptionActive()) {
      const design = await axiosHelper.retrieveCompleteDesign(props.idDesign);
      if (design) {
        console.log(design);
        setCurrentDesign(design);

        redirect(`/${ROUTEPATHS.CONTEST}/${design.contest.nameForURL}/gallery/${design?.nameForURL}`);
      }
    }
  };

  const goToEditDraft = async () => {
    if (currentUser) {
      if (currentUser.id === props.userId) {
        if (props.draft) {
          await axiosHelper.retrieveCompleteDesign(props.idDesign)?.then(async (design) => {
            console.log(design);
            setCurrentDesign(design);
            await axiosHelper.retrieveCompleteContest(design.contest.id).then((contest) => {
              setCurrentContest(contest.data);
              redirect(`/${ROUTEPATHS.COMMUNITY}/${currentUser?.username}/portfolio/${design?.nameForURL}`);
            });
          });
        }
      }
    }
  };

  useEffect(() => {
    // console.log(props.galleryImages);
    // console.log('entro in DesignRow');
    fillImagesPath();
  }, [props]);
  return (
    <div className={`${styles.contestRowWrapper} col-12 mb-5 position-relative   ms-2 px-2`}>
      <div className="row bg-light">
        <div className="col-12 px-1 col-lg-7 d-flex flex-nowrap px-0">
          {/* Cover Image */}
          <div
            className={`${
              props.image ? styles.contestRowImage : styles.contestRowImagePlaceholder
            } bg-light-gray-2 flex-shrink-0 position-relative ${
              (props.hidden &&
                hiddenOptionActive() &&
                (currentUser?.id !== props.userId ||
                  currentUser?.id === props.contest.client.id ||
                  currentUser?.userType === 'G')) ||
              props.contest.isPrivate
                ? ''
                : 'cursor-pointer'
            }`}
            onClick={() => goToDesignDetails()}
          >
            {props.isPrivateContest ? (
              props.userId === currentUser?.id ? (
                <img src={fullPathLogo(props.image)} />
              ) : (
                <img src={designDefaultImage} />
              )
            ) : props.image ? (
              <img
                src={
                  props.hidden && hiddenOptionActive()
                    ? currentUser?.id === props.userId ||
                      currentUser?.id === props.contest.client.id ||
                      currentUser?.userType === 'G'
                      ? fullPathLogoDesign(props.image)
                      : DesignDefaultImg
                    : fullPathLogoDesign(props.image)
                }
                alt="Design Cover"
              />
            ) : // <FontAwesomeIcon icon={faPencil as IconProp} className="color-light" />
            null}
            {props.hidden && hiddenOptionActive() && <ActionButton icon="private" position="bottom-right" color="" />}
            {/* <ActionButton icon="addFavorite" position="top-right" action={() => alert('Added as favorite')} /> */}
          </div>
          {/* Project Info */}
          <div className="flex-grow-1">
            <div className="row ps-0 flex-grow-1 h-100">
              {/* Project Title */}
              <div
                className="col-sm-7   col-lg-7 col-xl-8 d-flex flex-wrap  ps-5 h-100 mt-lg-0"
                style={{ height: '90px' }}
              >
                <div
                  className="d-flex align-items-center mt-2"
                  style={props.userId === currentUser?.id ? { cursor: 'pointer' } : {}}
                  onClick={() => goToDesignDetails()}
                >
                  <h4 className="text-xl d-flex fw-bold color-blue">
                    {props.hidden && hiddenOptionActive()
                      ? currentUser?.id === props.userId ||
                        (currentUser?.userType === 'C' && currentUser.id === props.idClient) ||
                        currentUser?.userType === 'G'
                        ? props.designName
                        : 'Hidden by author'
                      : props.hidden
                      ? 'Hidden by author'
                      : props.designName}
                    {/* {props.designName} */}
                  </h4>
                </div>
              </div>
              {/* Project Author */}
              {/* <div className="col-6  col-lg-4 d-flex flex-column justify-content-center ps-5">
                <div className="d-flex justify-content-start">
                  {/* <div className="d-flex flex-column justify-content-center">
                    <FontAwesomeIcon icon={faUser as IconProp} className=" text color-blue me-3" />
                  </div>
                  <div className="text-s text-start color-blue">{props.name}</div> .
                </div>
              </div> */}
              {/* Project Stats */}
              <div className="col-sm-5 h-100 col-lg-5 col-xl-4   d-flex flex-column justify-content-around px-2">
                <div className="d-flex justify-content-start ">
                  <div className="d-flex flex-column justify-content-center ">
                    <FontAwesomeIcon icon={faCalendar as IconProp} className=" text color-blue me-3" />
                  </div>

                  <div className="text-s text-start ">
                    Posted:
                    <span className={`${props.draft && 'color-danger'}`}>
                      {!props.draft ? ` ${designUploadDateString(props.uploadDate)}` : ' Not published yet'}
                    </span>
                  </div>
                </div>
                <div className="d-flex justify-content-start mb-1 " onClick={() => goToContestDetails()}>
                  <div className="d-flex flex-column justify-content-center ">
                    <FontAwesomeIcon icon={faTrophy as IconProp} className=" text color-blue me-3" />
                  </div>
                  <div className="text-s text-start color-blue cursor-pointer">
                    {props.contest ? props.contest.name : ''}
                  </div>
                </div>
                <div className="d-flex justify-content-start mb-2 ">
                  <div className="d-flex flex-column justify-content-center ">
                    <FontAwesomeIcon icon={faHeart as IconProp} className=" text color-blue me-3" />
                  </div>
                  <div className="text-s text-start color-blue">{props.votes} votes</div>
                </div>
              </div>
            </div>
            {props.draft && (
              <ActionButton
                icon="edit"
                position="top-right"
                label="edit draft"
                color="success"
                action={() => goToEditDraft()}
              />
            )}
          </div>
        </div>
        {/* Project Gallery */}
        <div
          className={`${styles.contestRowGallery} col-12 col-lg-5 d-flex justify-content-center justify-content-lg-end px-0 mt-3 mt-lg-0`}
        >
          {props.isPrivateContest ? (
            currentUser?.id === props.userId ? (
              <DesignRowGallery
                items={imagesPath}
                private={props.contest.isPrivate}
                idDesign={props.idDesign}
                contestNameForUrl={props.contest.nameForURL}
              />
            ) : (
              <div className="col-12 text-center align-self-center fw-bold ">
                <FontAwesomeIcon icon={faLock as IconProp} className=" text  me-3" /> PRIVATE GALLERY
              </div>
            )
          ) : props.hidden && hiddenOptionActive() ? (
            currentUser?.id === props.userId ||
            currentUser?.id === props.contest.client.id ||
            currentUser?.userType === 'G' ? (
              <DesignRowGallery
                items={imagesPath}
                private={props.contest.isPrivate}
                idDesign={props.idDesign}
                contestNameForUrl={props.contest.nameForURL}
              />
            ) : (
              ''
            )
          ) : (
            <DesignRowGallery
              items={imagesPath}
              private={props.contest.isPrivate}
              idDesign={props.idDesign}
              contestNameForUrl={props.contest.nameForURL}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DesignRow;
