import { BigImgProps, CreativesSupportProps, SlicedComponentProps } from '../../../global';
import { pathStrapiImage } from '../../../helpers/utils';
import HorizontalSection from '../../HorizontalSection/HorizontalSection';
import SplitSection from '../../SplitSection/SplitSection';

function BigImgComponent(props: BigImgProps) {
  return (
    <>
      {props.imagefloat == 'right' ? (
        <SplitSection
          key={props.id}
          bgColorLeft={`${props.bgcolor}`}
          itemLeft={
            <div className="d-flex h-100  flex-column align-items-start justify-content-center  ps-3 ">
              {props.title && (
                <div
                  className={` mb-3 align-self-start d-flex text-start ${
                    props.titlealign ? `text-${props.titlealign}` : `text-start`
                  }`}
                >
                  <span className={`bold-title color-${props.titlecolor} mt-5 mb-3`}>{props.title}</span>
                </div>
              )}
              {props.boldtext && (
                <span
                  className={`sub-title  mb-4  ${
                    props.boldtextalign ? `text-${props.boldtextalign}` : `text-start`
                  } color-${props.boldtextcolor}`}
                >
                  {props.boldtext}
                </span>
              )}
              {props.text && (
                <div
                  className={` mb-4 col-10 ${props.textalign ? `text-${props.textalign}` : `text-start`} color-${
                    props.textcolor
                  }`}
                >
                  <span className="text-16">{props.text}</span>
                </div>
              )}
              {props.buttontext && (
                <div className="  mb-5  ">
                  <a href={props.link} target={`${props.target ? props.target : '_self'}`}>
                    <button className={`btn-${props.btncolor} rounded   `}>{props.buttontext}</button>
                  </a>
                </div>
              )}
            </div>
          }
          itemRight={
            <div className="row   me-1 ps-3 ps-md-0  ">
              <div className="col-12 rounded ">
                <img
                  src={pathStrapiImage(props.image.data.attributes.url)}
                  className="img-fluid "
                  style={{ height: '450px', width: '585px' }}
                  alt="Placeholder"
                />
              </div>
            </div>
          }
        />
      ) : (
        <SplitSection
          key={props.id}
          bgColorLeft={`${props.bgcolor}`}
          itemLeft={
            <div className="row  ms-1 pe-md-0 ">
              <div className="col-12 rounded   ">
                <img
                  src={pathStrapiImage(props.image.data.attributes.url)}
                  className="img-fluid "
                  style={{ height: '450px', width: '585px' }}
                  alt="Placeholder"
                />
              </div>
            </div>
          }
          itemRight={
            <div className="h-100 ps-3 d-flex flex-column align-items-start justify-content-center ">
              {props.title && (
                <div
                  className={`mb-3 align-self-start  w-100 ${
                    props.titlealign ? `text-${props.titlealign}` : `text-start`
                  }`}
                >
                  <h1 className={`bold-title color-${props.titlecolor} mt-5 mb-3`}>{props.title}</h1>
                </div>
              )}
              {props.boldtext && (
                <div className={` w-75 ${props.boldtextalign ? `text-${props.boldtextalign}` : `text-start`} mb-4s`}>
                  <span className={`sub-title   color-${props.boldtextcolor}`}>{props.boldtext}</span>
                </div>
              )}
              {props.text && (
                <div
                  className={`col-10  mb-4  ${
                    props.textalign ? `text-${props.textalign} ` : `text-start`
                  } color-${props.textcolor}`}
                >
                  <span className="text-16 ">{props.text}</span>
                </div>
              )}

              {props.buttontext && (
                <div className="  mb-5   ">
                  <a href={props.link} target="_blank">
                    <button className={`btn-${props.btncolor} rounded `}>{props.buttontext}</button>
                  </a>
                </div>
              )}
            </div>
          }
        />
      )}
    </>
  );
}

export default BigImgComponent;
