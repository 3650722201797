import React from 'react';


export const NoContent: React.FC = () => {

  return (
    <div className="container " style={{ height: '450px' }}>
      <div className=" d-flex h-100 justify-content-center align-items-center ">
        <span className="sub-title"> No content for this page</span>
      </div>
    </div>
  );
};
