import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

//Header Data
import headerLogo from '../assets/desall_logo.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faTwitter, faFacebook, faGoogle, faLinkedin, faXTwitter } from '@fortawesome/free-brands-svg-icons';

//Assets
import creative from '../assets/creative_placeholder.png';
import footerImg from '../assets/footer_placeholder_transparent.png';

//Components
import AppHeader from '../components/AppHeader/AppHeader';
import AppFooter from '../components/AppFooter/AppFooter';
import axiosHelper from '../helpers/axiosHelper';
import DslStore, { setCommunityUser, setCurrentUser, setWelcomeMessage } from '../store/DslStore';
import { useStoreState } from 'pullstate';
import { getFooterMenu, getHeaderMenu } from '../store/Selectors';
import { FacebookTokenLogin, LoginData, LoginForm, TwitterTokenLogin, User } from '../global';
import {
  LoginSocialGoogle,
  LoginSocialFacebook,
  LoginSocialLinkedin,
  LoginSocialTwitter,
  IResolveParams,
} from 'reactjs-social-login';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../context/AuthContext';
import { ROUTEPATHS } from './routePaths';

const Login: React.FC = () => {
  const { login, ggLogin, fbLogin, twLogin, lkLogin } = useAuth();

  const footerMenu = useStoreState(DslStore, getFooterMenu);
  const headerMenu = useStoreState(DslStore, getHeaderMenu);
  const navigate = useNavigate();
  const [emailError, setEmailError] = useState(false);
  const [resetEmail, setResetEmail] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(true);
  const [error, setError] = useState('');

  const resetPassword = async () => {
    await axiosHelper
      .resetPassword(resetEmail)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!email || !password) {
      setError('Username (or email) and password are required');
      return;
    }
    setError('');
    login(email, password, rememberMe)
      .then((data) => {
        if (data) {
          setWelcomeMessage(true);
          navigate(`/${ROUTEPATHS.COMMUNITY}/${data.nameForURL}/profile-editable`);
        } else {
          setError('Unknown error');
        }
      })
      .catch((e) => {
        console.log(e);
        setError('Wrong username or password');
      });
  };

  const googleLogin = async (token: string, uid: string, email: string) => {
    setError('');
    ggLogin(email, token, uid)
      .then((data) => {
        if (data) {
          navigate(`/${ROUTEPATHS.COMMUNITY}/${data.nameForURL}/profile-editable`);
        } else {
          setError('Unknown error');
        }
      })
      .catch((e) => {
        console.log(e);
        setError('An error occurred');
      });
  };

  const facebookLogin = async (token: string, uid: string, email: string) => {
    setError('');
    fbLogin(email, token, uid)
      .then((data) => {
        if (data) {
          navigate(`/${ROUTEPATHS.COMMUNITY}/${data.nameForURL}/profile-editable`);
        } else {
          setError('Unknown error');
        }
      })
      .catch((e) => {
        console.log(e);
        setError('An error occurred');
      });
  };

  const twitterLogin = async (token: string, uid: string) => {
    setError('');
    twLogin(token, uid)
      .then((data) => {
        if (data) {
          navigate(`/${ROUTEPATHS.COMMUNITY}/${data.nameForURL}/profile-editable`);
        } else {
          setError('Unknown error');
        }
      })
      .catch((e) => {
        console.log(e);
        setError('An error occurred');
      });
  };

  const linkedinLogin = async (token: string, uid: string) => {
    setError('');
    lkLogin(token, uid)
      .then((data) => {
        if (data) {
          navigate(`/${ROUTEPATHS.COMMUNITY}/${data.nameForURL}/profile-editable`);
        } else {
          setError('Unknown error');
        }
      })
      .catch((e) => {
        console.log(e);
        setError('An error occurred');
      });
  };

  return (
    <>
      <AppHeader links={headerMenu} bgColor="#7997c5" logoImg={headerLogo} bgImage={creative} pageTitle="Login" />
      <main className="container-fluid py-5 bg-light-gray">
        <div className="row text-center justify-content-center">
          <div className="col-12 col-md-4 col-lg-3">
            <h1 className="sub-title color-dark mb-5">Connect with</h1>

            <LoginSocialFacebook
              appId={process.env.REACT_APP_FB_APP_ID || ''}
              onResolve={({ provider, data }: IResolveParams) => {
                console.log(data);
                console.log(data?.accessToken);
                facebookLogin(data?.accessToken, data?.userID, data?.email);
              }}
              onReject={(err) => {
                console.log(err);
              }}
            >
              <button className="btn-social__facebook full rounded mb-3">
                <div className="btn-icon">
                  <FontAwesomeIcon icon={faFacebook as IconProp} className="text color-light" />
                </div>
                facebook
              </button>
            </LoginSocialFacebook>
            <LoginSocialLinkedin
              client_id="78ic7x2w9maoqp"
              redirect_uri={process.env.REACT_APP_SOCIAL_LOGIN_ENDPOINT || ''}
              scope="profile email"
              client_secret="ioKQM8Z2vJzcnj7H"
              onLoginStart={() => console.log('start login')}
              onReject={(error: any) => {
                console.log(error);
              }}
              onResolve={({ provider, data }: IResolveParams) => {
                // setProvider(provider);
                // setProfile(data);
                console.log(data);
                console.log(data?.accessToken);
                linkedinLogin(data?.access_token, data?.id_token);
              }}
            >
              <button className="btn-social__linkedin full rounded mb-3">
                <div className="btn-icon">
                  <FontAwesomeIcon icon={faLinkedin as IconProp} className="text color-light" />
                </div>
                linkedin
              </button>
            </LoginSocialLinkedin>
            <LoginSocialGoogle
              client_id={'571721759610-a952df14qdtva6cbusfsv46sqqg5aja1.apps.googleusercontent.com'}
              scope="openid profile email"
              redirect_uri={process.env.REACT_APP_SOCIAL_LOGIN_ENDPOINT || ''}
              onReject={() => console.log('error')}
              onResolve={({ provider, data }: IResolveParams) => {
                console.log(provider);
                console.log(data);
                googleLogin(data?.access_token, data?.sub, data?.email);
              }}
            >
              <button className="btn-social__google full rounded mb-3">
                <div className="btn-icon">
                  <FontAwesomeIcon icon={faGoogle as IconProp} className="text color-light" />
                </div>
                google
              </button>
            </LoginSocialGoogle>
            <LoginSocialTwitter
              client_id="MWNPQmc5NHRQdE5sZ1RTczRyLTQ6MTpjaQ"
              redirect_uri={process.env.REACT_APP_SOCIAL_LOGIN_ENDPOINT || ''}
              // redirect_uri="http://localhost:3000/login"
              onReject={(error) => console.log(error)}
              onResolve={({ provider, data }: IResolveParams) => {
                console.log(provider);
                console.log(data);
                console.log(data?.access_token);
                console.log(data?.id);
                twitterLogin(data?.access_token, data?.id);
              }}
              onLoginStart={() => console.log('start twitter login')}
            >
              <button className="btn-social__twitter full rounded mb-3">
                <div className="btn-icon">
                  {/* <img src={xLogo} className='' style={{height:'15px', width:'15px'}}/> */}
                  <FontAwesomeIcon icon={faXTwitter as IconProp} className="text color-light" />
                </div>
                x-twitter
              </button>
            </LoginSocialTwitter>
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="row justify-content-center">
            <div className="col-12 col-md-4 col-lg-3">
              <div className="row">
                <div className="col-12 text-center">
                  <h1 className="sub-title color-dark my-5">or login</h1>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <input
                    id="email"
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="username or e-mail"
                    className="mb-5 text-center"
                  />
                </div>
              </div>
              <div className="row ">
                <div className="col-12">
                  <input
                    id="pwd"
                    value={password}
                    type="password"
                    placeholder="password"
                    // className={`${loginError ? 'mb-2' : 'mb-5'}  text-center`}
                    className="mb-5 text-center"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </div>

              <div className=" d-flex flex-row align-items-center justify-content-center mb-4 color-dark_red">
                {error ? (
                  <>
                    <FontAwesomeIcon icon={faCircleExclamation as IconProp} className="me-2" />
                    {error}
                  </>
                ) : (
                  <>&nbsp;</>
                )}
              </div>

              <div className="row ">
                <div className={`col-12 d-flex mb-5`}>
                  <div>
                    <input
                      type="checkbox"
                      checked={rememberMe}
                      onChange={(e) => setRememberMe(e.target.checked)}
                      id="checkbox-1"
                      className="flex-shrink-none"
                    />
                  </div>
                  <div>
                    <label htmlFor="checkbox-1" className="d-block text-start ps-4 py-2 text">
                      Remember me
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-12 col-md-3 col-lg-3 mb-5">
              <button type="submit" className="btn-dark-blue full rounded 3" disabled={emailError}>
                login
              </button>
            </div>
          </div>
        </form>
        <div className="row justify-content-center">
          <div className="col-12 col-md-4 col-lg-3">
            <div className="row">
              <div className="col-12 text-center">
                <h1 className="sub-title color-dark mt-7 mb-3">Not already member?</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12 col-md-3 col-lg-3 mb-5 mt-4">
            <button onClick={() => navigate('/signup')} className=" btn-dark-blue full rounded ">
              Signup
            </button>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12 col-md-4 col-lg-4">
            <div className="row">
              <div className="col-12  text-center">
                <h1 className="sub-title color-dark mt-7 mb-3">Forget your password?</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="row justify-content-center mt-4">
            <div className="col-12 col-md-4 col-lg-3">
              <input
                id="email"
                type="email"
                name="email"
                value={resetEmail ? resetEmail : ''}
                placeholder="email"
                className="mb-4 text-center"
                onChange={(e) => setResetEmail(e.target.value)}
              />
            </div>
          </div>
          <div className="col-12 col-md-3 col-lg-3 my-4">
            <button onClick={() => resetPassword()} className="btn-blue full rounded 3">
              Reset password
            </button>
          </div>
          <div className="row justify-content-center mt-3">
            <div className="col-12 col-md-4 col-lg-3 my-3 ">
              Please check your email to get the new password, if you don’t receive any email within a few hours, please
              write to <a href="info@desall.com">info@desall.com</a>
            </div>
          </div>
        </div>
      </main>

      <AppFooter links={footerMenu} bgColor="#304053" logoImg={headerLogo} bgImage={footerImg} />
    </>
  );
};

export default Login;
