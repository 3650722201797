import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styles from './SignupCard.module.scss';

interface ICardLarge {
  backGround?: string;
  topText?: string;
  bottomText?: string;
  link?: string;
  middleText?: string;
  showContent?: boolean;
  onClick?: () => void;
  selected: boolean;
}

const SignupCard: React.FC<ICardLarge> = (props) => {
  const handleOnClick = () => {
    props.onClick && props.onClick();
  };

  return (
    <>
      <div
        onClick={handleOnClick}
        className={`${styles.cardWrapper} ${props.selected && styles.selected}  ${
          props.showContent && styles.visibleContent
        }   w-100 border`}
      >
        {props.topText && (
          <div className={`color-blue sub-title   mt-md-5 w-100 d-flex justify-content-center align-items-center`}>
            <span>{props.topText}</span>
          </div>
        )}

        <img className="img-270  " src={props.backGround} alt="Placeholder" />

        <div className={`${styles.cardOverlay} d-flex flex-column`}>
          {props.topText && (
            <div
              className={`color-light sub-title w-100   d-flex  justify-content-center align-items-top mt-md-3 mt-lg-5 mt-sm-4`}
            >
              {props.topText}
            </div>
          )}
          {props.middleText && (
            <div
              className={`color-light mx-xl-5  mx-3  text-center mt-5 h-100 d-flex flex-column pb-xl-5 justify-content-center align-items-top `}
            >
              <span style={{ whiteSpace: 'pre-line' }} className="     ">
                {props.middleText}
              </span>
            </div>
          )}

          {props.bottomText && (
            <div className={`color-light sub-title  d-flex justify-content-center align-items-center`}>
              {props.bottomText}
            </div>
          )}
        </div>
        {props.selected ? (
          <div className={` text-center bg-light  ${styles.check}`} style={{ borderRadius: '50%' }}>
            <FontAwesomeIcon
              icon={faCircleCheck as IconProp}
              className="color-blue "
              // style={{ borderRadius: '50%' }}
              fontSize={'30px'}
            />
          </div>
        ) : (
          <>
            <div className="col-12 " style={{ height: '35px' }}></div>
          </>
        )}
      </div>
    </>
  );
};

export default SignupCard;
