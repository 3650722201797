import React, { useEffect, useState } from 'react';
import styles from './MiniTimeLine.module.scss';
import MemoProgressBar from './MemoProgressBar';
import { Contest } from '../../global';
import { contestPhaseExplanation, contestPhaseString, timelineInfo } from '../../helpers/contestUtilities';
interface TimelineWLFProps {
  contest: Contest;
}

const MiniTimeLineWLF: React.FC<TimelineWLFProps> = ({ contest }) => {
  const [stateProgress, setStateProgress] = useState(100);
  const [targetSeconds, setTargetSeconds] = useState<number | undefined>();
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const [uploadActive, setUploadActive] = useState(false);
  const [communityActive, setCommunityActive] = useState(false);
  const [clientActive, setClientActive] = useState(false);
  const [isClosed, setIsClosed] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const target = targetSeconds || 0;
      const difference = target - now.getTime() / 1000;
      if (difference < 0) {
        clearInterval(interval);
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
        return;
      }
      const days = Math.floor(difference / (60 * 60 * 24));
      const hours = Math.floor((difference / (60 * 60)) % 24);
      const minutes = Math.floor((difference / 60) % 60);
      const seconds = Math.floor(difference % 60);
      setTimeLeft({ days, hours, minutes, seconds });
    }, 1000);

    return () => clearInterval(interval);
  }, [targetSeconds]);

  useEffect(() => {
    if (contest) {
      // const status = computeContestStatus(contest);
      const result = timelineInfo(contest);
      console.log(result);
      setCommunityActive(result.communityVoteActive);
      setClientActive(result.clientVoteActive);
      setUploadActive(result.uploadActive);
      setIsClosed(result.closed);
      setTargetSeconds(result.targetSeconds);
      setStateProgress(result.stateProgress);
    }
  }, [contest]);

  return (
    <div className={`row mx-0 ${styles.timelineContainer} py-4`}>
      <div className="col-12 text-center pb-1 text-uppercase text-xl">{contestPhaseString(contest)}</div>
      <div className="col-12 text-center pb-3"> {contestPhaseExplanation(contest)}</div>
      <div className="col-12">
        <div className={`row ${styles.timelineWrapper} ${isClosed && styles.closed} d-flex`}>
          <div
            className={`col-12 px-0 ${styles.progressWrapper} ${uploadActive && styles.upload} ${communityActive && styles.community} ${clientActive && styles.client} ${
              isClosed && styles.closed
            }`}
          >
            <MemoProgressBar perc={stateProgress} />
          </div>
        </div>
      </div>
      <div className={`col-12 bg-color-light-gray color-dark d-flex justify-content-center align-items-center text-center py-4`}>
        <div className="px-3">
          <div className="text-xl fw-bold">{String(timeLeft.days).padStart(2, '0')}</div>
          <div>days</div>
        </div>
        <div className="d-none d-lg-flex px-3 px-md-0 px-lg-3 ps-5">
          <div className="px-2">
            <div className="text-xl fw-bold">{String(timeLeft.hours).padStart(2, '0')}</div>
            <div>hrs</div>
          </div>
          <div className="separator">:</div>
          <div className="px-2">
            <div className="text-xl fw-bold">{String(timeLeft.minutes).padStart(2, '0')}</div>
            <div>min</div>
          </div>
          <div className="separator">:</div>
          <div className="px-2">
            <div className="text-xl fw-bold">{String(timeLeft.seconds).padStart(2, '0')}</div>
            <div>sec</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MiniTimeLineWLF;
