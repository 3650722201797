import { INavLink } from "../global";

export const forCreativesPages: INavLink[] = [
  {
    text: "Panoramic",
    path: "/creatives",
    id: 0,
  },
  {
    text: "Additional info",
    path: "/creatives-additional-info",
    id: 1,
  },
  {
    text: "Dream Team",
    path: "/creatives-dream-team",
    id: 2,
  },
  {
    text: "Community",
    path: "/community",
    id: 3,
  },
  {
    text: "Contest",
    path: "/contest",
    id: 4,
  },    
];