import React, { useEffect, useState } from 'react';
import styles from './GalleryNavigation.module.scss';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import resetFilter from '../../../assets/Reset-Filter.svg';

import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import DslStore, { setContestGallery, setGalleryPageNumber } from '../../../store/DslStore';
import { GalleryInspirationSwitch } from '../../Reusable/LayoutSwitch';
import { getCoCreationContestGallery, getContestGallery, getContestGalleryCopy, getCurrentContest, getGalleryPageNumber } from '../../../store/Selectors';
import { useStoreState } from 'pullstate';
import { DropDownInspirationSort } from '../../Forms/DropDown/DropDownInspirations';

import { Contest, GalleryDesign } from '../../../global';

type IProjecNavigation = {
  designs: GalleryDesign[];
  contest: Contest;
  numberOfPages:number;
  onChangeLayout?: (val: number) => void;
};

type PaginationSwitchProps = {
  numberOfPages:number;
  onChangePage?: (val: number) => void;
};

export const PaginationSwitch: React.FC<PaginationSwitchProps> = ({ numberOfPages, onChangePage = () => {} }) => {
  const pageNumber = useStoreState(DslStore, getGalleryPageNumber);
  const designs = useStoreState(DslStore, getContestGallery);
  const cocreationDesigns = useStoreState(DslStore, getCoCreationContestGallery);

  const gallery: number = designs.length > 0 ? designs.length : cocreationDesigns.length;

  //   const contests = useStoreState(DslStore, getInspirations);
  // const gallery = 4;

  const [array, setArray] = useState<Array<number>>([1, 2, 3, 4, 5]);

  const arrayCorrectPage = () => {
    let arrayCorrect: Array<number> = [];
    if (numberOfPages < 5) {
      for (let i = 1; i <= numberOfPages; i++) {
        arrayCorrect[i] = i;
        // console.log(arrayCorrect);
      }
      setArray(arrayCorrect);
    }
  };

  useEffect(() => {
    setGalleryPageNumber(1);
  }, []);

  useEffect(() => {
    console.log('cambiato numero page');
    if (pageNumber >= 3 && pageNumber <= numberOfPages - 2) {
      setArray([pageNumber - 2, pageNumber - 1, pageNumber, pageNumber + 1, pageNumber + 2]);
    }
  }, [numberOfPages, pageNumber]);

  useEffect(() => {
    arrayCorrectPage();
    // fillContests(pageNumber -1);
  }, [gallery]);

  const handleChange = (value: number) => {
    console.log(value);
    if (value > 0 && value <= numberOfPages) {
      onChangePage(value);
      if (pageNumber >= 3 && pageNumber <= numberOfPages - 2) {
        setArray([pageNumber - 2, pageNumber - 1, pageNumber, pageNumber + 1, pageNumber + 2]);
      }
    } else {
      console.log('0 o max++');
    }
  };

  return (
    <div className={`${styles.paginationSwitch} bg-light py-2 px-2 `}>
      <button className="btn-icon" onClick={() => handleChange(pageNumber - 1)}>
        <FontAwesomeIcon icon={faAngleLeft as IconProp} className="btn-icon active " />
      </button>

      {/* <button className={'btn-icon active'} onClick={() => handleChange(pageNumber - 1)}>
        <strong> &lt; </strong>
      </button> */}

      {array.map((index: number) => (
        <button key={index} className={pageNumber === index ? 'btn-icon active' : 'btn-icon'} onClick={() => handleChange(index)}>
          <small>{index}</small>
        </button>
      ))}

      {/* <button className={'btn-icon active'} onClick={() => handleChange(pageNumber + 1)}>
        <strong> &gt; </strong>
      </button> */}

      <button className="btn-icon" onClick={() => handleChange(pageNumber + 1)}>
        <FontAwesomeIcon icon={faAngleRight as IconProp} className="btn-icon active " />
      </button>

      {/* 
      <button className={"btn-icon active"} 
      //  onClick={() => handleClick(1)}
      > <b> &lt; </b> </button>
       <button className={pageNumber == 1 ? "btn-icon active" : "btn-icon"} 
      //  onClick={() => handleClick(1)}
      >
        1
      </button>
      <button className={pageNumber == 2 ? "btn-icon active" : "btn-icon"} 
      // onClick={() => handleClick(2)}
      >
        2
      </button>
      <button className={pageNumber == 3 ? "btn-icon active" : "btn-icon"} 
      // onClick={() => handleClick(3)}
      >
        3
      </button>
      <button className={pageNumber == 4 ? "btn-icon active" : "btn-icon"} 
      // onClick={() => handleClick(4)}
      >
        4
      </button>
      <button className={pageNumber == 5 ? "btn-icon active" : "btn-icon"} 
      // onClick={() => handleClick(5)}
      >
        5
      </button>  */}

      {/* <button className="btn-icon">
         <FontAwesomeIcon icon={faAngleRight as IconProp} className="text" />
       </button> */}

      {/* <Pagination count={numberOfPages} page={pageNumber} color='primary'  hideNextButton={numberOfPages > 1 ? false : true} hidePrevButton={numberOfPages > 1 ? false : true} size="small" onChange={handleChange} /> */}
    </div>
  );
};

let filterCategories = [
  {
    label: 'All',
    value: '0',
  },
  {
    label: 'Product Design',
    sort: 'Product Design',
    value: '1',
  },
  {
    label: 'New Idea',
    sort: 'New Idea',
    value: '2',
  },
  {
    label: 'Interior Design',
    sort: 'Interior design',
    value: '3',
  },
  {
    label: 'Packaging',
    sort: 'Packaging',
    value: '3',
  },
  {
    label: 'Naming',
    sort: 'Naming',
    value: '3',
  },
  {
    label: 'Co-Creation',
    sort: 'Co-creation',
    value: '3',
  },
  {
    label: 'Craft Design',
    sort: 'Craft design',
    value: '3',
  },
  {
    label: 'Link to Other',
    sort: 'Link to other',
    value: '3',
  },
];

let filterSort = [
  {
    label: 'Title',
    sort: 'designName',
    value: '1',
  },
  {
    label: 'Votes',
    sort: 'votes',
    value: '2',
  },

  {
    label: 'Date',
    sort: 'uploadDate',
    value: '3',
  },
];

const GalleryNavigationWLF: React.FC<IProjecNavigation> = (props) => {
  const designsCopy = useStoreState(DslStore, getContestGalleryCopy);
  const cocreationDesigns = useStoreState(DslStore, getCoCreationContestGallery);
  const [descs, setDescs] = useState(false);

  const [reload, setReload] = useState(false);
  const [sort, setSort] = useState('');

  // useEffect(() => {
  //   console.log(sort);
  //   console.log(descs);
  // }, [sort]);

  // useEffect(() => {
  //   console.log(sort);
  //   console.log(descs);
  // }, []);

  const sortDesigns = () => {
    if (sort) {
      if (sort === 'uploadDate') {
        if (descs) {
          let sorted: GalleryDesign[] = [...props.designs].sort((a, b) => {
            if (a.uploadDate && b.uploadDate) {
              return a.uploadDate.localeCompare(b.uploadDate);
            }
            return 0;
          });
          setContestGallery(sorted);
        } else {
          let sorted: GalleryDesign[] = [...props.designs].sort((a, b) => {
            if (a.uploadDate && b.uploadDate) {
              return b.uploadDate.localeCompare(a.uploadDate);
            }
            return 0;
          });
          setContestGallery(sorted);
        }
      } else if (sort === 'votes') {
        let sorted: GalleryDesign[] = [...props.designs].sort((a, b) => (a.votesCount && b.votesCount && a.votesCount < b.votesCount ? 1 : a.votesCount > b.votesCount ? -1 : 0));
        setContestGallery(sorted);
      } else if (sort === 'designName') {
        if (descs) {
          let sorted: GalleryDesign[] = [...props.designs].sort((a, b) => (b.name.toLowerCase() > a.name.toLowerCase() ? 1 : a.name.toLowerCase() > b.name.toLowerCase() ? -1 : 0));
          setContestGallery(sorted);
        } else {
          let sorted: GalleryDesign[] = [...props.designs].sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0));
          setContestGallery(sorted);
        }
      }
    }
  };

  let designPerPage = 20;

  const pageNumberLimitCoCreation = Math.ceil(cocreationDesigns.length / designPerPage);

  useEffect(() => {
    console.log(pageNumberLimitCoCreation);
  });

  useEffect(() => {
    sortDesigns();
  }, [descs, sort]);

  return (
    <div className="row py-4 mt-4">
      <div className="col-12 col-md-12 col-lg-8 d-flex flex-column px-3 flex-lg-row justify-content-between mb-4 mb-md-0">
        {/* <div className="me-0 me-md-4 w-100 mb-2 mb-lg-0">
          <DropDownInspirationCategory label="Category" options={filterCategories} thin />
        </div> */}
        <div className=" w-100 d-flex flex-row align-items-center me-3 mb-sm-3 mb-lg-0">
          <div className="me-5">
            <DropDownInspirationSort
              label="Sort by"
              reset={reload}
              onSelect={(option) => option.sort && setSort(option.sort)}
              descs={(descs) => setDescs(descs)}
              options={filterSort}
              thin
            />
          </div>
          <div
            // className="btn-blue rounded"
            className=" d-flex flex-row align-items-center justify-content-center btn-reset-blue   "
            onClick={() => (setReload(!reload), setContestGallery(designsCopy))}
            style={{ width: '3rem', borderRadius: '8px' }}
          >
            <img src={resetFilter} style={{ height: '25px' }} />
          </div>
        </div>
        {/* <div className=" d-flex flex-row align-items-center justify-content-center h-100 border"> */}

        {/* </div> */}
      </div>
      <div className={`col-12 col-md-12 col-lg-4 d-flex px-1 justify-content-sm-start justify-content-lg-end `}>
        <div className="ms-sm-2 ps-sm-1 me-lg-5 me-sm-5  ">
          {props.contest?.contestType !== 'Co-creation' && props.numberOfPages > 0 ?<><PaginationSwitch numberOfPages={props.numberOfPages} onChangePage={setGalleryPageNumber} /></> : ''}
          {props.contest?.contestType === 'Co-creation' && pageNumberLimitCoCreation > 0 ? <PaginationSwitch numberOfPages={props.numberOfPages} onChangePage={setGalleryPageNumber} /> : ''}
        </div>
        <div>
          {props.contest?.contestType !== 'Co-creation' && props.numberOfPages > 0 ? <><GalleryInspirationSwitch onChangeLayout={props.onChangeLayout} /> </>: ''}
          {props.contest?.contestType === 'Co-creation' && pageNumberLimitCoCreation > 0 ? <GalleryInspirationSwitch onChangeLayout={props.onChangeLayout} /> : ''}
        </div>
      </div>
    </div>
  );
};

export default GalleryNavigationWLF;
