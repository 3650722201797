import { useStoreState } from 'pullstate';
import { useEffect, useRef, useState } from 'react';
import AppFooter from '../components/AppFooter/AppFooter';

import DslStore, { setStrapiPage } from '../store/DslStore';
import { getFooterMenu, getHeaderMenu } from '../store/Selectors';
import footerImg from '../assets/footer_placeholder_transparent.png';
import headerLogo from '../assets/desall_logo.png';
import axiosHelper from '../helpers/axiosHelper';
import { ContactUsMessage, StrapiPageData } from '../global';

import { DropDownContactUs } from '../components/Forms/DropDown/DropDownContactUs';
import AppHeader from '../components/AppHeader/AppHeader';
import { useForm } from 'react-hook-form';

interface IDropDownItem {
  label: string;
  value: string;
}

const typologyDropdown: IDropDownItem[] = [
  {
    label: 'Choose one',
    value: '0',
  },
  {
    label: 'Help',
    value: '1',
  },
  {
    label: 'General info',
    value: '2',
  },
  {
    label: 'Contest info',
    value: '3',
  },
  {
    label: 'Feedback',
    value: '4',
  },
  {
    label: 'Other',
    value: '5',
  },
];

const ContactUs = () => {
  const footerMenu = useStoreState(DslStore, getFooterMenu);
  const headerMenu = useStoreState(DslStore, getHeaderMenu);
  const [privacy, setPrivacy] = useState(false);
  const typology = useRef('');
  const { register, handleSubmit } = useForm<ContactUsMessage>();

  const [page, setPage] = useState<StrapiPageData>();

  const fetchPage = async () => {
    await axiosHelper.fetchStrapiPage('/')?.then((response) => {
      console.log(response.data);
      setPage(response.data[0]);
      setStrapiPage(response.data[0]);
    });
  };

  const onSubmit = (data: ContactUsMessage) => {
    data.type = typology.current;
    console.log(typology.current);
    if (data.type !== 'Choose one' && data.type !== '' && privacy) {
      axiosHelper.sendContactUsMessage(data).then((response) => console.log(response));
    }
  };

  useEffect(() => {
    console.log(process.env.REACT_APP_API_ENDPOINT);
    fetchPage();
  }, []);

  const handleDropDown = (e: any) => {
    console.log(e);
    typology.current = e.label;
  };

  useEffect(() => {
    console.log(typology.current);
  }, [typology]);

  return (
    <>
      <AppHeader
        links={headerMenu}
        bgColor={page?.attributes.headercolor ? page?.attributes.headercolor : '#7997c5'}
        logoImg={headerLogo}
        pageTitle="Contact Us"
      />
      <div className="bg-light-gray h-100 d-flex">
        <main className="container mb-5 overflow-hidden px-4 px-sm-0 ">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row mt-7 mb-7 ">
              <p className="bold-title"> Contact us </p>
              <span className="text-16">
                Fill out the form or send and email to <a href="mailto:info@desall.com">info@desall.com</a>
              </span>
            </div>
            <div className="row col-12 mb-5 justify-content-center  align-items-center ">
              <div className=" col-12 col-md-2">
                <span className="text-16 fw-bold">Typology</span>
              </div>
              <div className=" col-12 col-md-7">
                <DropDownContactUs options={typologyDropdown} onSelect={handleDropDown} />
              </div>
            </div>
            <div className="row col-12 mb-5 justify-content-center  align-items-center">
              <div className=" col-12 col-md-2">
                <span className="text-16 fw-bold">Email</span>
              </div>
              <div className=" col-12 col-md-7">
                <input
                  type="email"
                  {...register('email', { required: true })}
                  className=" d-flex align-items-center"
                  style={{ height: '40px' }}
                />
              </div>
            </div>
            <div className="row col-12 mb-5 justify-content-center  align-items-center ">
              <div className=" col-12 col-md-2">
                <span className="text-16 fw-bold">Subject</span>
              </div>
              <div className=" col-12 col-md-7">
                <input
                  type="text"
                  {...register('subject', { required: true })}
                  className=" d-flex align-items-center"
                  style={{ height: '40px' }}
                />
              </div>
            </div>
            <div className="row col-12 mb-5 justify-content-center  ">
              <div className=" col-12 col-md-2 mt-2">
                <span className="text-16 fw-bold">Message</span>
              </div>
              <div className=" col-12 col-md-7">
                <textarea className="" {...register('textDescription', { required: true })} />
              </div>
            </div>

            <div className="row col-12 mb-5 justify-content-center   ">
              <div className=" col-12 col-md-2 mt-2"></div>
              <div className=" col-12 col-md-7 align-items-center d-flex ">
                <input type="checkbox" className="" checked={privacy} onChange={() => setPrivacy(!privacy)} />
                <span className="ms-3 text-xl"> I agree to the Privacy Policy.</span>
              </div>
            </div>
            <div className="row col-12 mb-5 justify-content-center   ">
              <div className=" col-12 col-md-2 mt-2"></div>
              <div className=" col-12 col-md-7 align-items-center d-flex ">
                <span className=" text-xl">
                  Privacy Policy Art. 13 Regulation (EU) 2016/679: your personal data will be processed in print and in
                  electronic format, exclusively for the purpose of providing you with the requested information. Your
                  data shall not be communicated or disseminated. At any time you may exercise your rights foreseen in
                  Articles 15, 16, 17, 18, 20, 21 of the Regulation. To read the full text of the “Privacy Policy
                  Contact form”, please <a href="">click here.</a>
                </span>
              </div>
            </div>
            <div className="row col-12 mb-5 justify-content-center   ">
              <div className=" col-12 col-md-9 align-items-center  d-flex  ">
                <div className="col-md-3 "></div>
                <div className="col-12 col-md-9  justify-content-center d-flex">
                  <button className="btn-success  rounded col-3 "> send</button>
                </div>
              </div>
            </div>
          </form>
        </main>
      </div>

      <AppFooter links={footerMenu} bgColor="#304053" logoImg={headerLogo} bgImage={footerImg} />
    </>
  );
};

export default ContactUs;
